import React, {useEffect, useState} from "react";
import {Container, Row} from "react-bootstrap";
import BreadCrumb from "../components/BreadCrumb";
import {useParams} from "react-router-dom";
import Slider from "../components/Slider";
import moment from "moment";
import {getBlogBySlug} from "../Api-Calls/blog";
import HTMLRenderer from "react-html-renderer";
import banner from "../assets/img/blog-banner.jpg";
import mobilebanner from "../assets/img/blog-banner-mobile.jpg";
import HelmetSEO from "../components/HelmetSEO";

const BlogDetails = () => {
  const {slug} = useParams();
  const [blog, setBlog] = useState([]);

  const getBlogData = async () => {
    try {
      const res = await getBlogBySlug(slug);
      if (res?.status == 200) {
        setBlog(res?.data?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getBlogData();
  }, []);
  return (
    <>
      <HelmetSEO title={blog?.title} description={blog?.meta_description} />
      <BreadCrumb
        heading="BLOG DETAILS"
        badge="Blog Detail"
        webImage={banner}
        mobileImage={mobilebanner}
      />
      <section className="container">
        <div className="section whyBuildingBridges">
          <Container>
            <Row className="g-4">
              {blog && (
                <div class="container p-0 m-0">
                  <div class="col-md-12">
                    <h2 class="text-center">{blog?.title}</h2>
                  </div>
                  <div className="col-lg-12 card p-4  border-0">
                    <Slider
                      carouselinner1="carouse-linner1"
                      dynamicClass="fundRaisingImgBackground2 w-100"
                      item={blog.attachments}
                      alt={blog?.meta_image_description}
                    />
                  </div>
                  <p className="text-right last-date">
                    <small className="text-muted">
                      Published on {moment(blog?.created).format("L")}
                    </small>
                  </p>
                  <div class="card  p-5 mt-3 w-100 border-0">
                    <div class="">
                      <HTMLRenderer html={blog?.description} />
                    </div>
                  </div>
                </div>
              )}
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
};

export default BlogDetails;
