import { Button, Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import "../assets/css/TeamBuilding.css";
import { Card, Modal, ProgressBar } from "react-bootstrap";
import logo from "../assets/img/logo.png";
import bg from "../assets/img/bg.png"
import whyBuildingBridge1 from "../assets/img/WhyIcons/1.svg";
import whyBuildingBridge2 from "../assets/img/WhyIcons/2.svg";
import whyBuildingBridge3 from "../assets/img/WhyIcons/3.svg";


const TeamBuilding = () => {

  return (
    <>
      <div className="main-div">

        <section className="banner-section">
          <img className="banner-img" src={bg} />
          <div className="shadow-box">
            <img className="form-img" src={logo} />
            <div className="title-t">Work With BuildingBridges</div>
            <div className="subtitle"> Everyday, we strive to make this world a better place.
              Our commitment towards bringing a change drives us and if you share our passion, come join us.</div>
            <button className="btn__primary shadow mt-4 mx-auto px-5 buttonPrimary">
              EXPLORE OPPORTUNITIES
            </button>
          </div>
        </section>

        <section className="container mt-5 pt-5">
          <div className="sections first-section">
            <div className="title-t">What BuildingBridges Is Made Of</div>
            <div className="subtitle-t d-none d-lg-block d-md-block">Eight years ago, we set out with the goal of making healthcare more accessible to everyone.
              So, with a team of <br /> 200+ people, we've raised for over 2.8 lakh+ campaigns to ensure that everyone who needs help gets it.
              Our<br /> young, dynamic,and committed workforce is charged with the mission of harnessing the power of technology<br /> to make a large-scale social impact.</div>
            <img src="https://ketto.gumlet.io/assets/images/team/dna_image.svg?w=1140&dpr=1.0" className="dna-img" loading="lazy"/>
          </div>
          {/* <div className="sections mt-5"> */}
          {/* <div className="title-pad-40">
              More Reasons To Love BuildingBridges
              </div> */}
          <div className="section whyBuildingBridges">
            <Container >
              <div className="text-center mb-5">
                <h2>  More Reasons To Love BuildingBridges</h2>
              </div>
              <Row lg={3} md={3} sm={2} xs={2} className="g-4">
                <Col>
                  <div className="text-center">
                    <img src={whyBuildingBridge1} loading="lazy"/>
                    <div className="hrBuildingBridge my-3"></div>
                    <h5>Hand-in-Hand With Technology</h5>
                    <p className="px-3">Technology and saving lives go hand-in-hand at Ketto! We continue to implement new and improved technology with the goal of helping as many people in need as we can.</p>
                  </div>
                </Col>
                <Col>
                  <div className="text-center">
                    <img src={whyBuildingBridge2} loading="lazy"/>
                    <div className="hrBuildingBridge my-3"></div>
                    <h5>People-Centric Culture</h5>
                    <p className="px-3">We make sure that people are always at the forefront. In addition to benefits, perks, incentives, and acknowledgement, we take a compassionate approach to leadership.</p>
                  </div>
                </Col>
                <Col>
                  <div className="text-center">
                    <img src={whyBuildingBridge3} loading="lazy"/>
                    <div className="hrBuildingBridge my-3"></div>
                    <h5>Learn and Shine On The Go</h5>
                    <p className="px-3">With exceptional mentorship, you can expect to develop professionally and personally. Our priority is to guide and support our team because we’re invested in their growth.</p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </section>

        <section className="container">
          <div className="life-at-bb">
            <h3 className="title-t">Life at BuildingBridges</h3>

          </div>

        </section>

        <section className="join-us p-4">
          <h2 className="title-t">Come, join our team if the idea of making a difference makes you tick!</h2>
          <button className="btn__primary shadow mt-4 mx-auto px-5 buttonPrimary mb-3">
            View all openings
          </button>

        </section>

        <section className="know-more-bg">
          <img className="banner-img-bg" src={bg} />
          <div className="send-card">
            {/* <img className="form-img" src={logo} /> */}
            <p className="wait">Wait!</p>
            <div className="title-t">Can’t find a relevant role?</div>
            <div className="hrBuildingBridge my-3"></div>
            <div className="subtitle"> We are always looking for talent, send in your resume and we will connect with you.</div>
            <button className="btn__primary shadow mt-4 mx-auto px-5 buttonPrimary">
              Submit Resume
            </button>
          </div>
        </section>

      </div>
      {/* </div> */}
    </>
  );
}

export default TeamBuilding;
