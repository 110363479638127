import aboard from "../assets/img/staticIcons/aboardIcon.png";
import country from "../assets/img/staticIcons/countryIcon.png";
import online from "../assets/img/staticIcons/onlineIcon.png";
import webBanner from "../assets/img/banner/volunteerWeb.jpg";
import mobileBanner from "../assets/img/banner/volunteerMobile.jpg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Gallery from "../components/Gallery";
import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import BreadCrumb from "../components/BreadCrumb";
import { useForm } from "react-hook-form";
import TextInput from "../components/TextInput";
import { addVolunteer } from "../Api-Calls/contactUs";
import { SwalMessage } from "../components/sweetAlertMessage";
import Select from "react-select";
import { countries } from "../data/country";
import InputMask from "react-input-mask";
import HelmetSEO from "../components/HelmetSEO";

const VolunteerForRamdan = () => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    setFocus,
    formState: { errors },
  } = useForm();
  const [loader, setLoader] = useState(false);
  const [maskNo, setMaskNo] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [countryState, setCountryState] = useState({
    country: "",
    countryCode: "",
  });
  const [stateData, setState] = useState({
    phoneNo: "",
    zipcode: "",
    errors: {
      cell: "",
      zipcode: "",
      country: "",
    },
  });
  useEffect(() => {
    if (window.innerWidth <= 600) {
      const fieldsWithErrors = Object.keys(errors).filter(
        (field) => !!errors[field]
      );
      if (fieldsWithErrors.length > 0) {
        setFocus(fieldsWithErrors[0]);
        document.documentElement.scrollTo({
          top: window.innerWidth <= 600 ? 1600 : 0,
          left: 0,
          behavior: "smooth",
        });
      }
    }
  }, [
    errors.fullName,
    errors.email,
    errors.phoneNo,
    errors.city,
    errors.volunteerType,
    setFocus,
  ]);

  const onSubmit = async (data) => {
    try {
      if (window.innerWidth <= 600) {
        if (countryState.country === undefined || countryState.country === "") {
          document.documentElement.scrollTo({
            top: window.innerWidth <= 600 ? 1600 : 0,
            left: 0,
            behavior: "smooth",
          });
        }
      }

      let detail = {
        ...data,
        phoneNo: `${countryState.countryCode} ${data.phoneNo}`,
        country: countryState?.country.trim(),
      };
      if (countryState.country === "") {
        setState({ ...stateData, errors: { ...errors, country: false } });
        return;
      }
      setLoader(true);
      const res = await addVolunteer(detail);
      if (res?.status == 201) {
        SwalMessage(res?.data?.status, res?.data?.message, "success");
        setSelectedCountry(null);
        reset({
          fullName: "",
          email: "",
          phoneNo: "",
          city: "",
          country: "",
          volunteerType: "",
        });
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const onCountryChange = (e) => {
    setCountryState({ country: `${e.label} `, countryCode: `+${e.code}` });
    setMaskNo(e.mask);
    setSelectedCountry(e);
  };
  const customStyles = {
    option: (base, state) => ({
      ...base,
      color: "#1e2022",
      backgroundColor: "white",
      padding: ".5rem 3rem .5rem .5rem",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "rgba(189,197,209,.3)", // Change this to your desired border color on hover
      },
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
    control: (baseStyles, stateData) => ({
      ...baseStyles,
      paddingLeft: "10px",
      paddingRight: "10px",
      paddingTop: "8px",
      paddingBottom: "8px",
      backgroundColor: "#fff",
    }),
  };
  return (
    <>
      <BreadCrumb
        heading="Volunteer for Ramadan "
        badge="Volunteer for Ramadan"
        description="This Ramadan, enhance your blessings by joining us as a volunteer. Register deserving individuals on our platform, and explore trending fundraisers to get donors connected"
        webImage={webBanner}
        mobileImage={mobileBanner}
      />
      <section className="section">
        <Container className="">
          <Row>
            <Col xl="10" className="mx-auto text-center px-3">
              <h2>Become A Volunteer Now!</h2>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="volunteer__page">
        <Container className="text-center">
          <div className="mx-auto volunteer-section">
            <div className="section__one one">
              <div className="">
                <h4>Register the needy</h4>
                <img className="mb-4" src={aboard} />
              </div>
              <span className="bocomeVolunteer">
                Help any needy person you know get them registered on the
                Building Bridges platform to create their fundraiser.
              </span>
            </div>
            <div className="section__one two">
              <div className="">
                <h4>Connect Donors</h4>
                <img className="mb-4" src={country} />
              </div>
              <sapn className="bocomeVolunteer">
                Help us match the cases with any potential generous donor who
                would like to assist them during this holy month.
              </sapn>
            </div>
            <div className="section__one three">
              <div className="">
                <h4>Join our Family</h4>
                <img className="mb-4" src={online} />
              </div>
              <span className="bocomeVolunteer">
                Are you passionate for contributing to social cahnge? If yes,
                then join us to become a part of the Building Bridges family.
              </span>
            </div>
          </div>
        </Container>
      </section>

      <section className="section  section--gray volunteer__form">
        <Container>
          <div className="text-center mb-5 ">
            <strong>
              “The meaning of life is to find your gift. The purpose of life is
              to give it away”{" "}
            </strong>
            <p className="col-md-8 mx-auto">
              Get involved with the community that's building bridges and you
              can immediately start making a difference in the lives of other
              people.
            </p>
          </div>
          <Col xl="8" className="mx-auto">
            <Form className="mt-5 container" onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col className="mb-3" lg={6}>
                  <Form.Label className="mb-1">
                    <b>Name</b> <span className="text-danger">*</span>
                  </Form.Label>
                  <div className="mb-1">
                    <TextInput
                      name={"fullName"}
                      className="form-control"
                      rules={{
                        required: "Name is required.",
                      }}
                      control={control}
                      placeholder="Name"
                    />
                  </div>
                </Col>
                <Col className="mb-3" lg={6}>
                  <Form.Label className="mb-1">
                    <b>Email Address</b> <span className="text-danger">*</span>
                  </Form.Label>
                  <div className="mb-1">
                    <TextInput
                      name={"email"}
                      className="form-control"
                      rules={{
                        required: "Email is required.",
                        pattern: {
                          value:
                            /^\s*(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/,
                          message: "Invalid  email address.",
                        },
                      }}
                      control={control}
                      placeholder="Email Address"
                    />
                  </div>
                </Col>
                <Col className="mb-3" lg={6}>
                  <Form.Label className="mb-1">
                    <b>Country</b>
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <div className="mb-1">
                    <Select
                      onChange={(e) => onCountryChange(e)}
                      styles={customStyles}
                      className="form-contro"
                      options={countries}
                      value={selectedCountry}
                    />
                    {stateData?.errors?.country === false && (
                      <p className="text-danger  my-2 ms-2 ">
                        Country is required.
                      </p>
                    )}
                  </div>
                </Col>
                <Col className="mb-3" lg={2} md={3} sm={4}>
                  <Form.Label className="mb-1">
                    <b>Code</b>
                  </Form.Label>
                  <Form.Label className="mb-1"></Form.Label>
                  <div className="mb-1">
                    <input
                      readOnly
                      control={control}
                      autoComplete="off"
                      value={
                        countryState.countryCode !== "+undefined"
                          ? countryState.countryCode
                          : ""
                      }
                      name="PhoneCode"
                      placeholder="+92"
                      className="form-control dir-ltr customInputLogin"
                    />
                  </div>
                </Col>
                <Col className="mb-3" lg={4} md={9} sm={8}>
                  <Form.Label className="mb-1">
                    <b>Phone Number</b>
                  </Form.Label>
                  <div className="mb-1">
                    <InputMask
                      inputMode="numeric"
                      {...register("phoneNo", {
                        required: "Phone Number is required",
                      })}
                      autoComplete="off"
                      mask={maskNo ? maskNo : "99999-99999"}
                      maskChar={null}
                      name="phoneNo"
                      placeholder="Phone Number"
                      className="form-control dir-ltr customInputLogin"
                    />
                    <p className="text-danger  my-2 ms-2 ">
                      {errors?.phoneNo?.message}
                    </p>
                  </div>
                </Col>
                <Col className="mb-3" lg={6}>
                  <Form.Label className="mb-1">
                    <b>City Name</b> <span className="text-danger">*</span>
                  </Form.Label>
                  <div className="mb-1">
                    <TextInput
                      name={"city"}
                      className="form-control"
                      rules={{
                        required: "City Name is required.",
                      }}
                      control={control}
                      placeholder="City Name"
                    />
                  </div>
                </Col>
                <Col className="mb-3" lg={6}>
                  <Form.Label className="mb-1">
                    <b>Volunteer Type</b> <span className="text-danger">*</span>
                  </Form.Label>
                  <select
                    className="form-control form-select"
                    {...register("volunteerType", {
                      required: "Select Volunteer type.",
                    })}>
                    <option value="">Choose volunteer type</option>
                    <option value="Register the needy">
                      Register the needy
                    </option>
                    <option value="Connect Donors">Connect Donors</option>
                    <option value="Join our Family">Join our Family</option>
                  </select>

                  <p className="text-danger  my-2 ms-2 ">
                    {errors?.volunteerType?.message}
                  </p>
                </Col>
                <div className="text-center mt-4">
                  {loader ? (
                    <button className="btn__primary mx-auto shadow buttonPrimary">
                      <i
                        className="fa mx-3 fa-circle-o-notch fa-spin"
                        style={{ fontSize: "24px" }}></i>
                    </button>
                  ) : (
                    <button className="btn__primary mx-auto shadow buttonPrimary">
                      Become a Volunteer
                    </button>
                  )}
                </div>
              </Row>
            </Form>
          </Col>
        </Container>
      </section>

      <ToastContainer />
      <Gallery />
    </>
  );
};

export default VolunteerForRamdan;
