import { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";

const Jumbotran = (props) => {
  const { auth: { user } } = useSelector(store => store)

  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  return (
    <div className="panel-header bg-primary-gradient">
      <div className="page-inner py-5">
        <button
          className="navbar-toggler sidenav-toggler d-flex"
          type="button"
          data-toggle="collapse"
          data-target="collapse"
          aria-expanded="false"
          aria-label="Toggle navigation">
          <span className="navbar-toggler-icon">
          </span>
        </button>
        <div className="d-flex align-items-lg-left align-items-center align-items-md-center flex-column flex-md-row justify-content-between">
          <div>
            <h2 className="text-center text-md-start text-white pb-2 fw-bold">
              {props?.page}
            </h2>
            <h5
              className="text-white op-7 mb-2 dashboard-text-center"
              style={{ color: "#fff !important", opacity: "1" }}>
              <Link
                className="text-decoration-none text-white "
                to={"/dashboard/user-dashboard"}>
                {" "}
                Dashboard{" "}
              </Link>{" "}
              {props?.desc && <i className="fa fa-angle-right "></i>}{" "}
              <span style={{ opacity: ".57" }}>{props?.desc}</span>
            </h5>
          </div>
          {!props?.category && !props?.supporter && !props?.conatctus && (
            <>
              {props?.user ? (
                <div className="ml-md-auto py-2 py-md-0">
                  <Link to="/add-user" className="btn btn-secondary btn-round">
                    Add User
                  </Link>
                </div>
              ) : (
                <>
                  {user?.status == "Verified" && (
                    <div className="ml-md-auto py-2 py-md-0 button-responsive">
                      <Link
                        to="/dashboard/user-fundraisers"
                        className="fun-donate-btn2 mx-2">
                        Manage Cases
                      </Link>
                      <Link
                        to="/dashboard/add-fundraise"
                        className="fun-donate-btn2 mx-2 mt-3">
                        Create a Case
                      </Link>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Jumbotran;
