import React, { useEffect, useState } from "react";
import bannerBg from "./../assets/img/ramdan-image.png";
import zakat from "./../assets/img/ramadan-images/zakat.png";
import webBanner from "./../assets/img/ramadan-images/WebBanner.jpg";
import mobileBanner from "./../assets/img/ramadan-images/mobileBanner.jpg";
import needyPerosn from "./../assets/img/ramadan-images/needy-person.png";
import volunteer from "./../assets/img/ramadan-images/volunteer.png";
import zakatDonation from "./../assets/img/ramadan-images/zakat-donation.png";
import hungerFreeDonation from "./../assets/img/ramadan-images/hungerfree-donation.png";
import medicalDonation from "./../assets/img/ramadan-images/medical-donation.png";
import emergencyDonation from "./../assets/img/ramadan-images/emergency-donation.png";
import BreadCrumb from "../components/BreadCrumb";
import HelmetSEO from "../components/HelmetSEO";
import OrganizeZakat from "../components/OrganizeZakat";
import FundingForm from "../components/FundingForm";
import {Row,Col, Card, Container } from "react-bootstrap";
import Stepper from "../components/Stepper";
import { useContext } from "react";
import { Link } from "react-router-dom";
import Context from "../Context/context";
import moment from "moment";
import Slider from "../components/Slider";
import { getBlog } from "../Api-Calls/blog";

const RamadanCampaign = () => {
  const [tagModal, setTagModal] = useState(false);
  const [state, dispatch] = useContext(Context);
  const getBlogList = async () => {
    try {
      const res = await getBlog();
      console.log(res);
      if (res?.status == 200) {
        let data = res?.data?.result?.result;
        dispatch({
          type: "BLOGS",
          payload: data,
        });
        localStorage.setItem("blogs", JSON.stringify(data));
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(()=>{
    getBlogList()
  },  [])
  return (
    <>
      <HelmetSEO
        title="Ramadan Giving Campaign"
        description="Ramadan Giving Campaign"
      />

      <BreadCrumb
        heading="Ramadan Giving Campaign"
        badge="Ramadan Giving Campaign"
        description="This Ramadan increase your blessings by registering deserving individuals on our platform, connect donors, and explore our trending fundraisers to donate your zakat"
        webImage={bannerBg}
        mobileImage={bannerBg}
        ramadanBanner={true}
      />
      <section className="container py-5">
        <div className=" ">
          <>
            <section className="my-5">
              <div className="my-5 text-center">
                <h2>Organize Your Zakat</h2>
              </div>
              <div className="ramadan-zakat-flex justify-content-around">
                <OrganizeZakat
                  img={zakat}
                  title="Donate Your Zakat"
                  path="zakat-fundraiser"
                  button="View More"
                />
                <OrganizeZakat
                  img={needyPerosn}
                  title="Register a Needy Person"
                  path="RegisterNow"
                  button="Register Now"
                  setTagModal={setTagModal}
                  tagModal={tagModal}
                />
                <OrganizeZakat
                  img={volunteer}
                  title={"Volunteer for Ramadan"}
                  path={"volunteer"}
                  button="View More"
                />
              </div>
            </section>
          </>
        </div>
      </section>
      <section className="">
        <FundingForm
          title="Become a community volunteer"
          paragraph={`
          Register cases of those who require financial support online. During this holy month, together we can make a difference by connecting the needy with the help they deserve.
            `}
          mobileBanner={mobileBanner}
          webBanner={webBanner}
        />
      </section>
      <section className="container py-5">
        <div className=" ">
          <>
            <section className="my-5">
              <div className="my-5 text-center">
                <h2>Support Our Trending Fundraisers</h2>
              </div>
              <div className="ramadan-zakat-flex">
                <OrganizeZakat
                  img={zakatDonation}
                  title="Sponsor Orphans"
                  path="education , children"
                  button="View More"
                />
                <OrganizeZakat
                  img={hungerFreeDonation}
                  title="Support Single Mothers"
                  path="hunger-free"
                  button="View More"
                />
                <OrganizeZakat
                  img={medicalDonation}
                  title="Provide Medical Support"
                  path="medical"
                  button="View More"
                />
                <OrganizeZakat
                  img={emergencyDonation}
                  title="Help Emergency Cases"
                  path="emergency"
                  button="View More"
                />
              </div>
            </section>
          </>
        </div>
      </section>
      <Container>
        <section className="fq-div">
          <div className="my-5 text-center">
            <h1>Ramadan Blogs</h1>
          </div>
          {/* {state.blogList && state.blogList.length > 0 &&(
          <div className="news-ltr-flex">
            <Card className="news-ltr-card" style={{ width: "80%" }}>
              <Card.Body>
                <img
                  className="nws-ltr-card-img  "
                  src={
                    state?.blogList && state?.blogList[1]?.attachments[0]?.url
                  }
                  alt="newsletter"
                  style={{
                    width: "100%",
                  }}
                  loading="lazy"
                />

                <div className="card-body p-2">
                  <h5 className={"card-title story-title"}>
                    {state?.blogList[1]?.title}
                  </h5>
                  <p className="card-text mb-0 line3"></p>
                  <Link to={`/blogs/${state?.blogList[1]?.slug}`}>
                  <a className="read-more" >
                    Read More...
                  </a>
                  </Link>
                  <p className="text-right">
                    <small className="text-muted">
                      Published on{" "}
                      {moment(state?.blogList[1]?.created).format("L")}
                    </small>
                  </p>
                </div>
              </Card.Body>
            </Card>
          </div>
          )} */}
          <Row lg={3} md={3} sm={2} xs={1} className="g-4">
                  {state?.blogList &&
                    state?.blogList?.length > 0 &&
                    state?.blogList?.slice(-2)?.map((blog) => {
                      return (
                        <Col>
                          <Link to={`/blogs/${blog?.slug}`}>
                            <div className={"card mb-0"}>
                              <div className="col-lg-12">
                                <Slider
                                  carouselinner1="carouse-linner1"
                                  dynamicClass="fundRaisingImgBackground2 w-100"
                                  item={blog?.attachments}
                                  alt={blog?.meta_image_description}
                                />
                              </div>
                              <div className="card-body p-2">
                                <h5 className={"card-title story-title"}>
                                  {blog?.title}
                                </h5>
                                <p className="card-text mb-0 line3"></p>
                                <a className="read-more" href="">
                                  Read More...
                                </a>
                                <p className="text-right">
                                  <small className="text-muted">
                                    Published on{" "}
                                    {moment(blog?.created).format("L")}
                                  </small>
                                </p>
                              </div>
                            </div>
                          </Link>
                        </Col>
                      );
                    })}
                </Row>
        </section>
      </Container>
      <Stepper tagModal={tagModal} setTagModal={setTagModal} />
    </>
  );
};

export default RamadanCampaign;
