import React, {useEffect, useState} from "react";
import {Container} from "react-bootstrap";
import {useParams} from "react-router-dom";
import "../../assets/css/MedicalFunding.css";
import OwlCarousel from "react-owl-carousel";
import testimonialBackground from "../../assets/img/Testimonials.jpg";
import TestimonialCard from "../../components/TestimonialCard";
import Accordion from "react-bootstrap/Accordion";
import {constantIcons, constantImages} from "../../Constant";
import {getFundraiseByCategorySlug} from "../../Api-Calls/categoryApi";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FundingForm from "../../components/FundingForm";
import FundraiserBreadCrumb from "../../components/FundraiserBreadCrumb";
import WhatBuildingBridgesOffer from "../../components/staticComponents/WhatBuildingBridgesOffer";
import ActiveFundraiser from "../../components/ActiveFundraiser";
import webBanner from "../../assets/img/staticImages/child-crowdfunding-images/child-banner.png";
import mobileBanner from "../../assets/img/staticImages/child-crowdfunding-images/child-banner-mobile.png";
import mobileImage from "../../assets/img/staticImages/child-crowdfunding-images/mobile-image.png";
import HelmetSEO from "../../components/HelmetSEO";
import WhyChooseBuildingBridges from "../../components/WhyChooseBuildingBridges";
import SupportFundraiserFaqQuestion from "../../components/SupportFundraiserFaqQuestion";

const ChildFunding = () => {
  const {slug} = useParams();
  const images = constantImages();
  const icons = constantIcons();
  const [fundraiser, setFundraiser] = useState([]);

  const getFundraisebyCategotyId = async () => {
    try {
      const res = await getFundraiseByCategorySlug(slug);
      if (res?.status == 200) {
        setFundraiser(res?.data?.result?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getFundraisebyCategotyId();
  }, []);

  return (
    <>
      <HelmetSEO title="Children Funding" description="Children Funding" />

      <ToastContainer />
      <FundraiserBreadCrumb
        heading="Children Funding"
        description={
          <p>
            Building Bridges is an online fundraising platform empowering users
            to create fundraisers for transforming young lives through education
            and healthcare initiatives. Your contributions are vital for
            children to access their primary right to education.
          </p>
        }
        webImage={images.childSection1}
        mobileImage={mobileImage}
      />

      <ActiveFundraiser fundraiser={fundraiser} slug={slug} />
      
      <WhyChooseBuildingBridges
        mainHeading="Children"
        icon1={icons.childChooseIcon1}
        icon2={icons.childChooseIcon2}
        icon3={icons.childChooseIcon3}
        icon4={icons.childChooseIcon4}
        heading1="Support for Education & Healthcare"
        heading2="Empowering Financial Aid Seekers"
        heading3="Rapid Response to Urgent Cases"
        heading4="Building a Compassionate Community"
        description1="Building Bridges provides everyone the option to create fundraisers for supporting the education and healthcare of the children belonging to the vulnerable communities."
        description2="Building Bridges empowers the financial aid seekers by providing them with the option of hassle free online fundraising. The collected amount can be withdrawn anytime."
        description3="Building Bridges enables efficient fundraising for urgently required educational fee or healthcare support, helping the individuals receive assistance in a brief period of time."
        description4="Building Bridges aims to create a supportive community that financially aids the children from vulnerable communities, offering assistance with their education and healthcare."
      />

      <section className="">
        <FundingForm
          title="Building Bridges for Children Funding "
          paragraph={`
            Building Bridges allows you to create medical fundraisers in three easy steps. 
            This process allows donors to connect with children belonging to underprivileged 
            communities, providing vital financial support for their education and healthcare.
            `}
          mobileBanner={mobileBanner}
          webBanner={webBanner}
        />
      </section>
      <WhatBuildingBridgesOffer />

      <section
        className=" testimonialSection py-5 section--gray"
        style={{
          backgroundImage: `url(${testimonialBackground}) `,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
        }}
      >
        <div className="sectionHeading text-center mb-5">
          <h2 className="mb-4 high-title title-background mt-5">
            Success Stories
          </h2>
          <div className="col-md-7 mx-auto divider"></div>
        </div>
        <div>
          <div className="sectionHeading text-center mb-5"></div>
          <div className="container">
            <div className="col-md-9 mx-auto ">
              <OwlCarousel
                items={1}
                margin={30}
                autoplay={true}
                className="owl-theme fundRaisingSection testimonial-background"
              >
                <TestimonialCard image="false" />
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
      <SupportFundraiserFaqQuestion
        heading1="What are children funding projects and how do these projects benefit children?"
        heading2="How can I get involved in supporting a children funding project?"
        heading3="Are there any success stories or testimonials from children who have benefited from the projects?"
        heading4="How will you ensure that the money taken for the children is being actually utilized for their needs?"
        heading5="How can I stay updated on the impact of my donation?"
        heading6="How can I get involved beyond donating?"
        description1="Building bridges is an online fundraising platform that
        provides the users to create fundraisers for funding
        education and providing healthcare facilities to
        underprivileged children. The purpose of these fundraisers
        is to ensure that every underprivileged child get access to
        their primary rights to health and education."
        description2="You can create fundraisers for any deserving child who’s
        case you have thoroughly verified and have all the necessary
        proofs that justify your fundraiser. Once the fundraiser is
        created, and the required amount is collect you can withdraw
        it anytime and spend it on the cause for which it was
        created. After making an impact on the lives of the children
        you can share your success story with us that will be
        featured on our website and all social media platforms in
        order to motivate others."
        description3="Yes, we gather success stories against all our fundraisers.
        These success stories are featured on our website and all
        social media platforms. Testimonials of the children or any
        of their family members are also posted in the testimonials
        section on the website."
        description4=" Building Bridges only allows those users to create
        fundraisers on the platform who provide complete information
        regarding the case or a cause they aim to support. Once the
        fundraiser is created, everyone can see the related
        documents that have been uploaded with each fundraiser. The
        donors can contact the school or the hospital and ensure
        that the case is authentic. Once the user has withdrawn the
        collected funds, and spent them on the cause for which the
        fundraiser was created, they will then share the success
        story of that individual with Building Bridges. We will then
        post that story on our website and all social media
        platforms in order to ensure transparency and foster a sense
        of trust."
        description5="Our donors can receive updates on the progress of the
        fundraisers they support via success stories, testimonials,
        newsletters, emails, and social media platforms."
        description6="Apart form donating, you can always create a fundraiser for
        supporting any cause that is either close to your heart or
        has been recommended to you by someone you know. You can
        become a part of our fundraising campaigns by sharing the
        fundraisers on your personal social media accounts and
        encourage your friends and loved ones to do the same.
        Volunteering to spread awareness and encourage potential
        donors to support our platform and give special attention to
        the Children Funding charity page is also an option.
        Building Bridged offers you the opportunity to create a
        fundraiser in the name of your deceased loved ones too."
      />
    </>
  );
};

export default ChildFunding;
