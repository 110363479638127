import React from "react";
import moment from "moment";
import NoResultFound  from "../../../components/NoResultFound";
import UserNameLength from "../../../components/UserNameLength";


export default function FeedActivity({donationDetail}) {
  return (
    <div className="card full-height">
      <div className="card-header">
        <div className="card-title">Feed Activity</div>
      </div>
      <div className="card-body">
        <ol className="activity-feed feed-container">
          {donationDetail && donationDetail?.length > 0 ? (
            <>
              {donationDetail?.map((item) => (
                <>
                  <li
                    className="d-flex align-items-sm-center justify-content-between  feed-item feed-item-success"
                    key={item.id}
                  >
                    <span className="text" style={{letterSpacing: "0.7px"}}>
                      <strong>Thank you,</strong>{" "}
                      <strong> <UserNameLength
                              username={
                                item?.personalInfo?.username
                              }
                              length={20}
                            />,</strong> for your
                      heartfelt donation of{" "}
                      <strong>({item?.amount} PKR)</strong> to support this
                      fundraising <strong>{item?.fundraise_id?.title}.</strong>
                    </span>
                    <time
                      className="date"
                      style={{color: "black"}}
                      dateTime="9-25"
                    >
                      {moment(item?.updated).format("MMM D y ")}
                    </time>
                  </li>
                  <div className="col-md-12 mx-auto feed-divider"></div>
                </>
              ))}
            </>
          ) : (
            <NoResultFound />
          )}
        </ol>
      </div>
    </div>
  );
}
