import axios from "axios";

export const addSupporter = async (payload) => {
  return await axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/supporter/add-supporter`,
    payload
  );
};

export const getSupporter = async () => {
  return await axios.get(
    `${process.env.REACT_APP_BACKEND_URL}/supporter/get-supporters`
  );
};

export const getUserSupporter = async (id) => {
  return await axios.get(
    `${process.env.REACT_APP_BACKEND_URL}/supporters/${id}`
  );
};

export const getSupporterByFundraiseId = async (slug) => {
  return await axios.get(
    `${process.env.REACT_APP_BACKEND_URL}/supporter/get-supporter-with-fundraiser-slug/${slug}`
  );
};

export const getSupporterById = async (id) => {
  return await axios.get(
    `${process.env.REACT_APP_BACKEND_URL}/supporter/get-supporter-by-id/${id}`
  );
};
export const getUserTransaction = async (id) => {
  return await axios.get(
    `${process.env.REACT_APP_BACKEND_URL}/supporter/get-supporter-with-fundraiser-slug/${id}`
  );
};

export const updateSupporterById = async (id, payload) => {
  const token = JSON.parse(localStorage.getItem("userToken"));
  return await axios.patch(
    `${process.env.REACT_APP_BACKEND_URL}/supporter/update-payment-status/${id}`,
    payload,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const updateSupporterByTheirId = async (id, payload) => {
  const token = JSON.parse(localStorage.getItem("userToken"));
  return await axios.patch(
    `${process.env.REACT_APP_BACKEND_URL}/supporter/upload-proof-of-payment/${id}`,
    payload,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const getSupporterPayment = async (id) => {
  const token = JSON.parse(localStorage.getItem("userToken"));
  return await axios.get(
    `${process.env.REACT_APP_BACKEND_URL}/user/payment-details/${id}`,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
export const getUserDonationDetails = async (id) => {
  const token = JSON.parse(localStorage.getItem("userToken"));
  return await axios.get(
    `${process.env.REACT_APP_BACKEND_URL}/user/donation-detail/${id}`,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const updateSupporterByIdDash = async (id, payload) => {
  const token = JSON.parse(localStorage.getItem("userToken"));
  return await axios.patch(
    `${process.env.REACT_APP_BACKEND_URL}/supporter/update-suporter/${id}`,
    payload,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const sendEmailPaymentDecline = async (id) => {
  return await axios.get(
    `${process.env.REACT_APP_BACKEND_URL}/supporter/payment-decline-email/${id}`
  );
};

export const checkTransactionStatus = async (id, payload) => {
  return await axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/supporter/check-transaction-status/${id}`
  );
};
