import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Card, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getUserById } from '../Api-Calls/authentication'
import swal from 'sweetalert'
import { updateUserState } from '../Features/user/authSlice'

const OrganizeZakat = ({ img, title, path, button, setTagModal }) => {
  const navigate = useNavigate()
  const {
    auth: { user }
  } = useSelector(store => store)

  const handleView = pathUrl => {
    if (
      pathUrl === 'medical' ||
      pathUrl === 'hunger-free' ||
      pathUrl === 'emergency'
    ) {
      handleFundraiseCategory(pathUrl)
    } else if (pathUrl === 'education , children') {
      handleFundraiseCategory(pathUrl)
    } else if (pathUrl === 'RegisterNow') {
      UpdateUserProfile()
    } else {
      navigate(`/${pathUrl}`)
    }
  }

  const handleFundraiseCategory = slug => {
    navigate(`/category`, { state: { slug: slug } })
  }

  const dispatchRedux = useDispatch()

  const UpdateUserProfile = async () => {
    if (user) {
      try {
        const res = await getUserById(user?._id)
        if (res?.status == 200) {
          let userData = res?.data?.result?.user
          dispatchRedux(updateUserState(res?.data?.result?.user))
          if (
            userData?.status === 'Rejected' ||
            userData?.status === 'Pending'
          ) {
            if (
              userData?.country !== '' &&
              userData?.phoneNo !== '' &&
              userData?.address !== '' &&
              userData?.hear_about !== '' &&
              userData?.cnicNo !== '' &&
              userData?.zipcode !== '' &&
              userData?.cnic_picture?.length > 0
            ) {
              swal({
                title: 'Please Note',
                text: "Your personal information has been added, please wait for admin's approval to proceed forward.",
                icon: 'warning',
                buttons: true,
                dangerMode: true
              })
            } else {
              try {
                swal({
                  title: 'Confirmation!',
                  text: 'Before creating a case, kindly ensure that you have completed your profile.',
                  icon: 'warning',
                  buttons: true,
                  dangerMode: true
                }).then(data => {
                  if (data) {
                    setTagModal(true)
                  }
                })
              } catch (err) {
                console.log(err)
              }
            }
          }
        }
        if (res?.data?.result?.user?.status === 'Verified') {
          navigate('/dashboard/add-fundraise')
        }
      } catch (error) {
        console.log(error)
      }
    } else {
      navigate('/login')
    }
  }
  return (
    <>
      <Card className='ramadan-zakat-card'>
        <Card.Body>
          <img
            className='ramadan-zakat-card-img  '
            src={img}
            alt=''
            loading='lazy'
          />
          <p
            className='mt-2 ltr-card-txt-title'
            style={{
              color: '#002866',
              fontSize: '18px',
              fontWeight: 700
            }}
          >
            {title}
          </p>
          <div>
            <button
              className='w-100 py-3 btn__primary buttonPrimary '
              onClick={e => handleView(path)}
            >
              {button}
            </button>
          </div>
        </Card.Body>
      </Card>
    </>
  )
}

export default OrganizeZakat
