import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import BreadCrumb from "../components/BreadCrumb";
import involvedBg from "../assets/img/pmr-bg-mission.jpg";
import { Link } from "react-router-dom";

const CookiesNotice = () => {
  return (
    <>
      <BreadCrumb heading="Cookies Notice" badge="PRIVACY POLICY" />

      <section className="section--about">
        <section className="section section--white">
          <Container>
            <div className="text-center">
              <h3>COMMING SOOM</h3>
            </div>
          </Container>
        </section>
      </section>
    </>
  );
};

export default CookiesNotice;
