import React, {useState} from "react";
import {Container, Form} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {Link, useNavigate} from "react-router-dom";
import TextInput from "../components/TextInput";
import "react-toastify/dist/ReactToastify.css";
import {ToastContainer} from "react-toastify";
import eyeIcon from "../assets/img/Hide.png";
import hideEyeIcon from "../assets/img/Hide-2.png";
import {useDispatch} from "react-redux";
import {signUp} from "../Features/user/authSlice";
import HelmetSEO from "../components/HelmetSEO";
import WebImage from "../assets/img/signup.png";
import MobileImage from "../assets/img/signup-mobile.png";

const Signup = () => {
  const dispatchRedux = useDispatch();
  const {register, handleSubmit, control} = useForm();
  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const onSubmit = async (data) => {
    setLoader(true);
    dispatchRedux(
      signUp({
        username: data.username,
        email: data.email,
        password: data.password,
      })
    ).then((res) => {
      setLoader(false);
      if (res.type === "user/signup/fulfilled") {
        setTimeout(() => {
          navigate("/");
        }, 5000);
      }
    });
  };

  return (
    <>
      <HelmetSEO title="Signup" description="Signup" />
      <section className="signUpSection">
        <Container>
          <div className="shadow cardShadow">
            <div className="row flex-md-column flex-lg-row flex-md-column-reverse flex-column-reverse">
              <div className="col-lg-6">
                <div
                  className="formAuthentication"
                  style={{paddingRight: "24px"}}
                >
                  <form className="w-100 p-5" onSubmit={handleSubmit(onSubmit)}>
                    <h2 className="text-center text-uppercase my-5">
                      Create Account
                    </h2>
                    <Form.Label className="mb-1">
                      <b>Username</b>
                    </Form.Label>
                    <div className="mb-1">
                      <TextInput
                        name={"username"}
                        className="form-control"
                        rules={{
                          required: "Username field is required.",
                          pattern: {
                            minLength: {
                              value: 3,
                              message: "Username must be 3 characters long.",
                            },
                          },
                        }}
                        control={control}
                        placeholder="Username"
                      />
                    </div>

                    <Form.Label className="mt-3 mb-1">
                      <b>Email Address</b>
                    </Form.Label>
                    <div className="mb-1">
                      <TextInput
                        name={"email"}
                        className="form-control"
                        rules={{
                          required: "Email field is required.",
                          pattern: {
                            value:
                              /^\s*(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/,
                            message: "Invalid email address.",
                          },
                        }}
                        control={control}
                        placeholder="Email address"
                      />
                    </div>

                    <Form.Label className="mt-3 mb-1">
                      <b>Password</b>
                    </Form.Label>
                    <div className="mb-1">
                      <div className="mb-1 position-relative">
                        <TextInput
                          type={`${showPassword ? "text" : "password"}`}
                          {...register("password")}
                          className={`form-control `}
                          control={control}
                          showIcon={eyeIcon}
                          icon="true"
                          hideIcon={hideEyeIcon}
                          placeholder="Password"
                          setShowPassword={setShowPassword}
                          showPassword={showPassword}
                          rules={{
                            required: "Password field is required.",
                            minLength: {
                              value: 6,
                              message: "Password must be 6 characters long.",
                            },
                          }}
                        />
                      </div>
                    </div>
                    <div className="d-flex justify-content-end mt-4">
                      {loader ? (
                        <button className="btn__primary shadow buttonPrimary">
                          <i
                            className="fa mx-3 fa-circle-o-notch fa-spin"
                            style={{fontSize: "24px"}}
                          ></i>
                        </button>
                      ) : (
                        <button
                          className="btn__primary shadow buttonPrimary"
                          type="submit"
                        >
                          Sign up
                        </button>
                      )}
                    </div>
                    <div className="d-flex align-items-center justify-content-center mt-5">
                      <p className="mt-3 dontHaveAccount">
                        If you already have an account.{" "}
                        <span>
                          <Link to={"/login"}>Log in</Link>
                        </span>
                      </p>
                    </div>
                  </form>
                </div>
              </div>

              <div className="col-lg-6">
                <div
                  className="sideSectionAuthentication position-relative "
                  style={{
                    height: `${window?.innerWidth <= 600 ? "250px" : ""}`,
                    backgroundImage: `url(${
                      window?.innerWidth <= 600 ? MobileImage : WebImage
                    })`,
                  }}
                >
                  <div class="auth-overlay"></div>
                  <div className="position-relative">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <ToastContainer />
      </section>
    </>
  );
};

export default Signup;
