import moment from "moment";
import React, {useState} from "react";
import {Card, Modal} from "react-bootstrap";
import {staticImages} from "../Constant";

const CommentSection = ({commentList}) => {
  const [show, setShow] = useState(false);
  const [imageUrl, setImageUrl] = useState({});
  const [CommentIndex, setCommentIndex] = useState(5);
  const Comments = commentList?.slice(0, CommentIndex);
  const handleClose = () => setShow(false);
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  const loadMoreComments = () => {
    setCommentIndex(CommentIndex + 5);
  };

  return (
    <>
      {Comments.length > 0 ? (
        <div className="recent__transection mb-4">
          <>
            <div className="flex1">
              {Comments?.reverse()?.map((comment) => {
                return (
                  <>
                    <div className="d-flex singleComment mb-2">
                      <Card.Img
                        className="transection__image"
                        variant="top"
                        src={comment?.user?.picture !== "" ? comment?.user?.picture : staticImages.userDefaultImg}
                      />
                      <div className="flex1">
                        <h5 className="name mb-0 text-capitalize">
                          {comment?.user?.username}
                        </h5>
                        <div className="mb-2">{comment?.comment}</div>

                        <div className="d-flex">
                          {comment?.attachments?.map((url, index) => {
                            return (
                              <>
                                <img
                                  onClick={() => {
                                    handleSelect(index);
                                    setImageUrl(url);
                                    setShow(true);
                                  }}
                                  className="commentPreviewImage"
                                  src={url.url}
                                  alt="comment"
                                />
                              </>
                            );
                          })}
                        </div>

                        <div className="date d-flex justify-content-end">
                          {" "}
                          {moment(comment?.created).fromNow()}
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </>

          <Modal
            className="donatenow__modal"
            show={show}
            onHide={handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Preview Modal</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <img style={{width: "100%"}} src={imageUrl?.url} />
            </Modal.Body>
          </Modal>
        </div>
      ) : (
        <>
          <div className="text-center">
            <div className="col-md-8 mx-auto">
              <p>Fundraise comments not available.</p>
            </div>
          </div>
        </>
      )}

      {commentList?.length > 5 && (
        <div className="d-flex justify-content-center">
          <button
            className="btn__primary shadow buttonPrimary"
            onClick={loadMoreComments}
          >
            load more
          </button>
        </div>
      )}
    </>
  );
};

export default CommentSection;
