import axios from "axios";
export const getAllCategory = async () => {
  return await axios.get(`${process.env.REACT_APP_BACKEND_URL}/category/get-categories`);
};

export const getFundraiseByCategorySlug = async (slug, param) => {
  let url = `/fundraiser/get-category-fundraiser-with-slug/${slug}`;
  if (param && param.page && param.limit) {
    url = `/fundraiser/get-category-fundraiser-with-slug/${slug}?page=${param.page}&limit=${param.limit}`;
  }
  return await axios.get(`${process.env.REACT_APP_BACKEND_URL}${url}`);
};

export const getFixedCategory = async (id) => {
  return await axios.get(
    `${process.env.REACT_APP_BACKEND_URL}/category/get-fixed-category`,
    {
      headers: {
        "Content-type": "application/json",
      },
    }
  );
};

export const getCategory = async () => {
  return await axios.get(`${process.env.REACT_APP_BACKEND_URL}/category/get-categories`);
};

export const deleteCategory = async (id) => {
  return await axios.delete(
    `${process.env.REACT_APP_BACKEND_URL}/category/delete-category/${id}`
  );
};

export const addCategory = async (payload) => {
  return await axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/category/add-category`,
    payload
  );
};

export const updateCategory = async (id, payload) => {
  return await axios.put(
    `${process.env.REACT_APP_BACKEND_URL}/category/update-category/${id}`,
    payload
  );
};
