import axios from "axios";
import {config} from "./axiosHeader";

export const getUserFundraise = async (id) => {
  return await axios.get(
    `${process.env.REACT_APP_BACKEND_URL}/user-fundraise/${id}`,
    {
      headers: {
        "Content-type": "application/json",
      },
    }
  );
};
export const getFundraiseSearch = async (key) => {
  return await axios.get(
    `${process.env.REACT_APP_BACKEND_URL}/fundraiser/search/${key}`,
    {
      headers: {
        "Content-type": "application/json",
      },
    }
  );
};
export const getFundraiseBySlug = async (slug) => {
  return await axios.get(
    `${process.env.REACT_APP_BACKEND_URL}/fundraiser/get-fundraiser-by-slug/${slug}`,
    {
      headers: {
        "Content-type": "application/json",
      },
    }
  );
};
export const getFundraiseBySeqId = async (seqId) => {
  return await axios.get(
    `${process.env.REACT_APP_BACKEND_URL}/get-fundraise-with-seqId/${seqId}`,
    {
      headers: {
        "Content-type": "application/json",
      },
    }
  );
};

export const updateFundraise = async (id, formData) => {
  const token = JSON.parse(localStorage.getItem("userToken"));
  return await axios.patch(
    `${process.env.REACT_APP_BACKEND_URL}/fundraiser/update-user-fundraiser/${id}`,
    formData,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const deleteImage = async (id, payload) => {
  return await axios.put(
    `${process.env.REACT_APP_BACKEND_URL}/fundraiser/delete-user-fundraiser-image/${id}`,
    payload,
    config
  );
};
export const deleteDocument = async (id, payload) => {
  const token = JSON.parse(localStorage.getItem("userToken"));
  return await axios.put(
    `${process.env.REACT_APP_BACKEND_URL}/fundraiser/delete-user-fundraiser-document/${id}`,
    payload,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
export const deleteFundrasierVideo = async (id, payload) => {
  const token = JSON.parse(localStorage.getItem("userToken"));
  return await axios.put(
    `${process.env.REACT_APP_BACKEND_URL}/fundraiser/delete-user-fundraiser-video/${id}`,
    payload,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
export const addFundraise = async (formData) => {
  const token = JSON.parse(localStorage.getItem("userToken"));
  return await axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/fundraiser/add-user-fundraiser`,
    formData,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
export const withdrawlFundraiserAmmount = async (data, id) => {
  const token = JSON.parse(localStorage.getItem("userToken"));
  return await axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/fundraiser/withdraw-amount/${id}`,
    data,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
export const deleteUserFundraiser = async (id) => {
  const token = JSON.parse(localStorage.getItem("userToken"));
  return await axios.delete(
    `${process.env.REACT_APP_BACKEND_URL}/fundraiser/delete-user-fundraiser/${id}`,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
