import React, {useContext, useEffect} from "react";
import {Col, Container, Form, Row} from "react-bootstrap";
import {useState} from "react";
import {useForm} from "react-hook-form";
import TextInput from "../../components/TextInput";
import InputMask from "react-input-mask";
import {
  deleteCnicImage,
  deleteProfileImage,
  updateUserProfile,
} from "../../Api-Calls/authentication";
import {countries} from "../../data/country";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Context from "../../Context/context";
import Loader from "../../components/loader";
import Jumbotran from "./Jumbotran";
import SideMenu from "./SideMenu";
import DashBoardFooter from "./DashBoardFooter";
import ImgCrop from "antd-img-crop";
import {Upload, Modal} from "antd";
import VideoPreviewModal from "../../components/ImagePreviewModal";
import swal from "sweetalert";
import Select from "react-select";
import {
  SwalErrorMessage,
  SwalMessage,
} from "../../components/sweetAlertMessage";
import {useDispatch, useSelector} from "react-redux";
import {updateUserState} from "../../Features/user/authSlice";
import HelmetSEO from "../../components/HelmetSEO";

const DashboardProfile = () => {
  const {
    auth: {user},
  } = useSelector((store) => store);
  const dispatchRedux = useDispatch();

  const numberInputInvalidChars = ["-", "+", "e"];
  const [loading, setLoading] = useState(true);
  const [state, dispatch] = useContext(Context);
  const [file, setFile] = useState({});
  const [cnicFile, setCnicFile] = useState({});
  const [loader, setLoader] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [cnicFileList, setCnicFileList] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [mediaURL, setMediaURL] = useState({});
  const [previewImage, setPreviewImage] = useState({});
  const [showCnicPicture, setShowCnicPicture] = useState([]);
  const [maskNo, setMaskNo] = useState("");
  const [showProfilePicture, setShowProfilePicture] = useState([]);
  const [countryState, setCountryState] = useState({
    country: "",
    countryCode: "",
  });

  const handleOnChangeImages = ({fileList: newFileList}) => {
    setFileList(newFileList);
    setFile({
      files: newFileList,
      fileUrl: URL?.createObjectURL(newFileList[0]?.originFileObj),
    });
  };

  const handleOnChangeCnicImages = async ({file, fileList: newFileList}) => {
    setState({...stateData, errors: {...errors, cnicImg: true}});
    setCnicFileList(
      newFileList.filter((item) => item.status === "done" || "uploading")
    );
    if (newFileList[1]) {
      setCnicFile({
        files: newFileList,
        fileUrl: URL?.createObjectURL(newFileList[1]?.originFileObj),
      });
    } else {
      setCnicFile({
        files: newFileList,
        fileUrl: URL?.createObjectURL(newFileList[0]?.originFileObj),
      });
    }
  };
  useEffect(() => {
    if (user?.picture || user?.picture != "") {
      setShowProfilePicture(user?.picture);
      setFileList((prev) => [
        {
          thumbUrl: user?.picture,
        },
      ]);
    }
    if (user?.cnic_picture?.length > 0) {
      const newCnicFileList = user?.cnic_picture?.map((item) => ({
        thumbUrl: item?.url,
      }));
      setCnicFileList(newCnicFileList);
      setShowCnicPicture(user.cnic_picture);
    }
  }, [user]);

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const onPreview = async (file, thumbUrl, title) => {
    if (file.thumbUrl) {
      const previewData = {
        src: file.thumbUrl,
        visible: true,
        title: title,
      };
      setPreviewImage(previewData);
    } else {
      const previewData = {
        src: thumbUrl,
        visible: true,
        title: title,
      };
      setPreviewImage(previewData);
    }
  };
  
  const {
    handleSubmit,
    control,
    register,
    formState: {errors},
  } = useForm();
  const [stateData, setState] = useState({
    phoneNo: user?.phoneNo,
    zipcode: user?.zipcode,
    cnicNo: user?.cnicNo,
    errors: {
      phoneNo: "",
      zipcode: "",
    },
  });
  const removeProfileImage = async (e, key) => {
    if (key) {
      let url = key?.split("/")[4]?.split("?")[0];
      const res = await deleteProfileImage(user?._id, {url});
      dispatchRedux(updateUserState(res?.data?.result));
      let data = user?.picture?.filter(
        (item) => item?.url?.split("/")[4]?.split("?")[0] !== url
      );
      setFileList([]);
    }
  };
  const removeCnicImage = async (e, index, key) => {
    console.log(e);
    if (key) {
      let url = key?.split("/")[4]?.split("?")[0];
      const res = await deleteCnicImage(user?._id, {url});
      dispatchRedux(updateUserState(res?.data?.result));
      let data = user?.cnic_picture?.filter(
        (item) => item?.url?.split("/")[4]?.split("?")[0] !== url
      );
      const newCnicFileList = data?.map((item) => ({
        thumbUrl: item?.url,
      }));
      if (user?.cnic_picture?.length >= 0) {
        setCnicFileList(newCnicFileList);
      } else {
        setCnicFileList([]);
      }
    }
  };
  const handleDeleteProfileImage = async (e, data) => {
    if (!data?.originFileObj) {
      let url = data?.split("/")[4]?.split("?")[0];
      try {
        swal({
          title: "Are you sure?",
          text: "Once deleted, you will not be able to recover this image.",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then(async (willDelete) => {
          if (willDelete) {
            const res = await deleteProfileImage(user?._id, {url});
            dispatchRedux(updateUserState(res?.data?.result));
            let data = user?.picture?.filter(
              (item) => item?.url?.split("/")[4]?.split("?")[0] !== url
            );
            setFileList([]);
            setShowProfilePicture([]);
            swal("Poof! Your selected image has been deleted!", {
              icon: "success",
            });
          }
        });
      } catch (error) {
        SwalErrorMessage(
          "Oops!" + " " + error?.response?.data?.status,
          error?.response?.data?.message,
          "error",
          true
        );
      }
    }
  };
  const handleDeleteCnicImage = async (e, data) => {
    if (!data?.originFileObj) {
      let url = data?.split("/")[4]?.split("?")[0];
      try {
        swal({
          title: "Are you sure?",
          text: "Once deleted, you will not be able to recover this image.",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then(async (willDelete) => {
          if (willDelete) {
            const res = await deleteCnicImage(user?._id, {url});
            dispatchRedux(updateUserState(res?.data?.result));
            let data = user?.cnic_picture?.filter(
              (item) => item?.url?.split("/")[4]?.split("?")[0] !== url
            );
            setCnicFileList([]);
            setShowCnicPicture([]);
            swal("Poof! Your selected image has been deleted!", {
              icon: "success",
            });
          }
        });
      } catch (error) {
        SwalErrorMessage(
          "Oops!" + " " + error?.response?.data?.status,
          error?.response?.data?.message,
          "error",
          true
        );
      }
    }
  };

  const setInputFields = (evt) => {
    if (evt.target.name === "phoneNo") {
      if (evt.target.value.length < 15) {
        setState({
          ...stateData,
          phoneNo: evt.target.value,
        });
      }
    } else {
      setState({
        ...stateData,
        [evt.target.name]: evt.target.value,
      });
    }
  };

  const updateProfile = async (data) => {
    let detail = {
      ...data,
      zipcode: data.zipcode,
      cnicNo: data.cnicNo,
      country: countryState.country,
    };
    const formData = new FormData();
    if (file?.files?.length > 0) {
      Array?.from(file?.files)?.forEach((item) => {
        formData?.append("files", item?.originFileObj);
      });
    } else if (user?.picture) {
      let img = user?.picture;
      let url = img?.split("/")[4]?.split("?")[0];
      formData?.append("picture", url);
    }
    if (user.cnic_picture.length == 2) {
      setCnicFile({});
    } else {
      if (cnicFile?.files?.length > 0) {
        if (cnicFile?.files[0]?.thumbUrl[0]?.includes("https")) {
          let filtered = cnicFile?.files?.filter((item) => item.originFileObj);
          formData?.append("cnic_picture", filtered?.originFileObj);
        } else {
          Array?.from(cnicFile?.files)?.forEach((item, index) => {
            formData?.append("cnic_picture", item?.originFileObj);
          });
        }
      }
    }

    if (user?.cnic_picture?.length > 0) {
      let imgArr = user?.cnic_picture;
      imgArr?.map((item, index) => {
        let url = item?.url?.split("/")[4]?.split("?")[0];
        formData?.append("old_cnic_picture", url);
      });
    }
    formData?.append("username", detail?.username);
    formData?.append("email", detail?.email);
    formData?.append(
      "phoneNo",
      `${countryState.countryCode} ${
        data?.phoneNo?.split(" ")[1]
          ? data?.phoneNo?.split(" ")[1]
          : data.phoneNo
      }`
    );
    formData?.append("address", detail.address);
    formData?.append("zipcode", detail?.zipcode);
    formData?.append("status", detail?.status);
    formData?.append("country", detail?.country);
    formData?.append("cnicNo", detail?.cnicNo);
    formData?.append("hear_about", detail?.hear_about);

    setLoader(true);
    if (
      cnicFileList.length > 0 &&
      detail.cnicNo &&
      detail.phoneNo !== "" &&
      detail?.country !== undefined &&
      detail?.zipcode !== ""
    ) {
      try {
        const res = await updateUserProfile(user?._id, formData);
        if (res?.status === 200) {
          localStorage.setItem("user", JSON.stringify(res?.data));
          dispatchRedux(updateUserState(res.data.result.user));
          setShowCnicPicture(res?.data?.result?.user?.cnic_picture);
          setShowProfilePicture(res?.data?.result?.user?.picture);
          SwalMessage(res?.data?.status, res?.data?.message, "success");
          setLoader(false);
        }
      } catch (error) {
        SwalErrorMessage(
          "Oops!" + " " + error?.response?.data?.status,
          error?.response?.data?.message,
          "error",
          true
        );
        setLoader(true);
      }
    }
    if (detail?.country === undefined) {
      setState({...stateData, errors: {...errors, country: false}});
      setLoader(false);
    }
    if (detail.phoneNo === "") {
      setState({...stateData, errors: {...errors, phoneNo: false}});
      setLoader(false);
    }
    if (detail?.zipcode === "") {
      setState({...stateData, errors: {...errors, zipcode: false}});
      setLoader(false);
    }
    if (!detail.cnicNo) {
      setState({...stateData, errors: {...errors, cnic: false}});
      setLoader(false);
    }
    if (cnicFileList.length === 0) {
      setState({...stateData, errors: {...errors, cnicImg: false}});
      setLoader(false);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  const onCountryChange = (e) => {
    setState({...stateData, errors: {...errors, country: true}});
    setCountryState({country: `${e.label} `, countryCode: `+${e.code}`});
    setMaskNo(e.mask);
  };
  useEffect(() => {
    setCountryState({
      country:
        countries[countries.findIndex((c) => c.label === user?.country?.trim())]
          ?.label,
      countryCode: `+${
        countries[countries.findIndex((c) => c.label === user?.country?.trim())]
          ?.code
      }`,
    });
  }, [user?.country]);
  const customStyles = {
    option: (base, state) => ({
      ...base,
      color: "#1e2022",
      backgroundColor: state.isSelected ? "rgba(189,197,209,.3)" : "white",
      padding: ".5rem 3rem .5rem .5rem",
      cursor: "pointer",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return {...provided, opacity, transition};
    },
    control: (baseStyles, stateData) => ({
      ...baseStyles,
      paddingLeft: "10px",
      paddingRight: "10px",
      paddingTop: "8px",
      paddingBottom: "8px",
      backgroundColor: "#fff",
    }),
  };
  return (
    <>
      <HelmetSEO title="Profile" description="Profile" />
      <div className="App">
        <div className="">
          <SideMenu />
          <div className="main-panel">
            <div className="container">
              <Jumbotran
                page="User Profile"
                desc="User Profile"
                supporter="User Profile"
              />
              <div className="page-inner">
                <>
                  <Container>
                    <div className="col-md-9 mx-auto">
                      <div className="mb-5 text-center">
                        <h3>USER PROFILE</h3>
                      </div>
                      {loading ? (
                        <>
                          <div
                            className="section d-flex align-item-center justify-content-center"
                            style={{minHeight: "100vh"}}
                          >
                            <Loader />
                          </div>
                        </>
                      ) : (
                        <>
                          <form
                            onSubmit={handleSubmit(updateProfile)}
                            style={{textAlign: "left"}}
                          >
                            <div className="profile-img">
                              {" "}
                              <ImgCrop
                                showGrid
                                rotationSlider
                                id="profileUploadFile"
                                showReset
                                u
                              >
                                <Upload
                                  action=""
                                  listType="picture-card"
                                  onPreview={(e) => {
                                    onPreview(e, "Profile", "Profile Image");
                                  }}
                                  fileList={fileList}
                                  onChange={handleOnChangeImages}
                                  onRemove={(e) => {
                                    removeProfileImage(
                                      e,
                                      fileList[0]?.thumbUrl
                                    );
                                  }}
                                >
                                  {fileList?.length < 1 && "+ Upload"}
                                </Upload>
                              </ImgCrop>
                              <div className="my-5 text-center">
                                <b>Upload Image</b>
                              </div>
                            </div>
                            <Row>
                              <Col className="mb-3" lg={6}>
                                <Form.Label className="mb-1">
                                  <b>Name</b>{" "}
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <div className="mb-1">
                                  <TextInput
                                    name={"username"}
                                    className="form-control"
                                    defaultValue={
                                      user?.username ? user?.username : ""
                                    }
                                    rules={{
                                      required: "Username is required.",
                                    }}
                                    control={control}
                                    placeholder="Username"
                                  />
                                </div>
                              </Col>
                              <Col className="mb-3" lg={6}>
                                <Form.Label className="mb-1">
                                  <b>Email Address</b>{" "}
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <div className="mb-1">
                                  <TextInput
                                    name={"email"}
                                    disabled={"1"}
                                    defaultValue={user?.email}
                                    className="form-control"
                                    rules={{
                                      required: "Email field is required.",
                                      pattern: {
                                        value:
                                          /^\s*(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/,
                                        message: "Invalid  email address.",
                                      },
                                    }}
                                    control={control}
                                    placeholder="Email Address"
                                  />
                                </div>
                              </Col>
                              <Col className="mb-3" lg={6}>
                                <Form.Label className="mb-1">
                                  <b>Country</b>
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <div className="mb-1">
                                  {user && (
                                    <Select
                                      onChange={(e) => onCountryChange(e)}
                                      styles={customStyles}
                                      defaultValue={
                                        countries[
                                          countries.findIndex(
                                            (c) =>
                                              c.label === user?.country?.trim()
                                          )
                                        ]
                                      }
                                      className="form-contro"
                                      options={countries}
                                    />
                                  )}
                                  {stateData?.errors?.country === false && (
                                    <p className="text-danger  my-2 ms-2 ">
                                      Country is required.
                                    </p>
                                  )}
                                </div>
                              </Col>
                              <Col className="mb-3" lg={2} md={3} sm={4}>
                                <Form.Label className="mb-1">
                                  <b>Code</b>
                                </Form.Label>
                                <Form.Label className="mb-1"></Form.Label>
                                <div className="mb-1">
                                  <input
                                    readOnly
                                    control={control}
                                    autoComplete="off"
                                    value={
                                      countryState.countryCode !== "+undefined"
                                        ? countryState.countryCode
                                        : ""
                                    }
                                    name="PhoneCode"
                                    placeholder="+92"
                                    className="form-control dir-ltr customInputLogin"
                                  />
                                </div>
                              </Col>
                              <Col className="mb-3" lg={4} md={9} sm={8}>
                                <Form.Label className="mb-1">
                                  <b>Phone Number</b>
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <div className="mb-1">
                                  <InputMask
                                    {...register("phoneNo", {
                                      required: "Phone Number is required",
                                    })}
                                    autoComplete="off"
                                    mask={
                                      maskNo
                                        ? maskNo
                                        : countries.find(
                                            (c) =>
                                              c.label === user?.country?.trim()
                                          )?.mask
                                        ? countries.find(
                                            (c) =>
                                              c.label === user?.country?.trim()
                                          )?.mask
                                        : "99999-99999"
                                    }
                                    maskChar={null}
                                    name="phoneNo"
                                    defaultValue={
                                      user?.phoneNo?.split(" ")[1]
                                        ? user?.phoneNo?.split(" ")[1]
                                        : user?.phoneNo
                                    }
                                    placeholder="Phone Number"
                                    className="form-control dir-ltr customInputLogin"
                                  />
                                  <p className="text-danger  my-2 ms-2 ">
                                    {errors?.phoneNo?.message}
                                  </p>
                                </div>
                              </Col>

                              <Col className="mb-3" lg={6}>
                                <Form.Label className="mb-1">
                                  <b>Address</b>{" "}
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <div className="mb-1">
                                  <TextInput
                                    name={"address"}
                                    defaultValue={user?.address}
                                    className="form-control"
                                    rules={{
                                      required: "Address is required.",
                                    }}
                                    control={control}
                                    placeholder="Enter Address"
                                  />
                                </div>
                              </Col>
                              <Col className="mb-3" lg={6}>
                                <Form.Label className="mb-1">
                                  <b>Zipcode</b>
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <div className="mb-3">
                                  <InputMask
                                    {...register("zipcode", {
                                      required: "Zipcode is required",
                                    })}
                                    autoComplete="off"
                                    mask="99999"
                                    maskChar={null}
                                    name="zipcode"
                                    defaultValue={
                                      user?.zipcode ? user?.zipcode : ""
                                    }
                                    placeholder="zipcode"
                                    className="form-control dir-ltr customInputLogin"
                                  />
                                  <p className="text-danger  my-2 ms-2 ">
                                    {errors?.zipcode?.message}
                                  </p>
                                </div>
                              </Col>
                              <Col className="mb-3" lg={6}>
                                <Form.Label className="mb-1">
                                  <b>Status</b>
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <div className="mb-1">
                                  <TextInput
                                    type="text"
                                    name="status"
                                    className="form-control"
                                    disabled
                                    placeholder="status"
                                    defaultValue={
                                      user?.status ? user?.status : "Pending"
                                    }
                                    rules={false}
                                    control={control}
                                  />
                                </div>
                              </Col>

                              <Col className="mb-3" lg={6}>
                                <Form.Label className="mb-1">
                                  <b>How did you hear about us</b>{" "}
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <select
                                  className="form-control form-select"
                                  defaultValue={
                                    user?.hear_about ? user?.hear_about : ""
                                  }
                                  {...register("hear_about", {
                                    required: "Choose one option.",
                                  })}
                                >
                                  <option disabled value="">
                                    Choose Type
                                  </option>
                                  <option value="Friend">Friend</option>
                                  <option value="Social Media">
                                    Social Media
                                  </option>
                                  <option value="News Letter">
                                    Newsletter
                                  </option>
                                </select>
                                {errors?.hear_about && (
                                  <div className="text-danger mx-1 my-2">
                                    {errors?.hear_about?.message}
                                  </div>
                                )}
                              </Col>
                              <Col className="mb-3" lg={6}>
                                <Form.Label className="mb-1">
                                  <b>CNIC No</b>
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <div className="mb-1">
                                  <InputMask
                                    autoComplete="off"
                                    mask="99999-9999999-9"
                                    defaultValue={
                                      user?.cnicNo ? user?.cnicNo : ""
                                    }
                                    maskChar={null}
                                    name="cnicNo"
                                    onChange={setInputFields}
                                    placeholder="CNIC Number"
                                    className="form-control dir-ltr customInputLogin"
                                    {...register("cnicNo", {
                                      required: "CNIC no is required",
                                    })}
                                  />
                                  <p className="text-danger  my-2 ms-2 ">
                                    {errors?.cnicNo?.message}
                                  </p>
                                </div>
                              </Col>
                              <Col className="col-md-12 col-sm-12">
                                <Form.Label className="mb-1">
                                  <b>CNIC Picture</b>
                                  <span className="text-danger">*</span>
                                </Form.Label>
                                <div className="mb-1 cnic-img">
                                  <ImgCrop
                                    showGrid
                                    rotationSlider
                                    aspect={16 / 10}
                                    id="cnicUploadFile"
                                    showReset
                                  >
                                    <Upload
                                      action=""
                                      listType="picture-card"
                                      fileList={cnicFileList}
                                      onChange={handleOnChangeCnicImages}
                                      onPreview={(e) => {
                                        cnicFileList.map((item, index) => {
                                          onPreview(
                                            e,
                                            item?.thumbUrl,
                                            "Cnic Image"
                                          );
                                        });
                                      }}
                                      onRemove={(e) => {
                                        cnicFileList.map((item, index) => {
                                          removeCnicImage(
                                            e,
                                            index,
                                            item?.thumbUrl
                                          );
                                        });
                                      }}
                                    >
                                      {cnicFileList?.length < 2 && "+ Upload"}
                                    </Upload>
                                  </ImgCrop>
                                  {stateData?.errors?.cnicImg === false && (
                                    <p className="text-danger  my-2 ms-2 ">
                                      Please Upload Both side of Cnic Picture.
                                    </p>
                                  )}

                                  <Modal
                                    open={previewImage.visible}
                                    title={previewImage.title}
                                    footer={null}
                                    onCancel={() =>
                                      setPreviewImage({
                                        ...previewImage,
                                        visible: false,
                                      })
                                    }
                                  >
                                    <img
                                      alt="example"
                                      style={{width: "100%"}}
                                      src={previewImage.src}
                                    />
                                  </Modal>
                                </div>
                              </Col>
                              <div className="text-center my-4">
                                {loader ? (
                                  <button className="btn__primary mx-auto shadow buttonPrimary">
                                    <i
                                      className="fa mx-3 fa-circle-o-notch fa-spin"
                                      style={{fontSize: "24px"}}
                                    ></i>
                                  </button>
                                ) : (
                                  <button
                                    className="btn__primary my-4 mx-auto shadow buttonPrimary"
                                    type="submit"
                                  >
                                    Update profile
                                  </button>
                                )}
                              </div>
                            </Row>
                          </form>
                        </>
                      )}
                    </div>
                  </Container>
                </>
              </div>
            </div>
            <VideoPreviewModal
              media={mediaURL}
              show={modalShow}
              onHide={() => setModalShow(false)}
            />
            <DashBoardFooter />
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default DashboardProfile;
