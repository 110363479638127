import React from "react";

const AboutTab = ({description}) => {
  return (
    <section className="aboutSectionFundraise mt-5">
      <div>
        <h4>About the Fundraiser</h4>
        {description}
      </div>
    </section>
  );
};

export default AboutTab;
