import React from "react";
import {Link} from "react-router-dom";

export default function DashBoardFooter() {
  return (
    <footer className="footer">
      <div className="d-flex justify-content-between align-items-center">
        <nav className="pull-left">
          <ul className="nav">
            <li className="nav-item">
              <Link className="nav-link" to="/privacy-policy">
                Privacy Policy
              </Link>
            </li>
          </ul>
        </nav>
        <div className="copyright text-right">
          © All right reserved 2023{" "}
          <strong className="text-primary">buildingbridges</strong>{" "}
        </div>
      </div>
    </footer>
  );
}
