import React, {useEffect, useState} from "react";
import {Alert, Form, InputGroup} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import facebook from ".././assets/img/socialshare/fb.png";
import instagram from ".././assets/img/socialshare/instagram.png";
import linkdin from ".././assets/img/socialshare/linkdin.png";
import whatsapp from ".././assets/img/socialshare/whatsapp.png";
import twitter from ".././assets/img/socialshare/twitter.png";
import {
  FacebookShareButton,
  InstapaperShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
} from "react-share";

const ModalBody = (props) => {
  const {id} = props;
  const [showAlert, setShowAlert] = useState(false);
  const [copyText, setCopyText] = useState("");

  const myFunction = () => {
    var copyText = document.getElementById("myInput");
    copyText.select();
    copyText.setSelectionRange(0, 99999); // For mobile devices
    navigator.clipboard.writeText(copyText.value);
    setShowAlert(true);
    setCopyText(copyText.value);
    setTimeout(() => {
      setShowAlert(false);
      setCopyText("");
    }, 4000);
  };

  return (
    <>
      <Modal.Header className="border-0 px-5 pt-5" closeButton></Modal.Header>
      <Modal.Body className="p-5">
        {showAlert && (
          <>
            <h5 className="mb-2" style={{fontWeight: "600", color: "#002866"}}>
              Copied to Clipboard Successfully.
            </h5>
            <Alert onClick={() => setShowAlert(false)} className="primaryAlert" style={{wordWrap: "break-word"}}>
              {copyText}
            </Alert>
          </>
        )}
        <h4>Help by sharing</h4>
        <p>Fundraisers shared on social networks raise up to 5x more.</p>

        <div className="mb-4">
          <Form.Label className="mb-1">
            <b>Copy Link</b>
          </Form.Label>
          <div className="mb-1 position-relative">
            <InputGroup className="mb-3">
              <Form.Control
                id="myInput"
                disabled
                className="form-control"
                aria-describedby="basic-addon2"
                value={`${process.env.REACT_APP_BASEURL}/fundraiser/${id}`}
              />
              <InputGroup.Text
                id="basic-addon2"
                onClick={myFunction}
                className="px-5"
                style={{cursor: "pointer"}}
              >
                {showAlert ? "Copied" : "Copy"}
              </InputGroup.Text>
            </InputGroup>
          </div>
        </div>
        <div className="text-center  mt-5 mb-2">
          <strong>Tip:</strong>&nbsp;Paste this fundraiser link anywhere.
        </div>

        <div className="text-center d-flex align-items-center mb-5 justify-contrent-center">
          <ul className="footer__social__icon w-100 justify-content-center mb-0">
            <li className="mx-2">
              {/* <FacebookShareButton
                url={`${process.env.REACT_APP_BASEURL}/fundraiser/${id}`}
                quote={"Dummy text!"}
                hashtag="#BuildingBridges"
                style={{backgroundColor: "none"}}
              > */}
              <a
                href={`https://web.facebook.com/sharer/sharer.php?u=${process.env.REACT_APP_BASEURL}/fundraiser/${id}}`}
                target="_blank"
              >
                <img src={facebook} alt="facebook" loading="lazy"/>
              </a>

              {/* </FacebookShareButton> */}
            </li>
            {/* <li className="mx-2">
              <InstapaperShareButton
                url={`${process.env.REACT_APP_BASEURL}/fundraiser/${id}`}
                quote={"Dummy text!"}
                hashtag="#BuildingBridges"
                style={{backgroundColor: "none"}}
              >
                <img src={instagram} alt="instagram" loading="lazy"/>
              </InstapaperShareButton>
            </li> */}
            <li className="mx-2">
              <a
                href={`https://twitter.com/intent/tweet?url=${process.env.REACT_APP_BASEURL}/fundraiser/${id}`}
                target="_blank"
              >
                {" "}
                <img src={twitter} alt="twitter" loading="lazy"/>
              </a>
            </li>
            <li className="mx-2">
              <a
                href={`https://linkedin.com/shareArticle?url=${process.env.REACT_APP_BASEURL}/fundraiser/${id}`}
                target="_blank"
              >
                {" "}
                <img src={linkdin} alt="linkdin" loading="lazy"/>
              </a>
            </li>
            <li className="mx-2">
              <a
                href={`https://web.whatsapp.com/send?text=${process.env.REACT_APP_BASEURL}/fundraiser/${id}`}
                target="_blank"
              >
                {" "}
                <img src={whatsapp} alt="whatsapp" loading="lazy"/>
              </a>
            </li>
          </ul>
        </div>
      </Modal.Body>
    </>
  );
};

export default ModalBody;
