import React from 'react'
import { Container, Row, Col, ProgressBar, Card } from "react-bootstrap";
import { constantIcons } from '../../Constant';

export default function FundraiserSteps() {
    const icons = constantIcons();
    return (
        <section className="section--about" id="crowdfunding">
            <section className="section " style={{background:"white"}}>
                <div className='step-container'>

                    {/* <Container> */}
                    <div className="text-center">
                        <h2 className="mt-4 mb-5" style={{ lineHeight: "38px" }}>
                        Launch your Fundraising Campaign with Building Bridges
                        </h2>
                    </div>
                    <div className="  mt-5 ">
                        <div className="step-flex text-center position-relative  " >
                            <div className="step-card-first ">
                                <div className="text-center">
                                    <img className="step-img" src={icons.stepOne} alt='step' loading="lazy" />
                                </div>
                                <div className='mt-3  step-border position-relative'>
                                    <h3 className='step-title'>Start a fundraiser</h3>
                                    <div className='steps-li'>
                                        <ul className="border-1 ps-md-5  mx-auto text-start  mt-2 ">
                                            <li>Set a goal</li>
                                            <li>Share your story</li>
                                        </ul>
                                    </div>

                                    <div className='step-border-top-right'></div>
                                    <div className='step-border-top-left'></div>
                                </div>

                            </div>
                            {/* arrow */}
                            <div className=" mt-4 ">
                                <img className='step-arrow' src={icons.stepArrow} alt="arrow" loading="lazy"/>
                            </div>
                            <div className="step-card" >
                                <div className="text-center">
                                    <img className="step-img" src={icons.stepTwo} alt='stpe' loading="lazy"/>
                                </div>
                                <div className='mt-3 step-border position-relative'>

                                    <h4 className='step-title'>Share your fundraiser</h4>
                                    <div className='steps-li'>
                                        <ul className='text-start ps-md-5  mt-2 '>
                                            <li>Share it with family & friends</li>
                                            <li>Share it on social media</li>
                                        </ul>
                                    </div>

                                    <div className='step-border-top-right'></div>
                                    <div className='step-border-top-left'></div>
                                </div>

                            </div>
                            {/* arrow */}
                            <div className=" mt-4 ">
                                <img className='step-arrow-second' src={icons.stepArrow} alt="arrow" loading="lazy"/>
                            </div>
                            <div className="step-card-first ">
                                <div className="text-center">
                                    <img className="step-img" src={icons.stepThree} alt='step' loading="lazy"/>
                                </div>
                                <div className='mt-3 step-border position-relative  '>
                                    <h3 className='step-title'>Manage Donations</h3>
                                    <div className='steps-li'>
                                        <ul className='text-start ms-md-2 mt-2 '>
                                            <li>Withdraw collected funds</li>
                                            <li>Share your success story</li>
                                        </ul>
                                    </div>
                                    <div className='step-border-top-right'></div>
                                    <div className='step-border-top-left'></div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                {/* </Container> */}
            </section>
        </section>
    )
}
