import SideBar from "./SideBar";
import Logo from "../../assets/img/Logo-04-1.svg";
import {Link} from "react-router-dom";
import {useState} from "react";

export default function SideMenu() {
  const [openSideBar, setOpenSideBar] = useState(false);
  const changeSideBarStatus = () => {
    setOpenSideBar(!openSideBar);
  };
  return (
    <div
      className={"sidebar sidebar-style-2"}
      style={{width: openSideBar == false ? 65 : "250px"}}
    >
      <div
        style={{overflowX: "hidden"}}
        className="sidebar-wrapper scrollbar scrollbar-inner"
      >
        <div className="sidebar-content">
          <div className="d-flex align-items-center justify-content-center">
            <Link to={"/"}>
              <img src={Logo} alt="header-logo" className="navbar__logo py-3" loading="lazy"/>
            </Link>
          </div>
          <SideBar
            openSideBar={openSideBar}
            changeSideBarStatus={changeSideBarStatus}
          />
        </div>
      </div>
    </div>
  );
}
