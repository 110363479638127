import React, {useContext} from "react";
import {Col, Container, Row} from "react-bootstrap";
import BreadCrumb from "../components/BreadCrumb";
import {Link} from "react-router-dom";
import Context from "../Context/context";
import Slider from "../components/Slider";
import moment from "moment";
import banner from "../assets/img/stories-banner.png";
import mobileBanner from "../assets/img/stories-banner-mobile.png";
import HTMLRenderer from "react-html-renderer";
import {getSuccessStories} from "../Api-Calls/successStories";
import {useEffect} from "react";
import HelmetSEO from "../components/HelmetSEO";
import NoResultFound from "../components/NoResultFound";
import ReactPaginate from "react-paginate";

const SuccessStories = () => {
  const [state, dispatch] = useContext(Context);
  const storyPages = localStorage.getItem("storyPages");

  const getSuccessStoriesList = async () => {
    try {
      const params = {
        page: 1,
        limit: 12,
      };
      const res = await getSuccessStories(params);
      if (res?.status == 200) {
        let data = res?.data?.result?.result;
        let pages = res?.data?.result?.totalPages;

        dispatch({
          type: "SUCCESS_STORIES",
          payload: data,
        });
        localStorage.setItem("successStories", JSON.stringify(data));
        localStorage.setItem("storyPages", JSON.stringify(pages));
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getSuccessStoriesList();
  }, []);
  const handlePageClick = async (event) => {
    const params = {
      page: event.selected + 1,
      limit: 12,
    };
    const res = await getSuccessStories(params);
    if (res?.status == 200) {
      let data = res?.data?.result?.result;
      let pages = res?.data?.result?.totalPages;
      dispatch({
        type: "SUCCESS_STORIES",
        payload: data,
      });
      localStorage.setItem("storyPages", JSON.stringify(pages));
    }
  };
  return (
    <>
      <HelmetSEO title="Success Stories" description="Success Stories List" />

      <BreadCrumb
        heading="SUCCESS STORIES"
        badge="Success Stories"
        webImage={banner}
        mobileImage={mobileBanner}
        overlay={false}
      />
      <section className="container">
        <div className="section whyBuildingBridges">
          <Container>
            {state?.successStoriesList &&
            state?.successStoriesList?.length > 0 ? (
              <>
                <Row lg={3} md={3} sm={2} xs={1} className="g-4">
                  {state?.successStoriesList &&
                    state?.successStoriesList?.length > 0 &&
                    state?.successStoriesList?.map((story) => {
                      return (
                        <Col>
                          <Link to={`/success-stories/${story?.slug}`}>
                            <div className={"card mb-0"}>
                              <div className="col-lg-12">
                                <Slider
                                  carouselinner1="carouse-linner1"
                                  dynamicClass="fundRaisingImgBackground2 w-100"
                                  item={story?.imageUrl}
                                  alt={story?.meta_image_description}
                                />
                              </div>
                              <div
                                className="card-body p-2"
                                style={{minHeight: "230px"}}
                              >
                                <h5 className={"card-title story-title"}>
                                  {story?.title}
                                </h5>
                                <div className="card-text line3 story-description">
                                  <HTMLRenderer html={story?.description} />
                                </div>
                                <a
                                  className="read-more"
                                  href={`/success-stories/${story?._id}`}
                                >
                                  Read More...
                                </a>
                                <p
                                  className="text-right "
                                  style={{
                                    position: "absolute",
                                    bottom: "0",
                                    right: "10px",
                                  }}
                                >
                                  <small className="text-muted">
                                    Published on{" "}
                                    {moment(story?.created).format("L")}
                                  </small>
                                </p>
                              </div>
                            </div>
                          </Link>
                        </Col>
                      );
                    })}
                </Row>
              </>
            ) : (
              <NoResultFound />
            )}
          </Container>
        </div>
      </section>
      {state?.successStoriesList?.length >= 12 && (
        <ReactPaginate
          previousLabel="previous"
          breakLabel="..."
          nextLabel="next"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={storyPages}
          renderOnZeroPageCount={null}
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination justify-content-center"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          activeClassName="active"
          marginPagesDisplayed={2}
        />
      )}
    </>
  );
};

export default SuccessStories;
