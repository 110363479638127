import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import BreadCrumb from "../components/BreadCrumb";
import involvedBg from "../assets/img/volunteer-small-banner.png";
import banner from "../assets/img/charity.png";
import {Link} from "react-router-dom";
import Gallery from "../components/Gallery";

const Charity = () => {
  return (
    <>
      <BreadCrumb heading="CHARITY" badge="CHARITY" image={banner} />

      <section className="section--charity">
        <section
          className="section section--white"
          data-aos="fade-right"
          data-aos-duration="1000"
        >
          <Container>
            <h2 className="text-center mb-4">
              START A FUNDRAISING CAMPAIGN TODAY
            </h2>
            <p> Come forward and join hands to help needy families.</p>
            <p>
              "We believe that saving one life is equivalent to saving
              humanity."
            </p>
            <p> Recognize your rights as a volunteer.</p>

            <h2>A little goes a long way</h2>
            <p>
              We have worked with brilliant kids, generous families, and
              aspiring individuals who have admirable dreams but unfortunate
              circumstances. So, when we say that we are incredibly lucky to
              have seen this side of the people, we mean it.{" "}
            </p>
            <p>
              Naturally, this makes us eager to do as much as we can, but that’s
              not possible without your help. While we are prepared to take
              action, we’re looking for funding and resources. As someone who
              shares the same goals as us and wants to see the community
              overcome its challenges, your donations can make all the
              difference.{" "}
            </p>
            <p>
              Even if you choose to send $5 our way, we’ll make sure that it
              serves a useful purpose. Building Bridges for Islam shares every
              wonderful moment enabled by your money through pictures and
              videos—you get to see the good your charity did!
            </p>
          </Container>
        </section>
        <section
          className="section section--bg involvedSection mobile__text--center"
          style={{background: `#fff url(${involvedBg}) no-repeat center`}}
        >
          <div className="section__overlay" />
          <div
            className="section__overlay-content"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <Container>
              <Row className="row align-items-center justify-content-center text-center">
                <Col lg="8">
                  <h2 className="mb-4">DONOR WITH US</h2>
                  <p className="mb-4">
                    Jump in the scene and personally rescue those in need. We
                    value your passion so we are always happy to have a brave
                    soul like you on board.
                  </p>
                  <Link to={"/become-a-donor"}>
                    <button className=" mx-auto shadow btn__secondry shadow buttonPrimary">
                      DONOR with us
                    </button>
                  </Link>
                </Col>
              </Row>
            </Container>
          </div>
        </section>
      </section>
      <Gallery />
    </>
  );
};

export default Charity;
