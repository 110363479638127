import React, {useState} from "react";
import eyeIcon from "../assets/img/Hide.png";
import hideEyeIcon from "../assets/img/Hide-2.png";
import {useNavigate} from "react-router";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {changePassword} from "../Api-Calls/authentication";
import {useParams} from "react-router";
import {useForm} from "react-hook-form";
import BreadCrumb from "../components/BreadCrumb";
import {Col, Container, Form, Row} from "react-bootstrap";
import TextInput from "../components/TextInput";
import {SwalErrorMessage} from "../components/sweetAlertMessage";
const ForgotPasswordChange = (props) => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    formState: {errors},
  } = useForm();
  const navigate = useNavigate();
  const {user_id, token} = useParams();
  const pwd = watch("newPassword");
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [loader, setLoader] = useState(false);

  const onSubmit = async (data) => {
    try {
      let detail = {
        user_id,
        token: token,
        newPassword: data.newPassword,
      };
      setLoader(true);
      const res = await changePassword(detail);
      if (res?.status == 200) {
        SwalErrorMessage(res?.data?.status, res?.data?.message, "success");
        setLoader(false);
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      }
    } catch (error) {
      SwalErrorMessage(
        "Oops!" + " " + error?.response?.data?.status,
        error?.response?.data?.message,
        "error",
        true
      );
    }
  };

  return (
    <>
      <BreadCrumb heading="Reset Password" badge="RESET PASSWORD" />

      <section className="section--about">
        <section className="section section--white">
          <Container>
            <div className="col-md-6 mx-auto">
              <div className="mb-5 text-center">
                <h3>RESET PASSWORD</h3>
              </div>

              <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col className="mb-3" lg={12}>
                    <Form.Label className="mb-1">
                      <b>New Password</b> <span className="text-danger">*</span>
                    </Form.Label>
                    <div className="mb-1 position-relative">
                      <TextInput
                        type={`${showPassword1 ? "text" : "password"}`}
                        {...register("newPassword")}
                        className={`form-control `}
                        control={control}
                        showIcon={eyeIcon}
                        icon="true"
                        hideIcon={hideEyeIcon}
                        placeholder="Please enter your  new Password here"
                        setShowPassword={setShowPassword1}
                        showPassword={showPassword1}
                        rules={{
                          required: "New Password field is required.",
                          minLength: {
                            value: 8,
                            message: "Password must be 8 characters long.",
                          },
                        }}
                      />
                    </div>
                  </Col>
                  <Col className="mb-3" lg={12}>
                    <Form.Label className="mb-1">
                      <b>Confirm Password</b>{" "}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <div className="mb-1 position-relative">
                      <TextInput
                        type={`${showPassword2 ? "text" : "password"}`}
                        {...register("confirmPassword")}
                        className={`form-control `}
                        control={control}
                        showIcon={eyeIcon}
                        icon="true"
                        hideIcon={hideEyeIcon}
                        placeholder="Re-type your new password."
                        setShowPassword={setShowPassword2}
                        showPassword={showPassword2}
                        rules={{
                          required: "Confirm Password field is required.",
                          validate: (value) =>
                            value === pwd || "Confirm Password does not match.",
                        }}
                      />
                    </div>
                  </Col>

                  <div className="text-center d-flex align-items-center justify-content-center my-4">
                    {loader ? (
                      <button className="btn__primary buttonPrimary">
                        <i
                          className="fa mx-3 fa-circle-o-notch fa-spin"
                          style={{fontSize: "24px"}}
                        ></i>
                      </button>
                    ) : (
                      <button className="btn__primary buttonPrimary">
                        Update password
                      </button>
                    )}
                  </div>
                </Row>
              </form>
            </div>
          </Container>
          <ToastContainer />
        </section>
      </section>
    </>
  );
};

export default ForgotPasswordChange;
