import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
/** Layouts **/
// import AppLayout from "./layout/app-layout";
import React from 'react';
import "./App.css";
import Header from "./components/header/header";
import Home from "./pages/home";
import About from "./pages/AboutUs";
import Footer from "./components/footer/Footer";
import Charity from "./pages/charity";
import ContactUs from "./pages/ContactUs";
import DonationDetail from "./pages/DonationDetail";
import Fundraisers from "./pages/Fundraisers";
import ZakatFundraisers from "./pages/ZakatFundraisers";
import RamadanCampaign from "./pages/RamadanCampaign";
import FundraiseDetail from "./pages/FundraiseDetail";
import ScrollToTop from "./ScrollToTop";
import Categoryfundraiser from "./pages/CategoryFundraiser";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import ForgotPassword from "./pages/ForgotPassword";
import Questions from "./pages/Questions";
import PaymentFundraise from "./pages/PaymentFundraise";
import EmailLogin from "./components/EmailLogin";
import ForgotPasswordChange from "./pages/ForgotPasswordChange";
import HowItsWork from "./pages/HowItWork";
import Payment from "./pages/payment";
import AccountSetting from "./pages/accountSetting";
import StartFundraiser from "./pages/StartFundraiser";
import SinglePayment from "./pages/singlePayment";
import ProofOfPayment from "./pages/proofOfPayment";
import Thanks from "./pages/Thanks";
import Blogs from "./pages/Blogs";
import CookiesNotice from "./pages/CookiesNotice";
import PrivacyPolicy from "./pages/Privacy";
import MedicalFunding from "./pages/supportFundraiser/MedicalFunding";
import EducationFunding from "./pages/supportFundraiser/EducationFunding";
import SportsFunding from "./pages/supportFundraiser/SportsFunding";
import ChildFunding from "./pages/supportFundraiser/ChildFunding";
import AnimalFunding from "./pages/supportFundraiser/AnimalFunding";
import TeamBuilding from "./pages/TeamBuildingBridges";
import SuccessStories from "./pages/SuccessStories";
import SuccessStoriesDetails from "./pages/SuccessStoriesDetails";
import BlogDetails from "./pages/BlogDetails";
import UserDashBoardMain from "./pages/userDashboard/UserDashBoardMain";
import UserFundRaisers from "./pages/userDashboard/fundraisers/UserFundRaisers";
import Payments from "./pages/userDashboard/payments/Payments";
import Donation from "./pages/userDashboard/donation/Donation";
import DashboardProfile from "./pages/userDashboard/Profile";
import AccountSettings from "./pages/userDashboard/AccountSettings";
import Transaction from "./pages/userDashboard/transaction/Transaction";
import AddFundraise from "./pages/userDashboard/fundraisers/AddFundraise";
import WhatAreFundraiser from "./pages/WhatAreFundraiser";
import Transparancy from "./pages/Transparancy";
import EmergencyFunding from "./pages/supportFundraiser/EmergencyFunding";
import Categories from "./pages/Categories";
import HungerFreeFunding from "./pages/supportFundraiser/HungerFreeFunding";
import Terms from "./pages/Terms";
import BetaVersion from "./pages/BetaVersion";
import MemorialFunding from "./pages/supportFundraiser/MemorialFunding";
import PrivateRoute from "./privateRoute";
import UserFundraiserTransaction from "./pages/userDashboard/userFundraisertransaction/UserFundraiserTransaction";
import Anonymous from "./utils/Anonymous ";
import Support from "./pages/Support";
import NewsLetter from "./pages/NewsLetter";
import NotFound from "./pages/404";
import LoadingPage from './pages/LoadingPage';
import VolunteerForRamdan from "./pages/VolunteerForRamdan";

const RoutesPage = () => {
  return (
    <div className="wrapper">
      <Router>
        <Header />
        <ScrollToTop />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/home/:email" element={<Home />} />
          <Route element={<Anonymous />}>
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/forgot-password" element={<ForgotPassword />} />
            <Route exact path="/signup" element={<Signup />} />
          </Route>
          <Route
            exact
            path="/email-login/:email/:password"
            element={<EmailLogin />}
          />
          <Route
            exact
            path="/forgot-password-change/:user_id/:token"
            element={<ForgotPasswordChange />}
          />
          <Route
            exact
            path={`/payment-fundraiser/:slug`}
            element={<PaymentFundraise />}
          />
          <Route
            exact
            path="/donation-detail/:id"
            element={<DonationDetail />}
          />
          <Route exact path="/about-us" element={<About />} />
          <Route exact path="/news-letter" element={<NewsLetter />} />
          <Route exact path="/questions" element={<Questions />} />
          <Route exact path="/charity" element={<Charity />} />
          <Route exact path="/how-its-work" element={<HowItsWork />} />
          <Route exact path="/blogs" element={<Blogs />} />
          <Route exact path="/what-are-fundraiser" element={<WhatAreFundraiser />} />
          <Route exact path="/transparancy" element={<Transparancy />} />
          <Route exact path="/blogs/:slug" element={<BlogDetails />} />
          <Route exact path="/start-fundraiser" element={<StartFundraiser />} />
          <Route exact path="/redirect" element={<LoadingPage />} />

          {/* payments */}
          <Route exact path="/donations" element={<Payment />} />
          <Route exact path="/single-payment/:id" element={<SinglePayment />} />
          <Route
            exact
            path="/upload-proof-of-payment/:id"
            element={<ProofOfPayment />}
          />

          <Route
            exact
            path="/dashboard/user-dashboard"
            element={
              <PrivateRoute>
                <UserDashBoardMain />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/dashboard/user-fundraisers"
            element={
              <PrivateRoute>
                <UserFundRaisers />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/dashboard/edit-fundraise/:slug"
            element={
              <PrivateRoute>
                <AddFundraise />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/dashboard/add-fundraise"
            element={
              <PrivateRoute>
                <AddFundraise />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/dashboard/user-transaction/:slug"
            element={
              <PrivateRoute>
                <Transaction />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/dashboard/user-fundraiser-transaction/:slug"
            element={
              <PrivateRoute>
                <UserFundraiserTransaction />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/dashboard/payments"
            element={
              <PrivateRoute>
                <Payments />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/dashboard/donations"
            element={
              <PrivateRoute>
                <Donation />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/dashboard/profile-setting"
            element={
              <PrivateRoute>
                <DashboardProfile />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/dashboard/account-setting"
            element={
              <PrivateRoute>
                <AccountSettings />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/account-setting"
            element={
              <PrivateRoute>
                <AccountSetting />
              </PrivateRoute>
            }
          />

          <Route exact path="/thanks-for-donation" element={<Thanks />} />
          <Route exact path="/volunteer" element={<VolunteerForRamdan />} />
          <Route exact path="/fundraisers" element={<Fundraisers />} />
          <Route exact path="/ramadan-campaign" element={<RamadanCampaign />} />
          <Route exact path="/zakat-fundraiser" element={<ZakatFundraisers />} />
          <Route exact path="/category" element={<Categoryfundraiser />} />
          <Route exact path="/fundraiser/:slug" element={<FundraiseDetail />} />
          <Route exact path="/contact-us" element={<ContactUs />} />
          <Route exact path="/support" element={<Support />} />
          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route exact path="/terms-conditions" element={<Terms />} />
          <Route exact path="/beta" element={<BetaVersion />} />
          <Route exact path="/cookies-notice" element={<CookiesNotice />} />
          <Route
            exact
            path="/medical-funding/:slug"
            element={<MedicalFunding />}
          />
          <Route
            exact
            path="/memorial-funding/:slug"
            element={<MemorialFunding />}
          />
          <Route
            exact
            path="/education-funding/:slug"
            element={<EducationFunding />}
          />
          <Route
            exact
            path="/sports-funding/:slug"
            element={<SportsFunding />}
          />
          <Route
            exact
            path="/children-funding/:slug"
            element={<ChildFunding />}
          />
          <Route
            exact
            path="/hunger-free-funding/:slug"
            element={<HungerFreeFunding />}
          />
          <Route
            exact
            path="/emergency-funding/:slug"
            element={<EmergencyFunding />}
          />
          <Route
            exact
            path="/animal-funding/:slug"
            element={<AnimalFunding />}
          />
          <Route
            exact
            path="/team-building-bridges"
            element={<TeamBuilding />}
          />
          <Route exact path="/success-stories" element={<SuccessStories />} />
          <Route
            exact
            path="/success-stories/:slug"
            element={<SuccessStoriesDetails />}
          />
          <Route exact path="/categories" element={<Categories />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
};

export default RoutesPage;
