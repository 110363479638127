import React from "react";

const NoResultFound = () => {
  return (
    <div className="text-center nodatafound mb-5">
      <img
        src="https://ketto.gumlet.io/assets/images/browse-campaign/no-results.png?w=350&dpr=1.0"
        loading="lazy"
      />
      <h6>No results found</h6>
      <p>Try adjusting your search filters to find what you are looking for.</p>
    </div>
  );
};

export default NoResultFound;
