import {useState, useEffect} from "react";
import {useForm} from "react-hook-form";
import {Modal} from "react-bootstrap";
import {Col, Container, Form, Row} from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import TextInput from "./TextInput";
import InputMask from "react-input-mask";
import {
  deleteCnicImage,
  updateUserInformation,
} from "../Api-Calls/authentication";
import swal from "sweetalert";
import ImgCrop from "antd-img-crop";
import {Upload} from "antd";
import {countries} from "../data/country";
import Select from "react-select";
import {SwalErrorMessage, SwalMessage} from "./sweetAlertMessage";
import {useDispatch, useSelector} from "react-redux";
import {updateUserState} from "../Features/user/authSlice";
import ImagePreviewModal from "./ImagePreviewModal";
export default function ProfileInformationModal({tagModal, setTagModal}) {
  const numberInputInvalidChars = ["-", "+", "e", "E", "."];
  const {
    auth: {user},
  } = useSelector((store) => store);
  const dispatchRedux = useDispatch();
  const [fileList, setFileList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(true);
  const [cnicFileList, setCnicFileList] = useState([]);
  const [cnicFile, setCnicFile] = useState({});
  const [showCnicPicture, setShowCnicPicture] = useState([]);
  const [maskNo, setMaskNo] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [mediaURL, setMediaURL] = useState({});
  const [countryState, setCountryState] = useState({
    country: "",
    countryCode: "",
  });
  const {
    register,
    handleSubmit,
    control,
    formState: {errors},
  } = useForm({criteriaMode: "all"});
  const handleClose = () => {
    setTagModal(false);
  };
  const [stateData, setState] = useState({
    phoneNo: user?.phoneNo,
    zipcode: user?.zipcode,
    cnicNo: user?.cnicNo,
    errors: {
      cell: "",
      zipcode: "",
    },
  });
  
  const handleOnChangeCnicImages = async ({file, fileList: newFileList}) => {
    setState({...stateData, errors: {...errors, cnicImg: true}});

    setCnicFileList(
      newFileList.filter((item) => item.status === "done" || "uploading")
    );
    if (newFileList[1]) {
      setCnicFile({
        files: newFileList,
        fileUrl: URL?.createObjectURL(newFileList[1]?.originFileObj),
      });
    } else {
      setCnicFile({
        files: newFileList,
        fileUrl: URL?.createObjectURL(newFileList[0]?.originFileObj),
      });
    }
  };
  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const removeCnicImage = async (e, index, key) => {
    console.log(e);
    if (key) {
      let url = key?.split("/")[4]?.split("?")[0];
      const res = await deleteCnicImage(user?._id, {url});
      dispatchRedux(updateUserState(res?.data?.result));
      let data = user?.cnic_picture?.filter(
        (item) => item?.url?.split("/")[4]?.split("?")[0] !== url
      );
      const newCnicFileList = data?.map((item) => ({
        thumbUrl: item?.url,
      }));
      if (user?.cnic_picture?.length >= 0) {
        setCnicFileList(newCnicFileList);
      } else {
        setCnicFileList([]);
      }
    }
  };
  const handleDeleteCnicImage = (e, data) => {
    if (!data?.originFileObj) {
      let url = data?.split("/")[4]?.split("?")[0];
      try {
        swal({
          title: "Are you sure?",
          text: "Once deleted, you will not be able to recover this image.",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            deleteCnicImage(user?._id, {url});
            let data = user?.cnic_picture?.filter(
              (item) => item?.url?.split("/")[4]?.split("?")[0] !== url
            );

            setCnicFile([]);
            setCnicFileList([]);
            setShowCnicPicture([]);
            swal("Poof! Your selected image has been deleted!", {
              icon: "success",
            });
          }
        });
      } catch (error) {
        SwalErrorMessage(
          "Oops!" + " " + error?.response?.data?.status,
          error?.response?.data?.message,
          "error",
          true
        );
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    if (user?.cnic_picture?.length > 0) {
      const newCnicFileList = user?.cnic_picture?.map((item) => ({
        thumbUrl: item?.url,
      }));
      setCnicFileList(newCnicFileList);
      setShowCnicPicture(user.cnic_picture);
    }
  }, [user]);

  const updateProfile = async (data) => {
    const formData = new FormData();
    if (user.cnic_picture.length == 2) {
      setCnicFile({});
    } else {
      if (cnicFile?.files?.length > 0) {
        if (cnicFile?.files[0]?.thumbUrl[0]?.includes("https")) {
          let filtered = cnicFile?.files?.filter((item) => item.originFileObj);
          formData?.append("cnic_picture", filtered?.originFileObj);
        } else {
          Array?.from(cnicFile?.files)?.forEach((item, index) => {
            formData?.append("cnic_picture", item?.originFileObj);
          });
        }
      }
    }

    if (user?.cnic_picture?.length > 0) {
      let imgArr = user?.cnic_picture;
      imgArr?.map((item, index) => {
        let url = item?.url?.split("/")[4]?.split("?")[0];
        formData?.append("old_cnic_picture", url);
      });
    }
    formData?.append("username", data?.username);
    formData?.append("email", data?.email);
    formData?.append(
      "phoneNo",
      `${countryState.countryCode} ${
        data.phoneNo ? data.phoneNo : data.phoneNo
      }`
    );
    formData?.append("address", data?.address);
    formData?.append("zipcode", data?.zipcode);
    formData?.append("status", data?.status);
    formData?.append("country", countryState.country);
    formData?.append("cnicNo", data?.cnicNo);
    formData?.append("hear_about", data?.hear_about);
    setLoader(true);
    if (cnicFileList.length > 0) {
      try {
        if (cnicFile?.files?.length < 2) {
          setState({...stateData, errors: {...errors, cnicImg: false}});
          setLoader(false);
        } else if (countryState.country == undefined) {
          setState({...stateData, errors: {...errors, country: false}});
          setLoader(false);
          return;
        } else {
          const res = await updateUserInformation(user?._id, formData);
          if (res?.status == 200) {
            dispatchRedux(updateUserState(res?.data?.result?.user));
            SwalMessage(res?.data?.status, res?.data?.message, "success");
          }
          setShowCnicPicture(res?.data?.result?.user?.cnic_picture);
          setLoader(false);
          setTagModal(false);
          setFileList([]);
          setState({
            ...stateData,
            errors: {...errors, cnic: true, cnicImg: true},
          });
        }
      } catch (error) {
        SwalErrorMessage(
          "Oops!" + " " + error?.response?.data?.status,
          error?.response?.data?.message,
          "error",
          true
        );
      }
    }
    if (cnicFileList.length === 0) {
      setState({...stateData, errors: {...errors, cnicImg: false}});
      setLoader(false);
    }
  };
  const onCountryChange = (e) => {
    setState({
      ...stateData,
      errors: {...errors, country: true},
    });
    setCountryState({country: `${e.label} `, countryCode: `+${e.code}`});
    setMaskNo(e.mask);
  };
  useEffect(() => {
    setCountryState({
      country:
        countries[countries.findIndex((c) => c.label === user?.country?.trim())]
          ?.label,
      countryCode: `+${
        countries[countries.findIndex((c) => c.label === user?.country?.trim())]
          ?.code
      }`,
    });
  }, [user]);
  const customStyles = {
    option: (base, state) => ({
      ...base,
      color: "#1e2022",
      backgroundColor: state.isSelected ? "rgba(189,197,209,.3)" : "white",
      padding: ".5rem 3rem .5rem .5rem",
      cursor: "pointer",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return {...provided, opacity, transition};
    },
    control: (baseStyles, stateData) => ({
      ...baseStyles,
      paddingLeft: "10px",
      paddingRight: "10px",
      paddingTop: "8px",
      paddingBottom: "8px",
      backgroundColor: "#fff",
    }),
  };

  const ShowModalPreview = (file, media) => {
    console.log(file.thumbUrl, media);
    if (file.thumbUrl) {
      setModalShow(true);
      setMediaURL(file.thumbUrl);
    } else {
      setModalShow(true);
      setMediaURL(media);
    }
  };
  return (
    <div className="profileModal">
      <Modal
        show={tagModal}
        onHide={handleClose}
        style={{zIndex: 1000, marginTop: "50px"}}
      >
        <Modal.Header>
          <Modal.Title>User Profile</Modal.Title>
          <button onClick={handleClose} className="border-0">
            X
          </button>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <div className="col-md-9 mx-auto">
              <form
                onSubmit={handleSubmit(updateProfile)}
                style={{textAlign: "left"}}
              >
                <Row className="mt-5">
                  <Col className="mb-3" lg={6}>
                    <Form.Label className="mb-1">
                      <b>Name</b> <span className="text-danger">*</span>
                    </Form.Label>
                    <div className="mb-1">
                      <TextInput
                        name={"username"}
                        className="form-control"
                        defaultValue={user?.username ? user?.username : ""}
                        rules={{
                          required: "Username is required.",
                        }}
                        control={control}
                        placeholder="Username"
                      />
                    </div>
                  </Col>
                  <Col className="mb-3" lg={6}>
                    <Form.Label className="mb-1">
                      <b>Email Address</b>{" "}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <div className="mb-1">
                      <TextInput
                        name={"email"}
                        disabled={"1"}
                        defaultValue={user?.email}
                        className="form-control"
                        rules={{
                          required: "Email is required.",
                          pattern: {
                            value:
                              /^\s*(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/,
                            message: "Invalid  email address.",
                          },
                        }}
                        control={control}
                        placeholder="Email address"
                      />
                    </div>
                  </Col>
                  <Col className="mb-3" lg={6}>
                    <Form.Label className="mb-1">
                      <b>Country</b>
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <div className="mb-1">
                      {user && (
                        <Select
                          onChange={(e) => onCountryChange(e)}
                          styles={customStyles}
                          defaultValue={
                            countries[
                              countries.findIndex(
                                (c) => c.label === user?.country?.trim()
                              )
                            ]
                          }
                          className="form-contro"
                          options={countries}
                        />
                      )}
                      {stateData?.errors?.country === false && (
                        <p className="text-danger  my-2 ms-2 ">
                          Country is required.
                        </p>
                      )}
                    </div>
                  </Col>
                  <Col className="mb-3" lg={2} md={3} sm={4}>
                    <Form.Label className="mb-1">
                      <b>Code</b>
                    </Form.Label>
                    <Form.Label className="mb-1"></Form.Label>
                    <div className="mb-1">
                      <input
                        readOnly
                        control={control}
                        autoComplete="off"
                        value={
                          countryState.countryCode !== "+undefined"
                            ? countryState.countryCode
                            : ""
                        }
                        name="PhoneCode"
                        placeholder="+92"
                        className="form-control dir-ltr customInputLogin"
                      />
                    </div>
                  </Col>
                  <Col className="mb-3" lg={4} md={9} sm={8}>
                    <Form.Label className="mb-1">
                      <b>Phone Number</b>
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <div className="mb-1">
                      <InputMask
                        {...register("phoneNo", {
                          required: "Phone Number is required",
                        })}
                        autoComplete="off"
                        inputMode="numeric"
                        mask={maskNo ? maskNo : "99999-99999"}
                        maskChar={null}
                        name="phoneNo"
                        defaultValue={
                          user?.phoneNo?.split(" ")[1]
                            ? user?.phoneNo?.split(" ")[1]
                            : user?.phoneNo
                        }
                        placeholder="Phone Number"
                        className="form-control dir-ltr customInputLogin"
                      />
                      <p className="text-danger  my-2 ms-2 ">
                        {errors?.phoneNo?.message}
                      </p>
                    </div>
                  </Col>
                  <Col className="mb-3" lg={6}>
                    <Form.Label className="mb-1">
                      <b>Address</b> <span className="text-danger">*</span>
                    </Form.Label>
                    <div className="mb-1">
                      <TextInput
                        name={"address"}
                        defaultValue={user?.address ? user?.address : ""}
                        className="form-control"
                        rules={{
                          required: "Address is required.",
                        }}
                        control={control}
                        placeholder="Enter Address"
                      />
                    </div>
                  </Col>
                  <Col className="mb-3" lg={6}>
                    <Form.Label className="mb-1">
                      <b>Zipcode</b>
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <div className="mb-3">
                      <InputMask
                        {...register("zipcode", {
                          required: "Zipcode is required",
                        })}
                        inputMode="numeric"
                        autoComplete="off"
                        mask="99999"
                        maskChar={null}
                        name="zipcode"
                        defaultValue={user?.zipcode ? user?.zipcode : ""}
                        placeholder="zipcode"
                        className="form-control dir-ltr customInputLogin"
                      />
                      <p className="text-danger  my-2 ms-2 ">
                        {errors?.zipcode?.message}
                      </p>
                    </div>
                  </Col>
                  <Col className="mb-3" lg={6}>
                    <Form.Label className="mb-1">
                      <b>Status</b>
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <div className="mb-1">
                      <TextInput
                        type="text"
                        name="status"
                        className="form-control"
                        disabled
                        placeholder="Status"
                        defaultValue={user?.status ? user?.status : ""}
                        rules={false}
                        control={control}
                      />
                    </div>
                  </Col>
                  <Col className="mb-3" lg={6}>
                    <Form.Label className="mb-1">
                      <b>How did you hear about us</b>{" "}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <select
                      className="form-control form-select"
                      defaultValue={user?.hear_about ? user?.hear_about : ""}
                      {...register("hear_about", {
                        required: "Choose one option.",
                      })}
                    >
                      <option disabled value="">
                        Choose Type
                      </option>
                      <option value="Friend">Friend</option>
                      <option value="Social Media">Social Media</option>
                      <option value="Newsletter">Newsletter</option>
                    </select>
                    {errors?.hear_about && (
                      <div className="text-danger mx-1 my-2">
                        {errors.hear_about.message}
                      </div>
                    )}
                  </Col>
                  <Col className="mb-3" lg={6}>
                    <Form.Label className="mb-1">
                      <b>CNIC No</b>
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <div className="mb-1">
                      <InputMask
                        autoComplete="off"
                        mask="99999-9999999-9"
                        defaultValue={user?.cnicNo ? user?.cnicNo : ""}
                        {...register("cnicNo", {
                          required: "CNIC is required",
                        })}
                        maskChar={null}
                        inputMode="numeric"
                        name="cnicNo"
                        placeholder="CNIC Number"
                        className="form-control dir-ltr customInputLogin"
                      />
                      <p className="text-danger  my-2 ms-2 ">
                        {errors?.cnicNo?.message}
                      </p>
                    </div>
                  </Col>
                  <Col className="col-md-12">
                    <Form.Label className="mb-1">
                      <b>CNIC Image</b>
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <div className="mb-1 cnic-img col-md-12">
                      <ImgCrop
                        showGrid
                        rotationSlider
                        aspect={16 / 10}
                        id="cnicUploadFile"
                        showReset
                      >
                        <Upload
                          action=""
                          listType="picture-card"
                          fileList={cnicFileList}
                          onChange={handleOnChangeCnicImages}
                          onPreview={(e) =>
                            cnicFileList.map((item, index) => {
                              ShowModalPreview(e, item?.thumbUrl);
                            })
                          }
                          onRemove={(e) => {
                            cnicFileList.map((item, index) => {
                              removeCnicImage(e, index, item?.thumbUrl);
                            });
                          }}
                        >
                          {cnicFileList?.length < 2 && "+ Upload"}
                        </Upload>
                      </ImgCrop>
                      {stateData?.errors?.cnicImg === false && (
                        <p className="text-danger  my-2 ms-2 ">
                          Please Upload Both side of Cnic Picture.
                        </p>
                      )}
                      <ImagePreviewModal
                        media={mediaURL}
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                      />
                    </div>
                  </Col>
                  <div className="text-center my-4">
                    {loader ? (
                      <button className="btn__primary mx-auto shadow buttonPrimary">
                        <i
                          className="fa mx-3 fa-circle-o-notch fa-spin"
                          style={{fontSize: "24px"}}
                        ></i>
                      </button>
                    ) : (
                      <button
                        className="btn__primary mx-auto shadow buttonPrimary"
                        type="submit"
                      >
                        {user?.cnicNo &&
                        user?.country &&
                        user?.phoneNo &&
                        user?.cnic_picture
                          ? "Update profile"
                          : "Create profile"}
                      </button>
                    )}
                  </div>
                </Row>
              </form>
            </div>
          </Container>
        </Modal.Body>
      </Modal>
    </div>
  );
}
