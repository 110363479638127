import React from "react";
import {Container} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import FundraiserCard from "./FundraiserCard";
import NoResultFound from "./NoResultFound";

const ActiveFundraiser = ({fundraiser, slug}) => {
  const navigate = useNavigate();

  const handleFundraiseCategory = () => {
    navigate(`/category`, {state: {slug: slug}});
  };
  return (
    <>
      <section className="section section--gray ">
        <Container>
          <div className="text-center mb-5">
            <h2>Active Fundraisers</h2>
            <div className="divider"></div>
          </div>
          <div className="filterMainContent">
            <div className="fundraiser-flx  ">
              {fundraiser && fundraiser?.length > 0 ? (
                <>
                  {fundraiser?.slice(0, 4)?.map((fundraise, index) => {
                    return (
                      <div className="li-fundraiser-card">
                        <FundraiserCard fundraise={fundraise} />
                      </div>
                    );
                  })}
                </>
              ) : (
                <NoResultFound />
              )}
            </div>
            {fundraiser && fundraiser?.length > 0 && (
              <div className="d-flex justify-content-center mt-5">
                <button
                  className="py-3 btn__primary buttonPrimary"
                  onClick={handleFundraiseCategory}
                >
                  View All Fundraisers
                </button>
              </div>
            )}
          </div>
        </Container>
      </section>
    </>
  );
};

export default ActiveFundraiser;
