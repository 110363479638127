import axios from "axios";

export const getSuccessStories = async (param) => {
  let url = "/campaign-story/get-all-stories";
  if (param && param.page && param.limit) {
    url = `/campaign-story/get-all-stories?page=${param.page}&limit=${param.limit}`;
  }
  return await axios.get(`${process.env.REACT_APP_BACKEND_URL}${url}`);
};

export const getSuccessStoryById = async (id) => {
  return await axios.get(
    `${process.env.REACT_APP_BACKEND_URL}/story/get-story-by-id/${id}`,
    {
      headers: {
        "Content-type": "application/json",
      },
    }
  );
};

export const getSuccessStoryBySlug = async (slug) => {
  return await axios.get(
    `${process.env.REACT_APP_BACKEND_URL}/campaign-story/get-story-by-slug/${slug}`,
    {
      headers: {
        "Content-type": "application/json",
      },
    }
  );
};
