import React, { useState } from "react";
import { Col, Form, InputGroup, Offcanvas, Tooltip } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { addSupporter } from "../Api-Calls/supporter";
import { Slider } from "antd";
import { Link } from "react-router-dom";

const SideModalDonation = (props) => {
  const { fundraise, show, onHide, getSupporter, getFundraiseData } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [fundraiseData, setFundraiseData] = useState(true);
  const min = 1;
  const max = 9999;

  const [value, setValue] = useState();
  const [customAmount, setCustomAmount] = useState(false);

  const handleChange = (event) => {
    const value = Math.max(min, Math.min(max, Number(event.target.value)));
    setValue(value);
  };
  const [inputValue, setInputValue] = useState();
  const [percent, setpercent] = useState();
  const onChange = (newValue) => {
    setInputValue(newValue);
    const data = (value / 100) * newValue;
    var rounded = Math.round((data + Number.EPSILON) * 100) / 100;
    setpercent(rounded);
  };

  const handleCustomAmount = () => {
    setCustomAmount(true);
  };
  const handleDefault = () => {
    setCustomAmount(false);
  };

  const tooltip = (
    <Tooltip id="tooltip">
      Your name will only be visible to the organizer, any team members and the
      beneficiary
    </Tooltip>
  );

  return (
    <Offcanvas show={show} onHide={onHide}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Choose Amount</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div>
          {fundraiseData && (
            <>
              <h6 className="mb-3">Fundraiser information</h6>
              <div className="row">
                <div className="col-4">
                  <img
                    src="https://via.placeholder.com/150X100"
                    className="w-100"
                  />
                </div>
                <div className="col-8">
                  <p className="mb-0">
                    You're supporting <b>Case Name</b>
                  </p>
                  <small>descriptions</small>
                </div>
              </div>
              <hr />
            </>
          )}

          <h6 className="mb-1">Enter your donation</h6>
          <div class="input-group mb-3 customFieldPrice">
            <div class="input-group-prepend">
              <div className="input-group-text flex-column justify-content-center px-3 h-100">
                <div className="mx-3">
                  <b>$</b>
                </div>
                <div className="mx-1">
                  <b>USD</b>
                </div>
              </div>
            </div>
            <input
              type="number"
              value={value}
              onChange={handleChange}
              class="form-control inputAmmount border-0 px-0"
              aria-label="Amount (to the nearest dollar)"
            />
            <div class="input-group-append d-block">
              <span class="input-group-text h-100 inputAmmount">.00</span>
            </div>
          </div>
          <h6 className="mt-4">Tip Building Bridges services</h6>
          <p style={{ fontSize: "14px" }}>
            Building Bridges has a 0% platform fee for organizers. Building Bridges
            will continue offering its services thanks to donors who will leave
            an optional amount here:
          </p>

          {customAmount ? (
            <>
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <h6 className="mb-0">Tip amount</h6>
                </div>
                <div
                  onClick={handleDefault}
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                >
                  Back to default
                </div>
              </div>
              <InputGroup className="mb-3 customTipInput">
                <InputGroup.Text id="basic-addon1" className="px-3">
                  <b>$</b>
                </InputGroup.Text>
                <Form.Control
                  className="px-2"
                  type="number"
                  aria-describedby="basic-addon1"
                />
              </InputGroup>
              <p style={{ fontSize: "14px" }}>
                Adding a Building Bridges tip means being a key part of improving
                the services for donors like you and the campaigns you support.
              </p>
            </>
          ) : (
            <>
              <div className="d-flex align-items-center ">
                <div>
                  <h5>0%</h5>
                </div>
                <div className="flex1 mx-3">
                  <Col span={12}>
                    <Slider
                      min={0}
                      disabled={value ? false : true}
                      max={20}
                      onChange={onChange}
                      value={typeof inputValue === "number" ? inputValue : 0}
                    />
                  </Col>
                </div>
                <div>
                  <h5> 20%</h5>
                </div>
              </div>
              {value && (
                <div className="text-center">
                  <h6>
                    (${percent ? percent : 0}) {inputValue}%
                  </h6>
                </div>
              )}

              <div
                className="text-end my-4"
                onClick={handleCustomAmount}
                style={{ textDecoration: "underline", cursor: "pointer" }}
              >
                Enter custom tip
              </div>
            </>
          )}

          <Offcanvas.Title className="mt-3 mb-2">
            Payment method
          </Offcanvas.Title>
          <div>Card info </div>

          <Form.Group className="my-3 d-flex">
            <Form.Check
              label="Don't display my name publicly on the fundraiser."
              feedback="You must agree before submitting."
              feedbackType="invalid"
            />
          </Form.Group>
          <hr />

          <Offcanvas.Title className="mt-3 mb-2">Your donation</Offcanvas.Title>
          <div className="d-flex mb-2 align-items-center justify-content-between">
            <div>Your donation</div>
            <div>
              <b>${value}</b>
            </div>
          </div>

          <div className="d-flex mb-2 align-items-center justify-content-between">
            <div>Building Bridges tip</div>
            <div>
              <b>${percent}</b>
            </div>
          </div>
          <hr />

          <div className="d-flex align-items-center justify-content-between">
            <div>Total due today</div>
            <div>
              <b>${value + percent}</b>
            </div>
          </div>

          <div className="my-4">
            <button className="btn__secondary w-100 buttonSecondry">
              donate now
            </button>
            <p className="my-3">
              By continuing, you agree with{" "}
              <Link to="#">Building Bridges terms</Link> and{" "}
              <Link to={"#"}>privacy notice</Link>.
            </p>
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default SideModalDonation;
