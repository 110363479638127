import {Card, Container} from "react-bootstrap";
import newLetterBanner from "../assets/img/news-letter-header.png";
import {useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import {useContext} from "react";
import Context from "../Context/context";
import HTMLRenderer from "react-html-renderer";
import HelmetSEO from "../components/HelmetSEO";

const NewsLetter = () => {
  const navigate = useNavigate();
  const [state, dispatch] = useContext(Context);
  const {
    fundraiser: {fundraiseList},
  } = useSelector((store) => store);

  const handleDonateNow = (fundraise) => {
    navigate(`/payment-fundraiser/${fundraise.slug}`, {state: fundraise});
  };
  return (
    <>
      <HelmetSEO title="Newsletter" description="Newsletter" />
      <div style={{marginTop: "120px"}}>
        <Container>
          <div className="text-center">
            <h2>International Day of the Orphan Child</h2>
            <img
              src={newLetterBanner}
              className="news-letter-banner"
              alt="newsletter"
              style={{width: `${window.innerWidth <= 600 ? "100%" : "80%"}`}}
              loading="lazy"
            />
          </div>
          <section
            className="mt-5 fq-div"
            style={{
              width: `${window.innerWidth <= 600 ? "100%" : "80%"}`,
              marginLeft: `${window.innerWidth <= 600 ? "0" : "10%"}`,
            }}
          >
            <p className="card-text">
              The first people to officially care for orphaned children were the
              Romans, who opened the first orphanage in history in around 400
              AD. Long before them, however, both Jewish and Athenian law
              required that orphans be supported until age 18. The great
              philosopher Plato once said, “Orphans should be placed under the
              care of public guardians.
            </p>
            <p className="card-text">
              {" "}
              Men should have a fear of the loneliness of orphans and of the
              souls of their departed parents. A man should love the unfortunate
              orphan of whom he is a guardian as if he were his own child. He
              should be as careful and as diligent in the management of the
              orphan’s property as of his own or even more careful still.”
            </p>
            <p className="card-text">
              {" "}
              Later, in Medieval Europe, orphans were cared for by churches. By
              the early 1900s, a number of orphans in England had reached truly
              alarming proportions, not to mention the conditions in which the
              orphaned children lived, which were often appalling and abusive.
              Charles Dickens’ most famous novel, “Oliver Twist”, depicts
              perfectly the hardships orphaned children had to endure in
              orphanages.
            </p>
          </section>
          {/* Fundraisers */}
          <section className="my-5">
            <div className="my-5 text-center">
              <h2>Fundraisers</h2>
            </div>
            <div className="news-ltr-flex">
              {fundraiseList?.result?.slice(0, 3).map((fundraiser, index) => {
                const {title, description, slug} = fundraiser;
                return (
                  <Card className="news-ltr-card">
                    <Card.Body>
                      <img
                        className="nws-ltr-card-img  "
                        src={fundraiser?.imageUrl[0]?.url}
                        alt=""
                        loading="lazy"
                      />
                      <p
                        className="mt-2 ltr-card-txt-title"
                        style={{
                          color: "#002866",
                          fontSize: "18px",
                          fontWeight: 700,
                        }}
                      >
                        {title}
                      </p>
                      <p className="mb-2 ltr-card-txt">{description}</p>
                      <div>
                        <button
                          className="w-100 py-3 btn__primary buttonPrimary "
                          onClick={(e) => handleDonateNow(fundraiser)}
                        >
                          Donate Now
                        </button>
                      </div>
                    </Card.Body>
                  </Card>
                );
              })}
            </div>
          </section>

          <section
            className="mt-5 fq-div"
            style={{
              width: `${window.innerWidth <= 600 ? "100%" : "80%"}`,
              marginLeft: `${window.innerWidth <= 600 ? "0" : "10%"}`,
            }}
          >
            <p className="card-text">
              The first pe, who opened the first orphanage in history in around
              400 AD. Long before them, however, both Jewish and Athenian law
              required that orphans be supported until age 18. The great
              philosopher Plato once said, “Orphans should be placed under the
              care of public guardians.
            </p>
            <p className="card-text">
              {" "}
              Men should have a fear of the loneliness of orphans and of ople to
              officially care for orphaned children were the Romansthe souls of
              their departed parents. A man should love the unfortunate orphan
              of whom he is a guardian as if he were his own child. He should be
              as careful and as diligent in the management of the orphan’s
              property as of his own or even more careful still.”
            </p>
            <p className="card-text">
              {" "}
              Later, in Medieval Europe, orphans were cared for by churches. By
              the early 1900s, a number of orphans in England had reached truly
              alarming proportions, not to mention the conditions in which the
              orphaned children lived, which were often appalling and abusive.
              Charles Dickens’ most famous novel, “Oliver Twist”, depicts
              perfectly the hardships orphaned children had to endure in
              orphanages.
            </p>
          </section>
          {/* Success Stories */}
          <section className="fq-div">
            <div className="my-5 text-center">
              <h2>Success Stories</h2>
            </div>
            <div className="news-ltr-flex">
              {state?.successStoriesList?.slice(0, 3).map((story, index) => {
                const {_id, title, description} = story;
                return (
                  <Card className="news-ltr-card" key={_id}>
                    <Card.Body>
                      <img
                        className="nws-ltr-card-img  "
                        src={story.imageUrl[0]?.url}
                        alt=""
                        loading="lazy"
                      />
                      <p
                        className="mt-2 story-title "
                        style={{
                          color: "#002866",
                          fontSize: "18px",
                          fontWeight: 700,
                        }}
                      >
                        {title}
                      </p>
                      <div className="story-description mb-2">
                        <HTMLRenderer html={story?.description} />
                      </div>
                      <div>
                        <Link
                          to={`/success-stories/${story?.slug}`}
                          className="w-100 py-3 btn__primary buttonPrimary"
                        >
                          Read More
                        </Link>
                      </div>
                    </Card.Body>
                  </Card>
                );
              })}
            </div>
          </section>

          <section
            className="mt-5 fq-div"
            style={{
              width: `${window.innerWidth <= 600 ? "100%" : "80%"}`,
              marginLeft: `${window.innerWidth <= 600 ? "0" : "10%"}`,
            }}
          >
            <p>
              The first people to officially care for orphaned children were the
              Romans, who opened the first orphanage in history in around 400
              AD. Long before them, however, both Jewish and Athenian law
              required that orphans be supported until age 18. The great
              philosopher Plato once said, “Orphans should be placed under the
              care of public guardians.
            </p>
            <p className="card-text">
              Men should have a fear of the loneliness of orphans and of the
              souls of their departed parents. A man should love the unfortunate
              orphan of whom he is a guardian as if he were his own child. He
              should be as careful and as diligent in the management of the
              orphan’s property as of his own or even more careful still.”
            </p>
            <p className="card-text">
              Later, in Medieval Europe, orphans were cared for by churches. By
              the early 1900s, a number of orphans in England had reached truly
              alarming proportions, not to mention the conditions in which the
              orphaned children lived, which were often appalling and abusive.
              Charles Dickens’ most famous novel, “Oliver Twist”, depicts
              perfectly the hardships orphaned children had to endure in
              orphanages.
            </p>
          </section>
          {/* Blogs */}
          <section className="fq-div">
            <div className="my-5 text-center">
              <h1>Blogs</h1>
            </div>
            <div className="news-ltr-flex">
              <Card className="news-ltr-card" style={{width: "80%"}}>
                <Card.Body>
                  <img
                    className="nws-ltr-card-img  "
                    src={
                      state?.blogList && state?.blogList[0]?.attachments[0]?.url
                    }
                    alt="newsletter"
                    style={{
                      width: "100%",
                    }}
                    loading="lazy"
                  />
                  <p
                    className="mt-2"
                    style={{
                      color: "#002866",
                      fontSize: "18px",
                      fontWeight: 700,
                    }}
                  >
                    {state?.blogList && state?.blogList[0]?.title}
                  </p>
                  <div className="ltr-html-render-blog mb-4">
                    <HTMLRenderer
                      html={state?.blogList && state?.blogList[0]?.description}
                    />
                  </div>
                  <div className="d-flex justify-content-center ">
                    <Link
                      to={`/blogs/${
                        state?.blogList && state?.blogList[0]?.slug
                      }`}
                      className={`${
                        window.innerWidth <= 600
                          ? "w-100 py-3 btn__primary buttonPrimary"
                          : "w-25 py-3 btn__primary buttonPrimary"
                      }`}
                    >
                      Read More
                    </Link>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </section>
        </Container>
      </div>
    </>
  );
};

export default NewsLetter;
