import React, {useEffect, useState} from "react";
import Loader from "../components/loader";
import {updateUserState} from "../Features/user/authSlice";
import Cookies from "js-cookie";
import {useNavigate, useLocation} from "react-router-dom";
import {useDispatch} from "react-redux";

const LoadingPage = () => {
  const navigate = useNavigate();
  const [loading, setloading] = useState(true);
  const dispatchRedux = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const userData = searchParams.get("userData");
    if (userData) {
      const decodedUserData = JSON.parse(decodeURIComponent(userData));
      console.log("User data:", decodedUserData);
      dispatchRedux(updateUserState(decodedUserData?.user));
      localStorage.setItem("userToken", JSON.stringify(decodedUserData.token));
      localStorage.setItem("user", JSON.stringify(decodedUserData?.user));
      Cookies.set("userToken", JSON.stringify(decodedUserData.token), {
        // expires: new Date(Date.now() + 604800 * 1000),
        expires: new Date(Date.now() + 2 * 24 * 60 * 60 * 1000),
      });
      setTimeout(() => {
        navigate("/");
      }, 500);
    }
  }, [location.search]);

  return (
    loading && (
      <div
        className="section d-flex align-item-center justify-content-center"
        style={{minHeight: "100vh"}}
      >
        <Loader />
      </div>
    )
  );
};
export default LoadingPage;
