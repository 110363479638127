import React, {useContext} from "react";
import bannerBg from "./../assets/img/browse-fundraise.png";
import Context from "../Context/context";
import BreadCrumb from "../components/BreadCrumb";
import {useNavigate} from "react-router-dom";
import Gallery from "../components/Gallery";
import {Container} from "react-bootstrap";

const Categories = () => {
  const [state, dispatch] = useContext(Context);
  const navigate = useNavigate();
  const handleFundraiseCategory = (category) => {
    localStorage.setItem("category", JSON.stringify({id: category._id}));
    navigate(`/fundraisers/${category?._id}`);
  };
  return (
    <>
      <BreadCrumb
        heading="FUNDRAISER CATEGORIES"
        badge=""
        webImage={bannerBg}
        mobileImage={bannerBg}
      />
      <section className="section  offerSection pb-0">
        <Container>
          <div lg={6} md={4} sm={2} className="grid-container">
            {state?.categoryList && state?.categoryList?.length > 0 && (
              <>
                {state?.categoryList?.map((category, index) => {
                  return (
                    <div className="mb-4 grid-item">
                      <div
                        style={{backgroundImage: `url(${category?.image}) `}}
                        onClick={() => handleFundraiseCategory(category)}
                        className="what__we-offer helpingItem helpingItem--simple position-relative"
                      >
                        <div className="mb-3">
                          <div className="">
                            <img
                              className=""
                              src={JSON.parse(JSON.stringify(category.icon))}
                              alt="category icon"
                            />
                          </div>
                        </div>
                        <div className="helpingItem__content">
                          <div className="helpingItem__heading">
                            {category.title}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </Container>
      </section>
      <Gallery />
    </>
  );
};

export default Categories;
