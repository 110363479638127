import React, { useState } from 'react';
import { Button, Container, Modal } from 'react-bootstrap';
import "react-toastify/dist/ReactToastify.css";
import CompleteProfileModal from './CompleteProfileModal';
import CompleteFundraiserModal from './CompleteFundraiserModal';
const Stepper = ({ tagModal, setTagModal, }) => {
  const [step, setStep] = useState(1);

  const handleNext = () => {
    setStep(step + 1);
  };

  const handlePrev = () => {
    setStep(step - 1);
  };
  const stepTitles = {
    profile: ['Complete Your Profile - Step 1', 'Add Case - Step 2'],
  };
  const renderStepContent = () => {
    switch (step) {
      case 1:
        return (
          <CompleteProfileModal />
        );

      case 2:
        return (
          <CompleteFundraiserModal setTagModal={setTagModal} />
        );

      default:
        return null;
    }
  };
  return (
    <Container className="my-5">
      <Modal show={tagModal} onHide={() => setTagModal(false)} style={{ zIndex: 1000, marginTop: "50px" }}>
        <Modal.Header>
          <Modal.Title>{stepTitles['profile'][step - 1]}</Modal.Title>
          <button onClick={() => setTagModal(false)} className="border-0">
            X
          </button>
        </Modal.Header>
        <Modal.Body>{renderStepContent()}</Modal.Body>
        <Modal.Footer>
          {step == 2 &&
            <Button
              className="btn__secondary   buttonPrimary  btn-border-primary"
              onClick={() => setTagModal(false)}
            >
              Close
            </Button>}
          {step !== 1 &&
            <Button className='py-3 btn__primary buttonPrimary' onClick={handlePrev} disabled={step === 1}>
              Previous
            </Button>
          }
          {step == 1 &&
            <Button
              className="btn__secondary   buttonPrimary  btn-border-primary"
              onClick={() => setTagModal(false)}
            >
              Close
            </Button>
          }
          {step == 1 &&
            <Button className="py-3 btn__primary buttonPrimary" onClick={handleNext}>
              Next
            </Button>
          }
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Stepper;
