/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useContext, useEffect} from "react";
import {Col, Container, Row, Card} from "react-bootstrap";
import BreadCrumb from "../components/BreadCrumb";
import {Link} from "react-router-dom";
import Context from "../Context/context";
import Slider from "../components/Slider";
import moment from "moment";
import banner from "../assets/img/blog-banner.jpg";
import mobilebanner from "../assets/img/blog-banner-mobile.jpg";
import {getBlog} from "../Api-Calls/blog";
import HelmetSEO from "../components/HelmetSEO";
import NoResultFound from "../components/NoResultFound";
import ReactPaginate from "react-paginate";

const Blogs = () => {
  const [state, dispatch] = useContext(Context);
  const blogPages = localStorage.getItem("blogPages");
  const getBlogList = async () => {
    try {
      const params = {
        page: 1,
        limit: 12,
      };
      const res = await getBlog(params);
      if (res?.status == 200) {
        let data = res?.data?.result?.result;
        let pages = res?.data?.result?.totalPages;
        dispatch({
          type: "BLOGS",
          payload: data,
        });
        localStorage.setItem("blogs", JSON.stringify(data));
        localStorage.setItem("blogPages", JSON.stringify(pages));
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getBlogList();
  }, []);

  const handlePageClick = async (event) => {
    const params = {
      page: event.selected + 1,
      limit: 12,
    };
    const res = await getBlog(params);
    if (res?.status == 200) {
      let data = res?.data?.result?.result;
      let pages = res?.data?.result?.totalPages;
      dispatch({
        type: "BLOGS",
        payload: data,
      });
      localStorage.setItem("blogs", JSON.stringify({data, pages}));
    }
  };
  return (
    <>
      <HelmetSEO title="Blogs" description="Blogs List" />
      <BreadCrumb
        heading="BLOGS"
        badge="Blogs"
        webImage={banner}
        mobileImage={mobilebanner}
        overlay={false}
      />
      <section className="container">
        <div className="section whyBuildingBridges">
          <Container>
            {state?.blogList && state?.blogList?.length > 0 ? (
              <>
                <Row lg={3} md={3} sm={2} xs={1} className="g-4">
                  {state?.blogList &&
                    state?.blogList?.length > 0 &&
                    state?.blogList?.map((blog) => {
                      return (
                        <Col>
                          <Link to={`/blogs/${blog?.slug}`}>
                            <div className={"card mb-0"}>
                              <div className="col-lg-12">
                                <Slider
                                  carouselinner1="carouse-linner1"
                                  dynamicClass="fundRaisingImgBackground2 w-100"
                                  item={blog?.attachments}
                                  alt={blog?.meta_image_description}
                                />
                              </div>
                              <div className="card-body p-2">
                                <h5 className={"card-title story-title"}>
                                  {blog?.title}
                                </h5>
                                <p className="card-text mb-0 line3"></p>
                                <a className="read-more" href="">
                                  Read More...
                                </a>
                                <p className="text-right">
                                  <small className="text-muted">
                                    Published on{" "}
                                    {moment(blog?.created).format("L")}
                                  </small>
                                </p>
                              </div>
                            </div>
                          </Link>
                        </Col>
                      );
                    })}
                </Row>
              </>
            ) : (
              <NoResultFound />
            )}
          </Container>
        </div>
      </section>
      {state?.blogList?.length >= 12 && (
        <ReactPaginate
          previousLabel="previous"
          breakLabel="..."
          nextLabel="next"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={blogPages}
          renderOnZeroPageCount={null}
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination justify-content-center"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          activeClassName="active"
          marginPagesDisplayed={2}
        />
      )}
    </>
  );
};

export default Blogs;
