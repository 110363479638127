import React from "react";
import {Controller} from "react-hook-form";

const TextInput = ({
  control,
  rules = {},
  className,
  name,
  placeholder,
  type,
  style,
  defaultValue,
  as,
  disabled,
  hideIcon,
  handleChan,
  errorMessage,
  borderError,
  inputMode,
  showIcon,
  min,
  markIcon,
  icon,
  showPassword,
  setShowPassword,
}) => {
  return (
    <>
      <Controller
        control={control}
        name={name}
        rules={rules}
        defaultValue={defaultValue}
        render={({field: {value, onChange, onBlur}, fieldState: {error}}) => (
          <>
            <input
              as={as}
              className={className}
              value={value}
              onChange={handleChan ? handleChan : onChange}
              onBlur={onBlur}
              disabled={disabled == "1" && defaultValue ? true : false}
              style={style}
              placeholder={placeholder}
              type={type}
              min={min}
            />
            {icon && (
              <div
                className="input-group-append align-items-end "
                style={{
                  position: "absolute",
                  top: "14px",
                  right: "12px",
                }}
              >
                <button
                  className="btn btn-sm btn-link py-0 "
                  type="button"
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                >
                  <img
                    width="18"
                    src={!showPassword ? showIcon : hideIcon}
                    alt="icon"
                  />
                </button>
              </div>
            )}
            {error && markIcon && (
              <div
                className="input-group-append align-items-end error-icon "
                title={error.message}
              >
                <img height="20px" src={markIcon} alt="icon" />
              </div>
            )}
            {!(borderError == "true") && (
              <>
                {error && (
                  <p
                    className={
                      errorMessage
                        ? "text-danger mx-2 my-2 position-absolute"
                        : "text-danger mx-2 my-2"
                    }
                    style={{top: "100%"}}
                  >
                    {error?.message}
                  </p>
                )}
              </>
            )}
          </>
        )}
      />
    </>
  );
};
export default TextInput;
