import React, {useEffect, useState} from "react";
import {Button, Container} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {Table} from "antd";
import {getSupporterPayment} from "../Api-Calls/supporter";
import Loader from "../components/loader";
import {useSelector} from "react-redux";

const Payment = () => {
  const {
    auth: {user},
  } = useSelector((store) => store);
  const [paymentList, setPaymentList] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const getSupporterPayments = async () => {
    try {
      const res = await getSupporterPayment(user?._id);
      if (res?.status == 200) {
        setPaymentList(res?.data?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSupporterPayments();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  const gotoSinglePayment = (item) => {
    navigate(`/single-payment/${item?._id}`, {state: item});
  };

  const gotoProofOfPayment = (item) => {
    navigate(`/upload-proof-of-payment/${item?._id}`);
  };
  const data = [];
  for (let i = 0; i < paymentList?.length; i++) {
    data.push({
      key: i + 1,
      title: paymentList[i]?.fundraise_id?.title,
      description: paymentList[i]?.fundraise_id?.description,
      urgent: paymentList[i]?.fundraise_id?.urgent ? "Urgent" : "Normal",
      payment_method: paymentList[i]?.payment_method,
      total_amount: paymentList[i]?.totalAmount,
      payment_status: paymentList[i]?.payment_status,
      action: (
        <>
          <Button
            onClick={() => gotoSinglePayment(paymentList[i])}
            className="btn-sm btn-info"
          >
            <i className="fa fa-eye"></i>
          </Button>{" "}
          {paymentList[i]?.payment_status == "Pending" && (
            <Button
              onClick={() => gotoProofOfPayment(paymentList[i])}
              className="btn-sm btn-warning"
            >
              <i className="fa fa-upload"></i>
            </Button>
          )}
        </>
      ),
    });
  }

  const columns = [
    {
      title: "Sr.",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Urgent",
      dataIndex: "urgent",
      key: "urgent",
    },
    {
      title: "Payment Method",
      dataIndex: "payment_method",
      key: "payment_method",
    },

    {
      title: "Total Amount",
      dataIndex: "total_amount",
      key: "total_amount",
    },
    {
      title: "Payment Status",
      dataIndex: "payment_status",
      key: "payment_status",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
  ];

  return (
    <>
      <section className="section--about mt-5">
        <section className="section section--white">
          <Container>
            <div className="col-md-12 mx-auto">
              <div className="mb-5 text-center">
                <h3>LIST OF DONATIONS</h3>
              </div>
              {loading ? (
                <>
                  <Loader />
                </>
              ) : (
                <>
                  <Table dataSource={data} columns={columns} />
                </>
              )}
            </div>
          </Container>
        </section>
      </section>
    </>
  );
};

export default Payment;
