import React, {useEffect, useState} from "react";
import {Container} from "react-bootstrap";
import {useParams} from "react-router-dom";
import "../../assets/css/MedicalFunding.css";
import OwlCarousel from "react-owl-carousel";
import TestimonialCard from "../../components/TestimonialCard";
import Accordion from "react-bootstrap/Accordion";
import testimonialBackground from "../../assets/img/Testimonials.jpg";
import {constantIcons, constantImages} from "../../Constant";
import {getFundraiseByCategorySlug} from "../../Api-Calls/categoryApi";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FundingForm from "../../components/FundingForm";
import FundraiserBreadCrumb from "../../components/FundraiserBreadCrumb";
import WhatBuildingBridgesOffer from "../../components/staticComponents/WhatBuildingBridgesOffer";
import ActiveFundraiser from "../../components/ActiveFundraiser";
import webBanner from "../../assets/img/staticImages/educational-crowdfunding-images/educational-banner.png";
import mobileBanner from "../../assets/img/staticImages/educational-crowdfunding-images/educational-banner-mobile.png";
import mobileImage from "../../assets/img/staticImages/educational-crowdfunding-images/mobile-image.png";
import HelmetSEO from "../../components/HelmetSEO";
import WhyChooseBuildingBridges from "../../components/WhyChooseBuildingBridges";
import SupportFundraiserFaqQuestion from "../../components/SupportFundraiserFaqQuestion";

const EducationFunding = () => {
  const {slug} = useParams();
  const images = constantImages();
  const icons = constantIcons();
  const [fundraiser, setFundraiser] = useState([]);

  const getFundraisebyCategotyId = async () => {
    try {
      const res = await getFundraiseByCategorySlug(slug);
      if (res?.status == 200) {
        setFundraiser(res?.data?.result?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getFundraisebyCategotyId();
  }, []);

  return (
    <>
      <HelmetSEO
        title="Educational Funding"
        description="Educational Funding"
      />

      <ToastContainer />
      <FundraiserBreadCrumb
        heading="Education Funding"
        description={
          <p>
            Building Bridges is an online fundraising platform that enables
            users to create fundraisers for deserving students, opening doors to
            knowledge and a brighter future. Your generosity empowers students
            to reach their full potential and make a positive impact on society.
          </p>
        }
        webImage={images.educationalSection2}
        mobileImage={mobileImage}
      />

      <ActiveFundraiser fundraiser={fundraiser} slug={slug} />
      <WhyChooseBuildingBridges
        mainHeading="Education"
        icon1={icons.educationalChooseIcon1}
        icon2={icons.educationalChooseIcon2}
        icon3={icons.educationalChooseIcon3}
        icon4={icons.educationalChooseIcon4}
        heading1="Support for Quality Education"
        heading2="Empowering Financial Aid Seekers"
        heading3="Positive Impact on the Society"
        heading4="Building a Supportive Community"
        description1="Building Bridges provides support to all the deserving students by enabling users to create fundraisers for empowering them through quality education for a brighter future."
        description2="Building Bridges empowers the financial aid seekers by providing them with the option of hassle free online fundraising. The collected amount can be withdrawn anytime."
        description3="Building Bridges enables efficient fundraising for educational cases of deserving students for empowering them to reach their full potential and make a positive impact on the society."
        description4="Building Bridges aims to create a supportive community that financially aids the deserving students from all walks of life, offering them a chance to rise above their circumstances."
      />

      <section className="">
        <FundingForm
          title="Building Bridges for Education Funding "
          paragraph={`
            Building Bridges allows students and other users to create Education fundraisers
            in three easy steps. This process allows donors to connect with deserving students 
            who are struggling to acquire education due to financial constraints.
            `}
          mobileBanner={mobileBanner}
          webBanner={webBanner}
        />
      </section>

      <WhatBuildingBridgesOffer />

      <section
        className=" testimonialSection py-5 section--gray"
        style={{
          backgroundImage: `url(${testimonialBackground}) `,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
        }}
      >
        <div className="sectionHeading text-center mb-5">
          <h2 className="mb-4 high-title title-background mt-5">
            Success Stories
          </h2>
          <div className="col-md-7 mx-auto divider"></div>
        </div>
        <div>
          <div className="sectionHeading  text-center mb-5"></div>
          <div className="container">
            <div className="col-md-9 mx-auto ">
              <OwlCarousel
                items={1}
                margin={30}
                autoplay={true}
                className="owl-theme fundRaisingSection testimonial-background"
              >
                <TestimonialCard image="false" />
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
      <SupportFundraiserFaqQuestion
        heading1="What are the Education funding projects and who do these projects benefit?"
        heading2="What educational expenses can be covered through these fundraisers?"
        heading3="How can students or families directly apply for funding through Building Bridges?"
        heading4="Can supporters from around the world contribute to campaigns on Building Bridges?"
        heading5="Can donors receive updates on the students they support?"
        heading6="How can I get involved beyond donating?"
        description1="Building Bridges is an online funding platform which is
        dedicated to raising funds for educational purposes, helping
        students go to schools, colleges, universities, and other
        institutes that focus on skill building. Various
        individuals, educational institutions, and organizations can
        create campaigns. Supporters can then contribute funds
        online, and the collected donations are used to help
        students cover educational expenses."
        description2="Fund raisers created on Building Bridges platform can cover
        a range of expenses, including tuition fees, textbooks,
        online learning resources, equipment, accommodation, and
        other education-related costs."
        description3="Students or families that required funds for their education
        can create a fundraiser anytime on the Building Bridges
        platform, sharing their educational goals, financial need,
        and other relevant details."
        description4="Yes, Building Bridges is accessible globally, allowing
        supporters from various locations to create fundraisers and
        contribute to campaigns by either donating or by sharing the
        fundraisers on their personal social media platforms."
        description5="Yes"
        description6="Apart form donating, you can always create a fundraiser for
        supporting any cause that is either close to your heart or
        has been recommended to you by someone you know. You can
        become a part of our fundraising campaigns by sharing the
        fundraisers on your personal social media accounts and
        encourage your friends and loved ones to do the same.
        Volunteering to spread awareness and encourage potential
        donors to support our platform and give special attention to
        the Education Funding charity page is also an option.
        Building Bridged offers you the oppertunity to create a
        fundraiser in the name of your deceased loved ones too."
      />
    </>
  );
};

export default EducationFunding;
