import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { getAllCategory } from "../Api-Calls/categoryApi";
import { addContact } from "../Api-Calls/contactUs";
import "react-toastify/dist/ReactToastify.css";
import logo from "../assets/img/logo/PoweredByVariationBlack.svg";
import { SwalErrorMessage, SwalMessage } from "./sweetAlertMessage";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import ProfileInformationModal from "./ProfileInformaionModal";
import { updateUserState } from "../Features/user/authSlice";
import { getUserById } from "../Api-Calls/authentication";
import Stepper from "./Stepper";
import { Link } from "react-router-dom";

export default function FundingForm({
  title,
  paragraph,
  mobileBanner,
  webBanner,
  ramadanContent,
}) {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm({ criteriaMode: "all" });
  const {
    auth: { user },
  } = useSelector((store) => store);
  const [countryState, setCountryState] = useState({
    country: "",
    countryCode: "",
  });
  const navigate = useNavigate();
  const dispatchRedux = useDispatch();
  const [category, setCategory] = useState([]);
  const [tagModal, setTagModal] = useState(false);
  const [maskNo, setMaskNo] = useState("");
  const getCategoryData = async () => {
    const res = await getAllCategory();
    try {
      if (res?.status == 200) {
        setCategory(res?.data?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getCategoryData();
  }, []);

  const onSubmit = async (data) => {
    let details = {
      ...data,
      phoneNo: data.cell,
      subject: data.categoryType,
      country: countryState.country,
    };
    delete details.categoryType;
    delete details.cell;
    try {
      const res = await addContact(details);
      if (res?.status == 201) {
        SwalMessage(res?.data?.status, res?.data?.message, "success");
        reset({
          name: "",
          email: "",
          cell: "",
          categoryType: "",
          message: "",
        });
      }
    } catch (error) {
      SwalErrorMessage(
        "Oops!" + " " + error?.response?.data?.status,
        error?.response?.data?.message,
        "error",
        true
      );
    }
  };
  const onCountryChange = (e) => {
    setCountryState({ country: `${e.label} `, countryCode: `+${e.code}` });
    setMaskNo(e.mask);
  };

  const UpdateUserProfile = async () => {
    if (user) {
      try {
        const res = await getUserById(user?._id);
        if (res?.status == 200) {
          let userData = res?.data?.result?.user;
          dispatchRedux(updateUserState(res?.data?.result?.user));
          if (
            userData?.status === "Rejected" ||
            userData?.status === "Pending"
          ) {
            if (
              userData?.country !== "" &&
              userData?.phoneNo !== "" &&
              userData?.address !== "" &&
              userData?.hear_about !== "" &&
              userData?.cnicNo !== "" &&
              userData?.zipcode !== "" &&
              userData?.cnic_picture?.length > 0
            ) {
              swal({
                title: "Please Note",
                text: "Your personal information has been added, please wait for admin's approval to proceed forward.",
                icon: "warning",
                buttons: true,
                dangerMode: true,
              });
            } else {
              try {
                swal({
                  title: "Confirmation!",
                  text: "Before creating a case, kindly ensure that you have completed your profile.",
                  icon: "warning",
                  buttons: true,
                  dangerMode: true,
                }).then((data) => {
                  if (data) {
                    setTagModal(true);
                  }
                });
              } catch (err) {
                console.log(err);
              }
            }
          }
        }
        if (res?.data?.result?.user?.status === "Verified") {
          navigate("/dashboard/add-fundraise");
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      navigate("/login");
    }
  };
  return (
    <>
      <div
        className="solution5 position-relative"
        style={{
          backgroundImage: `url(${
            window.innerWidth <= 600 ? mobileBanner : webBanner
          })`,
        }}>
        <div>
          <div
            class="overlay"
            style={{
              backgroundColor: "rgba(0, 40, 102, 0.4)",
              zIndex: "-1",
            }}></div>
          <div className="container">
            <div className="d-flex flex-column flex-lg-row align-items-center justify-content-between py-5 text-md-center text-sm-center text-lg-start">
              <div className=" mx-lg-5">
                <div className="textSectionMF  mobile-veiw-alignment">
                  <h1 className="heading">
                    {title} <br />
                  </h1>
                  <p className="heading-p text-left mt-4 mb-3 mb-md-0">
                    {paragraph}
                  </p>
                </div>
              </div>
              <div className="maindiv-form mt-0 pt-0 pt-lg-5 my-5 ">
                <div className=" form-div mt-3 mb-4 mb-lg-0">
                  <div className="formSectionMF p-4 ">
                    <img className="form-img" src={logo} alt="fundingImage" />
                    <p className="heading-form">Help Us, Help You!</p>
                    <div className=" mx-lg-5">
                      <div className="textSectionMF mobile-veiw-alignment">
                        {ramadanContent ? (
                          <p className=" text-center mt-4 mb-4" style={{fontSize: "20px"}}>
                            Click on the button below to view our ramadan giving
                            campaign
                          </p>
                        ) : (
                          <p className=" text-center mt-4 mb-4 ">
                            Click on the button below to start a new fundraiser
                            and help the helpless
                          </p>
                        )}

                        <div className="mobile-view d-flex alighn-item-center justify-content-center mb-5">
                          <li className="list-inline-item">
                            <>
                              <a className="text-decoration-none">
                                {ramadanContent ? (
                                  <Link
                                    className="menus"
                                    to={"/ramadan-campaign"}>
                                    <button className="m-auto py-3 btn__primary buttonPrimary ">
                                      View
                                    </button>
                                  </Link>
                                ) : (
                                  <>
                                    <button
                                      onClick={UpdateUserProfile}
                                      className="m-auto py-3 btn__primary buttonPrimary ">
                                      Start a Fundraiser
                                    </button>
                                  </>
                                )}
                              </a>
                            </>
                          </li>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Stepper tagModal={tagModal} setTagModal={setTagModal} />
    </>
  );
}
