import React, { useEffect, useRef } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const LazyBackgroundImage = ({ imageUrl, to, className, id,category }) => {
  const divRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Load the image when it becomes visible
            divRef.current.style.backgroundImage = `url(${imageUrl})`;
            observer.unobserve(divRef.current);
          }
        });
      },
      { threshold: 0.5 } // Adjust the threshold as needed
    );

    observer.observe(divRef.current);

    return () => {
      observer.disconnect();
    };
  }, [imageUrl]);

  return (
    <Link
      ref={divRef}
      to={to}
      className={className}
      id={id}
    >
      <div className="mb-3">
        <div className="">
          {/* Consider loading the category.icon lazily as well if needed */}
          <img
            className=""
            src={JSON.parse(JSON.stringify(category.icon))}
            alt="category icon"
            loading="lazy"
          />
        </div>
      </div>
      <div className="helpingItem__content">
        <div className="helpingItem__heading">
          {category.title}
        </div>
      </div>
    </Link>
  );
};

export default LazyBackgroundImage;
