import React, {useState, useEffect} from "react";
import DashBoardFooter from "../DashBoardFooter";
import {ToastContainer} from "react-toastify";
import Jumbotran from "../Jumbotran";
import SideMenu from "../SideMenu";
import {useNavigate} from "react-router-dom";
import {getSupporterPayment} from "../../../Api-Calls/supporter";
import {Button, Modal} from "react-bootstrap";
import {Table} from "antd";
import moment from "moment";
import {useSelector} from "react-redux";
import userPicture from "../../../assets/img/userDefaultImg.png";
import HelmetSEO from "../../../components/HelmetSEO";
import UserNameLength from "../../../components/UserNameLength";

export default function Donation() {
  const [paymentList, setPaymentList] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const {
    auth: {user},
  } = useSelector((store) => store);
  const [tagModal, setTagModal] = useState(false);
  const [transactionDetail, setTransactionDetail] = useState();

  const showTagModal = (e, detail) => {
    setTransactionDetail(detail);
    setTagModal(true);
  };
  const handleClose = () => {
    setTagModal(false);
  };
  const getSupporterPayments = async () => {
    try {
      const res = await getSupporterPayment(user?._id);
      if (res?.status == 200) {
        setPaymentList(res?.data?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSupporterPayments();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);
  const gotoSinglePayment = (item) => {
    navigate(`/single-payment/${item?.slug}`, {state: item});
  };

  const gotoProofOfPayment = (item) => {
    window.location.replace(item?.checkout_url);
  };
  const data = [];
  for (let i = 0; i < paymentList?.length; i++) {
    data.push({
      sr: paymentList[i]?.seqId,
      fundraiser_title: paymentList[i]?.fundraise_id?.title,
      fundraiser_description: paymentList[i]?.fundraise_id?.description,
      payment_method: paymentList[i]?.payment_method,
      date: moment(paymentList[i]?.updated).format("L"),
      amount: <>Rs. {paymentList[i]?.amount}</>,
      payment_status: paymentList[i]?.payment_status,
      action: (
        <>
          <Button
            onClick={(e) => showTagModal(e, paymentList[i])}
            className="action-button-view"
          >
            <i className="fa fa-eye"></i>
          </Button>{" "}
          {paymentList[i]?.payment_status == "Pending" && (
            <Button
              onClick={() => gotoProofOfPayment(paymentList[i])}
              className="action-button-edit"
            >
              <i className="fa fa-edit"></i>
            </Button>
          )}
        </>
      ),
    });
  }

  const columns = [
    {
      title: "Sr.",
      dataIndex: "sr",
      key: "sr",
    },
    {
      title: "Fundraiser Title",
      dataIndex: "fundraiser_title",
      key: "fundraiser_title",
    },
    {
      title: "Fundraiser Description",
      dataIndex: "fundraiser_description",
      key: "fundraiser_description",
    },
    {
      title: "Payment Method",
      dataIndex: "payment_method",
      key: "payment_method",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Payment Status",
      dataIndex: "payment_status",
      key: "payment_status",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
    },
  ];

  return (
    <>
      <HelmetSEO title="Donations" description="Donations" />
      <div className="App">
        <div className="">
          <SideMenu />
          <div className="main-panel">
            <div className="container">
              <Jumbotran
                page="Donations"
                desc="Donations"
                supporter="Donations"
              />
              <div className="page-inner">
                <>
                  <Table dataSource={data} columns={columns} />
                  <Modal show={tagModal} onHide={handleClose}>
                    <Modal.Header>
                      <Modal.Title>Single Transaction Detail</Modal.Title>
                      <button onClick={handleClose} className="border-0">
                        X
                      </button>
                    </Modal.Header>
                    <Modal.Body style={{color: "black", fontSize: "18px"}}>
                      <div className="p-2">
                        <div className="d-flex mb-2">
                          <div className="mb-1 flex1">
                            <strong>Username:</strong>
                          </div>
                          <div className="mx-2 flex2 text-capitalize">
                            <UserNameLength
                              username={
                                transactionDetail?.personalInfo?.username
                              }
                              length={30}
                            />
                          </div>
                        </div>

                        <div className="d-flex mb-2">
                          <div className="mb-1 flex1">
                            <strong>Email:</strong>
                          </div>
                          <div className="mx-2 flex2">
                            {transactionDetail?.personalInfo?.email}
                          </div>
                        </div>
                        <div className="d-flex mb-2">
                          <div className="mb-1 flex1">
                            <strong>Profile Image:</strong>
                          </div>
                          <div className="mx-2 flex2">
                            <img
                              src={
                                transactionDetail?.personalInfo?.picture != ""
                                  ? transactionDetail?.personalInfo?.picture
                                  : userPicture
                              }
                              style={{
                                height: "100px",
                                width: "100px",
                                objectFit: "cover",
                              }}
                              alt="profileImage"
                            />
                          </div>
                        </div>
                        <div className="d-flex mb-4">
                          <div className="mb-1 flex1">
                            <strong>Fundraiser Title:</strong>
                          </div>
                          <div className="mx-2 flex2">
                            {transactionDetail?.fundraise_id?.title}
                          </div>
                        </div>
                        <div className="d-flex mb-4">
                          <div className="mb-1 flex1">
                            <strong>Fundraiser Description:</strong>
                          </div>
                          <div className="mx-2 flex2">
                            {transactionDetail?.fundraise_id?.description}
                          </div>
                        </div>
                        <div className="d-flex mb-4">
                          <div className="mb-1 flex1">
                            <strong>Payment Method:</strong>
                          </div>
                          <div className="mx-2 flex2">
                            {transactionDetail?.payment_method}
                          </div>
                        </div>
                        <div className="d-flex mb-4">
                          <div className="mb-1 flex1">
                            <strong>Total Amount:</strong>
                          </div>
                          <div className="mx-2 flex2">
                            Rs. {transactionDetail?.amount}
                          </div>
                        </div>
                        <div className="d-flex mb-4">
                          <div className="mb-1 flex1">
                            <strong>Payment Status:</strong>
                          </div>
                          <div className="mx-2 flex2">
                            {transactionDetail?.payment_status}
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        className="fun-donate-btn1 mx-3"
                        onClick={handleClose}
                      >
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </>
              </div>
            </div>

            <DashBoardFooter />
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
}
