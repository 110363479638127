import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  Button,
  Form,
  InputGroup,
  ProgressBar,
  ToggleButton,
  ToggleButtonGroup,
} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import ShowTerm from "./ShowTerm";
import Card from "react-bootstrap/Card";

const FoooterDonation = () => {
  const [showTerms, setShowTerms] = useState(false);
  const showTermCondition = () => {
    setShowTerms(!showTerms);
  };

  return (
    <>
      <Modal.Header className="border-0" closeButton>
        <Modal.Title className="h6">DONATE NOW</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Col xs="12" md="12">
          <InputGroup className="mb-3 giveTotals__input">
            <InputGroup.Text
              id="basic-addon1"
              className="d-flex justify-content-center"
              style={{ minWidth: "100px" }}
            >
              $
            </InputGroup.Text>
            <Form.Control
              placeholder="100"
              aria-label="ammount"
              aria-describedby="basic-addon1"
            />
          </InputGroup>
        </Col>
        <Col xs="12">
          <ToggleButtonGroup
            className="giveTotals__checks"
            type="radio"
            name="options"
            defaultValue={1}
          >
            <ToggleButton id="tbg-radio-1" variant="secondary" value={1}>
              $10
            </ToggleButton>
            <ToggleButton id="tbg-radio-2" variant="secondary" value={2}>
              $25
            </ToggleButton>
            <ToggleButton id="tbg-radio-3" variant="secondary" value={3}>
              $50
            </ToggleButton>
            <ToggleButton id="tbg-radio-4" variant="secondary" value={4}>
              $100
            </ToggleButton>
            <ToggleButton id="tbg-radio-5" variant="secondary" value={5}>
              $250
            </ToggleButton>
            <ToggleButton
              id="tbg-radio-6"
              variant="secondary"
              value={6}
              className="custom__amount"
            >
              Custom amount
            </ToggleButton>
          </ToggleButtonGroup>
        </Col>
        <div className="d-flex mb-3 radio__button">
          <div className="form-check mr-3">
            <Form.Check
              type={"radio"}
              id={"stripeCreditCard"}
              defaultChecked={true}
              label={"Stripe - Credit Card"}
            />
          </div>
        </div>
        <div>Personal Info</div>
        <hr className={"mt-1"} />

        <div>
          <Row>
            <Col xl="6" sm="12" className="mb-3">
              <Form.Label htmlFor="firstName">
                First Name <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type={"text"}
                id={"firstName"}
                placeholder={"First Name"}
                label={"First Name"}
              />
            </Col>
            <Col xl="6" sm="12" className="mb-3">
              <Form.Label htmlFor="lastName">
                Last Name <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type={"text"}
                id={"lastName"}
                placeholder={"Last Name"}
                label={"Last Name"}
              />
            </Col>
          </Row>
          <Row>
            <Col xl="12" sm="12" className="mb-3">
              <Form.Label htmlFor="emailAddress">
                Email Address <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type={"email"}
                id={"emailAddress"}
                placeholder={"Email Address"}
                label={"Email Address"}
              />
            </Col>
          </Row>

          <Form.Label>Credit Card Info</Form.Label>
          <hr className={"mt-1"} />
          <Form.Text> This is a secure SSL encrypted payment.</Form.Text>
          <Card className="my-3 border-0">
            <Card.Header className=" border-bottom">
              Notice: Credit Card fields are disabled. Please connect and
              configure your Stripe account to accept donations.
            </Card.Header>
          </Card>

          {showTerms && <ShowTerm />}
          <div
            className={"d-flex align-items-center justify-content-between mb-3"}
          >
            <div className="form-check mx-3">
              <Form.Check
                className="px-1"
                type={"checkbox"}
                id={"agree"}
                label={"Agree to Terms?"}
              />
            </div>
            <Form.Text
              className={"text-decoration-underline "}
              style={{ cursor: "pointer" }}
              onClick={showTermCondition}
            >
              {showTerms ? "Hide Terms" : "Show Terms"}
            </Form.Text>
          </div>
          <InputGroup className="mb-3 ">
            <InputGroup.Text
              style={{ flex: 0.2, textAlign: "center" }}
              id="basic-addon2"
            >
              Donation Total:
            </InputGroup.Text>
            <Form.Control
              placeholder="$ 100"
              aria-label="ammount"
              aria-describedby="basic-addon2"
            />
          </InputGroup>
        </div>
        <Button
          className="btn btn-primary btn-lg w-100"
          expend={"block"}
          variant="primary"
        >
          Donate Now
        </Button>
      </Modal.Body>
    </>
  );
};

export default FoooterDonation;
