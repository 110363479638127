import React, { useEffect, useState } from "react";
import SideMenu from "../SideMenu";
import Jumbotran from "../Jumbotran";
import { getUserSupporter } from ".././../../Api-Calls/supporter";
import DashBoardFooter from "../DashBoardFooter";
import { ToastContainer } from "react-toastify";
import SupporterDatatable from "./SupporterDatatable";
import Context from "../../../Context/context";
import { useContext } from "react";
import { useSelector } from "react-redux";

export default function Payments() {
  const { auth: { user } } = useSelector(store => store)
  const [supporter, setSupporter] = useState([]);
  const [state] = useContext(Context);

  useEffect(() => {
    getSupporterData();
  }, []);
  const getSupporterData = async () => {
    try {
      const res = await getUserSupporter(user?._id);
      if (res?.status == 200) {
        setSupporter(res?.data?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const columns = [
    {
      title: "Sr.",
      dataIndex: "sr",
    },
    {
      title: " Username",
      dataIndex: "username",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Fundraise Name",
      dataIndex: "title",
    },
    {
      title: "Payment Status",
      dataIndex: "payment_status",
    },
    {
      title: "Payment Method",
      dataIndex: "payment_method",
    },
    {
      title: "Amount",
      dataIndex: "totalAmount",
    },
    {
      title: "Action",
      dataIndex: "action",
    },
  ];
  return (
    <div className="App">
      <div className="">
        <SideMenu />
        <div
          className="main-panel"
          style={{
            width: state.opensidebar == true ? `calc(100% - 250px)` : "97%",
          }}>
          <div className="container">
            <Jumbotran page="Payments" desc="Payments" supporter="Payments" />
            <div className="page-inner">
              <SupporterDatatable
                columns={columns}
                supporter={supporter}
                setSupporter={setSupporter}
              />
            </div>
          </div>

          <DashBoardFooter />
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
