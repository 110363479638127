import React, {useState} from "react";
import {Container, Form} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {Link, useNavigate} from "react-router-dom";
import TextInput from "../components/TextInput";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import eyeIcon from "../assets/img/Hide.png";
import hideEyeIcon from "../assets/img/Hide-2.png";
import googleIcon from "../assets/img/staticIcons/Google.svg";
import WebImage from "../assets/img/Signin.png";
import MobileImage from "../assets/img/signin-mobile.png";
import {signIn} from "../Features/user/authSlice";
import {useDispatch} from "react-redux";
import HelmetSEO from "../components/HelmetSEO";
import {loginWithGoogle} from "../Api-Calls/authentication";
// import FacebookLogin from 'react-facebook-login';
const Login = () => {
  const {register, handleSubmit, control} = useForm();
  const dispatchRedux = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const onSubmit = (data) => {
    setLoader(true)
    dispatchRedux(signIn({email: data.email, password: data.password})).then(
      (res) => {
        setLoader(false)
        if (res.type === "user/signin/fulfilled") {
          navigate("/");
        }
      }
    );
  };
  const loginGoogle = async (e) => {
    e.preventDefault();
    await loginWithGoogle().then((res) => {
      console.log(res, "response");
    });
  };
  return (
    <>
      <HelmetSEO title="Login" description="Login" />

      <section className="signUpSection">
        <Container>
          <div className="shadow cardShadow">
            <div className="row">
              <div className="col-lg-6">
                <div
                  className="sideSectionAuthentication position-relative"
                  style={{
                    height: `${window?.innerWidth <= 600 ? "250px" : ""}`,
                    backgroundImage: `url(${
                      window?.innerWidth <= 600 ? MobileImage : WebImage
                    })`,
                  }}
                >
                  <div class="auth-overlay"></div>
                  <div className="position-relative"></div>
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="formAuthentication"
                  style={{paddingRight: "24px"}}
                >
                  <form
                    className="w-100 p-4"
                    onSubmit={handleSubmit(onSubmit)}
                    autoComplete="off"
                  >
                    <h2 className="text-center text-uppercase my-5">
                      Sign in to Building Bridges
                    </h2>
                    <Form.Label className="mb-1">
                      <b>Email Address</b>
                    </Form.Label>
                    <div className="mb-1">
                      <TextInput
                        name={"email"}
                        className="form-control"
                        rules={{
                          required: "Email field is required.",
                          pattern: {
                            value: /^\s*(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/,
                            message: "Invalid  email address.",
                          },
                        }}
                        control={control}
                        placeholder="Email address"
                      />
                    </div>

                    <Form.Label className="mt-3 mb-1">
                      <b>Password</b>
                    </Form.Label>
                    <div className="mb-1 position-relative">
                      <TextInput
                        type={`${showPassword ? "text" : "password"}`}
                        {...register("password")}
                        className={`form-control `}
                        control={control}
                        showIcon={eyeIcon}
                        icon="true"
                        hideIcon={hideEyeIcon}
                        placeholder="Password"
                        setShowPassword={setShowPassword}
                        showPassword={showPassword}
                        rules={{
                          required: "Password field is required.",
                          minLength: {
                            value: 6,
                            message: "Password must be 6 characters long.",
                          },
                        }}
                      />
                    </div>

                    <div className="mt-4 d-flex justify-content-between forgotPasswordAuth">
                      <Link to={"/forgot-password"}>Forgot your password?</Link>
                      {loader ? (
                        <button className="btn__primary shadow buttonPrimary">
                          <i
                            className="fa mx-3 fa-circle-o-notch fa-spin"
                            style={{fontSize: "24px"}}
                          ></i>
                        </button>
                      ) : (
                      <button
                        type="Submit"
                        className="btn__primary shadow buttonPrimary"
                      >
                        Sign in
                      </button>
                      )}
                    </div>
                    <div className="d-flex justify-content-center align-items-center mt-5">
                      <div
                        className="google-text"
                        style={{fontSize: "20px", marginRight: "10px"}}
                      >
                        Continue With
                      </div>
                      <div className="google-divider"></div>
                      <div className="google-container ml-2" onClick={(event) => loginGoogle(event)}>
                        <img
                          className="google-icon"
                          src={googleIcon}
                          alt="Google Icon"
                        />
                        <div
                          className="google-text"
                          style={{fontSize: "20px", marginLeft: "10px"}}
                        >
                          Google
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-center mt-3">
                      <p className="mt-3 dontHaveAccount">
                        Dont have an account yet?{" "}
                        <span>
                          <Link to={"/signup"}>Sign up for free!</Link>
                        </span>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <ToastContainer />
        {/* <FacebookLogin
              appId={process.env.REACT_APP_FACEBOOK_APP_ID}
              autoLoad={true}
              fields="name,email,picture"
              scope="public_profile,user_friends"
              callback={responseFacebook}
              icon="fa-facebook" /> */}
      </section>
    </>
  );
};

export default Login;
