import React from "react";

const ShowTerm = () => {
  return (
    <div>
      <p>
        Acceptance of any contribution, gift or grant is at the discretion of
        the Alone Covid 19. The Alone Covid 19 will not accept any gift unless
        it can be used or expended consistently with the purpose and mission of
        the Alone Covid 19.
      </p>

      <p>
        No irrevocable gift, whether outright or life-income in character, will
        be accepted if under any reasonable set of circumstances the gift would
        jeopardize the donor’s financial security.
      </p>

      <p>
        The Alone Covid 19 will refrain from providing advice about the tax or
        other treatment of gifts and will encourage donors to seek guidance from
        their own professional advisers to assist them in the process of making
        their donation.
      </p>

      <p>
        The Alone Covid 19 will accept donations of cash or publicly traded
        securities. Gifts of in-kind services will be accepted at the discretion
        of the Alone Covid 19.
      </p>

      <p>
        {" "}
        Certain other gifts, real property, personal property, in-kind gifts,
        non-liquid securities, and contributions whose sources are not
        transparent or whose use is restricted in some manner, must be reviewed
        prior to acceptance due to the special obligations raised or liabilities
        they may pose for Alone Covid 19.
      </p>

      <p>
        {" "}
        The Alone Covid 19 will provide acknowledgments to donors meeting tax
        requirements for property received by the charity as a gift. However,
        except for gifts of cash and publicly traded securities, no value shall
        be ascribed to any receipt or other form of substantiation of a gift
        received by Alone Covid 19.
      </p>

      <p>
        The Alone Covid 19 will respect the intent of the donor relating to
        gifts for restricted purposes and those relating to the desire to remain
        anonymous. With respect to anonymous gifts, the Alone Covid 19 will
        restrict information about the donor to only those staff members with a
        need to know.
      </p>

      <p>
        The Alone Covid 19 will not compensate, whether through commissions,
        finders' fees, or other means, any third party for directing a gift or a
        donor to the Alone Covid 19.
      </p>
    </div>
  );
};

export default ShowTerm;
