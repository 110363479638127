import React from "react";
import docxImage from "../assets/img/staticIcons/docx.png";
import pdfImage from "../assets/img/staticIcons/pdf.png";
const DocumentTab = (props) => {
  const {fundraise} = props;
  return (
    <section className="aboutSectionFundraise mt-5">
        <h4>Documents</h4>
      <div className="row mt-5">
        {fundraise && fundraise?.documents?.length > 0 ? (
          <>
            {fundraise?.documents?.map((item) => {
             
              return (
                <div className="col-lg-2 col-6" key={item?.type}>
                  <div className="card">
                  {" "}
                  {item?.type === "image" ? (
                    <a href={`${item.url}`} download={true} title="image">
                    <img  
                      className="w-100 py-4"
                      style={{
                        height: '200px',
                        objectFit: "contain",
                      }}
                      src={item?.url}
                      alt="documentImage"
                    />
                    </a>

                  ) : item?.type === "docx" ? (
                    <a href={`${item.url}`} download={true} title="docx">
                      <img
                        className="w-100 py-4"
                        src={docxImage}
                        alt="docx"
                        style={{
                          height: '200px',
                          objectFit: "contain",
                        }}
                      />
                    </a>
                  ) : (
                    item?.type === "pdf" && (
                      <div className="pdf-container">
                        <a
                          href={`${item?.url}`}
                          download={true}
                          title="pdf"
                        >
                          <img
                            className="w-100 py-4"
                            src={pdfImage}
                            alt="pdf"
                            style={{
                              height: '200px',
                              objectFit: "contain",
                            }}
                          />
                        </a>
                      </div>
                    )
                  )}
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <>
            <div className="text-center">
              <div className="col-md-8 mx-auto">
                <p>Fundraiser documents not available.</p>
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default DocumentTab;
