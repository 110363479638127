import axios from "axios";

export const getBlog = async (param) => {
  let url = "/blog/get-blogs";
  if (param && param.page && param.limit) {
    url = `/blog/get-blogs?page=${param.page}&limit=${param.limit}`;
  }
  return await axios.get(`${process.env.REACT_APP_BACKEND_URL}${url}`);
};

export const getBlogById = async (id) => {
  return await axios.get(`${process.env.REACT_APP_BACKEND_URL}/blog/get-blog-by-id/${id}`, {
    headers: {
      "Content-type": "application/json",
    },
  });
};

export const getBlogBySlug = async (slug) => {
  return await axios.get(
    `${process.env.REACT_APP_BACKEND_URL}/blog/get-blog-by-slug/${slug}`,
    {
      headers: {
        "Content-type": "application/json",
      },
    }
  );
};
