import React, {useEffect, useState} from "react";
import {Container} from "react-bootstrap";
import {useParams} from "react-router-dom";
import "../../assets/css/MedicalFunding.css";
import OwlCarousel from "react-owl-carousel";
import TestimonialCard from "../../components/TestimonialCard";
import Accordion from "react-bootstrap/Accordion";
import testimonialBackground from "../../assets/img/Testimonials.jpg";
import {constantIcons, constantImages} from "../../Constant";
import {getFundraiseByCategorySlug} from "../../Api-Calls/categoryApi";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FundingForm from "../../components/FundingForm";
import FundraiserBreadCrumb from "../../components/FundraiserBreadCrumb";
import WhatBuildingBridgesOffer from "../../components/staticComponents/WhatBuildingBridgesOffer";
import ActiveFundraiser from "../../components/ActiveFundraiser";
import webBanner from "../../assets/img//staticImages/memorial-crowdfunding-images/memorial-banner.png";
import mobileBanner from "../../assets/img/staticImages/memorial-crowdfunding-images/memorial-banner-mobile.png";
import mobileImage from "../../assets/img/staticImages/memorial-crowdfunding-images/mobile.Image.png";
import HelmetSEO from "../../components/HelmetSEO";
import WhyChooseBuildingBridges from "../../components/WhyChooseBuildingBridges";
import SupportFundraiserFaqQuestion from "../../components/SupportFundraiserFaqQuestion";

const MemorialFunding = () => {
  const {slug} = useParams();
  const images = constantImages();
  const icons = constantIcons();
  const [fundraiser, setFundraiser] = useState([]);

  const getFundraisebyCategotyId = async () => {
    try {
      const res = await getFundraiseByCategorySlug(slug);
      if (res?.status == 200) {
        setFundraiser(res?.data?.result?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getFundraisebyCategotyId();
  }, []);

  return (
    <>
      <HelmetSEO title="Memorial Funding" description="Memorial Funding" />

      <ToastContainer />
      <FundraiserBreadCrumb
        heading="Memorial Funding"
        description={
          <p>
            Building Bridges allows users to create fundraisers to cover funeral
            and burial costs. It also allows the users to honour their departed
            loved ones by supporting causes close to their hearts, ensuring
            their impact lives on by making a difference in the world even after
            their departure.
          </p>
        }
        webImage={images.memorialsection3}
        mobileImage={mobileImage}
      />

      <ActiveFundraiser fundraiser={fundraiser} slug={slug} />
      <WhyChooseBuildingBridges
        mainHeading="Memorial"
        icon1={icons.memorialChoosseIcon1}
        icon2={icons.memorialChoosseIcon2}
        icon3={icons.memorialChoosseIcon3}
        icon4={icons.memorialChoosseIcon4}
        heading1="Support for Funeral costs"
        heading2=" Empowering Financial Aid Seekers"
        heading3="Honour Deceased Loved ones"
        heading4="Building a Supportive Community"
        description1=" Building Bridges provides the option to create fundraisers for financially struggling bereaved families of those individuals who have either died naturally or in accidents."
        description2="Building Bridges empowers the financial aid seekers by providing them with the option of hassle free online fundraising. The collected amount can be withdrawn anytime."
        description3="Building Bridges supports families and individuals in honouring their departed loved ones, by allowing them to create fundraisers to support various charity causes in their name."
        description4="Building Bridges aims to create a supportive community that financially aids families to cover funeral and burial cost of their loved ones, offering assistance at a critical point in time."
      />

      <section className="">
        <FundingForm
          title="Building Bridges for Memorial Funding "
          paragraph={`
            Building Bridges allows you to create Funeral & Memorial fundraisers in three easy steps. 
            This process allows deprived families to cover funeral and burial costs of their loved ones. 
            You can also create fundraisers to support causes in the name of your deceased loved ones.
            `}
          mobileBanner={mobileBanner}
          webBanner={webBanner}
        />
      </section>

      <WhatBuildingBridgesOffer />

      <section
        className=" testimonialSection py-5 section--gray"
        style={{
          backgroundImage: `url(${testimonialBackground}) `,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
        }}
      >
        <div className="sectionHeading text-center mb-5">
          <h2 className="mb-4 high-title title-background mt-5">
            Success Stories
          </h2>
          <div className="col-md-7 mx-auto divider"></div>
        </div>
        <div>
          <div className="sectionHeading text-center mb-5"></div>
          <div className="container">
            <div className="col-md-9 mx-auto ">
              <OwlCarousel
                items={1}
                margin={30}
                autoplay={true}
                className="owl-theme fundRaisingSection testimonial-background"
              >
                <TestimonialCard image="false" />
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
      <SupportFundraiserFaqQuestion
        heading1="What is Funeral and Memorial funding on Building Bridges?"
        heading2="Can I choose the specific Memorial projects to support?"
        heading3="How can I ensure that the funding for funeral will be instantly collected?"
        heading4="What types of memorial projects are available on Building Bridges?"
        heading5="Can I involve the recipient organization in the memorial process?"
        heading6="How can I get involved beyond donating?"
        description1="Building Bridges is an online funding platform which enables
        efficient fundraising for providing immediate funereal and
        burial services to the deprived families who have just lost
        a loved one and can not afford to financially cover the last
        rites. The memorial funding allows donors to create a
        lasting tribute to a loved one by contributing to projects
        or causes that hold special significance and honour their
        memory."
        description2="Yes, Building Bridges allows the donors and supporters to
        browse and choose memorial projects that resonate with them
        and honour the memory of their loved one. You can choose a
        project that reflects the deceased person's interests or
        causes close to their heart."
        description3="Building Bridges supports swift collection and disbursement
        of funds for urgent causes featured on the platform. Using
        the Building Bridges platform, our users create and share
        fundraisers on the platform and the share those on their
        personal social media accounts. After the fundraiser reaches
        the potential donors, and the desired fund is collected, it
        can be withdrawn by the user at anytime to be handed over to
        the bereaved family."
        description4="Building Bridges features a wide range of memorial projects,
        including support for education, healthcare, environmental
        conservation, arts, culture, and any other area that stands
        close to the users’ deceased loved ones."
        description5="Yes, donors can collaborate with the recipient organization
        of the memorial project to discuss how the contribution will
        be used and how the memory of the loved one will be
        honoured. Building Bridges takes pride in getting the donors
        and organizations connected for a meaningful tribute that
        both honours the memory of the lost loved one and impacts
        the society in a positive manner."
        description6="Apart form donating, you can always create a fundraiser for
        supporting any cause that is either close to your heart or
        has been recommended to you by someone you know. You can
        become a part of our fundraising campaigns by sharing the
        fundraisers on your personal social media accounts and
        encourage your friends and loved ones to do the same.
        Volunteering to spread awareness and encourage potential
        donors to support our platform and give special attention to
        the Funeral and Memorial Funding charity page is also an
        option. Building Bridges offers you the opportunity to
        create a fundraiser in the loving memory of your deceased
        loved one and share that memorial project with family and
        friends, allowing them to contribute and participate in the
        tribute."
      />
    </>
  );
};

export default MemorialFunding;
