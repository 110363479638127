/* eslint-disable no-lone-blocks */
import React, {useEffect, useState} from "react";
import {Col, Form, Row} from "react-bootstrap";
import {useNavigate} from "react-router";
import Loader from "../../../components/loader";
import swal from "sweetalert";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  addFundraise,
  deleteDocument,
  deleteImage,
  getFundraiseBySlug,
  updateFundraise,
  deleteFundrasierVideo,
} from "../../../Api-Calls/fundraise";
import {useParams} from "react-router";
import moment from "moment";
import {getCategory} from "../../../Api-Calls/categoryApi";
import {isEmpty, pick} from "lodash";
import SideMenu from "../SideMenu";
import Jumbotran from "../Jumbotran";
import DashBoardFooter from "../DashBoardFooter";
import VideoPreviewModal from "../../../components/VideoPreviewModal";
import {useContext} from "react";
import Context from "../../../Context/context";
import ImgCrop from "antd-img-crop";
import docxImage from "../../../assets/img/staticIcons/docx.png";
import pdfImage from "../../../assets/img/staticIcons/pdf.png";
import {Upload, Modal} from "antd";
import {
  SwalErrorMessage,
  SwalMessage,
} from "../../../components/sweetAlertMessage";
import {useSelector} from "react-redux";
import HelmetSEO from "../../../components/HelmetSEO";
import ImagePreviewModal from "../../../components/ImagePreviewModal";

const initialState = {
  title: "",
  startDate: new Date(),
  category: [],
  imageUrl: [],
  endDate: new Date(),
  description: "",
  totalAmount: 0,
  collectedAmount: 0,
  status: "",
  tag: "",
  documents: "",
  fileList: [],
};

const AddFundraise = () => {
  const navigate = useNavigate();
  const {slug} = useParams();
  const [isEditPage, setIsEditPage] = useState(() => {
    return !isEmpty(slug);
  });
  const [state, dispatch] = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isCategoryArray, setIsCategoryArray] = useState(false);
  const [file, setFile] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [video, setVideo] = useState([]);
  const [titleError, setTitleError] = useState();
  const [funCatError, setFunCatError] = useState(null);
  const [funTagError, setFunTagError] = useState(null);
  const [amountError, setAmountError] = useState();
  const [imageError, setImageError] = useState(null);
  const [endDateError, setEndDateError] = useState(null);
  const [descError, setDescError] = useState();
  const [statusError, setStatusError] = useState();
  const [category, setCategory] = useState([]);
  const [fundraise, setFundraise] = useState(() => initialState);
  const [modalShow, setModalShow] = useState(false);
  const [mediaURL, setMediaURL] = useState({});
  const [fileList, setFileList] = useState([]);
  const [previewImage, setPreviewImage] = useState({});
  const [id, setId] = useState();
  const [messageLength, setMessageLength] = useState(0);
  const [titleLength, setTitleLength] = useState(0);
  const [descritionLengthError, setDescritionLengthError] = useState("");
  const [titleLengthError, setTitleLengthError] = useState("");
  const {
    auth: {user},
  } = useSelector((store) => store);
  useEffect(() => {
    getFundraise();
    getCategoryData();
  }, []);
  useEffect(() => {
    if (!slug) setFundraise(initialState);
  }, [slug]);

  const handleClear = () => {
    setFundraise(initialState);
    setFile();
    setDocuments();
    setVideo();
    setFileList([]);
  };

  const getCategoryData = async () => {
    try {
      const res = await getCategory();
      if (res?.status == 200) {
        setCategory(res?.data?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  const onPreview = async (file) => {
    if (file.thumbUrl) {
      setPreviewImage({
        src: file.thumbUrl,
        visible: true,
        title: file.name,
      });
    }
  };
  const removeFundraiserImage = (e, key) => {
    console.log("e", e);
    let url = key?.split("/")[4]?.split("?")[0];
    deleteImage(id, {url});
    let data = fundraise?.imageUrl?.filter((item) => item?.url !== key);
    setFundraise({...fundraise, imageUrl: data});
    if (data?.length == 0) {
      setFileList([]);
    }
  };
  const handleDeleteimage = (e, key) => {
    let url = key?.split("/")[4]?.split("?")[0];
    try {
      swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this image.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          deleteImage(id, {url});
          let data = fundraise?.imageUrl?.filter((item) => item?.url !== key);
          setFundraise({...fundraise, imageUrl: data});
          if (data?.length == 0) {
            setFileList([]);
          }
          swal("Poof! Your selected image has been deleted!", {
            icon: "success",
          });
        }
      });
    } catch (error) {
      SwalErrorMessage(
        "Oops!" + " " + error?.response?.data?.status,
        error?.response?.data?.message,
        "error",
        true
      );
    }
  };

  const handleDeleteDocument = (e, key) => {
    let url = key?.url?.split("/")[4]?.split("?")[0];
    try {
      swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this image.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          deleteDocument(id, {url});
          let data = fundraise?.documents?.filter((item) => item !== key);
          setFundraise({...fundraise, documents: data});
          swal("Poof! Your selected image has been deleted!", {
            icon: "success",
          });
        }
      });
    } catch (error) {
      SwalErrorMessage(
        "Oops!" + " " + error?.response?.data?.status,
        error?.response?.data?.message,
        "error",
        true
      );
    }
  };
  const handleDeleteVideo = (e, key) => {
    let url = key[0]?.url?.split("/")[4]?.split("?")[0];
    try {
      swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this video.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          deleteFundrasierVideo(id, {url});
          let data = fundraise?.video?.filter(
            (item) => item.url !== key[0].url
          );
          console.log(data);
          setFundraise({...fundraise, video: data});
          swal("Poof! Your selected video has been deleted!", {
            icon: "success",
          });
        }
      });
    } catch (error) {
      SwalErrorMessage(
        "Oops!" + " " + error?.response?.data?.status,
        error?.response?.data?.message,
        "error",
        true
      );
    }
  };
  const handleChange = (e) => {
    let {name, value} = e.target;
    if (name === "totalAmount" && "collectedAmount") {
      value = Number(value);
    }
    if (name === "category") {
      value = Array(value);
      setIsCategoryArray(true);
    }
    setFundraise({
      ...fundraise,
      [name]: value,
    });
    if (name === "category" && value) {
      setFunCatError(null);
    }
    if (name === "tag" && value) {
      setFunTagError(null);
    }
    if (name === "description" && value) {
      setMessageLength(value.length);
      if (value.length > 1000) {
        setDescritionLengthError("Description cannot exceed 1000 characters");
      }
    }
    if (name === "title" && value) {
      setTitleLength(value.length);
      if (value.length > 50) {
        setTitleLengthError("Title cannot exceed 50 characters");
      }
    }
    if (name === "endDate" && value) {
      const startDate = moment(fundraise.startDate);
      const endDate = moment(value);
      if (
        startDate.isAfter(endDate) === false ||
        startDate.isSame(endDate) === false
      ) {
        setEndDateError();
      }
    }
    if (name === "totalAmount" && value) {
      setAmountError();
    }
    if (name === "description" && value) {
      setDescError();
    }
    if (name === "status" && value) {
      setStatusError();
    }
    if (name === "title" && value) {
      setTitleError();
    }
  };
  const fieldValidation = () => {
    let check = true;
    const startDate = moment(fundraise.startDate);
    const endDate = moment(fundraise.endDate);

    if (titleLength > 50) {
      check = false;
      setTitleLengthError("Title cannot exceed 50 characters");
    }
    if (fundraise?.title === "") {
      check = false;
      setTitleError("Title is required!");
    }
    if (fundraise.category.length === 0) {
      check = false;
      setFunCatError("Category is  required!");
    }
    if (fundraise.tag === "") {
      check = false;
      setFunTagError("Tag is  required!");
    }
    if (fileList.length === 0) {
      check = false;
      setImageError("Image is required!");
    }
    if (startDate.isAfter(endDate) || startDate.isSame(endDate)) {
      check = false;
      setEndDateError("End date should not be equal or less than start date!");
    }
    if (fundraise?.totalAmount === 0) {
      check = false;
      setAmountError("Total amount is required!");
    }
    if (fundraise?.description === "") {
      check = false;
      setDescError("Description is required!");
    }
    if (messageLength > 1000) {
      check = false;
      setDescritionLengthError("Description cannot exceed 1000 characters");
    }

    return check;
  };
  const onSubmit = async () => {
    const formData = new FormData();
    if (file?.files?.length > 0) {
      Array?.from(file?.files)?.forEach((item, index) => {
        formData?.append("files", item?.originFileObj);
      });
    }
    if (fundraise?.imageUrl?.length > 0) {
      let imgArr = fundraise?.imageUrl;
      let url = imgArr[0]?.url?.split("/")[4]?.split("?")[0];
      formData?.append("imageUrl", url);
    }
    if (documents?.files?.length > 0) {
      Array?.from(documents?.files)?.forEach((item, index) => {
        formData?.append("documents", item);
      });
    }
    if (fundraise?.documents?.length > 0) {
      let imgArr = fundraise?.documents;
      imgArr?.map((item, index) => {
        let url = item?.url?.split("/")[4]?.split("?")[0];
        formData?.append("documents", url);
      });
    }
    if (video?.files?.length > 0) {
      Array?.from(video?.files)?.forEach((item, index) => {
        formData?.append("video", item);
      });
    }
    if (fundraise?.video?.length > 0) {
      let videoArr = fundraise?.video;
      videoArr?.map((item, index) => {
        let url = item?.url?.split("/")[4]?.split("?")[0];
        formData?.append("video", url);
      });
    }
    formData?.append("category", fundraise?.category);
    formData?.append("title", fundraise?.title);
    formData?.append("description", fundraise?.description);
    formData?.append("startDate", fundraise?.startDate);
    formData?.append("endDate", fundraise?.endDate);
    formData?.append("status", `${isEditPage ? fundraise?.status : "Pending"}`);
    formData?.append("totalAmount", fundraise?.totalAmount);
    formData?.append("collectedAmount", fundraise?.collectedAmount);
    formData?.append("tag", fundraise?.tag);
    formData?.append("userId", user?._id);
    if (!isCategoryArray) {
      formData.delete("category");
    }

    if (fieldValidation()) {
      setLoader(true);
      try {
        const res = isEditPage
          ? await updateFundraise(id, formData)
          : await addFundraise(formData);
        if (res?.status == 201 || res?.status == 200) {
          setFundraise(
            pick(res?.data?.result || fundraise, Object.keys(initialState))
          );
          {
            isEditPage
              ? SwalMessage(res?.data?.status, res?.data?.message, "success")
              : SwalMessage(res?.data?.status, res?.data?.message, "success");
          }

          setLoader(false);
          setTimeout(() => {
            navigate("/dashboard/user-fundraisers");
          }, [2000]);
        } else if (!res?.data?.status == "error") {
          SwalErrorMessage(
            "Oops!" + " " + res?.data?.status,
            res?.data?.message || "Some thing went wrong",
            "error",
            true
          );
          setLoader(false);
        }
        setFundraise(initialState);
      } catch (error) {
        SwalErrorMessage(
          "Oops!" + " " + error?.response?.data?.status,
          error.response.data.message,
          "error",
          true
        );
        setLoader(false);
      }
    } else {
      setLoader(false);
    }
    getFundraise();
  };
  const getFundraise = async () => {
    if (!isEmpty(slug))
      try {
        const res = await getFundraiseBySlug(slug);
        if (res?.status == 200) {
          setFundraise(res?.data?.result);
          setId(res?.data?.result?._id);
          if (res?.data?.result?.imageUrl?.length > 0) {
            setFileList((prev) => [
              // ...prev,
              {
                thumbUrl: res?.data?.result?.imageUrl[0]?.url,
              },
            ]);
          }
        }
      } catch (error) {
        console.log(error);
      }
  };
  const ShowModalPreview = (media) => {
    setModalShow(true);
    setMediaURL(media);
  };
  const ShowImageModalPreview = (media) => {
    if (media?.url) {
      setModalShow(true);
      setMediaURL(media.url);
    } else {
      setModalShow(true);
      setMediaURL(media);
    }
  };

  let documentObj = [];
  let documentArray = [];
  const uploadMultipleDocuments = (e) => {
    documentObj.push(e.target.files);
    for (let i = 0; i < documentObj[0].length; i++) {
      documentArray.push(URL.createObjectURL(documentObj[0][i]));
    }

    let detail = {
      files: e.target.files,
      fileUrl: documentArray,
    };
    setDocuments(detail);
  };
  console.log(documents);
  let vidoeObj = [];
  let vidoeArray = [];
  const uploadVideo = (e) => {
    vidoeObj.push(e.target.files);
    for (let i = 0; i < vidoeObj[0].length; i++) {
      vidoeArray.push(URL.createObjectURL(vidoeObj[0][i]));
    }

    let detail = {
      files: e.target.files,
      fileUrl: vidoeArray,
    };
    setVideo(detail);
  };
  const handleOnChangeImages = ({fileList: newFileList}) => {
    setImageError(null);
    setFileList(newFileList);
    setFile({
      files: newFileList,
      fileUrl: URL.createObjectURL(newFileList[0].originFileObj),
    });
  };
  return (
    <>
      <HelmetSEO
        title={isEditPage ? "Edit Fundraiser" : "Add Fundraiser"}
        description={isEditPage ? "Edit Fundraiser" : "Add Fundraiser"}
      />
      <div className="App">
        <SideMenu />

        {loading && <Loader />}

        <div
          className="main-panel"
          style={{
            width: state.opensidebar == true ? `calc(100% - 250px)` : "97%",
          }}
        >
          <div className="container">
            <Jumbotran
              page={isEditPage ? "Edit Case" : "Add Case"}
              desc={isEditPage ? "Edit Case" : "Add Case"}
            />
            <div className="page-inner">
              <div className="row">
                <div className="col-md-12">
                  <div className="card card-with-nav">
                    <div className="card-body">
                      <div className="row mt-3">
                        <Row>
                          <Col className="mb-3" lg={6}>
                            <Form.Label className="mb-1 d-flex ">
                              <b>TITLE</b>
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <div className="mb-1">
                              <input
                                name="title"
                                className="form-control"
                                placeholder="title"
                                onChange={handleChange}
                                style={{
                                  border: titleError && "1px solid #dc3545",
                                }}
                                value={fundraise?.title ? fundraise?.title : ""}
                              />
                              <p className="text-danger  my-2 ms-2 text-start  ">
                                {titleError}
                              </p>
                              {titleLength > 50 && (
                                <p className="text-danger  my-2 ms-2 text-start  ">
                                  {titleLengthError}
                                </p>
                              )}
                            </div>
                          </Col>
                          <Col className="mb-3" lg={6}>
                            <Form.Label className="mb-1 d-flex">
                              <b>SELECT FUNDRAISER CATEGORY</b>
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <select
                              borderError="true"
                              className={`form-control ${
                                funCatError !== null && "border-danger "
                              } `}
                              name="category"
                              onChange={handleChange}
                            >
                              <option
                                disabled={
                                  fundraise?.category?.length > 0 ? true : false
                                }
                                value=""
                              >
                                Select Category
                              </option>
                              {category?.map((category, index) => {
                                return (
                                  <option
                                    value={category._id}
                                    selected={
                                      fundraise?.category._id == category?._id
                                        ? true
                                        : false
                                    }
                                  >
                                    {category?.title}
                                  </option>
                                );
                              })}
                            </select>
                            <p className="text-danger  my-2 ms-2 text-start  ">
                              {funCatError}
                            </p>
                          </Col>
                          <Col className="mb-3 text-left col-2 " lg={6}>
                            <Form.Label className="mb-1 ms-2 d-flex">
                              <b>ADD IMAGE</b>
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <div className="fund-img mb-3">
                              {" "}
                              <ImgCrop
                                aspect={1 / 1.2}
                                showGrid
                                rotationSlider
                                id="profileUploadFile"
                                showReset
                              >
                                <Upload
                                  action=""
                                  listType="picture-card"
                                  onPreview={onPreview}
                                  // accept=".jpg,.jpeg,.png,.gif,.pdf"
                                  onRemove={(e) => {
                                    removeFundraiserImage(
                                      e,
                                      fileList[0]?.thumbUrl
                                    );
                                  }}
                                  fileList={fileList}
                                  onChange={handleOnChangeImages}
                                >
                                  {fileList?.length < 1 && "+ Upload"}
                                </Upload>
                              </ImgCrop>
                              <p className="text-danger  my-2 ms-2 text-start  ">
                                {imageError}
                              </p>
                              <Modal
                                open={previewImage.visible}
                                title={previewImage.title}
                                footer={null}
                                onCancel={() =>
                                  setPreviewImage({
                                    ...previewImage,
                                    visible: false,
                                  })
                                }
                              >
                                <img
                                  alt="example"
                                  style={{width: "100%"}}
                                  src={previewImage.src}
                                />
                              </Modal>
                            </div>
                          </Col>
                          <Col className="mb-3" lg={6}>
                            <Form.Label className="mb-1 d-flex">
                              <b>SELECT FUNDRAISER TAG</b>
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <select
                              borderError="true"
                              className={`form-control ${
                                funTagError !== null && "border-danger "
                              } `}
                              name="tag"
                              onChange={handleChange}
                            >
                              <option
                                disabled={
                                  fundraise?.tag ? true : false
                                }
                                value=""
                              >
                                Select Tag
                              </option>
                              <option
                                value={"Zakat"}
                                selected={
                                  fundraise?.tag === "Zakat"
                                    ? "selected"
                                    : ""
                                }
                              >
                                Zakat
                              </option>
                              <option
                                value={"Zakat and sadka"}
                                selected={
                                  fundraise?.tag === "Zakat and sadka"
                                    ? "selected"
                                    : ""
                                }
                              >
                               Zakat and Sadka
                              </option>
                              <option
                                value={"Sadka"}
                                selected={
                                  fundraise?.tag === "Sadka"
                                    ? "selected"
                                    : ""
                                }
                              >
                                Sadka
                              </option>
                              <option
                                value={"General Donation"}
                                selected={
                                  fundraise?.tag === "General Donation"
                                    ? "selected"
                                    : ""
                                }
                              >
                                General Donation
                              </option>
                              <option
                                value={"Sponsorship"}
                                selected={
                                  fundraise?.tag === "Sponsorship"
                                    ? "selected"
                                    : ""
                                }
                              >
                                Sponsorship
                              </option>
                            </select>
                            <p className="text-danger  my-2 ms-2 text-start  ">
                              {funTagError}
                            </p>
                          </Col>
                          <Col className="col-12">
                            <div className="mb-4">
                              <Form.Label className="mb-1 d-flex">
                                <b>ADD DOCUMENTS</b>
                              </Form.Label>
                              <input
                                type="file"
                                multiple
                                id="uploadDocument"
                                accept="*/*"
                                className="form-control"
                                name="documents"
                                placeholder="Add Documents"
                                onChange={uploadMultipleDocuments}
                              />
                            </div>
                            {documents?.fileUrl ? (
                              <>
                                <div className="d-flex mb-4">
                                  {documents?.fileUrl?.map((fileUrl, index) => {
                                    const fileName =
                                      documents?.files[index]?.name; // Access the filename
                                    const fileExtension = fileName
                                      .split(".")
                                      .pop();
                                    let imageSrc;

                                    if (fileExtension === "docx") {
                                      imageSrc = docxImage;
                                    } else if (fileExtension === "pdf") {
                                      imageSrc = pdfImage;
                                    } else {
                                      imageSrc = fileUrl; // Use the original image URL if it's not docx or pdf
                                    }
                                    return (
                                      <div key={index}>
                                        <img
                                          style={{
                                            width: "150px",
                                            height: "120px",
                                            marginRight: "10px",
                                            objectFit: "cover",
                                            borderRadius: "12px",
                                            cursor: "pointer",
                                          }}
                                          onClick={(e) =>
                                            ShowImageModalPreview(fileUrl)
                                          }
                                          src={imageSrc}
                                          alt="documents"
                                        />
                                      </div>
                                    );
                                  })}
                                </div>
                              </>
                            ) : (
                              <div className="d-flex mb-4">
                                {fundraise?.documents?.length > 0 &&
                                  fundraise?.documents?.map((item) => {
                                    return (
                                      <>
                                        {item?.type === "image" ? (
                                          <div style={{position: "relative"}}>
                                            <img
                                              style={{borderRadius: "12px"}}
                                              className="mx-2"
                                              src={item.url}
                                              width="70px"
                                              height="70px"
                                              onClick={(e) =>
                                                ShowImageModalPreview(item)
                                              }
                                              alt="documents"
                                            />
                                            <i
                                              className="fa fa-times"
                                              aria-hidden="false"
                                              id="imageDeleteIconDocument"
                                              onClick={(e) =>
                                                handleDeleteDocument(e, item)
                                              }
                                            ></i>
                                          </div>
                                        ) : item?.type === "docx" ? (
                                          <div style={{position: "relative"}}>
                                            <img
                                              style={{borderRadius: "12px"}}
                                              className="mx-2"
                                              src={docxImage}
                                              width="70px"
                                              height="70px"
                                              alt="documents"
                                            />
                                            <i
                                              className="fa fa-times"
                                              aria-hidden="false"
                                              id="imageDeleteIconDocument"
                                              onClick={(e) =>
                                                handleDeleteDocument(e, item)
                                              }
                                            ></i>
                                          </div>
                                        ) : (
                                          item?.type === "pdf" && (
                                            <div style={{position: "relative"}}>
                                              <img
                                                style={{borderRadius: "12px"}}
                                                className="mx-2"
                                                src={pdfImage}
                                                width="70px"
                                                height="70px"
                                                alt="documents"
                                              />
                                              <i
                                                className="fa fa-times"
                                                aria-hidden="false"
                                                id="imageDeleteIconDocument"
                                                onClick={(e) =>
                                                  handleDeleteDocument(e, item)
                                                }
                                              ></i>
                                            </div>
                                          )
                                        )}
                                      </>
                                    );
                                  })}
                              </div>
                            )}
                          </Col>
                          <Col className="col-12">
                            <div className="mb-4">
                              <Form.Label className="mb-1 d-flex">
                                <b>ADD VIDEO</b>
                              </Form.Label>
                              <input
                                type="file"
                                id="uploadVideo"
                                accept="video/*"
                                className="form-control"
                                name="video"
                                placeholder="Add Video"
                                onChange={uploadVideo}
                              />
                            </div>
                            {video?.fileUrl ? (
                              <>
                                <div className="d-flex mb-4">
                                  <video
                                    style={{
                                      width: "350px",
                                      border: "2px solid #000",
                                      borderRadius: "8px",
                                    }}
                                    muted
                                    controls
                                    loading="lazy"
                                  >
                                    <source
                                      src={video.fileUrl[0]}
                                      type="video/mp4"
                                    />
                                  </video>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="d-flex mb-4">
                                  {fundraise?.video &&
                                    fundraise?.video?.length > 0 && (
                                      <div style={{position: "relative"}}>
                                        <video
                                          style={{
                                            width: "300px",
                                            border: "2px solid #000",
                                            borderRadius: "8px",
                                          }}
                                          muted
                                          controls
                                          loading="lazy"
                                        >
                                          <source
                                            src={fundraise?.video[0].url}
                                            type="video/mp4"
                                          />
                                        </video>
                                        <i
                                          className="fa fa-times"
                                          aria-hidden="false"
                                          id="videoDeleteIcon"
                                          onClick={(e) =>
                                            handleDeleteVideo(
                                              e,
                                              fundraise?.video
                                            )
                                          }
                                        ></i>
                                      </div>
                                    )}
                                </div>
                              </>
                            )}
                          </Col>
                          <Col className="mb-3" lg={6}>
                            <Form.Label className="mb-1 d-flex">
                              <b>START DATE</b>
                            </Form.Label>
                            <div className="mb-1">
                              <input
                                className="form-control"
                                type="date"
                                id="datepicker"
                                name="startDate"
                                onChange={handleChange}
                                value={moment(fundraise?.startDate).format(
                                  "YYYY-MM-DD"
                                )}
                                placeholder="last date"
                                min={moment().format("YYYY-MM-DD")}
                              />
                            </div>
                          </Col>
                          <Col className="mb-3" lg={6}>
                            <Form.Label className="mb-1 d-flex">
                              <b>END DATE</b>
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <div className="mb-1">
                              <input
                                className="form-control"
                                type="date"
                                name="endDate"
                                id="datepicker"
                                placeholder="end date"
                                onChange={handleChange}
                                style={{
                                  border: endDateError && "1px solid #dc3545",
                                }}
                                value={moment(fundraise?.endDate).format(
                                  "YYYY-MM-DD"
                                )}
                                min={moment().format("YYYY-MM-DD")}
                              />
                              <p className="text-danger  my-2 ms-2 text-start  ">
                                {endDateError}
                              </p>
                            </div>
                          </Col>
                          <Col className="mb-3" lg={6}>
                            <Form.Label className="mb-1 d-flex">
                              <b>TOTAL AMOUNT</b>
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <div className="mb-1">
                              <input
                                type="text"
                                name="totalAmount"
                                maxLength={7}
                                inputMode="numeric"
                                className="form-control"
                                onChange={handleChange}
                                value={fundraise?.totalAmount || ""}
                                placeholder="Enter Amount"
                                style={{
                                  border: amountError && "1px solid #dc3545",
                                }}
                              />
                            </div>
                            <p className="text-danger  my-2 ms-2 text-start  ">
                              {amountError}
                            </p>
                          </Col>
                          <Col className="mb-3" lg={6}>
                            <Form.Label className="mb-1 d-flex">
                              <b>STATUS</b>
                            </Form.Label>
                            <div className="mb-1">
                              <input
                                type="text"
                                name="status"
                                className="form-control"
                                disabled
                                value={fundraise?.status || "Pending"}
                                placeholder="status"
                                style={{
                                  border: amountError && "1px solid #dc3545",
                                }}
                              />
                            </div>
                          </Col>
                          <Col className="mb-3" lg={12}>
                            <Form.Label className="mb-1 d-flex">
                              <b>DESCRIPTION</b>
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <div className="mb-1">
                              <textarea
                                className="form-control"
                                name="description"
                                value={fundraise?.description}
                                placeholder="Enter Description"
                                onChange={handleChange}
                                style={{
                                  border: descError && "1px solid #dc3545",
                                }}
                                rows="3"
                              ></textarea>
                            </div>
                            <p className="text-danger  my-2 ms-2 text-start  ">
                              {descError}
                            </p>
                            {messageLength > 1000 && (
                              <p className="text-danger  my-2 ms-2 text-start  ">
                                {descritionLengthError}
                              </p>
                            )}
                            {messageLength > 1000 ? (
                              <p className="text-danger text-start mx-1 my-2">
                                Message limit {messageLength} to 1000.
                              </p>
                            ) : (
                              <p className="mx-1 text-start my-2">
                                Message limit {messageLength} to 1000.
                              </p>
                            )}
                          </Col>
                        </Row>
                      </div>
                      <div className="text-right mt-3 mb-3">
                        <button
                          className="fun-donate-btn1 mx-3"
                          onClick={handleClear}
                        >
                          Clear
                        </button>

                        {isEditPage && fundraise.status == "Pending" ? (
                          <>
                            {loader ? (
                              <button className="btn__primary1 buttonPrimary1">
                                <i
                                  className="fa mx-3 fa-circle-o-notch fa-spin"
                                  style={{fontSize: "24px"}}
                                ></i>
                              </button>
                            ) : (
                              <button
                                className="btn__primary1 buttonPrimary1 mt-2"
                                onClick={onSubmit}
                              >
                                Update Case{" "}
                              </button>
                            )}
                          </>
                        ) : isEditPage && fundraise.status != "Pending" ? (
                          <></>
                        ) : (
                          <>
                            {loader ? (
                              <button className="btn__primary1 buttonPrimary1">
                                <i
                                  className="fa mx-3 fa-circle-o-notch fa-spin"
                                  style={{fontSize: "24px"}}
                                ></i>
                              </button>
                            ) : (
                              <button
                                className="btn__primary1 buttonPrimary1 mt-2"
                                onClick={onSubmit}
                              >
                                Add Case{" "}
                              </button>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <VideoPreviewModal
            media={mediaURL}
            show={modalShow}
            onHide={() => setModalShow(false)}
          />
          <ImagePreviewModal
            media={mediaURL}
            show={modalShow}
            onHide={() => setModalShow(false)}
          />
          <DashBoardFooter />
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default AddFundraise;
