import React, {useEffect, useState} from "react";
import {
  Button,
  Card,
  Container,
  Modal,
  ProgressBar,
  Tab,
  Tabs,
} from "react-bootstrap";
import AboutTab from "../components/AboutTab";
import CommentsTab from "../components/CommentsTab";
import DocumentTab from "../components/DocumentTab";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import {Link} from "react-router-dom";
import {useNavigate, useParams} from "react-router";
import {getFundraiseBySlug} from "../Api-Calls/fundraise";
import {getSupporterByFundraiseId} from "../Api-Calls/supporter";
import moment from "moment";
import VideoPreviewModal from "../components/VideoPreviewModal";
import Loader from "../components/loader";
import ModalBody from "../components/ModalBody";
import swal from "sweetalert";
import ProfileInformationModal from "../components/ProfileInformaionModal";
import {staticImages} from "../Constant";
import Slider from "../components/Slider";
import {useDispatch, useSelector} from "react-redux";
import OwlCarousel from "react-owl-carousel";
import HelmetSEO from "../components/HelmetSEO";
import NoResultFound from "../components/NoResultFound";
import {getUserById} from "../Api-Calls/authentication";
import {updateUserState} from "../Features/user/authSlice";
import UserNameLength from "../components/UserNameLength";
import Stepper from "../components/Stepper";
import VideoTab from '../components/VideoTab';

const FundraiseDetail = () => {
  const {
    auth: {user},
    fundraiser: {fundraiseList},
  } = useSelector((store) => store);
  const {slug} = useParams();
  const [fundraise, setFundraise] = useState([]);
  const [supporters, setSupporters] = useState([]);
  const [supporterIndex, setSupporterIndex] = useState(5);
  const topSuppoerters = fundraise?.topDoners?.slice(0, supporterIndex);
  const [modalShow, setModalShow] = useState(false);
  const [mediaURL, setMediaURL] = useState({});
  const [loading, setloading] = useState(true);
  const [show, setShow] = useState(false);
  const [id1, setId] = useState("");
  const [modalShowDonor, setModalShowDonor] = React.useState(false);
  const [modalShowDonation, setModalShowDonation] = React.useState(false);
  const [tagModal, setTagModal] = useState(false);

  const navigate = useNavigate();

  const toggleShow = () => {
    navigate(`/payment-fundraiser/${fundraise?.slug}`, {state: fundraise});
  };
  const dispatchRedux = useDispatch();
  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setShow(true);
    setId(id);
  };
  const responsiveOptions = {
    0: {
      items: 1, // Number of items to display at 0px and above
    },
    768: {
      items: 2, // Number of items to display at 768px and above
    },
    992: {
      items: 3, // Number of items to display at 992px and above
    },
    1300: {
      items: 3, // Number of items to display at 992px and above
    },
    1500: {
      items: 4, // Number of items to display at 992px and above
    },
  };
  const handleDonateNow = (fundraise) => {
    navigate(`/payment-fundraiser/${fundraise.slug}`, {state: fundraise});
  };
  const getFundraiseData = async () => {
    try {
      setloading(true);
      const res = await getFundraiseBySlug(slug);
      if (res?.status == 200) {
        setFundraise(res?.data?.result);
      }
      setTimeout(() => {
        setloading(false);
      }, 1500);
    } catch (error) {
      console.log(error);
    }
  };
  const getSupporter = async () => {
    try {
      const res = await getSupporterByFundraiseId(slug);
      if (res?.status == 200) {
        setSupporters(res?.data?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getFundraiseData();
    getSupporter();
  }, [slug]);

  const UpdateUserProfile = async () => {
    if (user) {
      try {
        const res = await getUserById(user?._id);
        if (res?.status == 200) {
          let userData = res?.data?.result?.user;
          dispatchRedux(updateUserState(res?.data?.result?.user));
          if (
            userData?.status === "Rejected" ||
            userData?.status === "Pending"
          ) {
            if (
              userData?.country !== "" &&
              userData?.phoneNo !== "" &&
              userData?.address !== "" &&
              userData?.hear_about !== "" &&
              userData?.cnicNo !== "" &&
              userData?.zipcode !== "" &&
              userData?.cnic_picture?.length > 0
            ) {
              swal({
                title: "Please Note",
                text: "Your personal information has been added, please wait for admin's approval to proceed forward.",
                icon: "warning",
                buttons: true,
                dangerMode: true,
              });
            } else {
              try {
                swal({
                  title: "Confirmation!",
                  text: "Before creating a case, kindly ensure that you have completed your profile.",
                  icon: "warning",
                  buttons: true,
                  dangerMode: true,
                }).then((data) => {
                  if (data) {
                    setTagModal(true);
                  }
                });
              } catch (err) {
                console.log(err);
              }
            }
          }
        }
        if (res?.data?.result?.user?.status === "Verified") {
          navigate("/dashboard/add-fundraise");
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      navigate("/login");
    }
  };
  return (
    <>
      <HelmetSEO
        title={fundraise?.title}
        description={fundraise?.description}
      />

      <Stepper tagModal={tagModal} setTagModal={setTagModal} />
      <div className="position-relative">
        {loading ? (
          <div
            className="section d-flex align-item-center justify-content-center"
            style={{minHeight: "100vh"}}
          >
            <Loader />
          </div>
        ) : (
          <>
            <section className="section pb-5 mb-5 fundraiserDetail">
              <Container>
                <div className="text-center col-lg-9 mx-auto d-flex align-items-center justify-content-center">
                  <h3 className="mb-0">{fundraise?.title}</h3>{" "}
                  {fundraise?.urgent && (
                    <span className="urgentTxt">Urgent</span>
                  )}
                </div>

                <div className=" d-flex flex-lg-row  justify-content-center  flex-md-column flex-column  mt-5 gap-4  ">
                  <div className="col-lg-4 col-md-12">
                    <div className="border-75 shadow border-0">
                      <div className="">
                        <Card.Img
                          variant="top"
                          className="fundRaisingImgBackgroundDetails"
                          src={fundraise?.imageUrl[0]?.url}
                        />
                      </div>
                    </div>
                  </div>
                  <Card
                    className="col-lg-4  col-md-12 "
                    style={{minHeight: "400px"}}
                  >
                    <Card.Body>
                      <div className="my-3">
                        <div className="fund-card-amount-detail mb-3">
                          <div className=" mx-auto px-3 py-2 justify-content-between d-flex ">
                            <div>
                              <div>
                                <strong>Fundraised</strong>
                              </div>
                              <div>
                                <strong>
                                  {"Rs. " +
                                    fundraise?.collectedAmount.toLocaleString()}
                                </strong>
                              </div>
                            </div>
                            <div className="ms-2">
                              <div>
                                <strong>Goal</strong>
                              </div>
                              <div>
                                {" "}
                                <strong
                                  style={{
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {"Rs. " +
                                    fundraise?.totalAmount.toLocaleString()}
                                </strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="fundRaisingProgress mt-2">
                        <ProgressBar
                          now={
                            (fundraise?.collectedAmount /
                              fundraise?.totalAmount) *
                            100
                          }
                        />
                        <div className="d-flex align-items-center justify-content-between">
                          <div>
                            <span>
                              <strong>{fundraise?.daysLeft}</strong>
                              {fundraise?.daysLeft === 1
                                ? " " + "day left"
                                : " " + "days left"}
                            </span>
                          </div>
                          <div>
                            <span>
                              <strong>
                                <i class="fa fa-heart text-danger"></i>{" "}
                                {fundraise?.supporters}
                              </strong>{" "}
                              {fundraise?.supporters === 1
                                ? " " + "Supporter"
                                : " " + "Supporters"}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="mt-4  position-absolute bottom-0 pb-4 end-0 start-0 px-4">
                        <button
                          onClick={toggleShow}
                          className="btn__primary shadow w-100 mb-3 buttonPrimary"
                        >
                          Donate now
                        </button>
                        <button
                          onClick={() => handleShow(slug)}
                          className="btn__secondary w-100  buttonPrimary  btn-border-primary"
                        >
                          Share
                        </button>
                      </div>
                    </Card.Body>
                  </Card>

                  <Card className="shadow border-0 col-lg-4 mb-4 col-md-12 ">
                    <Card.Body className="blog__content p-4 d-flex flex-column justify-content-between">
                      {fundraise?.topDoners?.length > 0 ? (
                        <>
                          <div className="py-4 mb-4">
                            <h6 className="">Recent Transaction</h6>
                            {fundraise?.topDoners?.length > 0 &&
                              fundraise?.topDoners
                                ?.slice(0, 3)
                                ?.map((topsupporter) => {
                                  return (
                                    <div className="recent__transection justify-content-between align-items-center my-3 pb-1">
                                      <div className="d-flex align-items-center">
                                        <Card.Img
                                          className="transection__image"
                                          variant="top"
                                          src={
                                            !topsupporter?.displayName
                                              ? topsupporter?.personalInfo
                                                  ?.picture !== ""
                                                ? topsupporter?.personalInfo
                                                    ?.picture
                                                : staticImages.userDefaultImg
                                              : staticImages.userDefaultImg
                                          }
                                        />
                                        <div className="">
                                          <h6 className="name mb-0 text-capitalize">
                                            <UserNameLength
                                              displayName={
                                                topsupporter?.displayName
                                              }
                                              username={
                                                topsupporter?.personalInfo
                                                  ?.username
                                              }
                                              length={15}
                                            />
                                          </h6>
                                          <div className="date">
                                            {" "}
                                            {moment(
                                              topsupporter.created
                                            ).fromNow()}
                                          </div>
                                        </div>
                                      </div>
                                      <h5>
                                        Rs.{" "}
                                        {topsupporter?.amount?.toLocaleString()}
                                      </h5>
                                    </div>
                                  );
                                })}
                          </div>
                          <div className="d-flex   bottom-0 pb-4 start-0 end-0    px-4 ">
                            <button
                              onClick={() => setModalShowDonation(true)}
                              className="btn__primary shadow w-50 me-lg-3 py-3 buttonPrimary me-2 me-lg-0"
                            >
                              All Donations
                            </button>
                            <button
                              onClick={() => setModalShowDonor(true)}
                              className="btn__secondary w-50 buttonPrimary  btn-border-primary"
                            >
                              Top Donors
                            </button>
                          </div>
                        </>
                      ) : (
                        <NoResultFound />
                      )}
                    </Card.Body>
                  </Card>
                </div>
                <div className="my-5 ">
                  <Tabs
                    defaultActiveKey="about"
                    transition={false}
                    id="noanim-tab-example"
                    className="my-4"
                  >
                    <Tab eventKey="about" title="About">
                      <AboutTab description={fundraise.description} />
                    </Tab>
                    <Tab eventKey="documents" title="Documents">
                      <DocumentTab fundraise={fundraise} />
                    </Tab>
                    {fundraise?.video?.length>0 && (
                    <Tab eventKey="video" title="Video">
                      <VideoTab fundraise={fundraise} />
                    </Tab>
                    )}
                    {user && (
                      <Tab eventKey="comments" title="Comments">
                        <CommentsTab
                          fundraise={fundraise}
                          getFundraiseData={getFundraiseData}
                        />
                      </Tab>
                    )}
                  </Tabs>
                </div>
              </Container>
            </section>

            <VideoPreviewModal
              media={mediaURL}
              show={modalShow}
              onHide={() => setModalShow(false)}
            />

            {/* share modal */}
            <Modal centered size="lg" show={show} onHide={handleClose}>
              <ModalBody id={id1} setShow={setShow} />
            </Modal>
            <div className="modal-alignment">
              <DonationModal
                show={modalShowDonation}
                supporters={supporters}
                ModalTitle={"All Donations"}
                onHide={() => setModalShowDonation(false)}
              />
              <DonorsModal
                show={modalShowDonor}
                topSuppoerters={fundraise?.topDoners}
                ModalTitle={"All Donors"}
                onHide={() => setModalShowDonor(false)}
              />
            </div>
            <section className="section">
              <Container>
                <div className="text-center mb-5">
                  <h2>Trending Fundraisers</h2>
                </div>
                <div className="row">
                  {fundraiseList?.result ? (
                    <>
                      <OwlCarousel
                        loop
                        margin={24}
                        nav
                        autoplay={true}
                        className="owl-theme fundRaisingSection"
                        responsive={responsiveOptions}
                        items={3}
                      >
                        {fundraiseList?.result?.map((fundraise, index) => {
                          return (
                            <div className="item" key={index}>
                              <Card className="d-flex flex-column justify-content-between position-relative">
                                {fundraise.urgent && (
                                  <div className="urgentFundraise">Urgent</div>
                                )}
                                <div className="cardSlider">
                                  <Link
                                    to={`/fundraiser/${fundraise?.slug}`}
                                    className="text-deceoration-none"
                                  >
                                    <Slider
                                      collectedAmount={true}
                                      item={fundraise?.imageUrl}
                                      amount={fundraise?.collectedAmount}
                                      tag={fundraise?.tag}
                                    />
                                  </Link>
                                  <Card.Body>
                                    <Link
                                      to={`/fundraiser/${fundraise?.slug}`}
                                      className="text-deceoration-none"
                                    >
                                      <Card.Title className="donation-heading">
                                        {fundraise?.title}
                                      </Card.Title>
                                    </Link>
                                    <div className="fundSection my-3">
                                      <Link
                                        to={`/fundraiser/${fundraise?.slug}`}
                                        className="text-deceoration-none"
                                        style={{color: "#000"}}
                                      >
                                        <div className=" mx-auto px-3 py-2 justify-content-between d-flex fund-card-amount">
                                          <div>
                                            <div>
                                              <strong>Fundraised</strong>
                                            </div>
                                            <div>
                                              <strong>Goal</strong>
                                            </div>
                                          </div>
                                          <div className="ms-2">
                                            <div>
                                              <strong>
                                                {"Rs. " +
                                                  fundraise?.collectedAmount.toLocaleString()}
                                              </strong>
                                            </div>
                                            <div>
                                              {" "}
                                              <strong
                                                style={{
                                                  overflow: "hidden",
                                                  whiteSpace: "nowrap",
                                                }}
                                              >
                                                {"Rs. " +
                                                  fundraise?.totalAmount.toLocaleString()}
                                              </strong>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="fundRaisingProgress mt-3">
                                          <ProgressBar
                                            now={
                                              (fundraise?.collectedAmount /
                                                fundraise?.totalAmount) *
                                              100
                                            }
                                          />
                                          <div className="d-flex align-items-center justify-content-between">
                                            <div>
                                              <i className="fa fa-clock-o"></i>{" "}
                                              <span>
                                                <strong>
                                                  {fundraise?.daysLeft}
                                                </strong>{" "}
                                                {fundraise?.daysLeft === 1
                                                  ? " " + "day left"
                                                  : " " + "days left"}{" "}
                                              </span>
                                            </div>
                                            <div>
                                              <i className="fa fa-heart text-danger"></i>{" "}
                                              <span>
                                                <strong>
                                                  {fundraise?.supporters}
                                                </strong>{" "}
                                                {fundraise?.supporters === 1
                                                  ? " " + "Supporter"
                                                  : " " + "Supporters"}{" "}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </Link>
                                      <div className="d-flex align-items-center justify-content-between  py-3">
                                        <button
                                          className="w-100 py-3 btn__primary buttonPrimary"
                                          onClick={(e) =>
                                            handleDonateNow(fundraise)
                                          }
                                        >
                                          Donate Now
                                        </button>
                                      </div>
                                    </div>
                                  </Card.Body>
                                </div>
                              </Card>
                            </div>
                          );
                        })}
                      </OwlCarousel>
                    </>
                  ) : (
                    <NoResultFound />
                  )}
                </div>
              </Container>
            </section>

            <section
              className="text-center mb-5 mt-4"
              style={{background: "#F3F3F3"}}
            >
              <Container>
                <div className="d-lg-flex justify-content-between  p-5 align-items-center ">
                  <h3 className="help-us-heading">
                    Help Us, {window?.innerWidth <= 380 && <br />} Help You!
                  </h3>
                  <li className="list-inline-item">
                    <>
                      <a className="text-decoration-none">
                        <button
                          onClick={UpdateUserProfile}
                          className="py-3 btn__primary buttonPrimary "
                        >
                          Start a Fundraiser
                        </button>
                      </a>
                    </>
                  </li>
                </div>
              </Container>
            </section>
          </>
        )}
      </div>
    </>
  );
};
function DonationModal(props) {
  const {ModalTitle, supporters} = props;
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {ModalTitle}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{height: "500px", overflowY: "scroll"}}>
        {supporters?.length > 0 &&
          supporters?.map((topsupporter) => {
            return (
              <div className="recent__transection justify-content-between align-items-center my-3 pb-1">
                <div className="d-flex align-items-center">
                  <Card.Img
                    className="transection__image"
                    variant="top"
                    src={
                      !topsupporter?.displayName
                        ? topsupporter?.personalInfo?.picture !== ""
                          ? topsupporter?.personalInfo?.picture
                          : staticImages.userDefaultImg
                        : staticImages.userDefaultImg
                    }
                  />
                  <div className="">
                    <h6 className="name mb-0 text-capitalize">
                      <UserNameLength
                        displayName={topsupporter?.displayName}
                        username={topsupporter?.personalInfo?.username}
                        length={30}
                      />
                    </h6>
                    <div className="date">
                      {" "}
                      {moment(topsupporter.created).fromNow()}
                    </div>
                  </div>
                </div>
                <h5>Rs. {topsupporter?.amount?.toLocaleString()}</h5>
              </div>
            );
          })}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
function DonorsModal(props) {
  const {ModalTitle, topSuppoerters} = props;
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {ModalTitle}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {topSuppoerters?.length > 0 &&
          topSuppoerters?.slice(0, 5)?.map((topsupporter) => {
            return (
              <div className="recent__transection justify-content-between align-items-center my-3 pb-1">
                <div className="d-flex align-items-center">
                  <Card.Img
                    className="transection__image"
                    variant="top"
                    src={
                      !topsupporter?.displayName
                        ? topsupporter?.personalInfo?.picture !== ""
                          ? topsupporter?.personalInfo?.picture
                          : staticImages.userDefaultImg
                        : staticImages.userDefaultImg
                    }
                  />
                  <div className="">
                    <h6 className="name mb-0 text-capitalize">
                      <UserNameLength
                        displayName={topsupporter?.displayName}
                        username={topsupporter?.personalInfo?.username}
                        length={30}
                      />
                    </h6>
                    <div className="date">
                      {" "}
                      {moment(topsupporter.created).fromNow()}
                    </div>
                  </div>
                </div>
                <h5>Rs. {topsupporter?.amount?.toLocaleString()}</h5>
              </div>
            );
          })}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
export default FundraiseDetail;
