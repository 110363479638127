import React from "react";
import BreadCrumb from "../components/BreadCrumb";
import web from "../assets/img/howdoesitwork.png";
import mobile from "../assets/img/howdoesitwork-mobile.png";
import {useState} from "react";
import ProfileInformationModal from "../components/ProfileInformaionModal";
import {useSelector} from "react-redux";
import HelmetSEO from "../components/HelmetSEO";

const HowItsWork = () => {
  const {
    auth: {user},
  } = useSelector((store) => store);
  const [tagModal, setTagModal] = useState(false);

  return (
    <>
      <HelmetSEO title="How it Works" description="How it Works" />
      <BreadCrumb
      font={true}
        overlay={false}
        heading="HOW DOES IT WORK"
        badge="HOW DOES IT WORK"
        description="Building Bridges is an online fundraising platform that connects the deprived communities with those who wish to make a change. This section is going to provide you step by step assistance and help you learn how to create fundraisers and donate to a cause on our website."
        webImage={web}
        mobileImage={mobile}
      />
      <ProfileInformationModal tagModal={tagModal} setTagModal={setTagModal} />
      <section className="section">
        <div className="container-fluid mid-div">
          <div className="container after-mid">
            <div className="my-5"></div>
            {/* firstsection */}
            <div className="my-3 row align-items-center ">
              {/* <div className="col-lg-6 col-md-12 textual-info">
                <div className="img-div-2">
                  <img
                    alt="How it work"
                    className="image-section"
                    src="https://ketto.gumlet.io/assets/images/medical-landing/trymedical.png?w=480&dpr=1.0"
                  />
                </div>
              </div> */}
              <div className="col-lg-6 col-md-12 textual-info">
                <div className="text-center ">
                  <video
                    style={{
                      width: "100%",
                      // border: "2px solid #000",
                      borderRadius: "8px",
                    }}
                    autoPlay
                    muted
                    controls
                  >
                    <source
                      src="https://building-bridges-for-islam-media.s3.us-west-2.amazonaws.com/uploads/26e7db26-9e90-4cbe-a91e-2515e72fd740-signup-video.mp4"
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 d-lg-block mt-5 mt-lg-0">
                <h2 className="how-work-title " style={{color: "#002866"}}>1. Sign up</h2>
                <div className="desc1">
                  After visiting Building Bridges website, you can sign up and
                  create your profile by providing the required details.
                  Shortly, you will receive an email of approval. You are now
                  all set to create your fundraisers supporting a cause that is
                  close to your heart or donate to a cause that resonates with
                  you.
                </div>
              </div>
            </div>
            {/* 2nd section */}
            <div className="my-3 row align-items-center d-md-flex flex-lg-row  flex-md-column flex-md-column-reverse flex-column-reverse">
              <div className="col-lg-6 col-md-12 mt-5  mt-lg-0 textual-info">
                <h2 className="how-work-title " style={{color: "#002866"}}>2. Start a Fundraiser</h2>
                <div className="desc1">
                  You can start your fundraiser by filling up a form that
                  requires all the details of the cause that you are supporting.
                  After submitting the form, you will receive an email approving
                  your fundraiser to go live on our website. Once your
                  fundraiser is live, you can share it with your family and
                  friends via social media platforms and wait for the donations
                  to be collected. After the donations are collected, you can
                  withdraw the amount anytime and serve the underprivileged
                  members of your community.
                </div>
              </div>
              {/* <div className="col-lg-6 col-md-12 d-lg-block mt-5 mt-lg-0">
                <div className="img-div-1">
                  <img
                    alt="How it work"
                    className="image-section"
                    src="https://ketto.gumlet.io/assets/images/medical-landing/startMedFundraiser.png?w=480&dpr=1.0"
                  />
                </div>
              </div> */}
              <div className="col-lg-6 col-md-12 textual-info">
                <div className="text-center ">
                  <video
                    style={{
                      width: "100%",
                      // border: "2px solid #000",
                      borderRadius: "8px",
                    }}
                    autoPlay
                    muted
                    controls
                  >
                    <source
                      src="https://building-bridges-for-islam-media.s3.us-west-2.amazonaws.com/uploads/03cd868d-1794-4613-a729-dbee97a1ef3b-start-a-fundraiser-video.mp4"
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
            </div>
            {/* 3rd Section */}
            <div className="my-3 row align-items-center">
              {/* <div className="col-lg-6 col-md-12 textual-info">
                <div className="img-div-2">
                  <img
                    alt="How it work"
                    className="image-section"
                    src="https://ketto.gumlet.io/assets/images/medical-landing/trymedical.png?w=480&dpr=1.0"
                  />
                </div>
              </div> */}
              <div className="col-lg-6 col-md-12 textual-info">
                  <div className="text-center ">
                    <video
                      style={{
                        width: "100%",
                        // border: "2px solid #000",
                        borderRadius: "8px",
                      }}
                      autoPlay
                      muted
                      controls
                    >
                      <source
                        src="https://building-bridges-for-islam-media.s3.us-west-2.amazonaws.com/uploads/86a83ca4-d8ea-4f28-be43-827b65ad5275-donate-to-a-cause-video.mp4"
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </div>
              <div className="col-lg-6 col-md-12 d-lg-block mt-5 mt-lg-0">
                <h2 className="how-work-title " style={{color: "#002866"}}>3. Donate to a Cause</h2>
                <div className="desc1">
                  If you visit Building Bridges as a donor, you can choose to
                  donate to any fundraiser out of the 8 featured areas of
                  interest. After choosing your fundraiser, you can enter the
                  donation amount and then proceed to continue. You will be
                  redirected to the payment gateway where you have to fill in
                  the required details to complete the payment transaction. Once
                  the donations are collected, you can withdraw the amount
                  anytime and make a difference by helping the helpless.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HowItsWork;
