import chooseIcon1 from "../src/assets/img/building-bridges-offer/icon1.png";
import chooseIcon2 from "../src/assets/img/building-bridges-offer/icon2.png";
import chooseIcon3 from "../src/assets/img/building-bridges-offer/icon3.png";
import chooseIcon4 from "../src/assets/img/building-bridges-offer/icon4.png";
import chooseIcon5 from "../src/assets/img/building-bridges-offer/icon5.png";
import chooseIcon6 from "../src/assets/img/building-bridges-offer/icon6.png";

import medicalSection1 from "../src/assets/img/staticImages/medical-crowdfunding-images/section-1.jpg";
import medicalSection2 from "../src/assets/img/staticImages/medical-crowdfunding-images/section-2.jpg";
import medicalSection3 from "../src/assets/img/staticImages/medical-crowdfunding-images/section-3.jpg";
import educationalSection1 from "../src/assets/img/staticImages/educational-crowdfunding-images/section1.png";
import educationalSection2 from "../src/assets/img/staticImages/educational-crowdfunding-images/section2.png";
import educationalSection3 from "../src/assets/img/staticImages/educational-crowdfunding-images/section3.png";
import sportsSection1 from "../src/assets/img/staticImages/sports-crowdfunding-images/section11.png";
import sportsSection2 from "../src/assets/img/staticImages/sports-crowdfunding-images/section2.png";
import sportsSection3 from "../src/assets/img/staticImages/sports-crowdfunding-images/section3.png";
import childSection1 from "../src/assets/img/staticImages/child-crowdfunding-images/section1.png";
import childSection2 from "../src/assets/img/staticImages/child-crowdfunding-images/section2.png";
import childSection3 from "../src/assets/img/staticImages/child-crowdfunding-images/section3.png";
import animalSection1 from "../src/assets/img/staticImages/animal-crowdfunding-images/section1.png";
import animalSection2 from "../src/assets/img/staticImages/animal-crowdfunding-images/section2.png";
import animalSection3 from "../src/assets/img/staticImages/animal-crowdfunding-images/section3.png";
import hungerFreeSection1 from "../src/assets/img/staticImages/hunger-free-funding/section1.png";
import hungerFreeSection2 from "../src/assets/img/staticImages/hunger-free-funding/section2.png";
import hungerFreeSection3 from "../src/assets/img/staticImages/hunger-free-funding/section3.png";
import memorialsection1 from "../src/assets/img/staticImages/memorial-crowdfunding-images/section1.png";
import memorialsection2 from "../src/assets/img/staticImages/memorial-crowdfunding-images/section2.png";
import memorialsection3 from "../src/assets/img/staticImages/memorial-crowdfunding-images/section3.png";

import medicalChoosseIcon1 from "../src/assets/img/staticImages/medical-crowdfunding-images/icon1.png";
import medicalChoosseIcon2 from "../src/assets/img/staticImages/medical-crowdfunding-images/icon2.png";
import medicalChoosseIcon3 from "../src/assets/img/staticImages/medical-crowdfunding-images/icon3.png";
import medicalChoosseIcon4 from "../src/assets/img/staticImages/medical-crowdfunding-images/icon4.png";
import memorialChoosseIcon1 from "../src/assets/img/staticImages/memorial-crowdfunding-images/icon1.png";
import memorialChoosseIcon2 from "../src/assets/img/staticImages/memorial-crowdfunding-images/icon2.png";
import memorialChoosseIcon3 from "../src/assets/img/staticImages/memorial-crowdfunding-images/icon3.png";
import memorialChoosseIcon4 from "../src/assets/img/staticImages/memorial-crowdfunding-images/icon4.png";
import emergencyChoosseIcon1 from "../src/assets/img/staticImages/emergency-crowdfunding-images/icon1.png";
import emergencyChoosseIcon2 from "../src/assets/img/staticImages/emergency-crowdfunding-images/icon2.png";
import emergencyChoosseIcon3 from "../src/assets/img/staticImages/emergency-crowdfunding-images/icon3.png";
import emergencyChoosseIcon4 from "../src/assets/img/staticImages/emergency-crowdfunding-images/icon4.png";
import educationalChooseIcon1 from "../src/assets/img/staticImages/educational-crowdfunding-images/icon1.png";
import educationalChooseIcon2 from "../src/assets/img/staticImages/educational-crowdfunding-images/icon2.png";
import educationalChooseIcon3 from "../src/assets/img/staticImages/educational-crowdfunding-images/icon3.png";
import educationalChooseIcon4 from "../src/assets/img/staticImages/educational-crowdfunding-images/icon4.png";
import sportsChooseIcon1 from "../src/assets/img/staticImages/sports-crowdfunding-images/icon1.png";
import sportsChooseIcon2 from "../src/assets/img/staticImages/sports-crowdfunding-images/icon2.png";
import sportsChooseIcon3 from "../src/assets/img/staticImages/sports-crowdfunding-images/icon3.png";
import sportsChooseIcon4 from "../src/assets/img/staticImages/sports-crowdfunding-images/icon4.png";
import childChooseIcon1 from "../src/assets/img/staticImages/child-crowdfunding-images/icon1.png";
import childChooseIcon2 from "../src/assets/img/staticImages/child-crowdfunding-images/icon2.png";
import childChooseIcon3 from "../src/assets/img/staticImages/child-crowdfunding-images/icon3.png";
import childChooseIcon4 from "../src/assets/img/staticImages/child-crowdfunding-images/icon4.png";
import animalChooseIcon1 from "../src/assets/img/staticImages/animal-crowdfunding-images/icon1.png";
import animalChooseIcon2 from "../src/assets/img/staticImages/animal-crowdfunding-images/icon2.png";
import animalChooseIcon3 from "../src/assets/img/staticImages/animal-crowdfunding-images/icon3.png";
import animalChooseIcon4 from "../src/assets/img/staticImages/animal-crowdfunding-images/icon4.png";

import educationCause1 from "../src/assets/img/staticImages/educational-crowdfunding-images/cause1.png";
import educationCause2 from "../src/assets/img/staticImages/educational-crowdfunding-images/cause2.png";
import educationCause3 from "../src/assets/img/staticImages/educational-crowdfunding-images/cause3.png";
import educationCause4 from "../src/assets/img/staticImages/educational-crowdfunding-images/cause4.png";
import educationCause5 from "../src/assets/img/staticImages/educational-crowdfunding-images/cause5.png";

import emergency1 from "../src/assets/img/staticImages/emergency-crowdfunding-images/section1.png";

import aboutUsOne from "../src/assets/img/aboutus/mission.png";
import aboutUsTwo from "../src/assets/img/aboutus/vision.png";
import aboutUsThree from "../src/assets/img/aboutus/values.png";
import stepOne from "./assets/img/stepOne.png";
import stepTwo from "./assets/img/stepTwo.png";
import stepThree from "./assets/img/stepThree.png";
import stepArrow from "./assets/img/stepArrow.png";
import CollectedDonationImg from "./assets/img/widgetIcons/CollectedDonation.png";
import totalWithdrawl from "./assets/img/widgetIcons/totalWithdrawl.png";
import totalfundraising from "./assets/img/widgetIcons/totalfundraising.png";
import totalDonors from "./assets/img/widgetIcons/totalDonors.png";
import userDefaultImg from "./assets/img/userDefaultImg.png";
import Donors from "./assets/img/widgetIcons/donors.png";
import testimonialsImage1 from "./assets/img/testimonials/image1.png";
import testimonialsImage2 from "./assets/img/testimonials/image2.png";
import testimonialsImage3 from "./assets/img/testimonials/image3.png";
import testimonialsImage4 from "./assets/img/testimonials/image4.png";
import testimonialsImage5 from "./assets/img/testimonials/image5.png";
import testimonialsImage6 from "./assets/img/testimonials/image6.png";
import testimonialsImage7 from "./assets/img/testimonials/image7.png";
export const staticImages = {
  userDefaultImg,
};
export const constantImages = () => {
  return {
    medicalSection1,
    medicalSection2,
    medicalSection3,
    educationalSection1,
    educationalSection2,
    educationalSection3,
    emergency1,
    sportsSection1,
    sportsSection2,
    sportsSection3,
    childSection1,
    childSection2,
    childSection3,
    animalSection1,
    animalSection2,
    animalSection3,
    hungerFreeSection1,
    hungerFreeSection2,
    hungerFreeSection3,
    memorialsection1,
    memorialsection2,
    memorialsection3,
    testimonialsImage1,
    testimonialsImage2,
    testimonialsImage3,
    testimonialsImage4,
    testimonialsImage5,
    testimonialsImage6,
    testimonialsImage7,
  };
};

export const constantIcons = () => {
  return {
    Donors,
    CollectedDonationImg,
    totalWithdrawl,
    totalfundraising,
    totalDonors,
    chooseIcon1,
    chooseIcon2,
    chooseIcon3,
    chooseIcon4,
    chooseIcon5,
    chooseIcon6,
    medicalChoosseIcon1,
    medicalChoosseIcon2,
    medicalChoosseIcon3,
    medicalChoosseIcon4,
    memorialChoosseIcon1,
    memorialChoosseIcon2,
    memorialChoosseIcon3,
    memorialChoosseIcon4,
    emergencyChoosseIcon1,
    emergencyChoosseIcon2,
    emergencyChoosseIcon3,
    emergencyChoosseIcon4,
    educationalChooseIcon1,
    educationalChooseIcon2,
    educationalChooseIcon3,
    educationalChooseIcon4,
    sportsChooseIcon1,
    sportsChooseIcon2,
    sportsChooseIcon3,
    sportsChooseIcon4,
    childChooseIcon1,
    childChooseIcon2,
    childChooseIcon3,
    childChooseIcon4,
    animalChooseIcon1,
    animalChooseIcon2,
    animalChooseIcon3,
    animalChooseIcon4,
    educationCause1,
    educationCause2,
    educationCause3,
    educationCause4,
    educationCause5,
    aboutUsOne,
    aboutUsTwo,
    aboutUsThree,
    stepOne,
    stepTwo,
    stepThree,
    stepArrow,
  };
};
