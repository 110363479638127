import React, {useEffect, useState} from "react";
import {Card, Container, ProgressBar, Row} from "react-bootstrap";
import BreadCrumb from "../components/BreadCrumb";
import {Link, useNavigate} from "react-router-dom";
import webBanner from "../assets/img/funding.png";
import mobileBanner from "../assets/img/funding-mobile.png";
import Loader from "../components/loader";
import {useSelector} from "react-redux";
import Slider from "../components/Slider";
import LazyBackgroundImage from "../components/LazyBackgroundImage";
import OwlCarousel from "react-owl-carousel";
import HelmetSEO from "../components/HelmetSEO";
import NoResultFound from "../components/NoResultFound";
const responsiveOptions = {
  0: {
    items: 1, // Number of items to display at 0px and above
  },
  768: {
    items: 2, // Number of items to display at 768px and above
  },
  992: {
    items: 3, // Number of items to display at 992px and above
  },
  1300: {
    items: 3, // Number of items to display at 992px and above
  },
  1500: {
    items: 4, // Number of items to display at 992px and above
  },
};
const Funding = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const {
    auth: {user},
    fundraiser: {fundraiseList, fundraiserCategories},
  } = useSelector((store) => store);
  const [fundraise, setFundraise] = useState(fundraiseList);
  const handleDonateNow = (fundraise) => {
    navigate(`/payment-fundraiser/${fundraise.slug}`, {state: fundraise});
  };
  useEffect(() => {
    setLoading(false);
  }, [fundraiseList]);

  return (
    <>
      <HelmetSEO
        title="What are Fundraisers"
        description="What are Fundraisers"
      />
      <BreadCrumb
        overlay={false}
        heading="FUNDRAISERS"
        webImage={webBanner}
        mobileImage={mobileBanner}
      />
      <section className="section">
        <Container>
          <h2 className="mb-4 mb-lg-0 text-center">What are Fundraisers?</h2>
          <div className="container-fluid mid-div">
            <div className="container after-mid">
              <div className="row align-items-center ">
                <div className="col-lg-6 col-md-12 d-lg-block ">
                  <div className="desc1 mt-0 mt-lg-4">
                    Fundraisers are small campaigns with huge impact that run on
                    the our website and social media platforms with the purpose
                    of raising funds for philanthropic causes. After signing up
                    on our website and completing your profile, you can start
                    your fundraiser by providing the required details about the
                    cause you are supporting. Once approved, your fundraiser
                    goes live on our site, and you can share it with your
                    network via social media. After donations are collected, you
                    can withdraw the amount any time. As a donor you can select
                    any fundraiser on our platform and donate. Your contribution
                    goes directly to the chosen cause, making a positive impact
                    in our communities.
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 textual-info">
                  <div className="text-center mt-5">
                    <video
                      style={{
                        width: "100%",
                        border: "2px solid #000",
                        borderRadius: "8px",
                      }}
                      autoPlay
                      muted
                      controls
                    >
                      <source
                        src="https://building-bridges-for-islam-media.s3.us-west-2.amazonaws.com/uploads/1f26402a-3fcb-4ac9-b629-f166a82c9248-Video-With-Music.mp4"
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className="section">
        <Container>
          <div className="sectionHeading text-center mb-5">
            <h2>Trending Fundraisers</h2>
          </div>
          {loading ? (
            <Loader />
          ) : (
            <>
              {fundraiseList?.result ? (
                <>
                  <OwlCarousel
                    loop
                    margin={24}
                    nav
                    autoplay={true}
                    className="owl-theme fundRaisingSection"
                    responsive={responsiveOptions}
                    items={3}
                  >
                    {fundraiseList?.result?.map((fundraise, index) => {
                      return (
                        <div className="item" key={index}>
                          <Card className="d-flex flex-column justify-content-between position-relative">
                            {fundraise.urgent && (
                              <div className="urgentFundraise">Urgent</div>
                            )}
                            <div className="cardSlider">
                              <Link
                                to={`/fundraiser/${fundraise?.slug}`}
                                className="text-deceoration-none"
                              >
                                <Slider
                                  collectedAmount={true}
                                  item={fundraise?.imageUrl}
                                  amount={fundraise?.collectedAmount}
                                  tag={fundraise?.tag}
                                />
                              </Link>
                              <Card.Body>
                                <Link
                                  to={`/fundraiser/${fundraise?.slug}`}
                                  className="text-deceoration-none"
                                >
                                  <Card.Title className="donation-heading">
                                    {fundraise?.title}
                                  </Card.Title>
                                </Link>
                                <div className="fundSection my-3">
                                  <Link
                                    to={`/fundraiser/${fundraise?.slug}`}
                                    className="text-deceoration-none"
                                    style={{color: "#000"}}
                                  >
                                    <div className=" mx-auto px-3 py-2 justify-content-between d-flex fund-card-amount">
                                      <div>
                                        <div>
                                          <strong>Fundraised</strong>
                                        </div>
                                        <div>
                                          <strong>Goal</strong>
                                        </div>
                                      </div>
                                      <div className="ms-2">
                                        <div>
                                          <strong>
                                            {"Rs. " +
                                              fundraise?.collectedAmount.toLocaleString()}
                                          </strong>
                                        </div>
                                        <div>
                                          {" "}
                                          <strong
                                            style={{
                                              overflow: "hidden",
                                              whiteSpace: "nowrap",
                                            }}
                                          >
                                            {"Rs. " +
                                              fundraise?.totalAmount.toLocaleString()}
                                          </strong>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="fundRaisingProgress mt-3">
                                      <ProgressBar
                                        now={
                                          (fundraise?.collectedAmount /
                                            fundraise?.totalAmount) *
                                          100
                                        }
                                      />
                                      <div className="d-flex align-items-center justify-content-between">
                                        <div>
                                          <i className="fa fa-clock-o"></i>{" "}
                                          <span>
                                            <strong>
                                              {fundraise?.daysLeft}
                                            </strong>{" "}
                                            {fundraise?.daysLeft === 1
                                              ? " " + "day left"
                                              : " " + "days left"}{" "}
                                          </span>
                                        </div>
                                        <div>
                                          <i className="fa fa-heart text-danger"></i>{" "}
                                          <span>
                                            <strong>
                                              {fundraise?.supporters}
                                            </strong>{" "}
                                            {fundraise?.supporters === 1
                                              ? " " + "Supporter"
                                              : " " + "Supporters"}{" "}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </Link>
                                  <div className="d-flex align-items-center justify-content-between  py-3">
                                    <button
                                      className="w-100 py-3 btn__primary buttonPrimary"
                                      onClick={(e) =>
                                        handleDonateNow(fundraise)
                                      }
                                    >
                                      Donate Now
                                    </button>
                                  </div>
                                </div>
                              </Card.Body>
                            </div>
                          </Card>
                        </div>
                      );
                    })}
                  </OwlCarousel>
                </>
              ) : (
                <NoResultFound />
              )}
            </>
          )}
          {fundraiseList?.result && fundraiseList?.result?.length > 0 && (
            <div className="d-flex justify-content-center mt-5">
              <Link to="/fundraisers" className="">
                <button
                  className="py-3 btn__primary buttonPrimary"
                  id="how-does-it-work"
                >
                  View All Fundraisers
                </button>
              </Link>
            </div>
          )}
        </Container>
      </section>
      <section className="section section--gray  offerSection pb-0 mb-5">
        <Container>
          <div className="text-center mb-5">
            <h2 className="mb-4 mt-5 mt-md-0">Support Fundraisers</h2>
          </div>

          <div lg={6} md={4} sm={2} className="grid-container">
            {fundraiserCategories ? (
              <>
                {fundraiserCategories?.map((category, index) => {
                  return (
                    <div className="grid-item" key={index}>
                      <LazyBackgroundImage
                        key={index}
                        imageUrl={category?.image}
                        to={`/${category.slug}-funding/${category.slug}`}
                        className="what__we-offer helpingItem helpingItem--simple position-relative"
                        id="donate-to-a-cause"
                        category={category}
                      />
                    </div>
                  );
                })}
              </>
            ) : (
              <NoResultFound />
            )}
          </div>
        </Container>
      </section>
    </>
  );
};

export default Funding;
