import {Col, Container, Form, Row} from "react-bootstrap";
import BreadCrumb from "../components/BreadCrumb";
import {useState} from "react";
import {useForm} from "react-hook-form";
import TextInput from "../components/TextInput";
import eyeIcon from "../assets/img/Hide.png";
import hideEyeIcon from "../assets/img/Hide-2.png";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {updateUserPassword} from "../Api-Calls/authentication";
import {SwalErrorMessage} from "../components/sweetAlertMessage";
import {SwalMessage} from "../components/sweetAlertMessage";
import {useSelector} from "react-redux";

const AccountSetting = () => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    formState: {errors},
  } = useForm();

  const pwd = watch("newPassword");
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const {
    auth: {user},
  } = useSelector((store) => store);

  const [state, setState] = useState({
    cell: "",
    errors: {
      cell: "",
    },
  });
  const setInputFields = (evt) => {
    setState({
      ...state,
      [evt.target.name]: evt.target.value,
    });
  };

  const onSubmit = async (data) => {
    try {
      const res = await updateUserPassword(user?._id, data);
      if (res?.status == 200) {
        SwalMessage(res?.data?.status, res?.data?.message, "success");
        reset({
          oldPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
      }
    } catch (error) {
      SwalErrorMessage(
        "Oops!" + " " + error?.response?.data?.status,
        error?.response?.data?.message,
        "error",
        true
      );
    }
  };
  return (
    <>
      <BreadCrumb heading="Account Setting" badge="account SETTING" />

      <section className="section--about">
        <section className="section section--white">
          <Container>
            <div className="col-md-6 mx-auto">
              <div className="mb-5 text-center">
                <h3>ACCOUNT SETTING</h3>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col className="mb-3" lg={12}>
                    <Form.Label className="mb-1">
                      <b>Current Password</b>{" "}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <div className="mb-1 position-relative">
                      <TextInput
                        type={`${showPassword ? "text" : "password"}`}
                        {...register("oldPassword")}
                        className={`form-control `}
                        control={control}
                        showIcon={eyeIcon}
                        icon="true"
                        hideIcon={hideEyeIcon}
                        placeholder="Password"
                        setShowPassword={setShowPassword}
                        showPassword={showPassword}
                        rules={{
                          required: "Old Password field is required.",
                          minLength: {
                            value: 8,
                            message: "Password must be 8 characters long.",
                          },
                        }}
                      />
                    </div>
                  </Col>
                  <Col className="mb-3" lg={12}>
                    <Form.Label className="mb-1">
                      <b>New Password</b> <span className="text-danger">*</span>
                    </Form.Label>
                    <div className="mb-1 position-relative">
                      <TextInput
                        type={`${showPassword1 ? "text" : "password"}`}
                        {...register("newPassword")}
                        className={`form-control `}
                        control={control}
                        showIcon={eyeIcon}
                        icon="true"
                        hideIcon={hideEyeIcon}
                        placeholder="Password"
                        setShowPassword={setShowPassword1}
                        showPassword={showPassword1}
                        rules={{
                          required: "New Password field is required.",
                          minLength: {
                            value: 8,
                            message: "Password must be 8 characters long.",
                          },
                        }}
                      />
                    </div>
                  </Col>
                  <Col className="mb-3" lg={12}>
                    <Form.Label className="mb-1">
                      <b>Confirm Password</b>{" "}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <div className="mb-1 position-relative">
                      <TextInput
                        type={`${showPassword2 ? "text" : "password"}`}
                        {...register("confirmPassword")}
                        className={`form-control `}
                        control={control}
                        showIcon={eyeIcon}
                        icon="true"
                        hideIcon={hideEyeIcon}
                        placeholder="Password"
                        setShowPassword={setShowPassword2}
                        showPassword={showPassword2}
                        rules={{
                          required: "Confirm Password field is required.",
                          validate: (value) =>
                            value === pwd || "Confirm Password does not match.",
                        }}
                      />
                    </div>
                  </Col>

                  <div className="text-center my-4">
                    <button className="btn__primary mx-auto shadow buttonPrimary">
                      Update Password
                    </button>
                  </div>
                </Row>
              </form>
            </div>
          </Container>
          <ToastContainer />
        </section>
      </section>
    </>
  );
};

export default AccountSetting;
