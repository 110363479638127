import React from "react";
import {Container, Card} from "react-bootstrap";
import BreadCrumb from "../components/BreadCrumb";
import banner from "../assets/img/about-us.png";
import mobileBanner from "../assets/img/about-us-mobile.png";
import {constantIcons} from "../Constant";
import LogoBlack from "../assets/img/Logo-04-1.svg";
import {HashLink} from "react-router-hash-link";
import HelmetSEO from "../components/HelmetSEO";
import OwlCarousel from "react-owl-carousel";
import TestimonialCardNew from "../components/TestimonialCardNew";

const About = () => {
  const icons = constantIcons();

  return (
    <>
      <HelmetSEO title="About us" description="Aboutus" />
      <BreadCrumb 
        heading="ABOUT US"
        description="Building Bridges is an online fundraising platform that strives to bridge the gap between those in need and those who can make a difference"
        badge="ABOUT US"
        webImage={banner}
        mobileImage={mobileBanner}
        overlay={false}
      />

      <section className="section--about ">
        <section className="section section--white" id="whoWeAre">
          <Container className="about-card-sec">
            <Card className=" mx-2  border-0 p-0 about about-card ">
              <Card.Body className="py-4 px-4" style={{flex: "1"}}>
                <img className="mt-3" height={80} src={icons.aboutUsOne} loading="lazy"/>
                <Card.Title className="my-3 ">Mission</Card.Title>

                <Card.Text className="">
                  To create a platform where masses support masses. Operating on
                  the principles of brotherhood and fraternity, we intend to
                  inspire stronger communities by connecting people to a
                  fundraising platform that operates in the eight sectors of
                  social services in Pakistan.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className=" mx-2  border-0 p-0 about-card ">
              <Card.Body className="py-4 px-4 " style={{flex: "1"}}>
                <img className="mt-3" height={80} src={icons.aboutUsTwo} loading="lazy"/>
                <Card.Title className="my-3 ">Vision</Card.Title>
                <Card.Text className="">
                  To foster a culture of humanity and service to others. Our
                  commitment is in harboring a trustworthy global funding
                  platform where people can come together to support the
                  downtrodden communities in Pakistan.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className=" mx-2  border-0 p-0 about-card ">
              <Card.Body className="py-4 px-4" style={{flex: "1"}}>
                <img className="mt-3" height={80} src={icons.aboutUsThree} loading="lazy"/>
                <Card.Title className="my-3 ">Core Values</Card.Title>
                <Card.Text className="">
                  To empower the downtrodden communities of Pakistan by
                  supporting them through Building Bridges. In all aspects of
                  our operations, we uphold the highest standards of integrity,
                  transparency, and ethical practices, ensuring that our
                  platform is trustworthy and reliable.
                </Card.Text>
              </Card.Body>
            </Card>
          </Container>
        </section>
      </section>
      <section className="">
        <Container>
          <Card className="p-5 fq-div">
            <img
              className="mt-3 text-center"
              height={80}
              src={LogoBlack}
              alt=""
              loading="lazy"
            />
            <Card.Text className="mt-5">
              Building Bridges is on a mission to bridge the gap between those
              in need and those who can make a difference. We are committed to
              creating a trustworthy global funding platform where individuals
              from around the world can come together to support disadvantaged
              communities. In everything we do, we uphold the highest standards
              of integrity, transparency, and ethical practices, ensuring that
              our platform is reliable and trustworthy. Whether you're a
              fundraiser or a donor, our platform empowers you to make a
              positive impact around you.
            </Card.Text>
            <Card.Text>
              Building Bridges is proudly powered by Techmate Technologies LLC,
              which stands as the cornerstone of support for this noble
              endeavor. Through this strategic partnership, Techmate
              Technologies LLC becomes the prime beneficiary of Building
              Bridges, demonstrating its unwavering commitment to community and
              social responsibility. In furtherance of this collaboration,
              Techmate Technologies LLC will link its Corporate Social
              Responsibility (CSR) initiatives with Building Bridges, aligning
              shared values to make a meaningful impact on society. Together, we
              aim to build stronger communities, foster a culture of giving, and
              pave the way for positive change.
            </Card.Text>
            <Card.Text>
              On Buildin Bridges, creating a fundraiser is simple. After signing
              up on our website and completing your profile, you can start your
              fundraiser by providing essential details about the cause you
              support. Once approved, your fundraiser goes live on our site, and
              you can share it with your network via social media. You can
              withdraw collected donations at any time.
            </Card.Text>
            <Card.Text>
              If you visit Building Bridges to make a difference as a donor, you
              can choose from a variety of fundraisers spanning eight areas of
              interest on our platform. After selecting your cause, enter your
              desired donation amount and proceed to our secure payment gateway.
              Your generosity leads to a positive change, as soon as your
              contributions reach those in need.
            </Card.Text>
            <Card.Text>
              Thank you! Only with your support can Building Bridges help the
              helpless. Together we can make a profound impact on our
              communities by extending a helping hand to those who need it most.
            </Card.Text>
          </Card>
        </Container>
      </section>
      <section className="section">
        {" "}
        <Container>
          <div className="sectionHeading text-center mb-5 mt-5 mt-md-0">
            <h2>Testimonials</h2>
          </div>

          <div className="col-md-10 mx-auto shadow-lg">
            <OwlCarousel
              items={1}
              margin={30}
              loop
              autoplay={true}
              className="owl-theme fundRaisingSection"
            >
              <TestimonialCardNew />
            </OwlCarousel>
          </div>
        </Container>
      </section>

      <section
        className="text-center mb-5 mt-4"
        style={{background: "#F3F3F3"}}
      >
        <Container>
          <div className="d-lg-flex  justify-content-between p-5 align-items-center ">
            <h3 className="help-us-heading">
              Help Us, {window?.innerWidth <= 380 && <br />} Help You!
            </h3>
            <li className="list-inline-item mt-4 mt-lg-0">
              <HashLink
                className="text-decoration-none"
                smooth
                to={"/#donate-to-a-cause"}
              >
                <button className="py-3 btn__primary buttonPrimary">
                  Donate to a cause
                </button>
              </HashLink>
            </li>
          </div>
        </Container>
      </section>
    </>
  );
};

export default About;
