import React, {Fragment, useEffect, useState} from "react";
import {getFundraiseByCategorySlug} from "../Api-Calls/categoryApi";
import {Container} from "react-bootstrap";
import FundraiserCard from "../components/FundraiserCard";
import bannerBg from "./../assets/img/browse-fundraise.png";
import Loader from "../components/loader";
import BreadCrumb from "../components/BreadCrumb";
import HelmetSEO from "../components/HelmetSEO";
import NoResultFound from "../components/NoResultFound";
import { useLocation } from "react-router-dom";
import ReactPaginate from "react-paginate";
const Categoryfundraiser = () => {

  const [allFundraiser, setAllFundraiser] = useState([]);
  const [loader, setLoader] = useState(false);
  const {state} = useLocation();
  const getFundraisebyCategotyId = async () => {
    try {
      setLoader(true);
      const params = {
        page: 1,
        limit: 12,
      };
      const slugsArray = state?.slug.split(',').map(s => s.trim()); // Split the string into an array of slugs
      const res = await getFundraiseByCategorySlug(slugsArray,params);
      if (res?.status == 200) {
        setAllFundraiser(res?.data?.result);
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getFundraisebyCategotyId();
  }, []);
  const handlePageClick = async(event) => {
    setLoader(true);
    const params = {
      page: event.selected + 1,
      limit: 12,
    };
    const res = await getFundraiseByCategorySlug(state?.slug,params);
    if (res?.status == 200) {
      setAllFundraiser(res?.data?.result);
      setLoader(false);
    }
  };
  return (
    <>
      <HelmetSEO title="Fundraisers" description="Fundraisers" />

      <BreadCrumb
        heading="FUNDRAISERS"
        badge="HOW DOES IT WORK"
        webImage={bannerBg}
        mobileImage={bannerBg}
      />
      <section className="section  ">
        <Container>
          <div className="filterMainContent">
            <div className="fundraiser-flx  ">
              {loader ? (
                <Loader />
              ) : (
                <Fragment>
                  {allFundraiser && allFundraiser?.result?.length > 0 ? (
                    <>
                      {allFundraiser?.result?.map((fundraise, index) => {
                        return (
                          <div className="li-fundraiser-card">
                            <FundraiserCard fundraise={fundraise} />
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <NoResultFound />
                  )}
                </Fragment>
              )}
            </div>
          </div>
        </Container>
      </section>
      {allFundraiser?.result?.length >= 12 && (
      <ReactPaginate
        previousLabel="previous"
        breakLabel="..."
        nextLabel="next"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={allFundraiser?.totalPages}
        renderOnZeroPageCount={null}
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination justify-content-center"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        activeClassName="active"
        marginPagesDisplayed={2}
      />
      )}
    </>
  );
};

export default Categoryfundraiser;
