import React from "react";
import {constantIcons} from "../Constant";

const WhyChooseBuildingBridges = (props) => {
    const icon = constantIcons();
  return (
    <section
        className="section section--bg py-5"
        style={{background: `#fff no-repeat center`}}
      >
        <div className="">
          <h2 className="mb-4 high-title">
            Why choose Building Bridges for {props.mainHeading} Funding?
          </h2>
          <div className="col-md-7 mx-auto divider"></div>
          <div className="row mx-auto">
            <div className="col-md-6 col-lg-3 col-12 mt-4">
              <div className="high-cost">
                <img src={props.icon1} loading="lazy"/>
              </div>
              <h4 className="text-heading text-center">
                {props.heading1}
              </h4>
              <p className="text-desc">
              {props.description1}
              </p>
            </div>
            <div className="col-md-6 col-lg-3 col-12 mt-4">
              <div className="high-cost">
                <img src={props.icon2} loading="lazy"/>
              </div>
              <h4 className="text-heading text-center">
              {props.heading2}
              </h4>
              <p className="text-desc">
              {props.description2}
              </p>
            </div>
            <div className="col-md-6 col-lg-3 col-12 mt-4">
              <div className="high-cost">
                <img src={props.icon3} loading="lazy"/>
              </div>
              <h4 className="text-heading text-center">
              {props.heading3}
              </h4>
              <p className="text-desc">
              {props.description3}
              </p>
            </div>
            <div className="col-md-6 col-lg-3 col-12 mt-4">
              <div className="high-cost">
                <img src={props.icon4} loading="lazy"/>
              </div>
              <h4 className="text-heading text-center">
              {props.heading4}
              </h4>
              <p className="text-desc">
              {props.description4}
              </p>
            </div>
          </div>
        </div>
      </section>
  );
};

export default WhyChooseBuildingBridges;
