import React, {useState} from "react";
import {Card, Modal, ProgressBar} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import ModalBody from "./ModalBody";
import Slider from "../components/Slider";

const FundraiserCard = ({fundraise}) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [id, setId] = useState("");

  const handleDonateNow = (fundraise) => {
    navigate(`/payment-fundraiser/${fundraise.slug}`, {state: fundraise});
  };
  const handleClose = () => setShow(false);
  return (
    <>
      <div className="item">
        <Card
          className="d-flex flex-column justify-content-between position-relative"
          style={{width: "350px"}}
        >
         
          <div className="cardSlider">
          <Link
                to={`/fundraiser/${fundraise?.slug}`}
                className="text-deceoration-none"
              >
            <Slider
             collectedAmount={true}
              item={fundraise?.imageUrl}
              amount={fundraise?.collectedAmount}
              tag={fundraise?.tag}
            />
              </Link>
            <Card.Body>
            <Link
                to={`/fundraiser/${fundraise?.slug}`}
                className="text-deceoration-none"
              >

                <Card.Title className="donation-heading">
                  {fundraise?.title}
                </Card.Title>
              </Link>
              <div className="fundSection my-3">
              <Link
                to={`/fundraiser/${fundraise?.slug}`}
                className="text-deceoration-none"
                style={{color: "#000"}}
              >
                <div className=" mx-auto px-3 py-2 justify-content-between d-flex fund-card-amount">
                  <div>
                    <div>
                      <strong>Fundraised</strong>
                    </div>
                    <div>
                      <strong>Goal</strong>
                    </div>
                  </div>
                  <div className="ms-2">
                    <div>
                      <strong>
                        {"Rs. " + fundraise?.collectedAmount.toLocaleString()}
                      </strong>
                    </div>
                    <div>
                      {" "}
                      <strong
                        style={{
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {"Rs. " + fundraise?.totalAmount.toLocaleString()}
                      </strong>
                    </div>
                  </div>
                </div>
                <div className="fundRaisingProgress mt-3">
                  <ProgressBar
                    now={
                      (fundraise?.collectedAmount / fundraise?.totalAmount) *
                      100
                    }
                  />
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <i className="fa fa-clock-o"></i>{" "}
                      <span>
                        <strong>{fundraise?.daysLeft}</strong>{" "}
                        {fundraise?.daysLeft == 1
                          ? " " + "day left"
                          : " " + "days left"}{" "}
                      </span>
                    </div>
                    <div>
                      <i className="fa fa-heart text-danger"></i>{" "}
                      <span>
                        <strong>{fundraise?.supporters}</strong>{" "}
                        {fundraise?.supporters === 1
                          ? " " + "Supporter"
                          : " " + "Supporters"}{" "}
                      </span>
                    </div>
                  </div>
                </div>
          </Link>
                <div className="d-flex align-items-center justify-content-between  pt-3">
                  <button
                    className="w-100  btn__primary buttonPrimary"
                    onClick={(e) => handleDonateNow(fundraise)}
                  >
                    Donate Now
                  </button>
                </div>
              </div>
            </Card.Body>
          </div>
        </Card>
      </div>
      {/* Modal start */}
      <Modal centered size="lg" show={show} onHide={handleClose}>
        <ModalBody id={id} setShow={setShow} />
      </Modal>
      {/* modal end */}
    </>
  );
};

export default FundraiserCard;
