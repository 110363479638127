import React from "react";
import {Container} from "react-bootstrap";
import BreadCrumb from "../components/BreadCrumb";
import privacyBanner from "../assets/img/banner/privacyPolicy.png";
import privacyBannerMobile from "../assets/img/privacy-policy-banner-mobile.png";
import {Link} from "react-router-dom";
import HelmetSEO from "../components/HelmetSEO";

const PrivacyPolicy = () => {
  return (
    <>
      <HelmetSEO title="Privacy Policy" description="Privacy Policy" />
      <BreadCrumb
        heading="Privacy Policy for Building Bridges"
        webImage={privacyBanner}
        mobileImage={privacyBannerMobile}
      />
      <div className="sec-uplift">
        <Container>
          <h2 className="mb-3">Privacy Policy</h2>
          <p className="mb-3">Last updated: September 01, 2023</p>
          <p className="fq-div">
            Building Bridges is proudly powered by Techmate Technologies LLC,
            which stands as the cornerstone of support for this noble endeavor.
            Through this strategic partnership, Techmate Technologies LLC
            becomes the prime beneficiary of Building Bridges, demonstrating its
            unwavering commitment to community and social responsibility. In
            furtherance of this collaboration, Techmate Technologies LLC will
            link its Corporate Social Responsibility (CSR) initiatives with
            Building Bridges, aligning shared values to make a meaningful impact
            on society. Together, we aim to build stronger communities, foster a
            culture of giving, and pave the way for positive change.
          </p>
          <p className="fq-div">
            This Privacy Policy describes our policies and procedures on the
            collection, use and disclosure of your information when you use the
            service and tells you about your privacy rights and how the law
            protects you. We use your personal data to provide and improve the
            service. By using the service, you agree to the collection and use
            of information in accordance with this Privacy Policy.
          </p>
        </Container>

        <Container>
          <h2 className="mb-3">Interpretation</h2>
          <p className="fq-div">
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
          </p>
          <h2 className="mb-3">Definitions</h2>
          <p className="fq-div">For the purposes of this Privacy Policy:</p>
          <ul className="fq-div">
            <li>
              <strong>Account</strong> means a unique account created for you to
              access our service or parts of our service.
            </li>
            <li>
              <strong>Affiliate</strong> means an entity that controls, is
              controlled by or is under common control with a party, where
              "control" means ownership of 50% or more of the shares, equity
              interest or other securities entitled to vote for election of
              directors or other managing authority.
            </li>
            <li>
              <strong>Company</strong> (referred to as either "Building
              Bridges", "we", "us" or "our" in this Agreement) refers to
              Building Bridges.
            </li>
            <li>
              <strong>Cookies</strong> are small files that are placed on your
              computer, mobile device or any other device by a website,
              containing the details of your browsing history on that website
              among its many uses.
            </li>
            <li>
              <strong>Country</strong> refers to: Pakistan
            </li>
            <li>
              <strong>Device</strong> means any device that can access the
              service such as a computer, a cellphone or a digital tablet.
            </li>
            <li>
              <strong>Personal Data</strong> is any information that relates to
              an identified or identifiable individual.
            </li>
            <li>
              <strong>Service</strong> refers to the website.
            </li>
            <li>
              <strong>Service Provider</strong> means any natural or legal
              person who processes the data on behalf of Building Bridges. It
              refers to third-party companies or individuals employed by
              Building Bridges to facilitate the service, to provide the service
              on behalf of Building Bridges, to perform services related to the
              service or to assist Building Bridges in analyzing how the service
              is used.
            </li>
            <li>
              <strong>Usage Data</strong> refers to data collected
              automatically, either generated by the use of the service or from
              the service infrastructure itself (for example, the duration of a
              page visit).
            </li>
            <li>
              <strong>Website</strong> refers to Building Bridges, accessible
              from{" "}
              <a
                style={{color: "#002866"}}
                href="https://mail.google.com/mail/u/0/?view=cm&fs=1&to=info@buildingbridgesforislam.com"
                target="_blank"
              >
                <b>buildingbridgeinfo@gmail.com</b>
              </a>
            </li>
            <li>
              <strong>You</strong> means the individual accessing or using the
              service, or Building Bridges, or other legal entity on behalf of
              which such individual is accessing or using the service, as
              applicable.
            </li>
          </ul>
        </Container>

        <Container>
          {/* <h2 className="mb-3">Collecting and Using your Personal Data</h2> */}
          <h2 className="mb-3">Types of Data Collected</h2>
          <h2 className="mb-3">Personal Data</h2>
          <p className="fq-div">
            While using our service, we may ask you to provide us with certain
            personally identifiable information that can be used to contact or
            identify you. Personally identifiable information may include, but
            is not limited to:
          </p>
          <ul>
            <li>Email address</li>
            <li>First name and last name</li>
            <li>Phone number</li>
            <li>Address, State, Province, ZIP/Postal code, City</li>
            <li>Usage Data</li>
          </ul>
        </Container>

        <Container>
          <h2 className="mb-3">Usage Data</h2>
          <p className="fq-div">
            Usage Data is collected automatically when using the service.
          </p>
          <p className="fq-div">
            Usage Data may include information such as your device's Internet
            Protocol address (e.g. IP address), browser type, browser version,
            the pages of our service that you visit, the time and date of your
            visit, the time spent on those pages, unique device identifiers and
            other diagnostic data.
          </p>
          <p className="fq-div">
            When you access the service by or through a mobile device, we may
            collect certain information automatically, including, but not
            limited to, the type of mobile device you use, your mobile device
            unique ID, the IP address of your mobile device, your mobile
            operating system, the type of mobile Internet browser you use,
            unique device identifiers and other diagnostic data.
          </p>
          <p className="fq-div">
            We may also collect information that your browser sends whenever you
            visit our service or when you access the service by or through a
            mobile device.
          </p>
        </Container>

        <section className=" section--white text-black">
          <Container>
            <h2 className="mb-3">Tracking Technologies and cookies</h2>
            <p className="fq-div">
              We use cookies and similar tracking technologies to track the
              activity on our service and store certain information. Tracking
              technologies used are beacons, tags, and scripts to collect and
              track information and to improve and analyze our service. The
              technologies we use may include:
            </p>
            <ul className="fq-div">
              <li>
                <strong>Cookies or Browser Cookies</strong> are small files
                placed on your device. You can instruct your browser to refuse
                all cookies or to indicate when a cookie is being sent. However,
                if you do not accept cookies, you may not be able to use some
                parts of our service. Unless you have adjusted your browser
                setting so that it will refuse cookies, our service may use
                cookies.
              </li>
              <li>
                <strong>Web Beacons.</strong> Certain sections of our service
                and our emails may contain small electronic files known as web
                beacons (also referred to as clear gifs, pixel tags, and
                single-pixel gifs) that permit Building Bridges, for example, to
                count users who have visited those pages or opened an email and
                for other related website statistics (for example, recording the
                popularity of a certain section and verifying system and server
                integrity).
              </li>
            </ul>
            <p className="fq-div">
              Cookies can be "Persistent" or "Session" cookies. Persistent
              cookies remain on your personal computer or mobile device when you
              go offline, while session cookies are deleted as soon as you close
              your web browser.
            </p>
            <p className="fq-div">
              We use both Session and Persistent cookies for the purposes set
              out below:
            </p>
            <ul className="fq-div">
              <li>
                <strong>Necessary / Essential cookies</strong>
                <br />
                Type: Session cookies
                <br />
                Administered by: US <br />
                Purpose: These cookies are essential to provide you with
                services available through the website and to enable you to use
                some of its features. They help to authenticate users and
                prevent fraudulent use of user accounts. Without these cookies,
                the services that you have asked for cannot be provided, and we
                only use these cookies to provide you with those services.
              </li>
              <li>
                <strong>cookies Policy / Notice Acceptance cookies</strong>
                <br />
                Type: Persistent cookies
                <br />
                Administered by: US
                <br />
                Purpose: These cookies identify if users have accepted the use
                of cookies on the website.
              </li>
              <li>
                <strong>Functionality cookies</strong>
                <br />
                Type: Persistent cookies
                <br />
                Administered by: US
                <br />
                Purpose: These cookies allow us to remember choices you make
                when you use the website, such as remembering your login details
                or language preference. The purpose of these cookies is to
                provide you with a more personal experience and to avoid you
                having to re-enter your preferences every time you use the
                website.
              </li>
            </ul>
            <p className="fq-div">
              For more information about the cookies we use and your choices
              regarding cookies, please visit our cookies Policy or the cookies
              section of our Privacy Policy.
            </p>
          </Container>
        </section>

        <Container>
          <h2 className="mb-3">Use of Your Personal Data</h2>
          <p className="fq-div">
            Building Bridges may use Personal Data for the following purposes:
          </p>
          <ul className="fq-div">
            <li>
              <strong>To provide and maintain our service,</strong> including to
              monitor the usage of our service.
            </li>
            <li>
              <strong>To manage your Account,</strong> and to manage your
              registration as a user of the service. The Personal Data you
              provide can give you access to different functionalities of the
              service that are available to you as a registered user.
            </li>
            <li>
              <strong>For the performance of a contract,</strong> the
              development, compliance and undertaking of the purchase contract
              for the products, items or services you have purchased or of any
              other contract with us through the service.
            </li>
            <li>
              <strong>To contact you, </strong> by email, telephone calls, SMS,
              or other equivalent forms of electronic communication, such as a
              mobile application's push notifications regarding updates or
              informative communications related to the functionalities,
              products or contracted services, including the security updates,
              when necessary or reasonable for their implementation.
            </li>
            <li>
              <strong>To provide you</strong> with news, special offers and
              general information about other goods, services and events which
              we offer that are similar to those that you have already purchased
              or enquired about unless you have opted not to receive such
              information.
            </li>
            <li>
              <strong>To manage your requests,</strong> to attend, and manage
              your requests to us.
            </li>
            <li>
              <strong>For business transfers,</strong> we may use your
              information to evaluate or conduct a merger, divestiture,
              restructuring, reorganization, dissolution, or other sale or
              transfer of some or all of our assets, whether as a going concern
              or as part of bankruptcy, liquidation, or similar proceeding, in
              which Personal Data held by us about our service users is among
              the assets transferred.
            </li>
            <li>
              <strong>For other purposes,</strong> we may use your information
              for other purposes, such as data analysis, identifying usage
              trends, determining the effectiveness of our promotional campaigns
              and to evaluate and improve our service, products, services,
              marketing and your experience.
            </li>
          </ul>
          <p className="fq-div">
            We may share your personal information in the following situations:
          </p>
          <ul className="fq-div">
            <li>
              <strong>With service providers,</strong> we may share your
              personal information to monitor and analyze the use of our service
              and to contact you.
            </li>
            <li>
              <strong>For business transfers,</strong> we may share or transfer
              your personal information in connection with, or during
              negotiations of, any merger, sale of Company assets, financing, or
              acquisition of all or a portion of our business to another
              company.
            </li>
            <li>
              <strong>With Affiliates,</strong> we may share your information in
              which case we will require those affiliates to honor this Privacy
              Policy. Affiliates include our parent company and any other
              subsidiaries, joint venture partners or other companies that we
              control or that are under common control with us.
            </li>
            <li>
              <strong>With business partners,</strong> we may share your
              information to offer you certain products, services or promotions.
            </li>
            <li>
              <strong>With other users:</strong> when you share personal
              information or otherwise interact in the public areas with other
              users, such information may be viewed by all users and may be
              publicly distributed outside.
            </li>
            <li>
              <strong>With your consent:</strong> we may disclose your personal
              information for any other purpose with your consent.
            </li>
          </ul>
        </Container>

        <section className=" section--white text-black">
          <Container>
            <h2 className="mb-3">Retention of Your Personal Data</h2>
            <p className="fq-div">
              Building Bridges will retain your personal data only for as long
              as is necessary for the purposes set out in this Privacy Policy.
              we will retain and use your personal data to the extent necessary
              to comply with our legal obligations (for example, if we are
              required to retain your data to comply with applicable laws),
              resolve disputes, and enforce our legal agreements and policies.
            </p>
            <p className="fq-div">
              Building Bridges will also retain usage data for internal analysis
              purposes. Usage Data is generally retained for a shorter period of
              time, except when this data is used to strengthen the security or
              to improve the functionality of our service, or we are legally
              obligated to retain this data for longer time periods.
            </p>
          </Container>
        </section>

        <Container>
          <h2 className="mb-3">Transfer of Your Personal Data</h2>
          <p className="fq-div">
            Your information, including personal data, is processed at Building
            Bridges's operating offices and in any other places where the
            parties involved in the processing are located. It means that this
            information may be transferred to — and maintained on — computers
            located outside of your State, Province, Country or other
            Governmental Jurisdiction where the data protection laws may differ
            than those from your Jurisdiction.
          </p>
          <p className="fq-div">
            Your consent to this Privacy Policy followed by your submission of
            such information represents your agreement to that transfer.
          </p>
          <p className="fq-div">
            Building Bridges will take all steps reasonably necessary to ensure
            that your data is treated securely and in accordance with this
            Privacy Policy and no transfer of your personal data will take place
            to an organization or a country unless there are adequate controls
            in place including the security of your data and other personal
            information.
          </p>
        </Container>

        <section className=" section--white text-black">
          <Container>
            <h2 className="mb-3">Delete Your Personal Data</h2>
            <p className="fq-div">
              You have the right to delete or request that we assist in deleting
              the personal data that we have collected about you.
            </p>
            <p className="fq-div">
              Our service may give you the ability to delete certain information
              about you from within the service.
            </p>
            <p className="fq-div">
              You may update, amend, or delete your information at any time by
              signing in to your account, if you have one, and visiting the
              account settings section that allows you to manage your personal
              information. You may also contact us to request access to,
              correct, or delete any personal information that you have provided
              to us.
            </p>
            <p className="fq-div">
              Please note, however, that we may need to retain certain
              information when we have a legal obligation or lawful basis to do
              so.
            </p>
          </Container>
        </section>

        <Container>
          <h1 className="mb-3">Disclosure of Your Personal Data</h1>
          <h2 className="mb-3">Business Transactions</h2>
          <p className="fq-div">
            If Building Bridges is involved in a merger, acquisition or asset
            sale, your Personal Data may be transferred. we will provide notice
            before your Personal Data is transferred and becomes subject to a
            different Privacy Policy.
          </p>
          <h2 className="mb-3">Law Enforcement</h2>
          <p className="fq-div">
            Under certain circumstances, Building Bridges may be required to
            disclose your Personal Data if required to do so by law or in
            response to valid requests by public authorities (e.g. a court or a
            government agency).
          </p>
          <h2 className="mb-3">Other Legal Requirements</h2>
          <p className="fq-div">
            Building Bridges may disclose your personal data in the good faith
            belief that such action is necessary to:
          </p>
          <ul className="fq-div">
            <li>Comply with a legal obligation.</li>
            <li>
              Protect and defend the rights or property of Building Bridges.
            </li>
            <li>
              Prevent or investigate possible wrongdoing in connection with the
              service.
            </li>
            <li>
              Protect the personal safety of users of the service or the public.
            </li>
            <li>Protect against legal liability.</li>
          </ul>
          <h2 className="mb-3">Security of Your Personal Data</h2>
          <p className="fq-div">
            The security of your personal data is important to us, but remember
            that no method of transmission over the Internet, or method of
            electronic storage is 100% secure. While we strive to use
            commercially acceptable means to protect your personal data, we
            cannot guarantee its absolute security.
          </p>
          <h2 className="mb-3">Children's Privacy</h2>
          <p className="fq-div">
            our service does not address anyone under the age of 13. we do not
            knowingly collect personally identifiable information from anyone
            under the age of 13. If you are a parent or guardian and you are
            aware that your child has provided us with personal data, please
            contact us. If we become aware that we have collected personal data
            from anyone under the age of 13 without verification of parental
            consent, we take steps to remove that information from our servers.
          </p>
          <p className="fq-div">
            If we need to rely on consent as a legal basis for processing your
            information and your country requires consent from a parent, we may
            require your parent's consent before we collect and use that
            information.
          </p>
        </Container>

        <section className=" section--white text-black">
          <Container>
            <h2 className="mb-3">Links to Other Websites</h2>
            <p className="fq-div">
              Our service may contain links to other websites that are not
              operated by us. If you click on a third party link, you will be
              directed to that third party's site. We strongly advise you to
              review the Privacy Policy of every site you visit.
            </p>
            <p className="fq-div">
              We have no control over and assume no responsibility for the
              content, Privacy Policies or practices of any third party sites or
              services.
            </p>
            <h2 className="mb-3">Changes to this Privacy Policy</h2>
            <p className="fq-div">
              We may update our Privacy Policy from time to time. we will notify
              you of any changes by posting the new Privacy Policy on this page.
            </p>
            <p className="fq-div">
              We will let you know via email and/or a prominent notice on our
              service, prior to the change becoming effective and update the
              "last updated" date at the top of this Privacy Policy.
            </p>
            <p className="fq-div">
              You are advised to review this Privacy Policy periodically for any
              changes. Changes to this Privacy Policy are effective when they
              are posted on this page.
            </p>
            <h2 className="mb-3">Contact US</h2>
            <p className="fq-div">
              If you have any questions about this Privacy Policy, you can
              contact us:
            </p>
            <ul>
              <ul>
                <li>
                  By email:{" "}
                  <a
                    style={{color: "#002866"}}
                    href="https://mail.google.com/mail/u/0/?view=cm&fs=1&to=info@buildingbridgesforislam.com"
                    target="_blank"
                  >
                    <b>buildingbridgeinfo@gmail.com</b>
                  </a>
                </li>
                <li>
                  By visiting this page on our website:{" "}
                  <Link style={{color: "#002866"}} to={"/contact-us"}>
                    <b>contact Us</b>
                  </Link>{" "}
                </li>
              </ul>
            </ul>
          </Container>
        </section>
      </div>
    </>
  );
};

export default PrivacyPolicy;
