import React from 'react'
import { constantIcons } from '../../Constant';

export default function WhatBuildingBridgesOffer() {
    const icons = constantIcons();
    return (
        <section className="section">
            <div className="container">
                <h2 className="high-title">What does Building Bridges Offer?</h2>
                <div className="col-md-7 mx-auto divider"></div>
                <div className="content-wrapper row">
                    <div className="box-wrapper col-12 col-lg-6">
                        <div className="content-box">
                            <img className="free-icon" src={icons.chooseIcon1} loading="lazy"/>
                            <div className="content-d">
                                Create fundraisers in three easy steps from any part of the world.
                            </div>
                        </div>
                    </div>
                    <div className="box-wrapper col-12 col-lg-6">
                        <div className="content-box">
                            <img className="free-icon" src={icons.chooseIcon2} loading="lazy"/>
                            <div className="content-d">
                                Donate anytime, in any currency, from anywhere in the world
                            </div>
                        </div>
                    </div>
                    <div className="box-wrapper col-12 col-lg-6">
                        <div className="content-box">
                            <img className="free-icon" src={icons.chooseIcon3} loading="lazy"/>
                            <div className="content-d">
                                Withdraw your funds anytime during your fundraising campaign
                            </div>
                        </div>
                    </div>
                    <div className="box-wrapper col-12 col-lg-6">
                        <div className="content-box">
                            <img className="free-icon" src={icons.chooseIcon4} loading="lazy"/>
                            <div className="content-d">
                                Manage your funds through secure payment methods
                            </div>
                        </div>
                    </div>
                    <div className="box-wrapper col-12 col-lg-6">
                        <div className="content-box">
                            <img className="free-icon" src={icons.chooseIcon5} loading="lazy"/>
                            <div className="content-d">
                                Get updates on your fundraisers through our real-time dashboard
                            </div>
                        </div>
                    </div>
                    <div className="box-wrapper col-12 col-lg-6">
                        <div className="content-box">
                            <img className="free-icon" src={icons.chooseIcon6} loading="lazy"/>
                            <div className="content-d">
                                Seek help from our fundraising managers that are available 24/7
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
