import React from "react";
import { Button, Modal } from "react-bootstrap";

const VideoPreviewModal = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        {props?.media?.type == "image" ? (
          <img style={{ width: "100%" }} src={props?.media} />
        ) : (
          <video style={{ width: "100%" }} controls={true} autoPlay={true}>
            <source src={props?.media?.url} type="video/mp4" />
          </video>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default VideoPreviewModal;
