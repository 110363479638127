import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "./loader";
import { useDispatch } from "react-redux";
import { signIn } from "../Features/user/authSlice";
const EmailLogin = () => {
  const { email, password } = useParams();
  const dispatchRedux = useDispatch()

  useEffect(() => {
    handleEmailLogin();
  }, []);
  const [loading, setLoading] = useState(true);
  const handleEmailLogin = async () => {
    const data = {
      email: email,
      password: password,
    };
    setLoading(true);
    dispatchRedux(signIn({ email: data.email, password: data.password }))
    setLoading(false);
  };
  return (
    <section className="section">
      <div className="text-center">
        {loading && (
          <>
            {" "}
            <Loader />{" "}
          </>
        )}
      </div>
    </section>
  );
};
export default EmailLogin;
