import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import {
  SwalErrorMessage,
  SwalMessage,
} from "../../components/sweetAlertMessage";
import axios, {axiosPrivate} from "../../Api-Calls/axios";

// initial state of the slice

const initialState = {
  user: null,
  isLoading: false,
};

export const signUp = createAsyncThunk(
  "user/signup",
  async ({username, email, password}, thunkAPI) => {
    try {
      const res = await axios.post("/user/signup", {username, email, password});
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);

export const signIn = createAsyncThunk(
  "user/signin",
  async ({email, password}, thunkAPI) => {
    try {
      const res = await axios.post("/user/login", {email, password});
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message);
    }
  }
);
export const signOut = createAsyncThunk(
  "user/signout",
  async (param, thunkAPI) => {
    try {
      const token = JSON.parse(localStorage.getItem("userToken"));
      const {user} = thunkAPI.getState();
      const res = await axiosPrivate.post(
        "/user/logout/",
        {token, user},
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return res;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const userSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateUserState: (state, {payload}) => {
      state.user = payload;
      localStorage.setItem("user", JSON.stringify(payload));
    },
  },
  extraReducers: {
    // SignUp
    [signUp.pending]: (state) => {
      state.isLoading = true;
    },
    [signUp.fulfilled]: (state, {payload}) => {
      state.isLoading = false;
      SwalMessage(payload?.status, payload?.message, "success");
    },
    [signUp.rejected]: (state, {payload}) => {
      state.isLoading = false;
      SwalErrorMessage("Oops!", payload, "error", true);
    },
    // signIn
    [signIn.pending]: (state) => {
      state.isLoading = true;
    },
    [signIn.fulfilled]: (state, {payload}) => {
      state.user = payload.result.user;
      state.isLoading = false;
      localStorage.setItem("userToken", JSON.stringify(payload?.result.token));
      localStorage.setItem("user", JSON.stringify(payload?.result?.user));
      Cookies.set("userToken", JSON.stringify(payload?.result.token), {
        // expires: new Date(Date.now() + 604800 * 1000),
        expires: new Date(Date.now() + 2 * 24 * 60 * 60 * 1000)
      });
      SwalMessage(payload?.status, payload?.message, "success");
    },
    [signIn.rejected]: (state, {payload}) => {
      state.isLoading = false;
      SwalErrorMessage("Oops!", payload, "error", true);
    },
    // signOut
    [signOut.pending]: (state) => {
      state.isLoading = true;
    },
    [signOut.fulfilled]: (state) => {
      state.user = null;
      localStorage.removeItem("userToken");
      localStorage.clear();
      localStorage.removeItem("user");
      Cookies.remove("userToken");
    },
    [signOut.rejected]: (state, {payload}) => {
      state.isLoading = false;
      SwalErrorMessage("Oops!", payload, "error", true);
    },
  },
});

export default userSlice.reducer;
export const {updateUserState} = userSlice.actions;
