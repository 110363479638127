import React, {useContext, useEffect} from "react";
import {Card, Col, Container, Row} from "react-bootstrap";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useState} from "react";
import {useForm} from "react-hook-form";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../components/loader";
import {
  getSupporterById,
  updateSupporterByTheirId,
} from "../Api-Calls/supporter";
import {SwalErrorMessage, SwalMessage} from "../components/sweetAlertMessage";
import Context from "../Context/context";
import {useSelector} from "react-redux";

const ProofOfPayment = () => {
  const {
    auth: {user},
  } = useSelector((store) => store);
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const {id} = useParams();
  const [singlePayment, setSinglePayment] = useState({});
  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    formState: {errors},
  } = useForm();
  const [file, setFile] = useState({fileUrl: ""});
  const navigate = useNavigate();

  const getSupporter = async () => {
    try {
      const res = await getSupporterById(id);
      if (res?.status == 200) {
        res?.data?.result?.map((item) => setSinglePayment(item));
        localStorage.setItem(
          "proofofPayment",
          JSON.stringify(res?.data?.result)
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [imageError, setImageError] = useState("");
  const onSubmit = async () => {
    try {
      if (!file?.fileUrl) {
        return setImageError("Image field is required.");
      }
      setButtonLoading(true);
      const formData = new FormData();
      Array?.from(file?.files)?.forEach((item) => {
        formData?.append("files", item);
      });
      formData?.append("payment_status", "Inprogress");
      const res = await updateSupporterByTheirId(id, formData);
      if (res?.status == 200) {
        setButtonLoading(false);
        SwalMessage(res?.data?.status, res?.data?.message, "success");
        getSupporter();
        {
          user
            ? setTimeout(() => {
                navigate("/");
              }, 2000)
            : setTimeout(() => {
                navigate("/dashboard/donations");
              }, 2000);
        }
      }
    } catch (error) {
      setButtonLoading(false);
      SwalErrorMessage(
        "Oops!" + " " + error?.response?.data?.status,
        error?.response?.data?.message,
        "error",
        true
      );
    }
  };

  useEffect(() => {
    getSupporter();
    setFile({fileUrl: singlePayment?.picture});
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    if (singlePayment?.payment_status == "Inprogress") {
      navigate("/donations");
    }
  }, []);

  return (
    <>
      <section className="section--about mt-5">
        <section className="section section--white">
          <Container>
            <div className="col-md-10 mx-auto">
              <div className="mb-5 text-center">
                <h3>PROOF OF PAYMENT</h3>
              </div>
              {loading ? (
                <>
                  <Loader />
                </>
              ) : (
                <>
                  <Card className="shadow border-0 mb-4 p-5">
                    <Card.Body className="blog__content p-4">
                      <>
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <div className="d-flex mb-3">
                            <div style={{flex: 1}}>
                              {" "}
                              <b>Title:</b>{" "}
                            </div>
                            <div
                              style={{flex: 4, color: "#002866"}}
                              className="mx-4"
                            >
                              <b>{singlePayment?.fundraise_id?.title}</b>
                            </div>
                          </div>

                          <div className="d-flex mb-3">
                            <div style={{flex: 1}}>
                              {" "}
                              <b>Email:</b>{" "}
                            </div>
                            <div style={{flex: 4}} className="mx-4">
                              {singlePayment?.personalInfo?.email}
                            </div>
                          </div>

                          <div className="d-flex mb-3">
                            <div style={{flex: 1}}>
                              {" "}
                              <b>Description:</b>{" "}
                            </div>
                            <div style={{flex: 4}} className="mx-4">
                              {singlePayment?.fundraise_id?.description}
                            </div>
                          </div>

                          <div className="d-flex mb-3">
                            <div style={{flex: 1}}>
                              {" "}
                              <b>Status:</b>{" "}
                            </div>
                            <div style={{flex: 4}} className="mx-4">
                              {singlePayment?.fundraise_id?.status}
                            </div>
                          </div>

                          <div className="d-flex mb-3">
                            <div style={{flex: 1}}>
                              {" "}
                              <b>Urgent:</b>{" "}
                            </div>
                            <div style={{flex: 4}} className="mx-4">
                              {singlePayment?.fundraise_id?.urgent
                                ? "Urgent"
                                : "Normal"}
                            </div>
                          </div>

                          <div className="d-flex mb-3">
                            <div style={{flex: 1}}>
                              {" "}
                              <b>Payment Method:</b>{" "}
                            </div>
                            <div style={{flex: 4}} className="mx-4">
                              {singlePayment?.payment_method}
                            </div>
                          </div>
                          <div className="d-flex mb-3">
                            <div style={{flex: 1}}>
                              {" "}
                              <b>Payment Status:</b>{" "}
                            </div>
                            <div style={{flex: 4}} className="mx-4">
                              {singlePayment?.payment_status}
                            </div>
                          </div>

                          {singlePayment?.payment_status == "Pending" && (
                            <>
                              <Row>
                                <Col className="mx-auto" lg={12}>
                                  <div className="mb-3 text-center"></div>
                                  <div className="mb-5">
                                    <div className="uploadImagesprofile1">
                                      <div className="my-3">
                                        <b>
                                          Upload Image{" "}
                                          <span className="text-danger">*</span>
                                        </b>
                                      </div>
                                      <input
                                        className="form-control"
                                        type={"file"}
                                        {...register("files")}
                                        accept="image/png, image/jpeg, image/jpg"
                                        onChange={(e) => {
                                          setFile({
                                            files: e.target.files,
                                            fileUrl: URL.createObjectURL(
                                              e.target.files[0]
                                            ),
                                          });
                                        }}
                                      />
                                      {file?.fileUrl == "" ||
                                        (file?.fileUrl == undefined && (
                                          <div className="text-danger mt-2">
                                            {imageError}
                                          </div>
                                        ))}
                                    </div>
                                  </div>
                                </Col>
                                <Col lg={7} className="mx-auto ">
                                  <img
                                    className="w-100"
                                    src={
                                      file?.fileUrl
                                        ? file?.fileUrl
                                        : singlePayment?.picture
                                    }
                                  />
                                </Col>

                                <div
                                  className={
                                    file?.fileUrl || singlePayment?.picture
                                      ? "text-center mt-5"
                                      : "text-center mt-0"
                                  }
                                >
                                  <button className="btn__primary mx-auto shadow buttonPrimary">
                                    {buttonLoading && (
                                      <i
                                        class="fa mx-3  fa-circle-o-notch fa-spin"
                                        style={{fontSize: "24px"}}
                                      ></i>
                                    )}
                                    upload proof of payment
                                  </button>
                                </div>
                              </Row>
                            </>
                          )}
                        </form>
                      </>
                    </Card.Body>
                  </Card>
                  <Link to={"/dashboard/donations"}>
                    <div className="backArrow mt-5">
                      <i class="fa fa-angle-left" aria-hidden="true"></i>
                      Back
                    </div>
                  </Link>
                </>
              )}
            </div>
          </Container>
          <ToastContainer />
        </section>
      </section>
    </>
  );
};

export default ProofOfPayment;
