import axios from "axios";
import {config} from "./axiosHeader";

export const forgotPassword = async (payload) => {
  const token = JSON.parse(localStorage.getItem("userToken"));
  return await axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/user/forgot-password`,
    payload,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const getForgotPasswordDetails = async (id) => {
  const token = JSON.parse(localStorage.getItem("userToken"));
  try {
    return await axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/user-forgot-password-details/${id}`
      )
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        return error.response.data || error;
      });
  } catch (error) {
    return error;
  }
};

export const changePassword = async (payload) => {
  const token = JSON.parse(localStorage.getItem("userToken"));
  return await axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/user/change-password`,
    payload
  );
};

export const updateUserPassword = async (id, payload) => {
  const token = JSON.parse(localStorage.getItem("userToken"));
  return await axios.put(
    `${process.env.REACT_APP_BACKEND_URL}/user/update-password/${id}`,
    payload,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const updateUserProfile = async (id, payload) => {
  const token = JSON.parse(localStorage.getItem("userToken"));
  return await axios.patch(
    `${process.env.REACT_APP_BACKEND_URL}/user/update-user/${id}`,
    payload,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const updateUserInformation = async (id, payload) => {
  const token = JSON.parse(localStorage.getItem("userToken"));
  return await axios.patch(
    `${process.env.REACT_APP_BACKEND_URL}/user/profile-update/${id}`,
    payload,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const getUserById = async (id) => {
  const token = JSON.parse(localStorage.getItem("userToken"));
  return await axios.get(`${process.env.REACT_APP_BACKEND_URL}/user/get-user-by-id/${id}`, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deleteCnicImage = async (id, payload) => {
  return await axios.put(
    `${process.env.REACT_APP_BACKEND_URL}/user/delete-cnic-image/${id}`,
    payload,
    config
  );
};

export const deleteProfileImage = async (id, payload) => {
  return await axios.put(
    `${process.env.REACT_APP_BACKEND_URL}/user/delete-profile-image/${id}`,
    payload,
    config
  );
};

export const loginWithGoogle = async (payload) => {
  try {
    window.location.href = `${process.env.REACT_APP_BACKEND_URL}/auth/google`;
  } catch (error) {
    return error;
  }
};
