import React, {useEffect, useReducer, useState} from "react";
/** Layouts **/
// import AppLayout from "./layout/app-layout";

import {initialState, Reducer} from "./Context/reducer";
import RoutesPage from "./Routes";
import {Provider} from "./Context/context";
import {Helmet} from "react-helmet";

const App = () => {
  const [state, dispatch] = useReducer(Reducer, initialState);

  return (
    <Provider value={[state, dispatch]}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Building Bridges</title>
      </Helmet>
      <RoutesPage />
    </Provider>
  );
};

export default App;
