import React, {useRef,useContext, useEffect, useState} from "react";
import {Container, Row, ProgressBar, Card} from "react-bootstrap";
import homebg from "../assets/img/banner/home_banner.jpg";
import worldMap from "../assets/img/ab-services-img.jpg";
import whyBuildingBridge1 from "../assets/img/WhyIcons/1.svg";
import whyBuildingBridge2 from "../assets/img/WhyIcons/2.svg";
import whyBuildingBridge3 from "../assets/img/WhyIcons/3.svg";
import whyBuildingBridge4 from "../assets/img/WhyIcons/4.svg";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Modal from "react-bootstrap/Modal";
import testimonialBackground from "../assets/img/Testimonials.jpg";
import stepsBackground from "../assets/img/verificationSteps/steps-background.png";
import step1 from "../assets/img/verificationSteps/step-1.png";
import step2 from "../assets/img/verificationSteps/step-2.png";
import step3 from "../assets/img/verificationSteps/step-3.png";
import videoBackground from "../assets/img/videoBackground.png";
import ModalBody from "../components/ModalBody";
import {Link, useNavigate, useParams} from "react-router-dom";
import Loader from "../components/loader";
import TestimonialCard from "../components/TestimonialCard";
import Slider from "../components/Slider";
import swal from "sweetalert";
import Context from "../Context/context";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {getSuccessStories} from "../Api-Calls/successStories";
import {getBlog} from "../Api-Calls/blog";
import Gallery from "../components/Gallery";
import ProfileInformationModal from "../components/ProfileInformaionModal";
import LazyBackgroundImage from "../components/LazyBackgroundImage";
import {getUserById} from "../Api-Calls/authentication";
import {HashLink} from "react-router-hash-link";
import {SwalMessage} from "../components/sweetAlertMessage";
import FundraiserSteps from "../components/staticComponents/FundraiserSteps";
import {useDispatch, useSelector} from "react-redux";
import {getAllFundraisers} from "../Features/fundraisers/fundraiserSlice";
import {updateUserState} from "../Features/user/authSlice";
import {updateSubscribers} from "../Api-Calls/contactUs";
import NoResultFound from "../components/NoResultFound";
import Stepper from "../components/Stepper";
import FundingForm from "../components/FundingForm";
import bannerBg from "./../assets/img/ramdan-image.png";

const responsiveOptions = {
  0: {
    items: 1, // Number of items to display at 0px and above
  },
  500: {
    items: 2, // Number of items to display at 768px and above
  },
  992: {
    items: 3, // Number of items to display at 992px and above
  },
  1300: {
    items: 3, // Number of items to display at 992px and above
  },
  1500: {
    items: 4, // Number of items to display at 992px and above
  },
};
const responsiveOptionsforBlogs = {
  0: {
    items: 1, // Number of items to display at 0px and above
  },
  768: {
    items: 2, // Number of items to display at 768px and above
  },
  992: {
    items: 3, // Number of items to display at 992px and above
  },
};

const Home = () => {
  const {email,token} = useParams();
  const {
    auth: {user},
    fundraiser: {fundraiseList, fundraiserCategories},
  } = useSelector((store) => store);
  const dispatchRedux = useDispatch();
  const [state, dispatch] = useContext(Context);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [id, setId] = useState("");
  const handleClose = () => setShow(false);
  const [urgent, setUrgent] = useState([]);
  const [fundraise, setFundraise] = useState(fundraiseList);
  const [tagModal, setTagModal] = useState(false);

  const getFundraiseData = async () => {
    try {
      setLoading(true);
      dispatchRedux(getAllFundraisers()).then((res) => {
        if (res.type === "all/fundraisers/fulfilled") {
          setLoading(false);
          setFundraise(fundraiseList);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  const iframeRef = useRef(null);
  // useEffect(() => {
  //   const handleResize = () => {
  //     const width = window.innerWidth <= 600 ? "90%" : "60%";
  //     const height = window.innerWidth <= 600 ? "350px" : "500px";
  //     iframeRef.current.style.width = width;
  //     iframeRef.current.style.height = height;
  //   };

  //   handleResize();

  //   window.addEventListener("resize", handleResize);

  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);
  const handleDonateNow = (fundraise) => {
    navigate(`/payment-fundraiser/${fundraise?.slug}`, {state: fundraise});
  };
  const navigate = useNavigate();

  const getSuccessStoriesList = async () => {
    try {
      const res = await getSuccessStories();
      if (res?.status == 200) {
        let data = res?.data?.result?.result;
        dispatch({
          type: "SUCCESS_STORIES",
          payload: data,
        });
        localStorage.setItem("successStories", JSON.stringify(data));
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getUser = async () => {
    try {
      const res = await getUserById(user?._id);
      if (res?.status == 200) {
        dispatchRedux(updateUserState(res?.data?.result?.user));
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getBlogList = async () => {
    try {
      const res = await getBlog();
      if (res?.status == 200) {
        let data = res?.data?.result?.result;
        dispatch({
          type: "BLOGS",
          payload: data,
        });
        localStorage.setItem("blogs", JSON.stringify(data));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSuccessStoriesList();
    getFundraiseData();
    getBlogList();
    if (user) {
      getUser();
    }
    if (email) {
      updateSubscriber();
    }
  }, []);

  const updateSubscriber = async () => {
    try {
      const res = await updateSubscribers(email);
      if (res?.status == 200) {
        let data = res?.data?.result;
        SwalMessage(res?.data?.status, res?.data?.message, "success");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const UpdateUserProfile = async () => {
    if (user) {
      try {
        const res = await getUserById(user?._id);
        if (res?.status == 200) {
          let userData = res?.data?.result?.user;
          dispatchRedux(updateUserState(res?.data?.result?.user));
          if (
            userData?.status === "Rejected" ||
            userData?.status === "Pending"
          ) {
            if (
              userData?.country !== "" &&
              userData?.phoneNo !== "" &&
              userData?.address !== "" &&
              userData?.hear_about !== "" &&
              userData?.cnicNo !== "" &&
              userData?.zipcode !== "" &&
              userData?.cnic_picture?.length > 0
            ) {
              swal({
                title: "Please Note",
                text:
                  "Your personal information has been added, please wait for admin's approval to proceed forward.",
                icon: "warning",
                buttons: true,
                dangerMode: true,
              });
            } else {
              try {
                swal({
                  title: "Confirmation!",
                  text:
                    "Before creating a case, kindly ensure that you have completed your profile.",
                  icon: "warning",
                  buttons: true,
                  dangerMode: true,
                }).then((data) => {
                  if (data) {
                    setTagModal(true);
                  }
                });
              } catch (err) {
                console.log(err);
              }
            }
          }
        }
        if (res?.data?.result?.user?.status === "Verified") {
          navigate("/dashboard/add-fundraise");
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      navigate("/login");
    }
  };
  return (
    <>
      {/* <ProfileInformationModal tagModal={tagModal} setTagModal={setTagModal} /> */}
      <section
        id="home"
        className="section section--bg banner position-relative"
        style={{
          background: `#fff url(${homebg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center center",
          minHeight: "625px",
        }}
      >
        <div className=" hero-section ">
          <div className="">
            <h1 className=" mt-5 mb-3 banner-title" style={{color: "white"}}>
              {" "}
              Happiness is
              <br />
              Helping the Helpless
            </h1>
            <ul className="list-inline my-5">
              <li className="list-inline-item">
                <>
                  <a className="text-decoration-none">
                    <button
                      onClick={UpdateUserProfile}
                      className="py-3 btn__secondary buttonPrimary start-fundraise-btn mx-auto mx-sm-0 "
                    >
                      Start a Fundraiser
                    </button>
                  </a>
                </>
              </li>
              <li className="list-inline-item">
                <HashLink
                  className="text-decoration-none"
                  smooth
                  to={"/#donate-to-cause"}
                >
                  <button className="py-3 btn__primary buttonPrimary">
                    Donate to a cause
                  </button>
                </HashLink>
              </li>
            </ul>
          </div>
          <ul className="list-inline my-5">
            <li className="list-inline-item" style={{marginRight: "12px"}}></li>
          </ul>
        </div>
      </section>
      <FundraiserSteps />
      <section className="donationsCard d-none">
        <Container>
          <Row className="position-relative">
            <Card className="col-md-9  border-0 p-0 mx-auto d-lg-flex d-none align-items-center flex-row cardRow">
              <OwlCarousel
                className="owl-theme shadow urgentFundrasise"
                loop
                margin={0}
                items={1}
                autoplay={true}
              >
                {loading ? (
                  <Loader />
                ) : (
                  <>
                    {urgent && urgent?.length > 0 ? (
                      <>
                        {urgent?.map((fundraise, index) => {
                          return (
                            <Link to={`fundraise/${fundraise._id}`}>
                              <Card
                                className="d-lg-flex border-0 p-0 d-none align-items-center flex-row"
                                key={index}
                              >
                                <Card.Body
                                  className="py-4 px-4"
                                  style={{flex: "1"}}
                                >
                                  <Card.Title>{fundraise.title}</Card.Title>
                                  <Card.Text
                                    className=""
                                    style={{color: "#777"}}
                                  >
                                    {fundraise.description}
                                    <span class="urgentFundraise">Urgent</span>
                                  </Card.Text>
                                </Card.Body>
                                <div
                                  style={{flex: ".4"}}
                                  className="h-100 fundrasiseUrgentCase"
                                >
                                  <Slider item={fundraise?.imageUrl} />
                                </div>
                              </Card>
                            </Link>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        <div
                          className="d-flex align-items-center justify-content-center"
                          style={{height: "200px"}}
                        >
                          <h6>No Urgent Fundraiser available</h6>
                        </div>
                      </>
                    )}
                  </>
                )}
              </OwlCarousel>
            </Card>
          </Row>
        </Container>
      </section>
      <section className="">
        {/* <FundingForm
          title="Ramadan Giving Campaign"
          paragraph={`
          This Ramadan increase your blessings by registering deserving individuals on our platform, connect donors, and explore our trending fundraisers to donate your zakat.
            `}
          mobileBanner={bannerBg}
          webBanner={bannerBg}
          ramadanContent={true}
        /> */}
      </section>
      <section className="section d-block ">
        <Container>
          <div className="sectionHeading text-center mb-5">
            <h2>Trending Fundraisers</h2>
          </div>
          {loading ? (
            <Loader />
          ) : (
            <>
              {fundraiseList?.result ? (
                <>
                  <OwlCarousel
                    loop
                    margin={24}
                    nav
                    autoplay={true}
                    className="owl-theme fundRaisingSection"
                    responsive={responsiveOptions}
                    items={3}
                  >
                    {fundraiseList?.result?.map((fundraise, index) => {
                      return (
                        <div className="item" key={index}>
                          <Card className="d-flex flex-column justify-content-between position-relative">
                            {fundraise.urgent && (
                              <div className="urgentFundraise">Urgent</div>
                            )}
                            <div className="cardSlider">
                              <Link
                                to={`/fundraiser/${fundraise?.slug}`}
                                className="text-deceoration-none"
                              >
                                <Slider
                                  collectedAmount={true}
                                  item={fundraise?.imageUrl}
                                  amount={fundraise?.collectedAmount}
                                  tag={fundraise?.tag}
                                />
                              </Link>
                              <Card.Body>
                                <Link
                                  to={`/fundraiser/${fundraise?.slug}`}
                                  className="text-deceoration-none"
                                >
                                  <Card.Title className="donation-heading">
                                    {fundraise?.title}
                                  </Card.Title>
                                </Link>
                                <div className="fundSection my-3">
                                  <Link
                                    to={`/fundraiser/${fundraise?.slug}`}
                                    className="text-deceoration-none"
                                    style={{color: "#000"}}
                                  >
                                    <div className=" mx-auto px-3 py-2 justify-content-between d-flex fund-card-amount">
                                      <div>
                                        <div>
                                          <strong>Fundraised</strong>
                                        </div>
                                        <div>
                                          <strong>Goal</strong>
                                        </div>
                                      </div>
                                      <div className="ms-2">
                                        <div>
                                          <strong>
                                            {"Rs. " +
                                              fundraise?.collectedAmount.toLocaleString()}
                                          </strong>
                                        </div>
                                        <div>
                                          {" "}
                                          <strong
                                            style={{
                                              overflow: "hidden",
                                              whiteSpace: "nowrap",
                                            }}
                                          >
                                            {"Rs. " +
                                              fundraise?.totalAmount.toLocaleString()}
                                          </strong>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="fundRaisingProgress mt-3">
                                      <ProgressBar
                                        now={
                                          (fundraise?.collectedAmount /
                                            fundraise?.totalAmount) *
                                          100
                                        }
                                      />
                                      <div className="d-flex align-items-center justify-content-between">
                                        <div>
                                          <i className="fa fa-clock-o"></i>{" "}
                                          <span>
                                            <strong>
                                              {fundraise?.daysLeft}
                                            </strong>{" "}
                                            {fundraise?.daysLeft == 1
                                              ? " " + "day left"
                                              : " " + "days left"}
                                          </span>
                                        </div>
                                        <div>
                                          <i className="fa fa-heart text-danger"></i>{" "}
                                          <span>
                                            <strong>
                                              {fundraise?.supporters}
                                            </strong>{" "}
                                            {fundraise?.supporters === 1
                                              ? " " + "Supporter"
                                              : " " + "Supporters"}{" "}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </Link>
                                  <div className="d-flex align-items-center justify-content-between  py-3">
                                    <button
                                      className="w-100 py-3 btn__primary buttonPrimary"
                                      onClick={(e) =>
                                        handleDonateNow(fundraise)
                                      }
                                    >
                                      Donate Now
                                    </button>
                                  </div>
                                </div>
                              </Card.Body>
                            </div>
                          </Card>
                        </div>
                      );
                    })}
                  </OwlCarousel>
                </>
              ) : (
                <NoResultFound />
              )}
            </>
          )}
          {fundraiseList?.result && fundraiseList?.result?.length > 0 && (
            <div className="d-flex justify-content-center mt-5">
              <Link to="/fundraisers" className="">
                <button
                  className="py-3 btn__primary buttonPrimary"
                  id="how-does-it-work"
                >
                  View All Fundraisers
                </button>
              </Link>
            </div>
          )}
        </Container>
      </section>
      <section
        className="section mb-3 mb-lg-0 section--bg counterSection"
        style={{background: `#fff url(${videoBackground}) no-repeat center`}}
      >
        <h2 className="mb-5 text-center mt-4 mt-md-0" style={{color: "#fff"}}>
          How does it work?
        </h2>
        <div className="text-center my-4">
          <video
            style={{
              width: window.innerWidth <= 600 ? "90%" : "60%",
              border: "2px solid #000",
              borderRadius: "8px",
            }}
            autoPlay
            muted
            controls
            loading="lazy"
          >
            <source
              src="https://building-bridges-for-islam-media.s3.us-west-2.amazonaws.com/uploads/b925362e-aa5e-4287-ab96-49f86b90eb97-Home-Page-video.mp4"
              type="video/mp4"
            />
          </video> 
       { /*  
          <iframe
      ref={iframeRef}
      src="https://www.youtube.com/embed/OFzbytz1mwg"
      title="YouTube video player"
      frameborder="0"
      style={{
        border: "2px solid #000",
        borderRadius: "8px",
      }}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>*/}
        </div>
      </section>

      <section
        className="section section--gray  offerSection pb-0 mb-5"
        id="donate-to-cause"
      >
        <Container>
          <div className="text-center mb-5">
            <h2 className="mb-4 mt-5 mt-md-0">Support Fundraisers</h2>
          </div>

          {/* <div lg={6} md={4} sm={2} className="grid-container">
            {fundraiserCategories ? (
              <>
                {fundraiserCategories?.map((category, index) => {
                  return (
                    <div className="grid-item" key={index}>
                      <Link
                        style={{
                          backgroundImage: `url(${category?.image}) `,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "100% 100%",
                          transition:
                            "all 1.5s cubic-bezier(0.11, 0.52, 0.53, 0.79",
                        }}
                        to={`/${category.slug}-funding/${category.slug}`}
                        className="what__we-offer helpingItem helpingItem--simple position-relative"
                        id="donate-to-a-cause"
                      >
                        <div className="mb-3">
                          <div className="">
                            <img
                              className=""
                              src={JSON.parse(JSON.stringify(category.icon))}
                              alt="category icon"
                              loading="lazy"
                            />
                          </div>
                        </div>
                        <div className="helpingItem__content">
                          <div className="helpingItem__heading">
                            {category.title}
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                })}
              </>
            ) : (
              <NoResultFound />
            )}
          </div> */}
          <div lg={6} md={4} sm={2} className="grid-container">
            {fundraiserCategories ? (
              <>
                {fundraiserCategories?.map((category, index) => (
                  <div className="grid-item" key={index}>
                    <LazyBackgroundImage
                      key={index}
                      imageUrl={category?.image}
                      to={`/${category.slug}-funding/${category.slug}`}
                      className="what__we-offer helpingItem helpingItem--simple position-relative"
                      id="donate-to-a-cause"
                      category={category}
                    />
                  </div>
                ))}
              </>
            ) : (
              <NoResultFound />
            )}
          </div>
        </Container>
      </section>

      {/* Modal start */}
      <Modal centered size="lg" show={show} onHide={handleClose}>
        <ModalBody id={id} setShow={setShow} />
      </Modal>

      {/* start Why Building bridge */}
      <section className="section section--white whyBuildingBridges">
        <div className="text-center mb-5">
          <h2>Why Building Bridges?</h2>
        </div>
        <Row lg={2} md={2} sm={2} xs={2} className="g-4 mt-4 mx-0 text-center ">
          <div className="col-md-6 col-lg-3 col-12 mt-4">
            <div className="high-cost">
              <img
                className="image-style"
                src={whyBuildingBridge1}
                loading="lazy"
                alt="iconimage"
              />
            </div>
            <h4 className="text-headind text-center">Simple setup</h4>
            <p className="text-desc">Create & share fundraisers </p>
          </div>
          <div className="col-md-6 col-lg-3 col-12 mt-4">
            <div className="high-cost">
              <img
                className="image-style"
                src={whyBuildingBridge2}
                loading="lazy"
                alt="iconimage"
              />
            </div>
            <h4 className="text-headind text-center">Security</h4>
            <p className="text-desc">Secure payment methods</p>
          </div>
          <div className="col-md-6 col-lg-3 col-12 mt-4">
            <div className="high-cost">
              <img
                className="image-style"
                src={whyBuildingBridge3}
                loading="lazy"
                alt="iconimage"
              />
            </div>
            <h4 className="text-headind text-center">Customer Care </h4>
            <p className="text-desc">24/7 support</p>
          </div>
          <div className="col-md-6 col-lg-3 col-12 mt-4">
            <div className="high-cost">
              <img
                className="image-style"
                src={whyBuildingBridge4}
                loading="lazy"
                alt="iconimage"
              />
            </div>
            <h4 className="text-headind text-center">0% Platform fee</h4>
            <p className="text-desc">No hidden charges</p>
          </div>
        </Row>
      </section>
      {/* end Why Building bridge */}

      {/* start verification steps */}
      <section className="section section--gray">
        <Container>
          <div className="text-center mb-5">
            <div className="mb-5">
              <h2>3-Step Verification</h2>
            </div>
            <div className="verification-step-main">
              <div className=" step-card-design">
                <div className="stpe-badge">step 1</div>
                <div className="step-card-body my-3">
                  <div className=" mx-auto px-3 py-2 justify-content-center d-flex verification-step-card">
                    <div>
                      <div className="step-image">
                        <img src={step1} loading="lazy" alt="step1" />
                      </div>
                      <div className="verification-step-content">
                        <h5>Call Beneficiary</h5>
                        <span>We call the beneficiary to confirm the case</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="step-card-design">
                <div className="stpe-badge">step 2</div>
                <div className="step-card-body my-3">
                  <div className=" mx-auto px-3 py-2 justify-content-center d-flex verification-step-card">
                    <div>
                      <div className="step-image">
                        <img src={step2} loading="lazy" alt="step1" />
                      </div>
                      <div className="verification-step-content">
                        <h5>Request Documents</h5>
                        <span>
                          We request all details and relevant documents
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="step-card-design">
                <div className="stpe-badge">step 3</div>
                <div className="step-card-body my-3">
                  <div className=" mx-auto px-3 py-2 justify-content-center d-flex verification-step-card">
                    <div>
                      <div className="step-image">
                        <img src={step3} loading="lazy" alt="step1" />
                      </div>
                      <div className="verification-step-content">
                        <h5>Visit Beneficiary</h5>
                        <span>
                          We visit the beneficiary for physical verification
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <div
          className="background-image"
          style={{
            backgroundImage: `url(${stepsBackground}) `,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
            marginTop: "-220px",
          }}
        >
          <div style={{height: "350px"}}></div>
        </div>
      </section>

      {/* end verification steps */}

      {/* testimonial section start */}
      <section
        className="section  testimonialSection"
        style={{
          backgroundImage: `url(${testimonialBackground}) `,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
        }}
      >
        <Container>
          <div className="sectionHeading text-center mb-5 mt-5 mt-md-0">
            <h2 style={{color: "#fff"}}>Success Stories</h2>
          </div>
          {state?.successStoriesList &&
          state?.successStoriesList?.length > 0 ? (
            <div className="col-md-10 mx-auto shadow-lg">
              <OwlCarousel
                items={1}
                margin={30}
                loop
                autoplay={true}
                className="owl-theme fundRaisingSection"
              >
                <TestimonialCard />
              </OwlCarousel>
            </div>
          ) : (
            <NoResultFound />
          )}
        </Container>
      </section>
      {/* testimonial section end */}
      <Stepper tagModal={tagModal} setTagModal={setTagModal} />
      <Gallery />
      <ToastContainer />
    </>
  );
};

export default Home;
