import React, {useState} from "react";
import {Card, Modal, ProgressBar} from "react-bootstrap";
import giveImg from "../assets/img/give-2.jpg";
import BreadCrumb from "../components/BreadCrumb";
import CommentSection from "../components/CommentSection";
import ModalBody from "../components/ModalBody";
const DonationDetail = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  return (
    <>
      <BreadCrumb heading="DONATION DETAILS" badge="DONATION DETAIL" />
      <section className="section donation__category">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-8 order-2 order-lg-1 description__detail--donation">
              <div>
                <span className="data mx-3">October 19, 2022</span>
                <span>
                  {" "}
                  Project In: <span className="taggg ">Children</span>
                </span>
              </div>
              <h3 className="description__text">
                Help to raise valuable donations for Kids
              </h3>
              <img
                className="w-100 mb-3"
                src="https://alonethemes.com/wp-content/uploads/2017/06/give-12.jpg"
              />
              <p>
                Neque porro quisquam est qui dolorem ipsum quia dolor sit amet,
                consectetur, adipisci velit, Lorem Ipsum is simply dummy text of
                the printing and typesetting industry.
              </p>

              <p>
                It has survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing.
              </p>
              <p>
                It has survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing.
              </p>
              <p>
                It has survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing.
              </p>
              <p>
                It has survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing.
              </p>

              <div className="my-5">
                <Card className="blog__card mb-4">
                  <Card.Body className="blog__content">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="tags1">
                        Tags:
                        <span>Charity</span>
                        <span>Charity</span>
                        <span>Charity</span>
                        <span>Charity</span>
                      </div>
                      <div className="share">
                        Share:
                        <span>
                          <i className="fa fa-facebook"></i>
                        </span>
                        <span>
                          {" "}
                          <i className="fa fa-twitter"></i>
                        </span>
                        <span>
                          {" "}
                          <i className="fa fa-envelope"></i>
                        </span>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </div>
            <div className="col-12 col-lg-4 mb-4 ml-5 order-1 order-lg-2 paddingLeft__desktop--40 detail__page ">
              <Card className="blog__card mb-4">
                <Card.Body className="blog__content">
                  <p className="mb-0">Join Us</p>

                  <h3 className="projectItem__title">We Need Your Help</h3>
                  <div className="projectItem__progress mb-3">
                    <div className="raised">
                      <div className="d-flex align-items-end">
                        <span className="income">$899</span> of{" "}
                        <span className="raisedS">$899</span> raised
                      </div>
                    </div>
                    <ProgressBar now={(40 / 200) * 100} />
                  </div>
                  <button
                    onClick={() => setShow(true)}
                    className="btn__primary shadow buttonPrimary"
                    style={{fontSize: "12px"}}
                  >
                    Donate now
                  </button>
                </Card.Body>
              </Card>

              <Card className="blog__card recent_donation mb-4">
                <Card.Body className="blog__content">
                  <h5 className="mb-4">Recent Donations</h5>
                  {/* first */}
                  <div className="recent__transection mb-4">
                    <Card.Img
                      className="transection__image"
                      variant="top"
                      src={giveImg}
                    />
                    <div className="">
                      <h5 className="name mb-0">name</h5>
                      <div className="amount">${"amount"}</div>
                      <div className="date">'time'</div>
                    </div>
                  </div>
                  {/* 2nd */}
                  <div className="recent__transection mb-4">
                    <Card.Img
                      className="transection__image"
                      variant="top"
                      src={giveImg}
                    />
                    <div className="">
                      <h5 className="name mb-0">Sikandar sohail</h5>
                      <div className="amount">${"amount"}</div>
                      <div className="date">'time'</div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <Card className="blog__card mb-4">
                <Card.Body className="blog__content">
                  <h5 className="mb-4">Comments</h5>
                  {/* mapp comments here */}
                  <CommentSection />
                  <hr />
                  <CommentSection />
                  <hr />
                  <CommentSection />
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>

        <Modal
          centered
          className="donatenow__modal"
          show={show}
          onHide={handleClose}
        >
          <ModalBody setShow={setShow} />
        </Modal>
      </section>
    </>
  );
};

export default DonationDetail;
