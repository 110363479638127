import React from "react";
import {Card, Container} from "react-bootstrap";
import {Link, useLocation} from "react-router-dom";
import Context from "../Context/context";

const SinglePayment = () => {
  const {state} = useLocation();
  return (
    <>
      <section className="section--about mt-5">
        <section className="section section--white">
          <Container>
            <div className="text-center mb-5">
              <h3>PAYMENT DETAIL</h3>
            </div>

            <Card className="shadow border-0 mb-4">
              <Card.Body className="blog__content p-4">
                <div className="d-flex mb-3">
                  <div style={{flex: 2}}>
                    {" "}
                    <b>Fundraiser Title:</b>{" "}
                  </div>
                  <div style={{flex: 4, color: "#002866"}} className="mx-4">
                    {state?.fundraise_id?.title}
                  </div>
                </div>

                <div className="d-flex mb-3">
                  <div style={{flex: 2}}>
                    {" "}
                    <b>Fundraiser Description:</b>{" "}
                  </div>
                  <div style={{flex: 4}} className="mx-4">
                    {state?.fundraise_id?.description}
                  </div>
                </div>

                <div className="d-flex mb-3">
                  <div style={{flex: 2}}>
                    {" "}
                    <b>Status:</b>{" "}
                  </div>
                  <div style={{flex: 4}} className="mx-4">
                    {state?.fundraise_id?.status}
                  </div>
                </div>

                <div className="d-flex mb-3">
                  <div style={{flex: 2}}>
                    {" "}
                    <b>Urgent:</b>{" "}
                  </div>
                  <div style={{flex: 4}} className="mx-4">
                    {state?.fundraise_id?.urgent ? "Urgent" : "Normal"}
                  </div>
                </div>

                <div className="d-flex mb-3">
                  <div style={{flex: 2}}>
                    {" "}
                    <b>Payment Method:</b>{" "}
                  </div>
                  <div style={{flex: 4}} className="mx-4">
                    {state?.payment_method}
                  </div>
                </div>
                <div className="d-flex mb-3">
                  <div style={{flex: 2}}>
                    {" "}
                    <b>Payment Status:</b>{" "}
                  </div>
                  <div style={{flex: 4}} className="mx-4">
                    {state?.payment_status}
                  </div>
                </div>

                <div className="d-flex mb-3">
                  <div style={{flex: 2}}>
                    {" "}
                    <b>Amount:</b>{" "}
                  </div>
                  <div style={{flex: 4}} className="mx-4">
                    Rs. {state?.amount}
                  </div>
                </div>
              </Card.Body>
            </Card>
            <Link to={"/dashboard/donations"}>
              <div className="backArrow">
                <i class="fa fa-angle-left" aria-hidden="true"></i>
                Back
              </div>
            </Link>
          </Container>
        </section>
      </section>
    </>
  );
};

export default SinglePayment;
