// import { configureStore } from '@reduxjs/toolkit'
// import userReducer from './Features/user/authSlice'
// import storage from 'redux-persist/lib/storage';
// import { persistReducer, persistStore } from 'redux-persist';
// import thunk from 'redux-thunk';

// const persistConfig = {
//     key: 'root',
//     storage,
//   }
//   const persistedReducer = persistReducer(persistConfig, userReducer)

// export const store = configureStore({
//     reducer: persistedReducer,
//     middleware: [thunk]
// })

// export const persistor = persistStore(store)


// more reducer presist

import { combineReducers, configureStore } from '@reduxjs/toolkit'
import userReducer from './Features/user/authSlice'
import fundraiserReducer from './Features/fundraisers/fundraiserSlice'
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

const persistConfig = {
    key: 'root',
    storage,
  }

  const rootReducer = combineReducers({ 
    auth: userReducer,
    fundraiser: fundraiserReducer
  })
  const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: [thunk]
})

export const persistor = persistStore(store)