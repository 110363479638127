import React, {useState} from "react";
import {Table} from "antd";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getSupporterById,
  updateSupporterByIdDash,
  getUserTransaction,
} from "../../../Api-Calls/supporter";
import {sendReview} from "../../../Api-Calls/contactUs";
import Loader from "../../../components/loader";
import moment from "moment";
import {
  SwalErrorMessage,
  SwalMessage,
} from "../../../components/sweetAlertMessage";
import {useForm} from "react-hook-form";
import UserNameLength from "../../../components/UserNameLength";
import userPicture from "../../../assets/img/userDefaultImg.png";

const TransactionDatatable = (props) => {
  const {fundraiseDetails, slug, setFundraiseDetails, columns} = props;
  const [tagModal, setTagModal] = useState(false);
  const [transactionDetail, setTransactionDetail] = useState();
  const [loading, setLoading] = useState(false);
  const [reviewTagModal, setReviewTagModal] = useState(false);
  const [messageLength, setMessageLength] = useState(0);
  const [reviewDetails, setReviewDetails] = useState({});
  const [errorMessageLength, setErrorMessageLength] = useState("");
  const showTagModal = (e, detail) => {
    setTransactionDetail(detail);
    setTagModal(true);
  };

  const showReviewModal = (details) => {
    setReviewDetails(details);
    setReviewTagModal(true);
  };
  const {
    handleSubmit,
    control,
    register,
    reset,
    formState: {errors},
  } = useForm();
  const handleReviewChange = (e) => {
    let {name, value} = e.target;
    if (name === "review") {
      setMessageLength(value.length);
    }
  };
  const submitReview = async (data) => {
    try {
      if (messageLength > 500) {
        setErrorMessageLength("Message cannot exceed 500 characters");
      } else {
        setLoading(true);
        const res = await sendReview(reviewDetails?._id, data);
        if (res?.status == 200) {
          SwalMessage(res?.data?.status, res?.data?.message, "success");
          reset({
            review: "",
          });
          setLoading(false);
          setReviewTagModal(false);
          getSupporters();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleClose = () => {
    setTagModal(false);
    setReviewTagModal(false);
  };
  const getSupporters = () => {
    getUserTransaction(slug)
      .then((res) => {
        if (res?.status == 200) {
          setFundraiseDetails(res?.data?.result);
        }
      })
      .catch((error) => {
        console.log(error?.response?.data?.message);
      });
  };
  const ShowModalPreview = () => {
    var el = document.getElementById("full-screenVideo");
    if (el.requestFullscreen) {
      el.requestFullscreen();
    } else if (el.msRequestFullscreen) {
      el.msRequestFullscreen();
    } else if (el.mozRequestFullScreen) {
      el.mozRequestFullScreen();
    } else if (el.webkitRequestFullscreen) {
      el.webkitRequestFullscreen();
    }
  };

  const data = [];

  for (let i = 0; i < fundraiseDetails?.length; i++) {
    data.push({
      sr: fundraiseDetails[i]?.seqId,
      username: fundraiseDetails[i]?.personalInfo?.username,
      email: fundraiseDetails[i]?.personalInfo?.email,
      title: fundraiseDetails[i]?.fundraise_id?.title,
      payment_status: fundraiseDetails[i]?.payment_status,
      payment_method: fundraiseDetails[i]?.payment_method,
      date: moment(fundraiseDetails[i]?.updated).format("L"),
      totalAmount: <>Rs. {fundraiseDetails[i]?.amount}</>,

      action: (
        <>
          <Button
            onClick={(e) => showTagModal(e, fundraiseDetails[i])}
            className="action-button-view"
          >
            <i className="fa fa-eye"></i>
          </Button>
        </>
      ),
      thank_donor:
        fundraiseDetails[i]?.is_review == false ? (
          <button
            onClick={(e) => showReviewModal(fundraiseDetails[i])}
            className="fun-donate-btn1"
          >
            Say Thanks
          </button>
        ) : (
          "Done"
        ),
    });
  }

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table?.SELECTION_ALL,
      Table?.SELECTION_INVERT,
      Table?.SELECTION_NONE,
      {
        key: "odd",
        text: "Select Odd Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys?.filter((_, index) => {
            if (index % 2 !== 0) {
              return false;
            }

            return true;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: "even",
        text: "Select Even Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys?.filter((_, index) => {
            if (index % 2 !== 0) {
              return true;
            }

            return false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };
  const [paymentStatus, setPaymentStaus] = useState("");
  const handleChange = (e) => {
    let {name, value} = e.target;

    setPaymentStaus({
      ...paymentStatus,
      [name]: value,
    });
  };

  const handleUpdate = () => {
    setLoading(true);
    updateSupporterByIdDash(transactionDetail?._id, paymentStatus)
      .then((res) => {
        SwalMessage(res?.data?.status, res?.data?.message, "success");
        handleDonations();
        handleClose();
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch((error) => {
        SwalErrorMessage(
          "Oops!" + " " + error?.response?.data?.status,
          error?.response?.data?.message,
          "error",
          true
        );
      });
  };
  const handleDonations = () => {
    getSupporterById(slug)
      .then((res) => {
        if (res?.status == 200) {
          setFundraiseDetails(res?.data?.result);
        }
      })
      .catch((error) => {
        console.log(error?.response?.data?.message);
      });
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={data}
          />
          <Modal show={tagModal} onHide={handleClose}>
            <Modal.Header>
              <Modal.Title>Single Transaction Detail</Modal.Title>
              <button onClick={handleClose} className="border-0">
                X
              </button>
            </Modal.Header>
            <Modal.Body style={{color: "black", fontSize: "18px"}}>
              <div className="p-2">
                <div className="d-flex mb-2">
                  <div className="mb-1 flex1">
                    <strong>Username:</strong>
                  </div>
                  <div className="mx-2 flex2 text-capitalize">
                    <UserNameLength
                      username={transactionDetail?.personalInfo?.username}
                      length={30}
                    />
                  </div>
                </div>

                <div className="d-flex mb-2">
                  <div className="mb-1 flex1">
                    <strong>Email:</strong>
                  </div>
                  <div className="mx-2 flex2">
                    {transactionDetail?.personalInfo?.email}
                  </div>
                </div>
                <div className="d-flex mb-2">
                  <div className="mb-1 flex1">
                    <strong>Profile Image:</strong>
                  </div>
                  <div className="mx-2 flex2">
                    <img
                      src={
                        transactionDetail?.personalInfo?.picture != ""
                          ? transactionDetail?.personalInfo?.picture
                          : userPicture
                      }
                      style={{
                        height: "100px",
                        width: "100px",
                        objectFit: "cover",
                      }}
                      alt="profileImage"
                    />
                  </div>
                </div>

                <div className="d-flex mb-4">
                  <div className="mb-1 flex1">
                    <strong>Payment Method:</strong>
                  </div>
                  <div className="mx-2 flex2">
                    {transactionDetail?.payment_method}
                  </div>
                </div>
                <div className="d-flex mb-4">
                  <div className="mb-1 flex1">
                    <strong>Total Amount:</strong>
                  </div>
                  <div className="mx-2 flex2">
                    Rs. {transactionDetail?.amount}
                  </div>
                </div>
                <div className="d-flex mb-4">
                  <div className="mb-1 flex1">
                    <strong>Payment Status:</strong>
                  </div>
                  <div className="mx-2 flex2">
                    {transactionDetail?.payment_status}
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button className="fun-donate-btn1 mx-3" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={reviewTagModal} onHide={handleClose}>
            <Modal.Header>
              <Modal.Title>Thank Your Donor</Modal.Title>
              <button onClick={handleClose} className="border-0">
                X
              </button>
            </Modal.Header>
            <Modal.Body>
              <div className="p-2">
                <form
                  style={{textAlign: "left"}}
                  onSubmit={handleSubmit(submitReview)}
                >
                  <Row>
                    <Col className="mb-3" lg={9}>
                      <Form.Label className="mb-1">
                        <b>Review</b> <span className="text-danger">*</span>
                      </Form.Label>
                      <div className="mb-1">
                        <textarea
                          placeholder="Thank Your Donor"
                          className="form-control"
                          style={{
                            resize: "none",
                            height: "150px",
                          }}
                          {...register("review", {
                            required: "Field is required",
                            onChange: (e) => handleReviewChange(e),
                          })}
                        ></textarea>
                        <p className="text-danger mx-1 my-2">
                          {errors?.review?.message}
                        </p>
                        {messageLength > 500 && (
                          <p className="text-danger mx-1 my-2">
                            {errorMessageLength
                              ? errorMessageLength
                              : "Message cannot exceed 500 characters"}
                          </p>
                        )}
                        {messageLength > 500 ? (
                          <p className="text-danger mx-1 my-2">
                            Message limit {messageLength} to 500.
                          </p>
                        ) : (
                          <p className="text-dark mx-1 my-2">
                            Message limit {messageLength} to 500.
                          </p>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <div style={{borderTop: "1px solid #dee2e6"}}></div>
                  <div className="text-right my-4 ">
                    <span
                      className="fun-donate-btn1 mx-3 py-2"
                      onClick={handleClose}
                    >
                      Close
                    </span>
                    {loading ? (
                      <button className="btn__primary1 buttonPrimary1 py-2">
                        <i
                          className="fa mx-3 fa-circle-o-notch fa-spin"
                          style={{fontSize: "24px"}}
                        ></i>
                      </button>
                    ) : (
                      <button
                        className="btn__primary1 buttonPrimary1 py-2"
                        type="submit"
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </Modal.Body>
          </Modal>
        </>
      )}
      <ToastContainer />
    </>
  );
};

export default TransactionDatatable;
