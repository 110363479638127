/* eslint-disable jsx-a11y/alt-text */
import React, {useState} from "react";
import "antd/dist/antd";
import {Table} from "antd";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {useNavigate} from "react-router";
import Loader from "../../../components/loader";
import swal from "sweetalert";
import {
  deleteUserFundraiser,
  withdrawlFundraiserAmmount,
} from "../../../Api-Calls/fundraise";
import moment from "moment";
import {
  SwalErrorMessage,
  SwalMessage,
} from "../../../components/sweetAlertMessage";
import {useForm} from "react-hook-form";
import TextInput from "../../../components/TextInput";
import {useDispatch, useSelector} from "react-redux";
import {getUserFundraise} from "../../../Features/fundraisers/fundraiserSlice";
import docxImage from "../../../assets/img/staticIcons/docx.png";
import pdfImage from "../../../assets/img/staticIcons/pdf.png";

const FundraiseDatatable = (props) => {
  const {
    auth: {user},
  } = useSelector((store) => store);
  const dispatchRedux = useDispatch();
  const {fundraiser, setFundraiser, columns} = props;
  const [fundraiserDetails, setFundraiserDetails] = useState({});
  const [tagModal, setTagModal] = useState(false);
  const [withdrawTagModal, setWithdrawTagModal] = useState(false);
  const [loader, setLoader] = useState(false);

  const showTagModal = (details) => {
    setFundraiserDetails(details);
    setTagModal(true);
  };
  const showWithdrawModal = (details) => {
    setFundraiserDetails(details);
    setWithdrawTagModal(true);
  };
  const handleClose = () => {
    setWithdrawTagModal(false);
    setTagModal(false);
  };
  const navigate = useNavigate();
  const handleDeleteFundraiser = (id) => {
    try {
      swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this fundraiser.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          deleteUserFundraiser(id);
          let filteredData = fundraiser?.filter((item) => item?._id !== id);
          setFundraiser(filteredData);
          swal("Poof! Your selected fundraiser has been deleted!", {
            icon: "success",
          });
        }
      });
    } catch (error) {
      SwalErrorMessage(
        "Oops!" + " " + error?.response?.data?.status,
        error?.response?.data?.message,
        "error",
        true
      );
    }
  };
  const gotoTransaction = (slug, title) => {
    localStorage.setItem("fundraiseTitle", title);
    navigate(`/dashboard/user-transaction/${slug}`);
  };
  const handleEditFundraise = (slug) => {
    navigate(`/dashboard/edit-fundraise/${slug}`);
  };
  const handleFundraiserAmount = (slug) => {
    navigate(`/dashboard/?"/${slug}`);
  };
  const data = [];

  for (let i = 0; i < fundraiser?.length; i++) {
    data.push({
      sr: fundraiser[i]?.seqId,
      title: fundraiser[i]?.title,
      category: fundraiser[i]?.category[0]?.slug,
      description: fundraiser[i]?.description,
      collectedAmount: (
        <>
          {fundraiser[i]?.collectedAmount == 0 ? "" : "Rs. "}
          {fundraiser[i]?.collectedAmount}
        </>
      ),
      totalAmount: <>Rs. {fundraiser[i]?.totalAmount}</>,
      endDate: moment(fundraiser[i]?.endDate).format("L"),
      transaction: (
        <button
          onClick={(e) =>
            gotoTransaction(fundraiser[i]?.slug, fundraiser[i]?.title)
          }
          className="fun-donate-btn1 mx-3"
        >
          View
        </button>
      ),
      status: fundraiser[i]?.status,
      withdraw_status: fundraiser[i]?.withdraw_status,
      withdraw:
        fundraiser[i]?.status != "Complete" ? (
          <>
            {fundraiser[i]?.collectedAmount > 0 ? (
              <button
                onClick={(e) => showWithdrawModal(fundraiser[i])}
                className="fun-donate-btn1"
              >
                withdraw
              </button>
            ) : (
              <button disabled className="btn btn-outline-secondary">
                withdraw
              </button>
            )}
          </>
        ) : (
          <button disabled className="btn btn-outline-secondary">
            withdraw
          </button>
        ),
      action: (
        <>
          <Button
            onClick={(e) => showTagModal(fundraiser[i])}
            className="action-button-view"
          >
            <i className="fa fa-eye"></i>
          </Button>{" "}
          &nbsp;
          {fundraiser[i]?.status === "Pending" && (
            <Button
              onClick={(e) => handleEditFundraise(fundraiser[i]?.slug)}
              className="action-button-edit me-2"
            >
              <i className="fa fa-edit"></i>
            </Button>
          )}
          {fundraiser[i]?.status == "Pending" && (
            <Button
              onClick={(e) => handleDeleteFundraiser(fundraiser[i]?._id)}
              className="action-button-delete"
            >
              <i className="fa fa-trash"></i>
            </Button>
          )}
        </>
      ),
    });
  }

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table?.SELECTION_ALL,
      Table?.SELECTION_INVERT,
      Table?.SELECTION_NONE,
      {
        key: "odd",
        text: "Select Odd Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys?.filter((_, index) => {
            if (index % 2 !== 0) {
              return false;
            }

            return true;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: "even",
        text: "Select Even Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys?.filter((_, index) => {
            if (index % 2 !== 0) {
              return true;
            }

            return false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };
  const [paymentStatus, setPaymentStaus] = useState("");
  const [ibanLength, setIbanLength] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    let {name, value} = e.target;

    setPaymentStaus({
      ...paymentStatus,
      [name]: value,
    });
  };
  const handleWithdraw = (e) => {
    const value = e.target.value;
    setIbanLength(value.length);
  };
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    control,
    register,
    reset,
    formState: {errors},
  } = useForm();

  const submitWithdraw = async (data) => {
    try {
      if (ibanLength > 24) {
        setErrorMessage("Iban Number cannot exceed 24 digit");
      } else {
        setLoader(true);
        const res = await withdrawlFundraiserAmmount(
          data,
          fundraiserDetails?._id
        );
        if (res?.status === 200) {
          SwalMessage(res?.data?.status, res?.data?.message, "success");
          reset({
            username: "",
            email: "",
            iban: "",
            accountName: "",
            bankName: "",
          });
          setLoader(false);
          setWithdrawTagModal(false);
          dispatchRedux(getUserFundraise(user?._id)).then((res) => {
            if (res.type === "user/fundraisers/fulfilled") {
              setFundraiser(res?.payload?.result?.result);
            }
          });
        }
      }
    } catch (error) {
      SwalErrorMessage(
        "Oops!" + " " + error?.response?.data?.status,
        error?.response?.data?.message,
        "error",
        true
      );
    }
  };
  const ShowModalPreview = (event) => {
    var el = document.getElementById(event);
    if (el.requestFullscreen) {
      el.requestFullscreen();
    } else if (el.msRequestFullscreen) {
      el.msRequestFullscreen();
    } else if (el.mozRequestFullScreen) {
      el.mozRequestFullScreen();
    } else if (el.webkitRequestFullscreen) {
      el.webkitRequestFullscreen();
    }
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={data}
          />
          <Modal show={tagModal} onHide={handleClose}>
            <Modal.Header>
              <Modal.Title>Fundraiser Detail</Modal.Title>
              <button onClick={handleClose} className="border-0">
                X
              </button>
            </Modal.Header>
            <Modal.Body style={{color: "black", fontSize: "18px"}}>
              <div className="p-2">
                <div className="d-flex">
                  <div className="mb-1 flex1">
                    <strong>SeqID:</strong>
                  </div>
                  <div className="mx-2 flex2">{fundraiserDetails?.seqId}</div>
                </div>
                <div className="d-flex">
                  <div className="mb-1 flex1">
                    <strong>Title:</strong>
                  </div>
                  <div className="mx-2 flex2">{fundraiserDetails?.title}</div>
                </div>
                <div className="d-flex">
                  <div className="mb-1 flex1">
                    <strong>Description:</strong>
                  </div>
                  <div
                    className="mx-2 flex2 mb-3"
                    style={{maxHeight: "300px", overflowY: "auto"}}
                  >
                    {fundraiserDetails?.description}
                  </div>
                </div>
                {fundraiserDetails?.tag &&(
                <div className="d-flex">
                  <div className="mb-1 flex1">
                    <strong>Tag Name:</strong>
                  </div>
                  <div
                    className="mx-2 flex2 mb-3"
                  >
                    {fundraiserDetails?.tag}
                  </div>
                </div>
                )}
                <div className="d-flex">
                  <div className="mb-1 flex1">
                    <strong>Start Date:</strong>
                  </div>
                  <div className="mx-2 flex2">
                    {moment(fundraiserDetails?.startDate).format("L")}
                  </div>
                </div>
                <div className="d-flex">
                  <div className="mb-1 flex1">
                    <strong>End Date:</strong>
                  </div>
                  <div className="mx-2 flex2">
                    {moment(fundraiserDetails?.endDate).format("L")}
                  </div>
                </div>
                <div className="d-flex">
                  <div className="mb-1 flex1">
                    <strong>Collected Amount:</strong>
                  </div>
                  <div className="mx-2 flex2">
                    Rs. {fundraiserDetails?.collectedAmount}
                  </div>
                </div>

                <div className="d-flex">
                  <div className="mb-1 flex1">
                    <strong>Total Amount:</strong>
                  </div>
                  <div className="mx-2 flex2">
                    Rs. {fundraiserDetails?.totalAmount}
                  </div>
                </div>
                <div className="d-flex mb-3">
                  <div className="mb-1 flex1">
                    <strong>Fundraiser Image:</strong>
                  </div>
                  <div className="mx-2 d-flex flex2">
                    {fundraiserDetails?.imageUrl?.length > 0 &&
                      fundraiserDetails?.imageUrl?.map((item) => {
                        return (
                          <>
                            <div style={{position: "relative"}}>
                              <img
                                src={item?.url}
                                id="fundraiserImage"
                                onClick={(e) =>
                                  ShowModalPreview("fundraiserImage")
                                }
                                width="150px"
                                height="120px"
                                style={{
                                  objectFit: "cover",
                                  cursor: "pointer",
                                }}
                                className="mx-1 mt-3"
                                alt=""
                              />
                            </div>
                          </>
                        );
                      })}
                  </div>
                </div>
                <div className="d-flex mb-3">
                  <div className="mb-1 flex1">
                    <strong>Fundraiser Documents:</strong>
                  </div>
                  <div className="mx-2 d-flex flex2">
                    {fundraiserDetails?.documents?.length > 0 &&
                      fundraiserDetails?.documents?.map((item) => {
                        return (
                          <>
                            {item?.type === "image" ? (
                              <div style={{position: "relative"}}>
                                <img
                                  style={{borderRadius: "12px"}}
                                  className="mx-2"
                                  src={item.url}
                                  width="70px"
                                  height="70px"
                                  alt="documentImage"
                                />
                              </div>
                            ) : item?.type === "docx" ? (
                              <div style={{position: "relative"}}>
                                <img
                                  style={{borderRadius: "12px"}}
                                  className="mx-2"
                                  src={docxImage}
                                  width="70px"
                                  height="70px"
                                  alt="documentDocx"
                                />
                              </div>
                            ) : (
                              item?.type === "pdf" && (
                                <div style={{position: "relative"}}>
                                  <img
                                    style={{borderRadius: "12px"}}
                                    className="mx-2"
                                    src={pdfImage}
                                    width="70px"
                                    height="70px"
                                    alt="documentPdf"
                                  />
                                </div>
                              )
                            )}
                          </>
                        );
                      })}
                  </div>
                </div>
                <div className="d-flex">
                  <div className="mb-1 flex1">
                    <strong>Status:</strong>
                  </div>
                  <div className="mx-2 flex2">{fundraiserDetails?.status}</div>
                </div>
                {fundraiserDetails?.video?.length > 0 && (
                  <div className="d-flex">
                    <div className="mb-1 flex1">
                      <strong>Video:</strong>
                    </div>
                    <div className="mx-2 flex2">
                      <video
                        style={{
                          width: "300px",
                          border: "2px solid #000",
                          borderRadius: "8px",
                        }}
                        muted
                        controls
                        loading="lazy"
                      >
                        <source
                          src={fundraiserDetails?.video[0]?.url}
                          type="video/mp4"
                        />
                      </video>
                    </div>
                  </div>
                )}
                {(fundraiserDetails?.withdraw_status === "In progress" ||
                  fundraiserDetails?.withdraw_status === "Verified") && (
                  <div className="d-flex">
                    <div className="mb-1 flex1">
                      <strong>Withdraw Status:</strong>
                    </div>
                    <div className="mx-2 flex2">
                      {fundraiserDetails?.withdraw_status}
                    </div>
                  </div>
                )}
              </div>
              {fundraiserDetails?.picture && (
                <div className="d-flex">
                  <div className="mb-1 flex1">
                    <strong>Proof of Payment:</strong>
                  </div>
                  <div className="mx-2 d-flex flex2">
                    <div style={{position: "relative"}}>
                      <img
                        src={fundraiserDetails?.picture}
                        id="fundraiserPrrofofPaymentImage"
                        onClick={(e) =>
                          ShowModalPreview("fundraiserPrrofofPaymentImage")
                        }
                        width="150px"
                        height="120px"
                        style={{objectFit: "cover", cursor: "pointer"}}
                        className="mx-1 mt-3"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button className="fun-donate-btn1 mx-3" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={withdrawTagModal} onHide={handleClose}>
            <Modal.Header>
              <Modal.Title>Fundraiser Withdraw Amount</Modal.Title>
              <button onClick={handleClose} className="border-0">
                X
              </button>
            </Modal.Header>
            <Modal.Body>
              <div className="p-2">
                <Modal.Title>Bank Information</Modal.Title>
                <form
                  style={{textAlign: "left"}}
                  onSubmit={handleSubmit(submitWithdraw)}
                >
                  <Row>
                    <Col className="mb-3" lg={9}>
                      <Form.Label className="mb-1">
                        <b>WithDraw Amount</b>{" "}
                      </Form.Label>
                      <div className="mb-1">
                        <TextInput
                          disabled
                          name={"withdrawAmmount"}
                          className="form-control"
                          defaultValue={fundraiserDetails?.collectedAmount}
                          control={control}
                        />
                      </div>
                    </Col>
                    <Col className="mb-3" lg={9}>
                      <Form.Label className="mb-1">
                        <b>Iban No</b> <span className="text-danger">*</span>
                      </Form.Label>
                      <div className="mb-1">
                        <TextInput
                          className="form-control"
                          {...register("iban", {
                            required: "Iban Number is required",
                            onChange: (e) => handleWithdraw(e),
                          })}
                          placeholder="IBAN No"
                          control={control}
                        />
                        {ibanLength > 24 && (
                          <p className="text-danger mx-1 my-2">
                            {errorMessage
                              ? errorMessage
                              : "Iban Number cannot exceed 24 digit"}
                          </p>
                        )}
                      </div>
                    </Col>
                    <Col className="mb-3" lg={9}>
                      <Form.Label className="mb-1">
                        <b>Account Holder Name</b>{" "}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <div className="mb-1">
                        <TextInput
                          name={"accountName"}
                          className="form-control"
                          rules={{
                            required: "Account Holder Name is requried",
                          }}
                          placeholder="Account Holder Name"
                          control={control}
                        />
                      </div>
                    </Col>
                    <Col className="mb-3" lg={9}>
                      <Form.Label className="mb-1">
                        <b>Bank Name</b> <span className="text-danger">*</span>
                      </Form.Label>
                      <div className="mb-1">
                        <TextInput
                          name={"bankName"}
                          className="form-control"
                          rules={{
                            required: "BanK Name is requried",
                          }}
                          placeholder="Bank Name"
                          control={control}
                        />
                      </div>
                    </Col>
                  </Row>
                  <div style={{borderTop: "1px solid #dee2e6"}}></div>
                  <div className="text-right my-4 ">
                    <span
                      className="fun-donate-btn1 mx-3 py-2"
                      onClick={handleClose}
                    >
                      Close
                    </span>
                    {loader ? (
                      <button className="btn__primary1 buttonPrimary1 py-2">
                        <i
                          className="fa mx-3 fa-circle-o-notch fa-spin"
                          style={{fontSize: "24px"}}
                        ></i>
                      </button>
                    ) : (
                      <button
                        className="btn__primary1 buttonPrimary1 py-2"
                        type="submit"
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </Modal.Body>
          </Modal>
        </>
      )}

      <ToastContainer />
    </>
  );
};

export default FundraiseDatatable;
