import React, {useEffect, useRef, useState} from "react";
import {Container} from "react-bootstrap";

const BreadCrumb = (props) => {

  const sectionRef = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Load the image when it becomes visible
            sectionRef.current.style.backgroundImage = `url(${
              window.innerWidth <= 600 ? props?.mobileImage : props?.webImage
            })`;
            observer.unobserve(sectionRef.current);
          }
        });
      },
      {threshold: 0.5} // Adjust the threshold as needed
    );

    observer.observe(sectionRef.current);

    return () => {
      observer.disconnect();
    };
  }, [props?.webImage, props?.mobileImage]);

  return (
    <section
      className="section donation--bg"
      ref={sectionRef}
      style={{
        marginTop: "63px",
        minHeight: props?.ramadanBanner ? "700px" : "500px",
        // backgroundPosition: "top",
        // backgroundImage: `url(${props?.image})`,
        // backgroundImage: `url(${window?.innerWidth <= 600 ? props?.mobileImage : props?.webImage})`,
      }}
    >
      {props?.overlay === false ? (
        ""
      ) : (
        <div
          class="overlay"
          style={{ backgroundColor: props?.ramadanBanner ? "rgba(0, 40, 102, 0.4)" : "rgba(0, 40, 102, 0.7)"}}
        ></div>
      )}
      <Container className="h-100">
        <div className="text-center d-flex justify-content-center flex-column align-items-center h-100">
          <h2
            style={{
              fontSize:
                window?.innerWidth <= 600 && props.font ? "36px" : "48px",
              color: "#fff",
              opacity: "0.9",
            }}
          >
            {props.heading}
          </h2>
          <div>
            {props?.description && (
              <p
                style={{
                  fontSize:
                    window?.innerWidth <= 600 && props.font ? "18px" : "20px",
                  color: "#fff",
                  opacity: "0.9",
                  maxWidth: "100%",
                  margin: "auto",
                }}
              >
                {props?.description}
              </p>
            )}
          </div>
          {/* {props.heading1 && (
            <h2 style={{ fontSize: "30px", color: "#fff", opacity: "0.9" }}>
              {props.heading1}
            </h2>
          )} */}

          {/* <div className="donation__badge">
            <span>{props.badge}</span>
          </div> */}
        </div>
      </Container>
    </section>
  );
};

export default BreadCrumb;
