import React from "react";

const UserNameLength = ({displayName, username, length}) => {
  return (
    <>
      {!displayName
        ? username?.length > 30
          ? username?.slice(0, length) + "..."
          : username?.slice(0, length)
        : "Anonymous"}
    </>
  );
};

export default UserNameLength;
