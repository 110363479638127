import { constantIcons } from "../../../Constant";

export default function DonationAmount({ amount }) {
  const icons = constantIcons();

  return (
    <div className="card">
      <div className="card-body">
        <div className="d-flex align-items-center ">
          <img className="cardIcon" src={icons.Donors} alt="Donation" />
          <div className="ms-1">
            <h3 className="cardTitle">
              {amount ? amount.toLocaleString() + " " + "Rs" : "0 Rs"}
            </h3>
            <p className="widget-text">Donation Amount</p>
          </div>
        </div>
      </div>
    </div>
  );
}
