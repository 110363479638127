import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import {getUserTransaction} from "../../../Api-Calls/supporter";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SideMenu from "../SideMenu";
import Jumbotran from "../Jumbotran";
import TransactionDatatable from "./TransectionDatatable";
import DashBoardFooter from "../DashBoardFooter";
import HelmetSEO from "../../../components/HelmetSEO";

const Transaction = (props) => {
  const {slug} = useParams();
  const [fundraiseDetails, setFundraiseDetails] = useState([]);

  useEffect(() => {
    if (fundraiseDetails) handleDonations();
  }, []);
  const handleDonations = async () => {
    try {
      const res = await getUserTransaction(slug);
      if (res?.status == 200) {
        setFundraiseDetails(res?.data?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      title: "Sr.",
      dataIndex: "sr",
      key: "sr",
    },
    {
      title: "Username",
      dataIndex: "username",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Fundraise Name",
      dataIndex: "title",
    },
    {
      title: "Payment Status",
      dataIndex: "payment_status",
    },
    {
      title: "Payment Method",
      dataIndex: "payment_method",
    },
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Amount",
      dataIndex: "totalAmount",
    },
    {
      title: "Action",
      dataIndex: "action",
    },
    {
      title: "Thank Your Donor",
      dataIndex: "thank_donor",
    },
  ];

  return (
    <>
    <HelmetSEO title="Fundraiser Transactions" description="Fundraiser Transactions" />
    <div className="App">
      <SideMenu />
      <div className="main-panel">
        <div className="container">
          <Jumbotran page="All Transaction" desc="Transaction" />
          <div className="page-inner">
            <h1 style={{color: "#002866"}}>
              <strong>{localStorage.getItem("fundraiseTitle")}</strong>
            </h1>
            <TransactionDatatable
              columns={columns}
              fundraiseDetails={fundraiseDetails}
              setFundraiseDetails={setFundraiseDetails}
              slug={slug}
            />
          </div>
        </div>
        <DashBoardFooter />
      </div>
      <ToastContainer />
    </div>
    </>
  );
};

export default Transaction;
