import React, { useState, useEffect } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import BreadCrumb from "../components/BreadCrumb";
import banner from "../assets/img/contactBanner.png";
import { useForm } from "react-hook-form";
import TextInput from "../components/TextInput";
import { addContact } from "../Api-Calls/contactUs";
import { SwalMessage } from "../components/sweetAlertMessage";
import Select from "react-select";
import { countries } from "../data/country";
import InputMask from "react-input-mask";
import HelmetSEO from "../components/HelmetSEO";

const Support = () => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    setFocus,
    formState: { errors },
  } = useForm();
  const [loader, setLoader] = useState(false);
  const [maskNo, setMaskNo] = useState("");
  const [messageLength, setMessageLength] = useState("");
  const [errorMessageLength, setErrorMessageLength] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [countryState, setCountryState] = useState({
    country: "",
    countryCode: "",
  });
  useEffect(() => {
    if (window.innerWidth <= 600) {
      const fieldsWithErrors = Object.keys(errors).filter(
        (field) => !!errors[field]
      );
      if (fieldsWithErrors.length > 0) {
        setFocus(fieldsWithErrors[0]);
        document.documentElement.scrollTo({
          top: window.innerWidth <= 600 ? 1400 : 0,
          left: 0,
          behavior: "smooth",
        });
      }
    }
  }, [
    errors.fullName,
    errors.email,
    errors.phoneNo,
    errors.subject,
    errors.message,
    setFocus,
  ]);
  const handleChange = (e) => {
    const value = e.target.value;
    setMessageLength(value.length);
  };
  const onSubmit = async (data) => {
    try {
      if (window.innerWidth <= 600) {
        if (countryState.country === undefined || countryState.country === "") {
          document.documentElement.scrollTo({
            top: window.innerWidth <= 600 ? 1400 : 0,
            left: 0,
            behavior: "smooth",
          });
        }
      }
      if (messageLength > 500) {
        setErrorMessageLength("Message cannot exceed 500 characters");
      } else {
        let detail = {
          ...data,
          phoneNo: `${countryState.countryCode} ${data.phoneNo}`,
          country: countryState?.country.trim(),
        };
        setLoader(true);
        const res = await addContact(detail);
        if (res?.status == 201) {
          SwalMessage(res?.data?.status, res?.data?.message, "success");
          setSelectedCountry(null);
          reset({
            fullName: "",
            email: "",
            phoneNo: "",
            country: "",
            subject: "",
            message: "",
          });
          setLoader(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const onCountryChange = (e) => {
    setCountryState({ country: `${e.label} `, countryCode: `+${e.code}` });
    setMaskNo(e.mask);
  };
  const customStyles = {
    option: (base, state) => ({
      ...base,
      color: "#1e2022",
      backgroundColor: "white",
      padding: ".5rem 3rem .5rem .5rem",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "rgba(189,197,209,.3)", // Change this to your desired border color on hover
      },
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
    control: (baseStyles, stateData) => ({
      ...baseStyles,
      paddingLeft: "10px",
      paddingRight: "10px",
      paddingTop: "8px",
      paddingBottom: "8px",
      backgroundColor: "#fff",
    }),
  };
  return (
    <>
      <HelmetSEO title="Support" description="Support" />

      <BreadCrumb
        heading="Support"
        badge="CONTACT US"
        webImage={banner}
        mobileImage={banner}
        overlay={false}
      />

      <section className="pt-0 section section__form--contact">
        <Container className="section--gray section">
          <div>
            <Col xl="8" className="text-center mx-auto px-3 px-xl-0">
              <h2 className="mb-4">Send our Support Team a Direct Message</h2>
            </Col>
            <Col xl="8" className="mx-auto">
              <Form
                className="mt-5 container"
                onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col className="mb-3" lg={6}>
                    <Form.Label className="mb-1">
                      <b>Name</b> <span className="text-danger">*</span>
                    </Form.Label>
                    <div className="mb-1">
                      <TextInput
                        name={"fullName"}
                        className="form-control"
                        rules={{
                          required: "Name is required.",
                        }}
                        control={control}
                        placeholder="Name"
                      />
                    </div>
                  </Col>

                  <Col className="mb-3" lg={6}>
                    <Form.Label className="mb-1">
                      <b>Email Address</b>{" "}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <div className="mb-1">
                      <TextInput
                        name={"email"}
                        className="form-control"
                        rules={{
                          required: "Email is required.",
                          pattern: {
                            value:
                              /^\s*(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/,
                            message: "Invalid  email address.",
                          },
                        }}
                        control={control}
                        placeholder="Email Address"
                      />
                    </div>
                  </Col>
                  <Col className="mb-3" lg={6}>
                    <Form.Label className="mb-1">
                      <b>Country</b>
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <div className="mb-1">
                      <Select
                        onChange={(e) => onCountryChange(e)}
                        styles={customStyles}
                        value={selectedCountry}
                        className="form-contro"
                        options={countries}
                      />
                    </div>
                  </Col>
                  <Col className="mb-3" lg={2} md={3} sm={4}>
                    <Form.Label className="mb-1">
                      <b>Code</b>
                    </Form.Label>
                    <Form.Label className="mb-1"></Form.Label>
                    <div className="mb-1">
                      <input
                        readOnly
                        control={control}
                        autoComplete="off"
                        value={
                          countryState.countryCode !== "+undefined"
                            ? countryState.countryCode
                            : ""
                        }
                        name="PhoneCode"
                        placeholder="+92"
                        className="form-control dir-ltr customInputLogin"
                      />
                    </div>
                  </Col>
                  <Col className="mb-3" lg={4} md={9} sm={8}>
                    <Form.Label className="mb-1">
                      <b>Phone Number</b>
                    </Form.Label>
                    <div className="mb-1">
                      <InputMask
                        {...register("phoneNo", {
                          required: "PhoneNo is required",
                        })}
                        inputMode="numeric"
                        autoComplete="off"
                        mask={maskNo ? maskNo : "99999-99999"}
                        maskChar={null}
                        name="phoneNo"
                        placeholder="Phone Number"
                        className="form-control dir-ltr customInputLogin"
                      />
                      <p className="text-danger  my-2 ms-2 ">
                        {errors?.phoneNo?.message}
                      </p>
                    </div>
                  </Col>
                  <Col className="mb-3" lg={6}>
                    <Form.Label className="mb-1">
                      <b>Subject</b> <span className="text-danger">*</span>
                    </Form.Label>
                    <div className="mb-1">
                      <TextInput
                        name={"subject"}
                        className="form-control"
                        rules={{
                          required: "Subject is required.",
                        }}
                        control={control}
                        placeholder="Type Your Subject"
                      />
                    </div>
                  </Col>
                  <Col className="mb-3" col={12}>
                    <Form.Label className="mb-1">
                      <b>Message</b> <span className="text-danger">*</span>
                    </Form.Label>
                    <textarea
                      placeholder="Type Your Message"
                      className="form-control"
                      style={{
                        resize: "none",
                        height: "150px",
                      }}
                      {...register("message", {
                        required: "Message is required",
                        onChange: (e) => handleChange(e),
                      })}></textarea>
                    <div className="text-danger mx-1 my-2">
                      {errors?.message?.message}
                    </div>
                    {messageLength > 500 && (
                      <p className="text-danger mx-1 my-2">
                        {errorMessageLength
                          ? errorMessageLength
                          : "Message cannot exceed 500 characters"}
                      </p>
                    )}
                    {messageLength > 500 ? (
                      <p className="text-danger mx-1 my-2">
                        Message limit {messageLength} to 500.
                      </p>
                    ) : (
                      <p className="text-dark mx-1 my-2">
                        Message limit {messageLength} to 500.
                      </p>
                    )}
                  </Col>
                  <div className="text-center mt-4">
                    {loader ? (
                      <button className="btn__primary mx-auto shadow buttonPrimary">
                        <i
                          className="fa mx-3 fa-circle-o-notch fa-spin"
                          style={{ fontSize: "24px" }}></i>
                      </button>
                    ) : (
                      <button className="btn__primary mx-auto shadow buttonPrimary">
                        Support
                      </button>
                    )}
                  </div>
                </Row>
              </Form>
            </Col>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Support;
