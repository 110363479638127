import {ToastContainer} from "react-bootstrap";
import SideMenu from "../SideMenu";
import Jumbotran from "../Jumbotran";
import FundraiseDatatable from "./FundraiseDatatable";
import {useState, useEffect} from "react";
import DashBoardFooter from "../DashBoardFooter";
import {useDispatch, useSelector} from "react-redux";
import {getUserFundraise} from "../../../Features/fundraisers/fundraiserSlice";
import HelmetSEO from "../../../components/HelmetSEO";

export default function UserFundRaisers() {
  const {
    auth: {user},
  } = useSelector((store) => store);
  const dispatchRedux = useDispatch();

  const [fundraiser, setFundraiser] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const hasWithdrawStatus = fundraiser?.some(
    (record) => record?.withdraw_status
  );
  useEffect(() => {
    dispatchRedux(getUserFundraise(user?._id)).then((res) => {
      if (res.type === "user/fundraisers/fulfilled") {
        setFundraiser(res?.payload?.result?.result);
        setFilteredList(res?.payload?.result?.result);
      }
    });
  }, []);
  const columns = [
    {
      title: "Sr.",
      dataIndex: "sr",
      key: "sr",
    },
    {
      title: "Title",
      dataIndex: "title",
    },
    {
      title: "Category",
      dataIndex: "category",
    },
    {
      title: "Description",
      dataIndex: "description",
    },

    {
      title: "Collected Amount",
      dataIndex: "collectedAmount",
    },
    {
      title: "Total Amount",
      dataIndex: "totalAmount",
    },
    {
      title: "End Date",
      dataIndex: "endDate",
    },
    {
      title: "Transactions",
      dataIndex: "transaction",
    },
    {
      title: "Withdraw Status",
      dataIndex: "withdraw_status",
      ...(hasWithdrawStatus
        ? {
            render: (text, record) => {
              return text; // Render the "withdraw_status" value if it exists
            },
          }
        : {
            // If "withdraw_status" doesn't exist in any record, hide the column
            className: "hidden-column",
            width: 0,
          }),
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Withdraw",
      dataIndex: "withdraw",
    },
    {
      title: "Action",
      dataIndex: "action",
    },
  ];
  const handleFilter = (e) => {
    const value = e.target.value;
    if (value !== "-1") {
      const filteredData = fundraiser?.filter((item) => {
        return value === "-1" || item.status === value;
      });
      setFilteredList(filteredData);
    } else {
      setFilteredList(fundraiser);
    }
  };

  const handleResetFilters = () => {
    setFilteredList(fundraiser);
  };

  const handleSearch = async (e) => {
    let value = e.target.value.toLowerCase();
    if (value.length >= 3) {
      const filteredData = fundraiser?.filter((item) => {
        return (
          item.category[0].slug.includes(value) ||
          item.seqId.includes(value.toUpperCase())
        );
      });
      setFilteredList(filteredData);
    } else {
      // If the input has less than 3 characters, reset to the original data
      setFilteredList(fundraiser);
    }
  };
  return (
    <>
      <HelmetSEO title="Fundraisers" description="Fundraisers" />
      <div>
        <div className="App">
          <div className="">
            <SideMenu />
            <div className="main-panel">
              <div className="container">
                <Jumbotran page="Cases" desc="Cases" />
                <div className="page-inner">
                  <div className="row">
                    {filteredList?.length>0 && (
                      <div className="ml-3 mb-3 d-flex w-100 justify-content-between align-items-center">
                        <div class="input-group w-25">
                          <input
                            style={{padding: "10px 20px"}}
                            type="search"
                            class="form-control"
                            placeholder="Search with Category"
                            onChange={(e) => handleSearch(e)}
                            aria-label="Search Something..."
                            aria-describedby="basic-addon2"
                          />
                          <span
                            class="input-group-text border-0"
                            id="search-addon"
                          >
                            <i class="fa fa-search"></i>
                          </span>
                        </div>

                        <div className="mr-3 d-flex">
                          <button
                            className="action-button-view  mr-2"
                            onClick={handleResetFilters}
                          >
                            reset
                          </button>
                          <select
                            className="form-control "
                            style={{width: "250px", padding: "10px 20px"}}
                            onChange={(e) => handleFilter(e)}
                          >
                            <option value="-1">Select Status</option>
                            <option value="Active">Active Fundraisers</option>
                            <option value="Pending">Pending Fundraisers</option>
                            <option value="Complete">
                              Complete Fundraisers
                            </option>
                          </select>
                        </div>
                      </div>
                    )}
                    <div className="col-md-12">
                      <FundraiseDatatable
                        columns={columns}
                        fundraiser={filteredList}
                        setFundraiser={setFundraiser}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <DashBoardFooter />
            </div>
            <ToastContainer />
          </div>
        </div>
      </div>
    </>
  );
}
