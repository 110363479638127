import React from "react";
import {Container} from "react-bootstrap";
import {Link} from "react-router-dom";
import HelmetSEO from "../components/HelmetSEO";

const BetaVersion = () => {
  return (
    <>
      <HelmetSEO
        title="Beta Verison"
        description="Beta Verison"
      />
      <section className="section--about mt-5">
        <section className="section section--white">
          <Container>
            <h2 className="mb-3">Beta Version:</h2>
            <p className="text-black">
              Building Bridges is an online fundraising platform that strives to
              bridge the gap between those in need and those who can make a
              difference. Beta version of this platform has the primary
              objective of seeking user feedback to enhance and refine Building
              Bridges. By engaging users in the beta testing phase, developers
              can gather valuable insights and make various resolutions in
              relation to the collected feedback. This collaborative approach
              will contribute to enhancing the quality of the product.
            </p>
          </Container>
        </section>

        <section className="section section--gray">
          <Container>
            <h2 className="mb-3">Secure Payment Gateways:</h2>
            <p className="text-black">
              Paymob on Building Bridges accepts a wide range of payment
              methods, including credit cards, debit cards, and secure online
              payment options, ensuring convenience for users worldwide.
            </p>
            <p className="text-black">
              Paymob employs advanced encryption and security protocols to
              safeguard your personal and financial data during the donation
              process. Your information is kept confidential and secure on the
              Building Bridges Platform as well. For more details, please read
              our{" "}
              <Link style={{color: "#002866"}} to={"/privacy-policy"}>
                <b>Privacy Policy.</b>
              </Link>{" "}
            </p>
            <p className="text-black">
              Donations made through Paymob are processed between 2-3 business
              days, ensuring that your support reaches the intended campaigns in
              a timely manner.
            </p>
            <h2 className="mb-3">Use of Your Personal Data:</h2>
            <p className="text-black">
              Building Bridges may use Personal Data for the following purposes:
            </p>
            <ul className="text-black">
              <li>
                To provide and maintain our service, including to monitor the
                usage of our service.
              </li>
              <li>
                To manage your Account, and to manage your registration as a
                user of the service. The Personal Data you provide can give you
                access to different functionalities of the service that are
                available to you as a registered user.
              </li>
              <li>
                For the performance of a contract, the development, compliance
                and undertaking of the purchase contract for the products, items
                or services you have purchased or of any other contract with us
                through the service.
              </li>
              <li>
                To contact you, by email, telephone calls, SMS, or other
                equivalent forms of electronic communication, such as a mobile
                application's push notifications regarding updates or
                informative communications related to the functionalities,
                products or contracted services, including the security updates,
                when necessary or reasonable for their implementation.
              </li>
              <li>
                To provide you with news, special offers and general information
                about other goods, services and events which we offer that are
                similar to those that you have already purchased or enquired
                about unless you have opted not to receive such information.
              </li>
              <li>
                To manage your requests, to attend, and manage your requests to
                us.
              </li>
              <li>
                For business transfers, we may use your information to evaluate
                or conduct a merger, divestiture, restructuring, reorganization,
                dissolution, or other sale or transfer of some or all of our
                assets, whether as a going concern or as part of bankruptcy,
                liquidation, or similar proceeding, in which Personal Data held
                by us about our service users is among the assets transferred.
              </li>
              <li>
                For other purposes, we may use your information for other
                purposes, such as data analysis, identifying usage trends,
                determining the effectiveness of our promotional campaigns and
                to evaluate and improve our service, products, services,
                marketing and your experience.
              </li>
            </ul>
            <p className="text-black">
              We may share your personal information in the following
              situations:
            </p>
            <ul className="text-black">
              <li>
                With service providers, we may share your personal information
                to monitor and analyze the use of our service and to contact
                you.
              </li>
              <li>
                For business transfers, we may share or transfer your personal
                information in connection with, or during negotiations of, any
                merger, sale of Company assets, financing, or acquisition of all
                or a portion of our business to another company.
              </li>
              <li>
                With business partners, we may share your information to offer
                you certain products, services or promotions.
              </li>
              <li>
                With other users: when you share personal information or
                otherwise interact in the public areas with other users, such
                information may be viewed by all users and may be publicly
                distributed outside.
              </li>
              <li>
                With your consent: we may disclose your personal information for
                any other purpose with your consent.
              </li>
            </ul>
          </Container>
        </section>

        <section className="section section--white text-black">
          <Container>
            <h2 className="mb-3">Retention of Your Personal Data:</h2>
            <p>
              Building Bridges will retain Your personal data only for as long
              as is necessary for the purposes set out in this Privacy Policy.
              we will retain and use your personal data to the extent necessary
              to comply with our legal obligations (for example, if we are
              required to retain your data to comply with applicable laws),
              resolve disputes, and enforce our legal agreements and policies.
            </p>
            <p>
              Building Bridges will also retain usage data for internal analysis
              purposes. Usage Data is generally retained for a shorter period of
              time, except when this data is used to strengthen the security or
              to improve the functionality of our service, or we are legally
              obligated to retain this data for longer time periods.
            </p>
          </Container>
        </section>

        <section className="section section--gray text-black">
          <Container>
            <h2 className="mb-3">Transfer of Your Personal Data:</h2>
            <p>
              Your information, including personal data, is processed at
              Building Bridges's operating offices and in any other places where
              the parties involved in the processing are located. It means that
              this information may be transferred to — and maintained on —
              computers located outside of your State, Province, Country or
              other Governmental Jurisdiction where the data protection laws may
              differ than those from your Jurisdiction.
            </p>
            <p>
              Your consent to this Privacy Policy followed by your submission of
              such information represents your agreement to that transfer.
            </p>
            <p>
              Building Bridges will take all steps reasonably necessary to
              ensure that your data is treated securely and in accordance with
              this Privacy Policy and no transfer of your personal data will
              take place to an organization or a country unless there are
              adequate controls in place including the security of your data and
              other personal information.
            </p>
          </Container>
        </section>

        <section className="section section--white text-black">
          <Container>
            <h2 className="mb-3">Delete Your Personal Data:</h2>
            <p>
              You have the right to delete or request that we assist in deleting
              the personal data that we have collected about you.
            </p>
            <p>
              Our service may give you the ability to delete certain information
              about you from within the service.
            </p>
            <p>
              You may update, amend, or delete your information at any time by
              signing in to your account, if you have one, and visiting the
              account settings section that allows you to manage your personal
              information. You may also contact us to request access to,
              correct, or delete any personal information that you have provided
              to us.
            </p>
            <p>
              Please note, however, that we may need to retain certain
              information when we have a legal obligation or lawful basis to do
              so.
            </p>
          </Container>
        </section>

        <section className="section section--gray text-black">
          <Container>
            <h2 className="mb-3">Disclosure of Your Personal Data:</h2>
            <h4 className="mb-3">Business Transactions:</h4>
            <p>
              If Building Bridges is involved in a merger, acquisition or asset
              sale, your Personal Data may be transferred. we will provide
              notice before your Personal Data is transferred and becomes
              subject to a different Privacy Policy.
            </p>
            <h4 className="mb-3">Law Enforcement:</h4>
            <p>
              Under certain circumstances, Building Bridges may be required to
              disclose your Personal Data if required to do so by law or in
              response to valid requests by public authorities (e.g. a court or
              a government agency).
            </p>
            <h4 className="mb-3">Other Legal Requirements:</h4>
            <p>
              Building Bridges may disclose your personal data in the good faith
              belief that such action is necessary to:
            </p>
            <ul className="text-black">
              <li>Comply with a legal obligation.</li>
              <li>
                Protect and defend the rights or property of Building Bridges.
              </li>
              <li>
                Prevent or investigate possible wrongdoing in connection with
                the service.
              </li>
              <li>
                Protect the personal safety of users of the service or the
                public.
              </li>
              <li>Protect against legal liability.</li>
            </ul>
          </Container>
        </section>
        <section className="section section--white text-black">
          <Container>
            <h2 className="my-3">Security of Your Personal Data:</h2>
            <p>
              The security of your personal data is important to us, but
              remember that no method of transmission over the Internet, or
              method of electronic storage is 100% secure. While we strive to
              use commercially acceptable means to protect your personal data,
              we cannot guarantee its absolute security.
            </p>
          </Container>
        </section>
        <section className="section section--gray text-black">
          <Container>
            <h2 className="mb-3">Children's Privacy:</h2>
            <p>
              our service does not address anyone under the age of 13. we do not
              knowingly collect personally identifiable information from anyone
              under the age of 13. If you are a parent or guardian and you are
              aware that your child has provided us with personal data, please
              contact us. If we become aware that we have collected personal
              data from anyone under the age of 13 without verification of
              parental consent, we take steps to remove that information from
              our servers.
            </p>
            <p>
              If we need to rely on consent as a legal basis for processing you
              information and your country requires consent from a parent, we
              may require your parent's consent before we collect and use that
              information.
            </p>
          </Container>
        </section>

        <section className="section section--white text-black">
          <Container>
            <h2 className="mb-3">Links to Other Websites:</h2>
            <p>
              Our service may contain links to other websites that are not
              operated by us. If you click on a third party link, you will be
              directed to that third party's site. We strongly advise you to
              review the Privacy Policy of every site you visit.
            </p>
            <p>
              We have no control over and assume no responsibility for the
              content, Privacy Policies or practices of any third party sites or
              services.
            </p>
          </Container>
        </section>

        <section className="section section--gray text-black">
          <Container>
            <h2 className="mb-3">Contact Us:</h2>
            <p>
              If you have any questions about the beta version, you can contact
              us:
            </p>
            <ul>
              <li>
                By email:{" "}
                <a
                  style={{color: "#002866"}}
                  href="https://mail.google.com/mail/u/0/?view=cm&fs=1&to=info@buildingbridgesforislam.com"
                  target="_blank"
                >
                  <b>buildingbridgeinfo@gmail.com</b>
                </a>
              </li>
              <li>
                By visiting this page on our website:{" "}
                <Link style={{color: "#002866"}} to={"/contact-us"}>
                  <b>contact Us</b>
                </Link>{" "}
              </li>
            </ul>
          </Container>
        </section>
      </section>
    </>
  );
};

export default BetaVersion;
