import React, {useEffect, useState} from "react";
import {Container} from "react-bootstrap";
import {useParams} from "react-router-dom";
import "../../assets/css/MedicalFunding.css";
import OwlCarousel from "react-owl-carousel";
import TestimonialCard from "../../components/TestimonialCard";
import Accordion from "react-bootstrap/Accordion";
import testimonialBackground from "../../assets/img/Testimonials.jpg";
import {getFundraiseByCategorySlug} from "../../Api-Calls/categoryApi";
import {constantIcons, constantImages} from "../../Constant";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FundingForm from "../../components/FundingForm";
import FundraiserBreadCrumb from "../../components/FundraiserBreadCrumb";
import WhatBuildingBridgesOffer from "../../components/staticComponents/WhatBuildingBridgesOffer";
import ActiveFundraiser from "../../components/ActiveFundraiser";
import webBanner from "../../assets/img/staticImages/sports-crowdfunding-images/sport-banner.png";
import mobileBanner from "../../assets/img/staticImages/sports-crowdfunding-images/sport-banner-mobile.png";
import mobileImage from "../../assets/img/staticImages/sports-crowdfunding-images/mobile-image.png";
import HelmetSEO from "../../components/HelmetSEO";
import WhyChooseBuildingBridges from "../../components/WhyChooseBuildingBridges";
import SupportFundraiserFaqQuestion from "../../components/SupportFundraiserFaqQuestion";

const SportsFunding = () => {
  const {slug} = useParams();
  const images = constantImages();
  const icons = constantIcons();
  const [fundraiser, setFundraiser] = useState([]);
  const getFundraisebyCategotyId = async () => {
    try {
      const res = await getFundraiseByCategorySlug(slug);
      if (res?.status == 200) {
        setFundraiser(res?.data?.result?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getFundraisebyCategotyId();
  }, []);

  return (
    <>
      <HelmetSEO title="Sports Funding" description="Sports Funding" />

      <ToastContainer />
      <FundraiserBreadCrumb
        heading="Sports Funding"
        description={
          <p>
            Building Bridges enables users to create fundraisers and empower
            athletes through your generous support. Fund sports initiatives for
            aspiring athletes, providing access to equipment, facilities,
            training, and opportunities to showcase their skills.
          </p>
        }
        webImage={images.sportsSection1}
        mobileImage={mobileImage}
      />
      <ActiveFundraiser fundraiser={fundraiser} slug={slug} />
      <WhyChooseBuildingBridges
        mainHeading="Sports"
        icon1={icons.sportsChooseIcon1}
        icon2={icons.sportsChooseIcon2}
        icon3={icons.sportsChooseIcon3}
        icon4={icons.sportsChooseIcon4}
        heading1="Support for Sports Equipment"
        heading2=" Empowering Financial Aid Seekers"
        heading3="Equipment, Maintenance & Training"
        heading4="Building a Supportive Community"
        description1="Building Bridges supports struggling athletes by creating fundraisers for sports initiatives, equipment, facilities, and training opportunities to enable them to showcase their skills."
        description2="Building Bridges empowers the financial aid seekers by providing them with the option of hassle free online fundraising. The collected amount can be withdrawn anytime."
        description3="Building Bridges enables fundraising for sports equipment, access to facilities and affordable training, so struggling athletes may be able to pave their path towards success."
        description4=" Building Bridges aims to create a supportive community that provides struggling athletes with opportunities to acquire training and showcase their talent locally and globally."
      />

      <section className="">
        <FundingForm
          title="Building Bridges for Sports Funding "
          paragraph={`
            Building Bridges allows you to create fundraisers for sports in three easy steps. 
            This process allows donors to connect with aspiring and struggling athletes, 
            providing them opportunities to access equipment, facilities and training. 
            `}
          mobileBanner={mobileBanner}
          webBanner={webBanner}
        />
      </section>

      <WhatBuildingBridgesOffer />

      <section
        className=" testimonialSection py-5 section--gray"
        style={{
          backgroundImage: `url(${testimonialBackground}) `,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
        }}
      >
        <div className="sectionHeading text-center mb-5">
          <h2 className="mb-4 high-title title-background mt-5">
            Success Stories
          </h2>
          <div className="col-md-7 mx-auto divider"></div>
        </div>
        <div>
          <div className="sectionHeading text-center mb-5"></div>
          <div className="container">
            <div className="col-md-9 mx-auto ">
              <OwlCarousel
                items={1}
                margin={30}
                autoplay={true}
                className="owl-theme fundRaisingSection testimonial-background"
              >
                <TestimonialCard image="false" />
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
      <SupportFundraiserFaqQuestion
        heading1=" What are Sports funding projects and who do these projects benefit?"
        heading2="Can I choose the specific sports project to support?"
        heading3="How do I know my donation is making an impact in sports development?"
        heading4="Are there projects focused on promoting inclusivity and diversity in sports?"
        heading5="How can sports-related businesses or organizations get involved?"
        heading6="How can I get involved beyond donating?"
        description1="Sports funding on Building Bridges is a dedicated initiative
        that enables the donors to connect with aspiring and
        struggling athletes, providing them with the opportunities
        to access equipment, facilities and training. Sports funding
        on Building Bridges allows users to create fundraisers
        supporting a variety of sports-related initiatives,
        including youth sports programs, athlete development, sports
        equipment provision, and the promotion of physical activity.
        Donors can contribute to trending sports fundraisers on
        Building Bridges, and play an active role in creating
        positive impacts in communities through sports
        participation, education, and development."
        description2="Yes, Building Bridges allows the donors and supporters to
        browse and choose from a variety of fundraisers that have
        been created by various individuals. As a donor you have the
        flexibility to choose from a selection of sports projects
        that align with your interests and preferences. You can
        select a project that focuses on a particular sport or
        region."
        description3="Building Bridges provides regular updates on the progress
        and impact of the sports projects through success stories,
        testimonials, blogs, newsletters, emails to the donors,
        project reports, and updates on the website and all official
        social media platforms. This way the donors can stay
        informed about how their contributions are making a
        difference."
        description4="Yes, Building Bridges features sports projects that
        prioritize inclusivity, diversity, and equal access to
        sports opportunities."
        description5="Building Bridges also welcomes sports-related businesses,
        organizations and corporate partnerships to support and
        sponsor sports projects on the platform, allowing donors to
        connect more directly with the cause. Business may donate to
        the fundraisers or directly collaborate with the users to
        make a positive impact on the struggling athletes and the
        various sports communities."
        description6="Apart form donating, you can always create a fundraiser for
        supporting any cause that is either close to your heart or
        has been recommended to you by someone you know. You can
        become a part of our fundraising campaigns by sharing the
        fundraisers on your personal social media accounts and
        encourage your friends and loved ones to do the same.
        Volunteering to spread awareness and encourage potential
        donors to support our platform and give special attention to
        the Sports Funding charity page is also an option. Building
        Bridges offers you the opportunity to create a fundraiser in
        the loving memory of your deceased loved one as well."
      />
    </>
  );
};

export default SportsFunding;
