import React, {useContext} from "react";
import Context from "../Context/context";
import {Link} from "react-router-dom";
import {Card} from "react-bootstrap";
import HTMLRenderer from "react-html-renderer";

const TestimonialCard = (props) => {
  const {image} = props;
  const [state] = useContext(Context);
  return (
    <>
      {state?.successStoriesList &&
        state?.successStoriesList?.length > 0 &&
        state?.successStoriesList?.map((story) => {
          return (
            <Link to={`/success-stories/${story?.slug}`}>
              <div className="card mb-0 ">
                <div className="row g-0">
                  <div className="col-md-4 ">
                    {/* {story.imageUrl[0]?.type == "image" ? ( */}
                      <Card.Img
                        style={{padding: "15px"}}
                        variant="top"
                        className={"fundRaisingImgBackground2"}
                        src={story.imageUrl[0]?.url}
                        loading="lazy"
                      />
                    {/* ) : (
                      <div className="position-relative overflow-hidden">
                        <div className="playIcon">
                          <i className="fa fa-play "></i>
                        </div>
                        <video
                          className={"fundRaisingImgBackground2"}
                          controls={false}
                          autoPlay={false}
                        >
                          <source
                            src={story.attachments[0]?.url}
                            type="video/mp4"
                          />
                        </video>
                      </div>
                    )} */}
                  </div>

                  <div className="col-md-8">
                    <div className="card-body p-5">
                      <h5
                        className="card-title"
                        style={{
                          fontSize: "24px",
                          fontWeight: "700",
                        }}
                      >
                        {story.title}
                      </h5>
                      <div className="card-text line3">
                        <HTMLRenderer
                          html={
                            story?.description?.length > 500
                              ? story?.description?.slice(0, 500) + "..."
                              : story?.description
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          );
        })}
    </>
  );
};

export default TestimonialCard;
