import React from "react";
import {constantImages} from "../Constant";

const TestimonialCardNew = () => {
  const images = constantImages();
  const testimonialsArray = [
    {
      id: 1,
      title: "Giving Charity more Efficiently",
      message: `"Building Bridges holds the potential of being a platform that may truly transform the nature of giving charity more efficiently. Their dedication to bringing people together to foster understanding is commendable. I am proud to support their mission of helping the helpless."`,
      image: images.testimonialsImage1,
    },
    {
      id: 2,
      title: "The Transformative power of Building Bridges",
      message: `"I have witnessed firsthand the transformative power of platforms like Building Bridges. The way they have connected those who can give to the ones who need assistance in our community is really praiseworthy. Keep up the great work!"`,
      image: images.testimonialsImage2,
    },
    {
      id: 3,
      title: "Bridging Divides Between Communities",
      message: `"As a user who has created multiple fundraisers with Building Bridges, I have had the privilege of touching many lives. This platform  is a shining example of what can be achieved when we bridge divides between the underprivileged communities and those who want to assist them."`,
      image: images.testimonialsImage3,
    },
    {
      id: 4,
      title: "Beacon of Hope in a Fractured World",
      message: `"Building Bridges is a beacon of hope in our fractured world. Their tireless efforts to promote a global collaboration inspires us all to strive for a better, stronger and self-sufficient society."`,
      image: images.testimonialsImage4,
    },
    {
      id: 5,
      title: "Making the World a Better Place",
      message: `"As a donor I have seen the tangible results of the  fundraisers on Building Bridges. This platform is making the world a better place one bridge at a time."`,
      image: images.testimonialsImage5,
    },
    {
      id: 6,
      title: "Catalyst for Change",
      message: `"Build Bridges Charity is a catalyst for change. Their initiatives promote diversity, equity, and inclusion, by breaking down barriers and promoting unity to create a brighter future for all."`,
      image: images.testimonialsImage6,
    },
    {
      id: 7,
      title: "Dismantling Barriers",
      message: `"I am proud to be a part of the Build Bridges Charity family. Their commitment to fostering connections and dismantling barriers is nothing short of extraordinary."`,
      image: images.testimonialsImage7,
    },
  ];
  return (
    <>
      {testimonialsArray?.map((item) => (
        <>
          <div
            className=" mb-0 card "
            style={{background: "red", borderRadius: "29px"}}
          >
            <div
              className="testi-flex g-0"
              style={{
                border: "solid #002866",
                borderRadius: "8px",
                backgroundColor: "#fff",
                padding: "30px",
              }}
            >
              <div className=" ">
                <div className="d-flex">
                  <div className=" text-center ms-5 me-3">
                    <img
                      style={{
                        objectFit: "cover",
                        borderRadius: "50%",
                        width: "180px",
                        height: "180px",
                      }}
                      className="mx-auto my-5 "
                      src={item?.image}
                      alt=""
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
              <div className="" key={item.id}>
                <div className="card-body mx-auto mt-3 ms-5 l-border">
                  <h5
                    className="card-title"
                    style={{
                      fontSize: "24px",
                      fontWeight: "700",
                    }}
                  >
                    {item.title}
                  </h5>
                  <div className="card-text  ">
                    <p>{item.message}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ))}
    </>
  );
};

export default TestimonialCardNew;
