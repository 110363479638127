import React, { useEffect, useState } from "react";
import { Col, Container, Form, InputGroup, ProgressBar } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { addSupporter } from "../Api-Calls/supporter";
import { Slider } from "antd";
import { Link, useLocation } from "react-router-dom";
import TextInput from "../components/TextInput";
import InputMask from "react-input-mask";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../components/loader";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { SwalErrorMessage } from "../components/sweetAlertMessage";
import { useSelector } from "react-redux";
import Select from "react-select";
import { countries } from "../data/country";
import HelmetSEO from "../components/HelmetSEO";
import { getFundraiseBySlug } from "../Api-Calls/fundraise";
import Autocomplete from "react-google-autocomplete";
import { sendEmailPaymentDecline } from "../Api-Calls/supporter";
import meeezanLogo from "../assets/img/payment-gateway-icons/MeezanBank.png";
import meeezanMobileLogo from "../assets/img/payment-gateway-icons/MeezanBankIcon.png";
import jazzCashLogo from "../assets/img/payment-gateway-icons/JazzCash.png";
import easypaisaLogo from "../assets/img/payment-gateway-icons/EasyPaisa1.png";
import easypaisaMobileLogo from "../assets/img/payment-gateway-icons/EasyPaisa.png";
import paymobLogo from "../assets/img/payment-gateway-icons/Paymob.png";
var converter = require("number-to-words");

const PaymentFundraise = () => {
  const {
    auth: { user },
  } = useSelector((store) => store);
  const {
    control,
    handleSubmit,
    register,
    setFocus,
    formState: { errors },
  } = useForm();
  const { slug } = useParams();
  const navigate = useNavigate();
  const [buttonLoading, setButtonLoading] = useState(false);
  const { state } = useLocation();
  const min = 0;
  const max = 100000000000;
  const [amount, setAmount] = useState("");
  const [value, setValue] = useState("");
  const [fundraise, setFundraise] = useState(state);
  const [valueTip, setValueTip] = useState();
  const [processingFeePercentage, setProcessingFeePercentage] = useState(2.9);
  const [customAmount, setCustomAmount] = useState(false);
  const [loading, setLoading] = useState(true);
  const [checked, setChecked] = useState(false);
  const [numberToWords, setNumberToWords] = useState("");
  const [inputValue, setInputValue] = useState(5);
  const [percent, setpercent] = useState();
  const [processingFee, setProcessingFee] = useState();
  const [paymentMethod, setPaymentMethod] = useState("Credit / Debit Card");
  const [maskNo, setMaskNo] = useState("");
  const [fullDis, setFullDis] = useState(false);
  const donationValue =
    value +
    (percent ? percent : valueTip ? valueTip : 0) +
    (processingFee ? processingFee : 0)
      ? value +
        (processingFee ? processingFee : 0) +
        JSON.parse(percent ? percent : valueTip ? valueTip : 0)
      : 0;
  const [countryState, setCountryState] = useState({
    country: "",
    countryCode: "",
  });

  useEffect(() => {
    const calculateCharges = () => {
      const convertedValue = (value / 100) * processingFeePercentage;
      const roundedValue =
        Math.round((convertedValue + Number.EPSILON) * 100) / 100;
      setProcessingFee(roundedValue);
    };
    if (paymentMethod === "Credit / Debit Card") {
      setProcessingFeePercentage(2.9);
    } else {
      setProcessingFeePercentage(3.42);
    }
    calculateCharges();
  }, [paymentMethod, processingFeePercentage]);

  const [stateData, setState] = useState({
    phoneNo: user?.phoneNo,
    zipcode: user?.zipcode,
    errors: {
      cell: "",
      zipcode: "",
      country: "",
    },
  });
  // const handleChange = (event) => {
  //   const inputValues = Number(event.target.value);
  //   if (!isNaN(inputValues) && inputValues >= min && inputValues <= max) {
  //     const value = inputValues;
  //     const data = (value / 100) * inputValues;
  //     const fivePercent = (value / 100) * inputValue;
  //     const convertedValue = (value / 100) * processingFeePercentage;
  //     var roundedValue = Math.round((convertedValue + Number.EPSILON) * 100) / 100;
  //     setValue(value);
  //     setNumberToWords(converter.toWords(value));
  //     setpercent(fivePercent);
  //     setProcessingFee(roundedValue);
  //     setAmount(""); // Clear any previous error message
  //   } else {
  //     setValue(0);
  //     setNumberToWords();
  //     setpercent(0);
  //     setProcessingFee(0);
  //     setAmount("Donation amount is required.");
  //   }
  // };
  const handleChange = (event) => {
    const inputValues = event.target.value.replace(/,/g, ""); // Remove commas
    const numericValue = Number(inputValues);

    if (!isNaN(numericValue) && numericValue >= min && numericValue <= max) {
      const value = numericValue;
      const data = (value / 100) * numericValue;
      const fivePercent = (value / 100) * inputValue;
      const convertedValue = (value / 100) * processingFeePercentage;
      const roundedValue =
        Math.round((convertedValue + Number.EPSILON) * 100) / 100;

      setValue(value);
      setNumberToWords(converter.toWords(value));
      setpercent(fivePercent);
      setProcessingFee(roundedValue);
      setAmount(""); // Clear any previous error message
    } else {
      setValue(null); // Set to null to prevent displaying an invalid value
      setNumberToWords();
      setpercent(0);
      setProcessingFee(0);
      setAmount("Donation amount is required.");
    }
  };
  const handleChange1 = (event) => {
    const inputValues = Number(event.target.value);
    if (!isNaN(inputValues)) {
      setValueTip(inputValues);
    } else {
      setValueTip(0);
    }
  };

  const onChange = (newValue) => {
    setInputValue(newValue);
    const data = (value / 100) * newValue;
    var rounded = Math.round((data + Number.EPSILON) * 100) / 100;
    setpercent(rounded);
  };

  const handleCustomAmount = () => {
    setCustomAmount(true);
    setpercent(0);
    setInputValue(0);
  };
  const handleDefault = () => {
    setInputValue(10);
    const data = (value / 100) * 10;
    var rounded = Math.round((data + Number.EPSILON) * 100) / 100;
    setpercent(rounded);
    setCustomAmount(false);
  };

  useEffect(() => {
    if (window.innerWidth <= 600) {
      const fieldsWithErrors = Object.keys(errors).filter(
        (field) => !!errors[field]
      );
      if (fieldsWithErrors.length > 0) {
        setFocus(fieldsWithErrors[0]);
        document.documentElement.scrollTo({
          top: window.innerWidth <= 600 ? 600 : 0,
          left: 0,
          behavior: "smooth",
        });
      }
    }
  }, [
    errors.username,
    errors.phoneNo,
    errors.address,
    errors.zipcode,
    setFocus,
  ]);
  const setInputFields = (evt) => {
    setState({
      ...stateData,
      [evt.target.name]: evt.target.value,
    });
  };
  const getFundraiseData = async () => {
    try {
      setLoading(true);
      const res = await getFundraiseBySlug(slug);
      localStorage.setItem("fundraiserSlug", JSON.stringify(slug));
      if (res?.status == 200) {
        setFundraise(res?.data?.result);
      }
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    } catch (error) {
      console.log(error);
    }
  };
  const sendPaymentDeclineEmail = async () => {
    try {
      await sendEmailPaymentDecline(state.seqId);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      getFundraiseData();
    }, 1000);
    if (state?.error) {
      SwalErrorMessage("Transaction Failed", state.error, "error", true);
      if (state?.seqId) {
        sendPaymentDeclineEmail();
      }
    }
  }, [state]);

  const onSubmit = async (data) => {
    if (window.innerWidth <= 600) {
      if (
        countryState.country === undefined ||
        value == 0 ||
        value == undefined
      ) {
        document.documentElement.scrollTo({
          top: window.innerWidth <= 600 ? 600 : 0,
          left: 0,
          behavior: "smooth",
        });
        if (value == 0 || value == undefined) {
          setAmount("Donation amount is required.");
        }
        return;
      }
    }
    let detail = {
      personalInfo: {
        ...data,
        phoneNo: `${countryState.countryCode} ${
          data?.phoneNo?.split(" ")[1]
            ? data?.phoneNo?.split(" ")[1]
            : data?.phoneNo
        }`,
        zipcode: data?.zipcode,
        picture: "",
        country: countryState.country,
      },
      fundraise_id: fundraise?._id,
      amount: value,
      tip: percent,
      totalAmount: value + percent + processingFee,
      displayName: checked,
      payment_method: paymentMethod,
    };
    if (countryState.country == undefined) {
      setState({ ...stateData, errors: { ...errors, country: false } });
      return;
    }
    if (value == 0 || value == undefined) {
      return;
    }
    setButtonLoading(true);
    if (!user) {
      try {
        const res = await addSupporter(detail);
        if (res?.status == 201) {
          setButtonLoading(false);
          window.location.replace(res?.data?.result?.payment_checkout_url);
        }
      } catch (error) {
        SwalErrorMessage(
          "Oops!" + " " + error?.response?.data?.status,
          error?.response?.data?.message,
          "error",
          true
        );
        setButtonLoading(true);
      }
    } else {
      try {
        const res = await addSupporter(detail);
        if (res?.status == 201) {
          setButtonLoading(false);
          window.location.replace(res?.data?.result?.payment_checkout_url);
        }
      } catch (error) {
        SwalErrorMessage(
          "Oops!" + " " + error?.response?.data?.status,
          error?.response?.data?.message,
          "error",
          true
        );
        setButtonLoading(true);
      }
    }
  };
  const onCountryChange = (e) => {
    setState({ ...stateData, errors: { ...errors, country: true } });
    setCountryState({ country: `${e.label} `, countryCode: `+${e.code}` });
    setMaskNo(e.mask);
  };
  useEffect(() => {
    setCountryState({
      country:
        countries[countries.findIndex((c) => c.label === user?.country?.trim())]
          ?.label,
      countryCode: `+${
        countries[countries.findIndex((c) => c.label === user?.country?.trim())]
          ?.code
      }`,
    });
  }, [user?.country]);

  const customStyles = {
    option: (base, state) => ({
      ...base,
      color: "#1e2022",
      backgroundColor: "white",
      padding: ".5rem 3rem .5rem .5rem",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "rgba(189,197,209,.3)", // Change this to your desired border color on hover
      },
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
    control: (baseStyles, stateData) => ({
      ...baseStyles,
      paddingLeft: "10px",
      paddingRight: "10px",
      paddingTop: "8px",
      paddingBottom: "8px",
      backgroundColor: "#fff",
    }),
  };

  return (
    <>
      <HelmetSEO
        title={fundraise?.title}
        description={fundraise?.description}
      />
      <section className="section">
        <Container>
          <div style={{ overflowX: "hidden" }}>
            <div className="p-3">
              <div className="text-center my-sm-5  ">
                <h2>Choose Amount</h2>
              </div>
              <div>
                <div>
                  {loading ? (
                    <>
                      <div
                        className="section d-flex align-item-center justify-content-center"
                        style={{ minHeight: "100vh" }}>
                        <Loader />
                      </div>
                    </>
                  ) : (
                    <>
                      {fundraise && (
                        <>
                          <h4 className="mb-3 text-center text-md-start">
                            Fundraiser Information
                          </h4>
                          <div className="row">
                            <div className=" col-md-12 col-lg-3 col-sm-12">
                              <img
                                src={fundraise?.imageUrl[0]?.url}
                                alt="Fundraiser"
                                className="w-100"
                                style={{
                                  height: "400px",
                                  borderRadius: "8px",
                                  objectFit: "cover",
                                }}
                              />
                            </div>
                            <div className=" col-md-12  col-lg-9 mt-4 mt-md-3  mt-lg-0">
                              <div className="d-flex mb-3 justify-content-between align-items-center">
                                <Link to={`/fundraiser/${slug}`}>
                                  <h4 className="donation-heading">
                                    {fundraise?.title}
                                  </h4>
                                </Link>
                                <button className="btn__secondary   days-left  btn-border-secondry ">
                                  {fundraise?.daysLeft}
                                  {fundraise?.daysLeft === 1
                                    ? " " + "Day Left"
                                    : " " + "Days Left"}
                                </button>
                              </div>

                              <div className="fundSection">
                                <div className="fund-card-amount-detail mb-3">
                                  <div className=" mx-auto px-3 py-2 justify-content-between d-flex ">
                                    <div>
                                      <div>
                                        <strong>Fundraised</strong>
                                      </div>
                                      <div>
                                        <strong>
                                          {"Rs. " +
                                            fundraise?.collectedAmount.toLocaleString()}
                                        </strong>
                                      </div>
                                    </div>
                                    <div className="ms-2">
                                      <div>
                                        <strong>Goal</strong>
                                      </div>
                                      <div>
                                        {" "}
                                        <strong
                                          style={{
                                            overflow: "hidden",
                                            whiteSpace: "nowrap",
                                          }}>
                                          {"Rs. " +
                                            fundraise?.totalAmount.toLocaleString()}
                                        </strong>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="fundRaisingProgress">
                                  <ProgressBar
                                    now={
                                      (fundraise?.collectedAmount /
                                        fundraise?.totalAmount) *
                                      100
                                    }
                                  />
                                  <div className="d-flex align-items-center justify-content-between mb-3">
                                    <div>
                                      <i className="fa fa-heart text-danger"></i>{" "}
                                      <span>
                                        <strong>{fundraise?.supporters}</strong>{" "}
                                        Supporters{" "}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <p
                                style={{ cursor: "pointer" }}
                                onClick={() => setFullDis(!fullDis)}
                                className={`${!fullDis && "descriptionFund"}`}>
                                {fundraise?.description}
                              </p>
                            </div>
                          </div>
                          <hr />
                        </>
                      )}
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className=" d-flex flex-column flex-md-row">
                          <div className="col-md-6 py-3">
                            <h6 className="mb-2">Enter your donation</h6>
                            <div
                              className={"input-group mb-2 customFieldPrice"}>
                              <div className="input-group-prepend">
                                <div
                                  className="input-group-text flex-column justify-content-center px-3 h-100"
                                  style={{
                                    height: "150px !important",
                                    backgroundColor: "#f9cb40",
                                    color: "#002866",
                                  }}>
                                  <div className="mx-3"></div>
                                  <div className="mx-1">
                                    <b>PKR</b>
                                  </div>
                                </div>
                              </div>
                              <div
                                className={
                                  value == 0 || value == undefined
                                    ? "borderDanger flex1 d-flex w-100"
                                    : "flex1 d-flex w-100 borderPrimary"
                                }>
                                <input
                                  type="text"
                                  inputMode="numeric"
                                  maxLength={7}
                                  value={
                                    value !== null ? value.toLocaleString() : ""
                                  }
                                  onChange={handleChange}
                                  className="form-control inputAmmount border-0 px-0"
                                  aria-label="Amount (to the nearest dollar)"
                                />
                                <div className="input-group-append d-block">
                                  <span className="input-group-text h-100 inputAmmount">
                                    .00
                                  </span>
                                </div>
                              </div>
                            </div>
                            {amount && (
                              <div className="text-danger">{amount}</div>
                            )}
                            <h4 className="mt-5 mb-3">Personal information</h4>

                            <div className="mb-3">
                              <TextInput
                                name={"username"}
                                className="form-control"
                                defaultValue={user?.username}
                                rules={{
                                  required: "Name is required.",
                                }}
                                control={control}
                                placeholder="Name"
                              />
                            </div>
                            <div className="mb-3">
                              <TextInput
                                name={"email"}
                                className="form-control"
                                disabled={"1"}
                                defaultValue={user?.email}
                                rules={{
                                  required: "Email is required.",
                                  pattern: {
                                    value:
                                      /^\s*(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/,
                                    message: "Invalid  email address.",
                                  },
                                }}
                                control={control}
                                placeholder="Email"
                              />
                            </div>
                            <div className="mb-3 ">
                              <Select
                                onChange={(e) => onCountryChange(e)}
                                styles={customStyles}
                                defaultValue={
                                  countries[
                                    countries.findIndex(
                                      (c) => c.label === user?.country?.trim()
                                    )
                                  ]
                                }
                                placeholder="Select Country"
                                className="form-contro"
                                options={countries}
                              />
                              {stateData?.errors?.country === false && (
                                <p className="text-danger  my-2 ms-2 ">
                                  Country is required.
                                </p>
                              )}
                            </div>
                            <div className="row">
                              <div className="mb-3 col-lg-3 col-md-4">
                                <input
                                  readOnly
                                  control={control}
                                  autoComplete="off"
                                  value={
                                    countryState.countryCode !== "+undefined"
                                      ? countryState.countryCode
                                      : ""
                                  }
                                  name="PhoneCode"
                                  placeholder="+92"
                                  className="form-control dir-ltr customInputLogin"
                                />
                              </div>
                              <div className="mb-3 col-lg-9 col-md-8">
                                <InputMask
                                  {...register("phoneNo", {
                                    required: "Phone Number is required",
                                  })}
                                  autoComplete="off"
                                  inputMode="numeric"
                                  mask={
                                    maskNo
                                      ? maskNo
                                      : countries.find(
                                          (c) =>
                                            c.label === user?.country?.trim()
                                        )?.mask
                                      ? countries.find(
                                          (c) =>
                                            c.label === user?.country?.trim()
                                        )?.mask
                                      : "99999-99999"
                                  }
                                  maskChar={null}
                                  name="phoneNo"
                                  defaultValue={
                                    user?.phoneNo?.split(" ")[1]
                                      ? user?.phoneNo?.split(" ")[1]
                                      : user?.phoneNo
                                  }
                                  placeholder="Phone Number"
                                  className="form-control dir-ltr customInputLogin"
                                />
                                <p className="text-danger  my-2 ms-2 ">
                                  {errors?.phoneNo?.message}
                                </p>
                              </div>
                            </div>
                            {/* <Autocomplete
                                            apiKey={process.env.REACT_APP_PLACESAPI_KEY}
                                            placeholder="Location"
                                            className="form-control"
                                            name='location'
                                            onPlaceSelected={(place)=>{console.log(place,"place");}}
                                            options={{
                                                types: ["geocode", "establishment"],
                                            }}
                                        /> */}
                            <div className="mb-3">
                              <TextInput
                                name={"address"}
                                className="form-control"
                                defaultValue={user?.address}
                                rules={{
                                  required: "Address is required.",
                                }}
                                control={control}
                                placeholder="Enter Address"
                              />
                            </div>

                            <div className="mb-3">
                              <InputMask
                                autoComplete="off"
                                mask="99 999"
                                maskChar={null}
                                inputMode="numeric"
                                s
                                defaultValue={user?.zipcode}
                                name="zipcode"
                                onChange={setInputFields}
                                placeholder="Zip Code"
                                className="form-control dir-ltr customInputLogin"
                                {...register("zipcode", {
                                  required: "Zip Code is required",
                                })}
                              />
                              <p className="text-danger  my-2 ms-2 ">
                                {errors?.zipcode?.message}
                              </p>
                            </div>
                          </div>
                          <div className=" p-3 mt-4">
                            <div
                              className="p-3"
                              style={{
                                border: "2px solid #002866",
                                borderRadius: "10px",
                              }}>
                              <p>
                                <span className="text-danger fw-bold">
                                  Note:{" "}
                                </span>
                                At Building Bridges, we are committed to
                                providing a fee-free platform. However, a humble
                                amount as your generous contributions is valued
                                for it plays a pivotal role in enhancing our
                                services, enabling us to connect with more
                                like-minded individuals who are ready to share
                                our mission globally. We thank you for your
                                support.
                              </p>
                              {customAmount ? (
                                <>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <div>
                                      <h6 className="mb-2">Fee Amount</h6>
                                    </div>
                                    <div
                                      onClick={handleDefault}
                                      style={{
                                        textDecoration: "underline",
                                        cursor: "pointer",
                                      }}>
                                      Back to default
                                    </div>
                                  </div>
                                  <InputGroup className="mb-3 customTipInput">
                                    <InputGroup.Text
                                      id="basic-addon1"
                                      className="px-3">
                                      <b>Rs.</b>
                                    </InputGroup.Text>
                                    <Form.Control
                                      disabled={value ? false : true}
                                      className="px-2"
                                      type="text"
                                      inputMode="numeric"
                                      maxLength={7}
                                      value={valueTip}
                                      onChange={handleChange1}
                                      aria-describedby="basic-addon1"
                                    />
                                  </InputGroup>
                                  <p>
                                    Adding a Patform Fee means being a key part
                                    of improving the services for donors like
                                    you and the campaigns you support.
                                  </p>
                                </>
                              ) : (
                                <>
                                  <div className="d-flex align-items-center ">
                                    <div>
                                      <h5>0%</h5>
                                    </div>
                                    <div className="flex1 mx-3 fundRaisingProgress">
                                      <Col span={12}>
                                        <Slider
                                          min={0}
                                          disabled={value ? false : true}
                                          max={20}
                                          onChange={onChange}
                                          value={
                                            typeof inputValue === "number"
                                              ? inputValue
                                              : 10
                                          }
                                        />
                                      </Col>
                                    </div>
                                    <div>
                                      <h5> 20%</h5>
                                    </div>
                                  </div>
                                  {value > 0 && (
                                    <div className="text-center">
                                      <h6>
                                        (Rs.{" "}
                                        {percent ? percent.toLocaleString() : 0}
                                        ) {inputValue}%
                                      </h6>
                                    </div>
                                  )}

                                  <div
                                    className="text-end my-4"
                                    onClick={handleCustomAmount}
                                    style={{
                                      textDecoration: "underline",
                                      cursor: "pointer",
                                    }}>
                                    Enter custom fee
                                  </div>
                                </>
                              )}
                            </div>

                            <h6 className="mt-5 mb-2">Your donation</h6>
                            <div className="d-flex mb-2 align-items-center justify-content-between">
                              <div>Donation Amount</div>
                              <div>
                                <b>Rs. {value ? value.toLocaleString() : 0}</b>
                              </div>
                            </div>
                            <div className="d-flex mb-2 align-items-center justify-content-between">
                              <div>Platform Fee</div>
                              <div>
                                <b>
                                  Rs.{" "}
                                  {percent
                                    ? percent.toLocaleString()
                                    : valueTip
                                    ? valueTip.toLocaleString()
                                    : 0}
                                </b>
                              </div>
                            </div>
                            <div className="d-flex mb-2 align-items-center justify-content-between">
                              <div>Processing Fee</div>
                              <div>
                                <b>
                                  (Rs.{" "}
                                  {processingFee
                                    ? processingFee.toLocaleString()
                                    : 0}
                                  ) {processingFeePercentage} %
                                </b>
                              </div>
                            </div>
                            <hr />
                            <div className="d-flex align-items-center justify-content-between">
                              <div>Total Amount</div>
                              <div>
                                <b>{"Rs. " + donationValue.toLocaleString()}</b>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="display-payment-gatway-icons">
                          <div className="form-check p-0 mb-3">
                            <input
                              type="checkbox"
                              id="custom-switch"
                              className="form-check-input"
                              defaultChecked={checked}
                              onChange={() => setChecked(!checked)}
                            />
                            <label
                              htmlFor="custom-switch"
                              className="form-check-label cursor-pointer mb-0">
                              Don't display my name publicly on the fundraiser.
                            </label>
                          </div>
                          <div className="d-flex flex-wrap justify-content-start">
                            <img
                              src={
                                window?.innerWidth <= 600
                                  ? meeezanMobileLogo
                                  : meeezanLogo
                              }
                              height={"50px"}
                              width={"auto"}
                              alt=""
                              loading="lazy"
                              className="m-1"
                            />
                            <img
                              src={paymobLogo}
                              height={"50px"}
                              width={"auto"}
                              alt=""
                              loading="lazy"
                              className="m-1 ms-3"
                            />
                            <img
                              src={jazzCashLogo}
                              height={"50px"}
                              width={"auto"}
                              alt=""
                              loading="lazy"
                              className="m-1 ms-3"
                            />
                            <img
                              src={
                                window?.innerWidth <= 600
                                  ? easypaisaMobileLogo
                                  : easypaisaLogo
                              }
                              height={"50px"}
                              width={"auto"}
                              alt=""
                              loading="lazy"
                              className="m-1 ms-3"
                            />
                          </div>
                        </div>

                        <h4 className="mt-3 mb-3 ">Payment method</h4>
                        <div>
                          <fieldset className=" ">
                            <Form.Group className="">
                              <div className="row">
                                {/* <div className="col-xl-3 ">
                                  <div
                                    className="payment-check  mb-3"
                                    onClick={() =>
                                      setPaymentMethod("Bank Transfer")
                                    }
                                  >
                                    <Form.Check
                                      type="radio"
                                      label="Bank Transfer"
                                      name="paymentMethod"
                                      value="Bank Transfer"
                                      id="formHorizontalRadios2"
                                      checked={
                                        paymentMethod === "Bank Transfer"
                                      }
                                      disabled
                                    />
                                  </div>
                                </div> */}
                                <div className="col-xl-4">
                                  <div
                                    className="payment-check  mb-3"
                                    onClick={() =>
                                      setPaymentMethod("Credit / Debit Card")
                                    }>
                                    <Form.Check
                                      type="radio"
                                      label="Credit / Debit Card"
                                      name="paymentMethod"
                                      id="formHorizontalRadios1"
                                      checked
                                    />
                                  </div>
                                </div>
                                <div className="col-xl-4">
                                  <div
                                    className="payment-check  mb-3"
                                    onClick={() =>
                                      setPaymentMethod("Jazz Cash")
                                    }>
                                    <Form.Check
                                      type="radio"
                                      label="Jazz Cash"
                                      name="paymentMethod"
                                      id="formHorizontalRadios3"
                                      checked={
                                        paymentMethod === "Jazz Cash" && true
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col-xl-4">
                                  <div
                                    className="payment-check  mb-3"
                                    onClick={() =>
                                      setPaymentMethod("EasyPaisa")
                                    }>
                                    <Form.Check
                                      type="radio"
                                      label="EasyPaisa"
                                      name="paymentMethod"
                                      id="formHorizontalRadios4"
                                      checked={
                                        paymentMethod === "EasyPaisa" && true
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </Form.Group>
                          </fieldset>
                        </div>

                        <div className="my-4">
                          <button className="btn__primary buttonPrimary w-50 mx-auto ">
                            {buttonLoading && (
                              <i
                                className="fa mx-3 fa-circle-o-notch fa-spin"
                                style={{ fontSize: "24px" }}></i>
                            )}
                            Proceed to continue
                          </button>
                          <p className="my-3 text-center ">
                            By continuing, you agree with Building Bridges{" "}
                            <Link
                              to={"/terms-conditions"}
                              className="termConditionTxt">
                              Terms
                            </Link>{" "}
                            <br />
                            and{" "}
                            <Link
                              to={"/privacy-policy"}
                              className="termConditionTxt">
                              Privacy Policy
                            </Link>
                            .
                          </p>
                        </div>
                      </form>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Container>
        <ToastContainer />
      </section>
    </>
  );
};

export default PaymentFundraise;
