import React, {useEffect, useState} from "react";
import {Container} from "react-bootstrap";
import {useParams} from "react-router-dom";
import "../../assets/css/MedicalFunding.css";
import OwlCarousel from "react-owl-carousel";
import TestimonialCard from "../../components/TestimonialCard";
import Accordion from "react-bootstrap/Accordion";
import testimonialBackground from "../../assets/img/Testimonials.jpg";
import {constantIcons, constantImages} from "../../Constant";
import {getFundraiseByCategorySlug} from "../../Api-Calls/categoryApi";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FundingForm from "../../components/FundingForm";
import FundraiserBreadCrumb from "../../components/FundraiserBreadCrumb";
import WhatBuildingBridgesOffer from "../../components/staticComponents/WhatBuildingBridgesOffer";
import ActiveFundraiser from "../../components/ActiveFundraiser";
import webBanner from "../../assets/img//staticImages/emergency-crowdfunding-images/emergency-banner.png";
import mobileBanner from "../../assets/img/staticImages/emergency-crowdfunding-images/emergency-banner-mobile.png";
import mobileImage from "../../assets/img/staticImages/emergency-crowdfunding-images/mobile-image.png";
import HelmetSEO from "../../components/HelmetSEO";
import WhyChooseBuildingBridges from "../../components/WhyChooseBuildingBridges";
import SupportFundraiserFaqQuestion from "../../components/SupportFundraiserFaqQuestion";

const EmergencyFunding = (params) => {
  const {slug} = useParams();
  const images = constantImages();
  const icons = constantIcons();
  const [fundraiser, setFundraiser] = useState([]);

  const getFundraisebyCategotyId = async () => {
    try {
      const res = await getFundraiseByCategorySlug(slug);
      if (res?.status == 200) {
        setFundraiser(res?.data?.result?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getFundraisebyCategotyId();
  }, []);

  return (
    <>
      <HelmetSEO title="Emergency Funding" description="Emergency Funding" />

      <ToastContainer />
      <FundraiserBreadCrumb
        heading="Emergency Funding"
        description={
          <p>
            Building Bridges enables users to create fundraisers for emergencies
            like natural disasters, humanitarian crises, and unforeseen events.
            Your support can make a significant impact and extend a helping hand
            to those facing crises while struggling with finances.
          </p>
        }
        webImage={images.emergency1}
        mobileImage={mobileImage}
      />

      <ActiveFundraiser fundraiser={fundraiser} slug={slug} />
      <WhyChooseBuildingBridges
        mainHeading="Emergency"
        icon1={icons.emergencyChoosseIcon1}
        icon2={icons.emergencyChoosseIcon2}
        icon3={icons.emergencyChoosseIcon3}
        icon4={icons.emergencyChoosseIcon4}
        heading1="Support for Quality Education"
        heading2="Empowering Financial Aid Seekers"
        heading3="Positive Impact on the Society"
        heading4="Building a Supportive Community"
        description1="Building Bridges provides support to all the deserving students by enabling users to create fundraisers for empowering them through quality education for a brighter future."
        description2="Building Bridges empowers the financial aid seekers by providing them with the option of hassle free online fundraising. The collected amount can be withdrawn anytime."
        description3="Building Bridges enables efficient fundraising for educational cases of deserving students for empowering them to reach their full potential and make a positive impact on the society."
        description4="Building Bridges aims to create a supportive community that financially aids the deserving students from all walks of life, offering them a chance to rise above their circumstances."
      />

      <section className="">
        <FundingForm
          title="Building Bridges for Emergency Funding "
          paragraph={`
            Building Bridges allows you to create Emergency fundraisers in three easy steps. This procedure enables supporters to engage with communities or individuals facing 
            emergencies, catastrophic incidents, and accidents, all while facing financial constraints.
            `}
          mobileBanner={mobileBanner}
          webBanner={webBanner}
        />
      </section>

      <WhatBuildingBridgesOffer />

      <section
        className=" testimonialSection py-5 section--white"
        style={{
          backgroundImage: `url(${testimonialBackground}) `,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
        }}
      >
        <div className="sectionHeading text-center mb-5">
          <h2 className="mb-4 high-title title-background mt-5">
            Success Stories
          </h2>
          <div className="col-md-7 mx-auto divider"></div>
        </div>
        <div>
          <div className="sectionHeading text-center mb-5"></div>
          <div className="container">
            <div className="col-md-9 mx-auto ">
              <OwlCarousel
                items={1}
                margin={30}
                autoplay={true}
                className="owl-theme fundRaisingSection testimonial-background"
              >
                <TestimonialCard image="false" />
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
      <SupportFundraiserFaqQuestion
        heading1="What are Emergency funding projects and who do these projects benefit?"
        heading2="Can I choose the specific emergency project to support?"
        heading3="How can students or families directly apply for funding through Building Bridges?"
        heading4="What happens if an emergency project is fully funded before I donate?"
        heading5="How do I receive updates on the impact of my emergency donation?"
        heading6="How can I get involved beyond donating?"
        description1=" Building Bridges is an online funding platform which enables
        efficient fundraising for emergencies and crisis in a short
        period of time, helping the individuals access critical
        support and aid in times of urgent need. Emergency funding
        on Building Bridges can be seen as a rapid-response
        initiative that allows the users to immediately create
        fundraisers for critical situations, such as natural
        disasters, humanitarian crises, and unforeseen emergencies.
        The platform also gives the donors a chance to provide
        immediate financial support to the trending emergency
        fundraisers."
        description2="Yes, Building Bridges allows the donors and supporters to
        browse and choose from a variety of fundraisers that have
        been created by various individuals. As a donor you can
        always select the project that aligns with your
        philanthropic goals and the type of emergency you wish to
        support."
        description3="Building Bridges has an efficient mechanism that supports
        swift disbursement of funds to the causes featured on the
        platform. Using the Building Bridges platform, our users are
        allowed to create fundraisers and then share those on their
        personal social media accounts. After the fundraiser reaches
        the potential donors, and the desired fund is collected, it
        can be withdrawn by the user at anytime. It is then the
        user’s responsibility to disburse the collected emergency
        funds as swiftly as possible to ensure timely support for
        affected communities."
        description4="In the event that an emergency project receives sufficient
        funding from other donors or sources, Building Bridges will
        keep the fundraiser open and allow the donors to continue
        funding the project till they are satisfied form the
        collected amount as their support can make a significant
        impact and extend a helping hand to those facing crises
        while struggling with finances."
        description5="Building Bridges provides regular updates on the progress
        and impact of emergency projects through success stories,
        testimonials, blogs, newsletters, emails to the donors,
        project reports, and updates on the website and all official
        social media platforms. This way the donors can stay
        informed about how their contributions are making a
        difference."
        description6="Apart form donating, you can always create a fundraiser for
        supporting any cause that is either close to your heart or
        has been recommended to you by someone you know. You can
        become a part of our fundraising campaigns by sharing the
        fundraisers on your personal social media accounts and
        encourage your friends and loved ones to do the same.
        Volunteering to spread awareness and encourage potential
        donors to support our platform and give special attention to
        the Emergency Funding charity page is also an option.
        Building Bridges offers you the opportunity to create a
        fundraiser in the loving memory of your deceased loved one
        as well."
      />
    </>
  );
};

export default EmergencyFunding;
