import React from "react";
import {Link} from "react-router-dom";
import {useState} from "react";
import swal from "sweetalert";
import ProfileInformationModal from "../../components/ProfileInformaionModal";
import {getUserById} from "../../Api-Calls/authentication";
import {useNavigate} from "react-router";
import {updateUserState} from "../../Features/user/authSlice";
import {useDispatch, useSelector} from "react-redux";

const SideBar = ({openSideBar, changeSideBarStatus}) => {
  const para = window.location.pathname;
  const {
    auth: {user},
  } = useSelector((store) => store);

  const [tagModal, setTagModal] = useState(false);
  const dispatchRedux = useDispatch();
  const navigate = useNavigate();
  const UpdateUserProfile = async () => {
    if (user) {
      try {
        const res = await getUserById(user?._id);
        if (res?.status == 200) {
          let userData = res?.data?.result?.user;
          dispatchRedux(updateUserState(res?.data?.result?.user));
          if (
            userData?.status === "Rejected" ||
            userData?.status === "Pending"
          ) {
            if (
              userData?.country !== "" &&
              userData?.phoneNo !== "" &&
              userData?.address !== "" &&
              userData?.hear_about !== "" &&
              userData?.cnicNo !== "" &&
              userData?.zipcode !== "" &&
              userData?.cnic_picture?.length > 0
            ) {
              swal({
                title: "Please Note",
                text: "Your personal information has been added, please wait for admin's approval to proceed forward.",
                icon: "warning",
                buttons: true,
                dangerMode: true,
              });
            } else {
              try {
                swal({
                  title: "Confirmation!",
                  text: "Before creating a case, kindly ensure that you have completed your profile.",
                  icon: "warning",
                  buttons: true,
                  dangerMode: true,
                }).then((data) => {
                  if (data) {
                    setTagModal(true);
                  }
                });
              } catch (err) {
                console.log(err);
              }
            }
          }
        }
        if (res?.data?.result?.user?.status === "Verified") {
          navigate("/dashboard/user-fundraisers");
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      navigate("/login");
    }
  };
  return (
    <>
      <ul className="nav nav-primary mt-4">
        {openSideBar ? (
          <i
            className="fa fa-times nav-item text-end"
            onClick={changeSideBarStatus}
            style={{color: "black"}}
          ></i>
        ) : (
          <i
            className="fa fa-bars nav-item ms-2"
            onClick={changeSideBarStatus}
            style={{color: "black"}}
          ></i>
        )}
        <li
          className={
            para == "/dashboard/user-dashboard" ? "nav-item active" : "nav-item"
          }
        >
          <Link to={"/dashboard/user-dashboard"} onClick={changeSideBarStatus}>
            <i className="fa fa-home"></i>
            {openSideBar && <p>Dashboard</p>}
          </Link>
        </li>
        <li
          className={
            para == "/dashboard/profile-setting"
              ? "nav-item active"
              : "nav-item"
          }
        >
          <Link to={"/dashboard/profile-setting"}>
            <i class="fa fa-user" aria-hidden="true"></i>
            {openSideBar && <p>Profile</p>}
          </Link>
        </li>
        <li
          className={
            para == "/dashboard/user-fundraisers"
              ? "nav-item active"
              : "nav-item"
          }
        >
          <a onClick={UpdateUserProfile}>
            <i className="fa fa-file"></i>
            {openSideBar && <p>Cases</p>}
          </a>
        </li>

        <li
          className={
            para == "/dashboard/donations" ? "nav-item active" : "nav-item"
          }
        >
          <Link to={"/dashboard/donations"}>
            <i className="fa fa-money"></i>
            {openSideBar && <p>Donations</p>}
          </Link>
        </li>
        <li
          className={
            para == "/dashboard/account-setting" ? "nav-item active" : "nav-item"
          }
        >
          <Link to={"/dashboard/account-setting"}>
            <i class="fa fa-cog" aria-hidden="true"></i>
            {openSideBar && <p>Change Password</p>}
          </Link>
        </li>
      </ul>
      <ProfileInformationModal tagModal={tagModal} setTagModal={setTagModal} />
    </>
  );
};

export default SideBar;
