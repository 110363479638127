import React, {useEffect, useState} from "react";
import CommentSection from "./CommentSection";
import {useParams} from "react-router";
import {addComment, getComment} from "../Api-Calls/fundraiseComment";
import {useSelector} from "react-redux";

const CommentsTab = (props) => {
  const {slug} = useParams();
  const [images, setImages] = useState([]);
  const {
    auth: {user},
  } = useSelector((store) => store);

  const [commentData, setCommentData] = useState({});
  const [imageURLs, setImageURLs] = useState([]);
  const [commentList, setCommentList] = useState([]);
  const [commentError, setCommentError] = useState("");
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (images?.length < 1) return;
    const newImageURLs = [];
    images.forEach((element) => {
      newImageURLs.push(URL.createObjectURL(element));
    });
    setImageURLs(newImageURLs);
  }, [images]);

  const onImageChange = (e) => {
    setImages([...e.target.files]);
  };
  const handleChange = (e) => {
    let {name, value} = e.target;
    setCommentData({
      ...commentData,
      [name]: value,
    });
    if (value.trim()) {
      setCommentError("");
    } else {
      setCommentError("Comment fied is required.");
    }
  };
  const getAllComments = async () => {
    try {
      const res = await getComment(slug);
      if (res?.status == 200) {
        setCommentList(res?.data?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleSubmit = async (e) => {
    if (
      commentData?.comment.trim() == undefined ||
      commentData?.comment.trim() == ""
    ) {
      return setCommentError("Comment field is required.");
    }

    e.preventDefault();

    const formData = new FormData();
    Array.from(images)?.forEach((item, index) => {
      formData?.append("files", item);
    });
    formData.append("user", user?._id);
    formData.append("comment", commentData?.comment.trim());
    try {
      setLoader(true);
      const res = await addComment(slug, formData);
      if (res?.status == 201) {
        setCommentData({});
        getAllComments();
        setImageURLs([]);
        setImages([]);
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllComments();
  }, []);

  return (
    <section className="commentsSectionFundraise mt-5">
      <div className="text-center">
        <h4>Add Comment</h4>
      </div>

      <div className="position-relative">
        <textarea
          className="form-control"
          name="comment"
          placeholder="Enter Comment..."
          value={commentData?.comment || ""}
          onChange={(e) => {
            handleChange(e);
          }}
        ></textarea>
        {!commentData?.comment == undefined ||
          (!commentData?.comment == "" && (
            <div className="fasend">
              <i class="fa fa-paperclip" aria-hidden="true"></i>
              <input
                type="file"
                className="uploadFileComment"
                multiple
                onChange={onImageChange}
              />

              {loader ? (
                <i
                  className="fa mx-3 fa-circle-o-notch fa-spin"
                  style={{fontSize: "24px"}}
                ></i>
              ) : (
                <i
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                  class="fa fa-send"
                  aria-hidden="true"
                ></i>
              )}
            </div>
          ))}
      </div>
      {commentError && <div className="text-danger">{commentError}</div>}

      <div className="d-flex align-items-center justify-content-between">
        <div className="my-3">
          {imageURLs.map((imageSrc) => (
            <img className="commentPreviewImage" src={imageSrc} />
          ))}
        </div>
      </div>

      <div className="text-center">
        <h4>All Comments</h4>
      </div>

      <div className="mt-3">
        <CommentSection commentList={commentList} />
      </div>
    </section>
  );
};

export default CommentsTab;
