import React from "react";
import {Container} from "react-bootstrap";
import BreadCrumb from "../components/BreadCrumb";
import WebBanner from "../assets/img/banner/authentic.png";
import MobileBanner from "../assets/img/banner/authentic-mobile.png";
import HelmetSEO from "../components/HelmetSEO";

const Transparancy = () => {
  return (
    <>
      <HelmetSEO title="Transparancy" description="Transparancy" />
      <BreadCrumb
        font={true}
        heading="TRANSPARENCY"
        webImage={WebBanner}
        mobileImage={MobileBanner}
      />

      <section className="section--about p-3">
        <section className="section section--white">
          <Container>
            <p>
              Building Bridges is dedicated to maintaining transparency and
              integrity as we work towards creating positive change in the
              world. Your trust and support are essential to our mission, and we
              value your involvement in this journey of transforming lives. So,
              as you embark on your fundraising campaign with Building Bridges,
              know that we are here to provide a safe and trustworthy platform
              because transparency and commitment are our non-negotiable traits.
            </p>
            <h2 className="mb-3">1. Privacy and Data Security</h2>
            <p>
              Building Bridges adheres to data protection laws as we prioritize
              the privacy and security of personal information provided by the
              users who choose our platform for creating fundraisers and the
              donors who choose to donate to our causes. Building Bridges shares
              data of the beneficiaries with the donors and supporters only
              after the consent of the beneficiaries.
            </p>
          </Container>
        </section>

        <section className="section section--gray">
          <Container>
            <h2 className="mb-3">2. Financial Accountability</h2>
            <p>
              Building Bridges commits to maintaining clear and accountable
              financial practices. We maintain accurate and detailed financial
              records, ensuring complete accountability. All financial reports
              are regularly audited by independent, reputable firms to ensure
              accuracy and compliance with regulations. Building Bridges charges
              0% platform fee, which means 100% of the proceeds are awarded to
              the beneficiaries.
            </p>
          </Container>
        </section>

        <section className="section section--white">
          <Container>
            <h2 className="mb-3">
              3. Building Trust through Clear and Honest Dialogue
            </h2>
            <p>
              Building Bridges believes in maintaining an open and honest
              communication with the donors, supporters, beneficiaries and all
              stakeholders involved, fostering trust by addressing questions,
              concerns, and updates honestly and promptly. Regular updates are
              provided on our website and through newsletters, blogs and success
              stories to keep everyone informed about our fundraisers and their
              progress.
            </p>
          </Container>
        </section>
        <section className="section section--gray">
          <Container>
            <h2 className="mb-3">
              4. Measuring and Sharing the Results of Our Efforts
            </h2>
            <p>
              In order to maintain transparency Building Bridges regularly
              shares impact reports detailing the outcomes and results of
              various fundraisers. Donors can see how their contributions have
              made a positive difference in the lives of those we have chosen to
              collectively serve.
            </p>
          </Container>
        </section>
        <section className="section section--white">
          <Container>
            <h2 className="mb-3">5. Feedback and Accountability</h2>
            <p>
              Building Bridges welcomes feedback from all the donors,
              beneficiaries, and the community to continuously improve our
              operations. Our team of dedicated members is accountable for
              addressing concerns and inquiries promptly and transparently.
            </p>
          </Container>
        </section>
        <section className="section section--gray">
          <Container>
            <h2 className="mb-3">6. Public disclosure</h2>
            <p>
              Building Bridges believes in sharing relevant information with the
              community that supports it. We believe in openness and sharing
              relevant information about our organization, projects, and
              financials with our community through various channels. User
              satisfaction is our first priority.
            </p>
          </Container>
        </section>
        <section className="section section--white">
          <Container>
            <h2 className="mb-3">7. Ethical Fundraising</h2>
            <p>
              Building Bridges believes in transparent practices in raising and
              releasing funds to the users. We uphold ethical fundraising
              practices, informing donors about the intended use of their
              contributions and ensuring that funds are safely transferred to
              the user in due time to be timely utilized for intended purposes.
            </p>
          </Container>
        </section>
        <section className="section section--gray">
          <Container>
            <h2 className="mb-3">
              8. Building bridges of trust with our supporters
            </h2>
            <p>
              We value our donors and cultivate strong relationships by keeping
              them informed about our activities, results, and the impact of
              their contributions.
            </p>
          </Container>
        </section>
        <section className="section section--white">
          <Container>
            <h2 className="mb-3">
              9. Learning and growing with transparency in mind
            </h2>
            <p>
              Building bridges believes in the philosophy of continuous
              improvement. Learning from our experiences, and being receptive to
              feedback from our stakeholders, we strive to enhance our impact
              and transparency for a unique and trusted user experience..
            </p>
          </Container>
        </section>
        <section className="section section--gray">
          <Container>
            <h2 className="mb-3">
              10. Adhering to Best Practices and Regulations{" "}
            </h2>
            <p>
              Building Bridges believes in maintaining compliance and standards.
              Therefore, we operate with a transparent governance framework,
              ensuring that decisions are made responsibly, and processes are
              accessible to all stakeholders. By adhering to industry best
              practices and regulatory standards, we focus on maintaining
              compliance with legal requirements to operate responsibly.
            </p>
            <p>
              Let’s Build Bridges with trust and confidence to empower our
              communities!
            </p>
          </Container>
        </section>
      </section>
    </>
  );
};

export default Transparancy;
