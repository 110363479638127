import SideMenu from "./SideMenu";
import DashBoard from "./DashBoard";
import Jumbotran from "./Jumbotran";
import DashBoardFooter from "./DashBoardFooter";

export default function UserDashBoardMain() {
  return (
    <div className=" ">
      <SideMenu />
      <div className="main-panel" >
        <div className="container">
          <Jumbotran page="Dashboard" desc="" />
          <DashBoard />
        </div>
        <DashBoardFooter />
      </div>
    </div>

  )
}
