import React from "react";
import {Container} from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";

const SupportFundraiserFaqQuestion = (props) => {
  return (
    <section className="section  testimonialSection container-fluid mid-div py-5">
      <Container>
        <div className="sectionHeading text-center mb-5">
          <h2 className="mb-4 high-title">Frequently Asked Questions</h2>
          <div className="col-md-7 mx-auto divider"></div>
        </div>
        <div className="col-lg-6 col-10 mx-auto">
          <Accordion className="border-0">
            <Accordion.Item eventKey="0" className="mb-3 border">
              <Accordion.Header className="header-style">
                {props.heading1}
              </Accordion.Header>
              <Accordion.Body>
                <p className="fq-div">{props.description1}</p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion className="border-0">
            <Accordion.Item eventKey="0" className="mb-3 border">
              <Accordion.Header className="header-style">
                {props.heading2}
              </Accordion.Header>
              <Accordion.Body>
                <p className="fq-div">{props.description2}</p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion className="border-0">
            <Accordion.Item eventKey="0" className="mb-3 border">
              <Accordion.Header className="header-style">
                {props.heading3}
              </Accordion.Header>
              <Accordion.Body>
                <p className="fq-div">{props.description3}</p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion className="border-0">
            <Accordion.Item eventKey="0" className="mb-3 border">
              <Accordion.Header className="header-style">
                {props.heading4}
              </Accordion.Header>
              <Accordion.Body>
                <p className="fq-div">{props.description4}</p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion className="border-0">
            <Accordion.Item eventKey="0" className="mb-3 border">
              <Accordion.Header className="header-style">
                {props.heading5}
              </Accordion.Header>
              <Accordion.Body>
                <p className="fq-div">{props.description5}</p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion className="border-0">
            <Accordion.Item eventKey="0" className="mb-3 border">
              <Accordion.Header className="header-style">
                {props.heading6}
              </Accordion.Header>
              <Accordion.Body>
                <p className="fq-div">{props.description6}</p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </Container>
    </section>
  );
};

export default SupportFundraiserFaqQuestion;
