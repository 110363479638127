export const initialState = {
  userData: {},
  fundraiseList: [],
  successStoriesList: [],
  categoryListHome: [],
  categoryList: [],
  singleFundraise: {},
  singlePayment: {},
};

export const Reducer = (state = initialState, action) => {
  switch (action?.type) {
    case "SUCCESS_STORIES":
      return {
        ...state,
        successStoriesList: action?.payload,
      };

    case "BLOGS":
      return {
        ...state,
        blogList: action?.payload,
      };
    default:
      return state;
  }
};
