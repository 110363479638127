import React, {useEffect, useState} from "react";
import {Container} from "react-bootstrap";
import {useParams} from "react-router-dom";
import "../../assets/css/MedicalFunding.css";
import OwlCarousel from "react-owl-carousel";
import TestimonialCard from "../../components/TestimonialCard";
import Accordion from "react-bootstrap/Accordion";
import {constantIcons, constantImages} from "../../Constant";
import {getFundraiseByCategorySlug} from "../../Api-Calls/categoryApi";
import testimonialBackground from "../../assets/img/Testimonials.jpg";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FundingForm from "../../components/FundingForm";
import FundraiserBreadCrumb from "../../components/FundraiserBreadCrumb";
import WhatBuildingBridgesOffer from "../../components/staticComponents/WhatBuildingBridgesOffer";
import ActiveFundraiser from "../../components/ActiveFundraiser";
import webBanner from "../../assets/img//staticImages/hunger-free-funding/hunger-free-banner.png";
import mobileBanner from "../../assets/img/staticImages/hunger-free-funding/hunger-free-banner-mobile.png";
import mobileImage from "../../assets/img/staticImages/hunger-free-funding/mobile-image.png";
import HelmetSEO from "../../components/HelmetSEO";
import WhyChooseBuildingBridges from "../../components/WhyChooseBuildingBridges";
import SupportFundraiserFaqQuestion from "../../components/SupportFundraiserFaqQuestion";

export default function HungerFreeFunding() {
  const {slug} = useParams();
  const images = constantImages();
  const icons = constantIcons();
  const [fundraiser, setFundraiser] = useState([]);

  const getFundraisebyCategotyId = async () => {
    try {
      const res = await getFundraiseByCategorySlug(slug);
      if (res?.status == 200) {
        setFundraiser(res?.data?.result?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getFundraisebyCategotyId();
  }, []);

  return (
    <>
      <HelmetSEO
        title="Hunger Free Funding"
        description="Hunger Free Funding"
      />

      <ToastContainer />
      <FundraiserBreadCrumb
        heading="Hunger Free Funding"
        description={
          <p>
            Building Bridges lets users create fundraisers to combat hunger and
            ensure food security for vulnerable communities. Donate meals or
            provide dry ration to underprivileged communities through our
            platform. Join us in making a positive impact on those in need.
          </p>
        }
        webImage={images.hungerFreeSection2}
        mobileImage={mobileImage}
      />

      <ActiveFundraiser fundraiser={fundraiser} slug={slug} />
      <WhyChooseBuildingBridges
        mainHeading="Hunger Free"
        icon1={icons.childChooseIcon1}
        icon2={icons.childChooseIcon2}
        icon3={icons.childChooseIcon3}
        icon4={icons.childChooseIcon4}
        heading1="Food Security and Hunger Eradication"
        heading2="Empowering Financial Aid Seekers"
        heading3="Rapid Response to Emergencies"
        heading4="Building a Supportive Community"
        description1="Building Bridges provides the option to create fundraisers to combat hunger and ensure food security for vulnerable communities by donating meals or providing dry ration."
        description2="Building Bridges empowers the financial aid seekers by providing them with the option of hassle free online fundraising. The collected amount can be withdrawn anytime."
        description3="Building Bridges enables efficient fundraising for day-to-day hunger crisis or for food shortage during natural calamities, helping individuals access cooked meals and dry ration."
        description4="Building Bridges aims to create a supportive community that financially aids the communities battling hunger crisis that stems from various types of local and global instabilities."
      />

      <section className="">
        <FundingForm
          title="Building Bridges for Hunger Free Funding "
          paragraph={`
            Building Bridges allows you to create Hunger Free fundraisers in three easy steps. 
            This process allows donors to donate meals, provide dry ration, support nutritional programs 
            and sustainable farming through our platform. Join us to combat hunger and ensure food security.
            `}
          mobileBanner={mobileBanner}
          webBanner={webBanner}
        />
      </section>
      <WhatBuildingBridgesOffer />

      <section
        className=" testimonialSection py-5 section--gray"
        style={{
          backgroundImage: `url(${testimonialBackground}) `,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
        }}
      >
        <div className="sectionHeading text-center mb-5">
          <h2 className="mb-4 high-title title-background mt-5">
            Success Stories
          </h2>
          <div className="col-md-7 mx-auto divider"></div>
        </div>
        <div>
          <div className="sectionHeading text-center mb-5"></div>
          <div className="container">
            <div className="col-md-9 mx-auto ">
              <OwlCarousel
                items={1}
                margin={30}
                autoplay={true}
                className="owl-theme fundRaisingSection testimonial-background"
              >
                <TestimonialCard image="false" />
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
      <SupportFundraiserFaqQuestion
        heading1="What is Hunger free funding on Building Bridges?"
        heading2="How does hunger alleviation funding work?"
        heading3="Can I choose the specific hunger alleviation project to support?"
        heading4="How do I know my donation is making an impact in alleviating hunger?"
        heading5="Can supporters from around the world contribute to campaigns on Building Bridges?"
        heading6="How can I get involved beyond donating?"
        description1="Building Bridges is an online funding platform which enables
        efficient fundraising for donating meals, providing dry
        ration, supporting nutritional programs and sustainable
        farming. Hunger free funding on Building Bridges is a
        dedicated initiative that enables donors to provide
        financial support to projects and organizations working to
        combat food insecurity and hunger. The platform also gives
        the donors a chance to provide immediate financial support
        to the trending emergency fundraisers. Building Bridges also
        welcomes corporate partnerships and contributions towards
        hunger alleviation projects. Businesses can donate to the
        fundraisers or directly collaborate with the users to make a
        positive impact on communities experiencing food insecurity."
        description2="Building Bridges supports swift disbursement of funds to the
        causes featured on the platform. Using the Building Bridges
        platform, our users create fundraisers that which range from
        providing meals to vulnerable populations to supporting
        sustainable agricultural practices that enhance food
        production and distribution. Later the user shares the
        fundraisers on social media accounts. After the fundraiser
        reaches the potential donors, and the desired fund is
        collected, it can be withdrawn by the user at anytime. It is
        then the user’s responsibility to disburse the collected
        emergency funds as swiftly as possible."
        description3="Yes, Building Bridges allows the donors and supporters to
        browse and choose from a variety of fundraisers that have
        been created by various individuals. As a donor you have the
        flexibility to choose from a selection of hunger alleviation
        projects that align with their values and preferences. You
        can select a project that focuses on a particular region or
        approach to addressing hunger."
        description4="Building Bridges provides regular updates on the progress
        and impact of hunger alleviation projects through success
        stories, testimonials, blogs, newsletters, emails to the
        donors, project reports, and updates on the website and all
        official social media platforms. This way the donors can
        stay informed about how their contributions are making a
        difference."
        description5="Yes, Building Bridges is accessible globally, allowing
        supporters from various locations to create fundraisers and
        contribute to campaigns by either donating or by sharing the
        fundraisers on their personal social media platforms."
        description6="Apart form donating, you can always create a fundraiser for
        supporting any cause that is either close to your heart or
        has been recommended to you by someone you know. You can
        become a part of our fundraising campaigns by sharing the
        fundraisers on your personal social media accounts and
        encourage your friends and loved ones to do the same.
        Volunteering to spread awareness and encourage potential
        donors to support our platform and give special attention to
        the Hunger Free Funding charity page is also an option.
        Building Bridges offers you the opportunity to create a
        fundraiser in the loving memory of your deceased loved one
        as well."
      />
    </>
  );
}
