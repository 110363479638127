import { constantIcons } from "../../../Constant";

export default function TotalFundRaiserCauses({ fundraiser }) {
  const icons = constantIcons();

  return (
    <div className="card">
      <div className="card-body">
        <div className="d-flex align-items-center ">
          <img
            className="cardIcon"
            src={icons.totalfundraising}
            alt="Donation"
          />
          <div className="ms-1">
            <h3 className="cardTitle">
              {fundraiser?.length ? fundraiser?.length : 0}
            </h3>
            <p className="widget-text">Fundraiser Created</p>
          </div>
        </div>
      </div>
    </div>
  );
}
