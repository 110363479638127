import React, { useEffect, useState } from "react";
import { Col, Container } from "react-bootstrap";
import Logo from "../assets/img/logo/PoweredByVariationBlack.svg";
import {
  updateSupporterById,
  checkTransactionStatus,
} from "../Api-Calls/supporter";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../components/loader";
const Thanks = () => {
  const navigate = useNavigate();
  const slug = JSON.parse(localStorage.getItem("fundraiserSlug"));
  const {
    auth: { user },
  } = useSelector((store) => store);
  const [loading, setloading] = useState(true);
  const searchParams = new URLSearchParams(window.location.search);
  const queryParams = {};
  for (const [key, value] of searchParams) {
    queryParams[key] = value;
  }
  console.log("All query parameters:", queryParams);
  console.log("message:", queryParams["data.message"]);
  const modifiedSeqId = queryParams?.merchant_order_id?.slice(0, -3);
  const handleUpdate = () => {
    let details = {
      payment_status: "Verified",
    };
    updateSupporterById(
      modifiedSeqId ? modifiedSeqId : queryParams?.seqId,
      details
    )
      .then((res) => {
        setloading(false);
        console.log(res, "res");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleCheckTransactionStatus = () => {
    checkTransactionStatus(queryParams?.seqId)
      .then((res) => {
        if (res.data.status === "Success" && res.data.result === true) {
          handleUpdate();
        } else {
          setloading(false);
          navigate(`/payment-fundraiser/${slug}`, {
            state: {
              error: res.data.result.message,
              seqId: modifiedSeqId ? modifiedSeqId : queryParams?.seqId,
            },
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (queryParams?.success === "true") {
      console.log("if condition");
      handleUpdate();
    } else if (queryParams?.seqId) {
      console.log("else if condition");
      handleCheckTransactionStatus();
    } else {
      console.log("else condition");
      navigate(`/payment-fundraiser/${slug}`, {
        state: {
          error: queryParams["data.message"],
          seqId: modifiedSeqId ? modifiedSeqId : queryParams?.seqId,
        },
      });
    }
  }, []);

  return (
    <>
      {loading ? (
        <div
          className="section d-flex align-item-center justify-content-center"
          style={{ minHeight: "100vh" }}>
          <Loader />
        </div>
      ) : (
        <section className="section my-5 thanksYouPage">
          <Container>
            <Col lg={6} className="mx-auto">
              <h2 className="text-center  mt-5 text-capitalize">
                Donation Received!
              </h2>
              <div className="mt-5 pt-5">
                <strong>
                  Dear{" "}
                  <span>{user?.username ? user.username : "Anonymous"},</span>
                </strong>
                <p className="my-5 fq-div">
                  We express our gratitude for your recent donation made to
                  Building Bridges. Your contributions are vital to our mission
                  of creating a positive change in the world. Supporters like
                  you inspire us to continue tirelessly, striving to make the
                  world a better place for everyone. Your trust in us reflects
                  the power of collective action, and we are honoured to have
                  you as a part of our community. Each donation brings us closer
                  to our goal of building a compassionate world by helping the
                  helpless. Your donation does not only reflect the financial
                  support you are willing to provide, but it also shows your
                  belief in our cause. We value you and look forward to a
                  lasting association.
                </p>
                <p className="my-5">
                  Thank you for your generosity, <br />
                  Building Bridges Team.
                </p>

                <div className="text-end">
                  <img
                    src={Logo}
                    alt="header-logo"
                    className="navbar__logo"
                    loading="lazy"
                  />
                </div>
              </div>
            </Col>
          </Container>
        </section>
      )}
    </>
  );
};
export default Thanks;
