import React from "react";
import docxImage from "../assets/img/staticIcons/docx.png";
import pdfImage from "../assets/img/staticIcons/pdf.png";
const VideoTab = (props) => {
  const {fundraise} = props;
  return (
    <section className="aboutSectionFundraise mt-5">
      <h4>Video</h4>
      <div className="row mt-5 d-flex align-item-center justify-content-center">
        {fundraise && fundraise?.video?.length>0 ? (
          <>
            <video
              style={{
                width: window.innerWidth <= 600 ? "90%" : "60%",
                borderRadius: "8px",
                padding: 0
              }}
              muted
              controls
              loading="lazy"
            >
              <source style={{borderRadius: "8px",}} src={fundraise?.video[0]?.url} type="video/mp4" />
            </video>
          </>
        ) : (
          <>
            <div className="text-center">
              <div className="col-md-8 mx-auto">
                <p>Fundraiser video not available.</p>
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default VideoTab;
