import React, {useEffect, useState} from "react";
import {Modal, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import FoooterDonation from "../FooterDonation";
import {HashLink} from "react-router-hash-link";
import {useLocation} from "react-router-dom";
import Logo from "../../assets/img/logo/PoweredByVariationWhite.svg";
import {useForm} from "react-hook-form";
import {addSubscription} from "../../Api-Calls/contactUs";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextInput from "../TextInput";
import fbImg from "../../assets/img/social/fb.png";
import instaImg from "../../assets/img/social/instagram.png";
import twitterImg from "../../assets/img/social/twitter1.png";
import linkedinImg from "../../assets/img/social/linkedin.png";
import youtubeImg from "../../assets/img/social/youtube.png";
import gmailImg from "../../assets/img/social/gmail.png";
import whatsapp from "../../assets/img/social/whatsapp.png";
import {
  SwalErrorMessage,
  SwalMessage,
} from "../../components/sweetAlertMessage";

const Footer = () => {
  const location = useLocation();
  const [dashBoardFooter, setDashBoardFooter] = useState(false);
  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const hideFooter = ["/login", "/forgot-password", "/signup"];
  useEffect(() => {
    setDashBoardFooter(location.pathname.split("/").includes("dashboard"));
  }, [location.pathname]);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    control,
    formState: {errors},
  } = useForm();
  const onSubmit = async (data) => {
    setLoader(true);
    try {
      const res = await addSubscription(data);
      if (res?.status == 200) {
        SwalMessage(res?.data?.status, res?.data?.message, "success");
        reset({
          email: "",
        });
        setLoader(false);
      }
    } catch (error) {
      SwalErrorMessage(
        "Oops!" + " " + error?.response?.data?.status,
        error?.response?.data?.message,
        "error",
        true
      );
    }
  };
  return (
    <>
      {dashBoardFooter
        ? ""
        : !hideFooter.includes(location.pathname) && (
            <>
              <section className="section__footer">
                <div className="overlay"></div>
                <div className="container position-relative">
                  <Row>
                    <div className="col-md-9">
                      <Row>
                        <div className="col-md-4">
                          <div className=" footer__section1">
                            <h4 className="footer__heading">Funding</h4>
                            <ul className="footer__list mb-0">
                              <li>
                                <Link to={"/what-are-fundraiser"}>
                                  What are Fundraisers?
                                </Link>
                              </li>
                              <li>
                                <Link smooth to={"/transparancy"}>
                                  Transparency
                                </Link>
                              </li>
                              <li>
                                <HashLink smooth to={"/#donate-to-cause"}>
                                  Support Fundraisers
                                </HashLink>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-md-4">
                          {" "}
                          <div className="footer__section1">
                            <h4 className="footer__heading">
                              Building Bridges
                            </h4>
                            <ul className="footer__list mb-0">
                              <li>
                                <Link to={"/blogs"}>Blogs</Link>
                              </li>
                              <li>
                                <Link to={"/success-stories"}>
                                  Success Stories
                                </Link>
                              </li>
                              <li>
                                <Link to={"/news-letter"}>Newsletter</Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="footer__section1">
                            <h4 className="footer__heading">Support</h4>
                            <ul className="footer__list mb-0">
                              <li>
                                <Link to={"/questions"}>FAQ's</Link>
                              </li>
                              <li>
                                <Link to={"/contact-us"}>Contact Us</Link>
                              </li>
                              <li>
                                <Link to={"/privacy-policy"}>
                                  Privacy Policy
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </Row>
                    </div>
                    <div className="col-md-3 ">
                      <div className="footer__section1  justify-content-center d-grid text-center">
                        <h4 className="footer__heading my-4 my-lg-0  my-md-0">
                          Connect With Us:
                        </h4>
                        <ul className="footer__social__icon d-flex flex-wrap mb-0 mt-3">
                          <li className="social-icons">
                            <a
                              href="https://www.facebook.com/people/Building-Bridges/61551243035166/?mibextid=LQQJ4d"
                              target={"_blank"}
                              rel="noreferrer"
                            >
                              <img src={fbImg} className="social-icon" alt="" loading="lazy" />
                            </a>
                          </li>
                          <li className="social-icons">
                            <a
                              href="https://x.com/buildingb29305?s=11&t=HvDthFTk8WnKzbE2aKuNCw"
                              target={"_blank"}
                              rel="noreferrer"
                            >
                              <img
                                src={twitterImg}
                                className="social-icon"
                                alt=""
                                loading="lazy"
                              />
                            </a>
                          </li>
                          <li className="social-icons">
                            <a
                              href={
                                "https://www.instagram.com/building_bridges_global/"
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src={instaImg}
                                className="social-icon"
                                alt=""
                                loading="lazy"
                              />
                            </a>
                          </li>
                          <li className="social-icons">
                            <a
                              href={
                                "https://www.linkedin.com/company/building-bridges-global/posts/?feedView=all"
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src={linkedinImg}
                                className="social-icon"
                                alt=""
                                loading="lazy"
                              />
                            </a>
                          </li>
                          <li className="social-icons">
                            <a
                              href={
                                "https://www.youtube.com/channel/UCIIxIxzK-rxIx9ibRNeO-7A"
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src={youtubeImg}
                                className="social-icon"
                                alt=""
                                loading="lazy"
                              />
                            </a>
                          </li>
                          <li className="social-icons">
                            <a
                              href={
                                "https://mail.google.com/mail/u/0/?view=cm&fs=1&to=info@buildingbridgesforislam.com"
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src={gmailImg}
                                className="social-icon"
                                alt=""
                                loading="lazy"
                              />
                            </a>
                          </li>
                          <li className="social-icons">
                            <a
                              href="https://wa.me/+9203352716051"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src={whatsapp}
                                className="social-icon"
                                alt=""
                                loading="lazy"
                              />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Row>
                  <Row className=" "></Row>
                  <div
                    className="d-lg-flex  justify-content-between mt-3  text-center"
                    style={{alignItems: "end"}}
                  >
                    <div className="">
                      <HashLink smooth to={"/"}>
                        <img
                          src={Logo}
                          alt="header-logo"
                          className="footer__logo"
                          loading="lazy"
                        />
                      </HashLink>
                    </div>
                     <div className="mt-5 mt-lg-5 my-3 my-lg-0">
                      <ul className="footer__list mb-0">
                        <li
                          className="w-25 p-2 text-center mx-auto "
                          // style={{
                          //   backgroundColor: "#f9cb40",
                          //   borderRadius: "8px",
                          // }}
                        >
                          <Link
                            style={{
                              color: "#f9cb40",
                              fontSize: "22px"
                            }}
                            smooth
                            to={"/beta"}
                          >
                            <strong>Beta</strong>
                          </Link>
                        </li>
                        <li className="mt-3">
                          {" "}
                          © All right reserved 2023{" "}
                          <strong>buildingbridges</strong>{" "}
                        </li>
                      </ul>
                    </div>
                    <div className="footer__section1">
                      <div className=" col-lg-12 mb-lg-0 footer-copyright-color">
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <div className="position-relative d-flex">
                            <TextInput
                              name={"email"}
                              className="form-control px-3 me-2  footer-input"
                              style={{
                                border: errors.email && "1px solid #dc3545",
                                borderRadius: "8px",
                              }}
                              borderError="true"
                              rules={{
                                required: "Email field is required.",
                                pattern: {
                                  value:
                                    /^\s*(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/,
                                  message: "Invalid  email address.",
                                },
                              }}
                              control={control}
                              placeholder="Enter Your Email Address"
                            />
                            {loader ? (
                              <button className="btn__secondry shadow buttonPrimary">
                                <i
                                  className="fa mx-3 fa-circle-o-notch fa-spin"
                                  style={{fontSize: "24px"}}
                                ></i>
                              </button>
                            ) : (
                              <button
                                className="btn__secondry shadow buttonPrimary"
                                style={{whiteSpace: "nowrap"}}
                              >
                                Subscribe
                              </button>
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <Modal
                centered
                className="donatenow__modal"
                show={show}
                onHide={handleClose}
              >
                <FoooterDonation />
              </Modal>
            </>
          )}
      <ToastContainer />
    </>
  );
};

export default Footer;
