import React from "react";
import {Container, Form} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {forgotPassword} from "../Api-Calls/authentication";
import TextInput from "../components/TextInput";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {SwalErrorMessage, SwalMessage} from "../components/sweetAlertMessage";
import {useState} from "react";
import HelmetSEO from "../components/HelmetSEO";
import WebImage from "../assets/img/forgot-password.png";
import MobileImage from "../assets/img/forgot-password-mobile.png";

const ForgotPassword = () => {
  const {
    handleSubmit,
    control,
    formState: {errors},
  } = useForm();
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const onSubmit = async (data) => {
    try {
      let details = {
        email: data.email.toLowerCase(),
      };
      setLoader(true);
      const res = await forgotPassword(details);
      if (res?.status == 200) {
        SwalMessage(res?.data?.status, res.data.message, "success");
        setLoader(false);
      }
    } catch (error) {
      SwalErrorMessage(
        "Oops!" + " " + error?.response?.data?.status,
        error?.response?.data?.message,
        "error",
        true
      );
    }
  };

  return (
    <>
      <HelmetSEO title="Forgot Password" description="Forgot Password" />

      <section className="signUpSection">
        <Container>
          <div className="shadow cardShadow">
            <div className="row">
              <div className="col-lg-6">
                <div
                  className="sideSectionAuthentication position-relative"
                  style={{
                    height: `${window?.innerWidth <= 600 ? "400px" : ""}`,
                    backgroundImage: `url(${
                      window?.innerWidth <= 600 ? MobileImage : WebImage
                    })`,
                  }}
                >
                  <div class="auth-overlay"></div>
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="formAuthentication"
                  style={{
                    paddingRight: "24px",
                    alignItems: `${window?.innerWidth <= 600 ? "start" : ""}`,
                  }}
                >
                  <form className="w-100 p-5" onSubmit={handleSubmit(onSubmit)}>
                    <h2 className="text-center text-uppercase my-5">
                      Forgot Password
                    </h2>
                    <Form.Label className="mb-1">
                      <b>Email Address</b>
                    </Form.Label>
                    <div className="mb-1">
                      <TextInput
                        name={"email"}
                        className="form-control"
                        rules={{
                          required: "Email field is required.",
                          pattern: {
                            value:
                              /^\s*(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/,
                            message: "Invalid email address.",
                          },
                        }}
                        control={control}
                        placeholder="Email address"
                      />
                    </div>

                    <div className="d-flex justify-content-end mt-3">
                      {loader ? (
                        <button className="btn__primary shadow buttonPrimary">
                          <i
                            className="fa mx-3 fa-circle-o-notch fa-spin"
                            style={{fontSize: "24px"}}
                          ></i>
                        </button>
                      ) : (
                        <button className="btn__primary shadow buttonPrimary">
                          submit
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <ToastContainer />
      </section>
    </>
  );
};

export default ForgotPassword;
