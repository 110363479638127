import {Container} from "react-bootstrap";
import BreadCrumb from "../components/BreadCrumb";
import support from "../assets/img/faq-bottom-section.png";
import {Link} from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import bannerBg from "./../assets/img/faq-crowd-funding.png";
import mobileBanner from "./../assets/img/faq-crowd-funding-mobile.png";
import HelmetSEO from "../components/HelmetSEO";
import {HashLink} from "react-router-hash-link";

const Questions = () => {
  return (
    <>
      <HelmetSEO title="FAQ's" description="FAQ's" />

      <BreadCrumb
        heading="FAQ'S"
        webImage={bannerBg}
        mobileImage={mobileBanner}
      />
      <div className="sec-uplift p-5">
        <p className="mb-4 fq-div">
          By addressing these Frequently Asked Questions, Building Bridges aims
          to ensure the security of donations and promote responsible fund
          utilization, ultimately fostering trust and transparency within the
          community.
        </p>
        <Accordion className="border-0" defaultActiveKey="0">
          <Accordion.Item eventKey="0" className="mb-3 border">
            <Accordion.Header>What is Building Bridges?</Accordion.Header>
            <Accordion.Body>
              <p>
                Building Bridges is a digital platform that is dedicated to
                facilitating emergency fundraisers. We connect individuals and
                communities facing catastrophic events, crises, unforeseen
                incidents, or accidents with potential donors who are willing to
                financially support them in difficult times.
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Accordion className="border-0">
          <Accordion.Item eventKey="0" className="mb-3 border">
            <Accordion.Header>
              How can I create fundraisers on Building Bridges?
            </Accordion.Header>
            <Accordion.Body>
              <p>
                To create any fundraiser, you have to sign up on our platform
                and follow simple steps that follow. For your ease, our user
                guide video featured on{" "}
                <HashLink to={"/#how-does-it-work"}>
                  <b style={{color: "#002866"}}>How does it work?</b>
                </HashLink>{" "}
                section of the platform walks you through all the steps. Once
                you have created your fundraiser, you can start raising funds by
                sharing your campaign with your network.
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Accordion className="border-0">
          <Accordion.Item eventKey="0" className="mb-3 border">
            <Accordion.Header>
              Who can create a fundraiser on Building Bridges?
            </Accordion.Header>
            <Accordion.Body>
              <p>
                Anyone can create a fundraiser on Building Bridges, provided it
                is for a legitimate emergency or crisis situation. We have
                guidelines in place to ensure the authenticity of campaigns.
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Accordion className="border-0">
          <Accordion.Item eventKey="0" className="mb-3 border">
            <Accordion.Header>
              Is there a fee to use Building Bridges?
            </Accordion.Header>
            <Accordion.Body>
              <p>
                Building Bridges is a free platform that welcomes everyone to
                make a difference in the world. As the platform is committed to
                helping those in need, we do not charge any fees to set up a
                fundraiser. However, if any user wishes to pay a nominal fee for
                using the platform to cover operational costs they are welcome
                to add their desired amount before making the donations.
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Accordion className="border-0">
          <Accordion.Item eventKey="0" className="mb-3 border">
            <Accordion.Header>
              How do I donate to a fundraiser on Building Bridges?
            </Accordion.Header>
            <Accordion.Body>
              <p>
                Donating on Building Bridges is simple and straightforward. You
                can browse through our eight areas of interests featured in the{" "}
                <HashLink to={"/#donate-to-a-cause"}>
                  <b style={{color: "#002866"}}>Support Fundraisers</b>
                </HashLink>{" "}
                section, choose the cause and the amount you would like to
                contribute, and follow the instructions for making a donation
                securely through our platform. Once your donations are received
                by Building Bridges, you will be notified.
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Accordion className="border-0">
          <Accordion.Item eventKey="0" className="mb-3 border">
            <Accordion.Header>Can we withdraw funds anytime?</Accordion.Header>
            <Accordion.Body>
              <p>
                Yes, the users are allowed to withdraw the amount anytime after
                their fundraiser goes live. Building Bridges however allows
                one-time withdrawal, which means that if the user has withdrawn
                the amount before the completion of the set goal, then their
                fundraiser would close. The user should ideally wait for the
                fundraiser to be completed before withdrawing the amount. In
                case of emergency they are welcome to withdraw the amount
                anytime.
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Accordion className="border-0">
          <Accordion.Item eventKey="0" className="mb-3 border">
            <Accordion.Header>
              What happens if a fundraiser doesn't reach its goal?
            </Accordion.Header>
            <Accordion.Body>
              <p>
                If a campaign does not reach its funding goal, the raised funds
                are still made available to the fundraiser creator to support
                the stated emergency or crisis. Building Bridges ensures that
                campaigns receive funds regardless of whether they meet their
                goal or not. We believe that every contribution can make a
                meaningful impact during challenging times.
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Accordion className="border-0">
          <Accordion.Item eventKey="0" className="mb-3 border">
            <Accordion.Header>
              How can I get in touch with Building Bridges for support?
            </Accordion.Header>
            <Accordion.Body>
              <p>
                If you have any questions, concerns, or need assistance with
                anything related to Building Bridges, you can reach out to our
                dedicated customer support team through the{" "}
                <Link to={"/contact-us"}>
                  <b style={{color: "#002866"}}>contact Us</b>
                </Link>{" "}
                page or the contact information provided on our platform.
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Accordion className="border-0">
          <Accordion.Item eventKey="0" className="mb-3 border">
            <Accordion.Header>
              Can I share fundraisers on social media or with my network?
            </Accordion.Header>
            <Accordion.Body>
              <p>
                Yes, we encourage you to share fundraisers with your friends,
                family, and social networks to help spread the word and increase
                financial support for those in need.
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Accordion className="border-0">
          <Accordion.Item eventKey="0" className="mb-3 border">
            <Accordion.Header>
              Is my personal information secure on Building Bridges?
            </Accordion.Header>
            <Accordion.Body>
              <p>
                We prioritize the security and privacy of your personal
                information. We have measures in place to safeguard your data,
                and we do not share it without your consent. You can review our
                “Privacy Policy” for more details.
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Accordion className="border-0">
          <Accordion.Item eventKey="0" className="mb-3 border">
            <Accordion.Header>
              What types of emergencies or crises can be fundraised for on
              Building Bridges?
            </Accordion.Header>
            <Accordion.Body>
              <p>
                Building Bridges supports fundraisers for a wide range of
                emergencies, including medical expenses, natural disasters,
                accidents, funeral costs, hunger alleviation, animal abuse, etc.
                If you believe your situation qualifies, you can create a
                compelling campaign.
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Accordion className="border-0">
          <Accordion.Item eventKey="0" className="mb-3 border">
            <Accordion.Header>
              Can I edit or update my fundraiser after it's been created?
            </Accordion.Header>
            <Accordion.Body>
              <p>
                Yes, you can make edits or updates to your fundraiser at any
                time. Simply log in to your account and access the campaign's
                settings to make the necessary changes. The Admins however
                monitor changes being made and in order to ensure transparency
                they decide which changes to approve and which to decline. In
                case of suspicious activity being observed, your fundraiser will
                be rejected and your account on the website will be deactivated.
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Accordion className="border-0">
          <Accordion.Item eventKey="0" className="mb-3 border">
            <Accordion.Header>
              How long does it take for funds to reach the campaign
              beneficiaries?
            </Accordion.Header>
            <Accordion.Body>
              <p>It may take 2-3 business days to transfer funds securely.</p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Accordion className="border-0">
          <Accordion.Item eventKey="0" className="mb-3 border">
            <Accordion.Header>
              What resources does Building Bridges provide to help promote
              fundraisers?
            </Accordion.Header>
            <Accordion.Body>
              <p>
                The campaign or fundraiser creators are encouraged to share
                their fundraisers with their network via personal social media
                accounts and handles. In this regard, Building Bridges also
                promotes fundraisers via its social media accounts, newsletters,
                and various other marketing tools.
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Accordion className="border-0">
          <Accordion.Item eventKey="0" className="mb-3 border">
            <Accordion.Header>
              How can I stay updated on the progress of a fundraiser I have
              donated to?
            </Accordion.Header>
            <Accordion.Body>
              <p>
                You can stay updated on a fundraiser's progress by following the
                campaign on the user dashboard, or simply track the fundraiser
                on our platform.
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Accordion className="border-0">
          <Accordion.Item eventKey="0" className="mb-3 border">
            <Accordion.Header>
              What payment methods are adopted by Building Bridges?
            </Accordion.Header>
            <Accordion.Body>
              <p>
                Paymob on Building Bridges accepts a wide range of payment
                methods, including credit cards, debit cards, and secure online
                payment options, ensuring convenience for donors worldwide.
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Accordion className="border-0">
          <Accordion.Item eventKey="0" className="mb-3 border">
            <Accordion.Header>
              Is Paymob on Building Bridges secure for international donors?
            </Accordion.Header>
            <Accordion.Body>
              <p>
                Yes, Paymob ensures a high level of security for international
                donors. It employs robust encryption and security measures to
                protect your financial information.
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Accordion className="border-0">
          <Accordion.Item eventKey="0" className="mb-3 border">
            <Accordion.Header>
              How does Paymob protect my personal and financial data when making
              a donation?
            </Accordion.Header>
            <Accordion.Body>
              <p>
                Paymob employs advanced encryption and security protocols to
                safeguard your personal and financial data during the donation
                process. Your information is kept confidential and secure on the
                Building Bridges Platform as well. For more details, please read
                our “Privacy Policy”.
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Accordion className="border-0">
          <Accordion.Item eventKey="0" className="mb-3 border">
            <Accordion.Header>
              How quickly are donations processed through Paymob on Building
              Bridges?
            </Accordion.Header>
            <Accordion.Body>
              <p>
                Donations made through Paymob are processed between 2-3 business
                days, ensuring that your support reaches the intended campaigns
                in a timely manner.
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <Container>
        <div className="col-md-8 mx-auto mb-5 pt-5">
          <div className="row border stillNeedHelp">
            <div className="col-md-5 p-0">
              <img className="w-100 h-100" src={support} alt="support" />
            </div>
            <div className="col-md-7">
              <div className="p-5">
                <h4>STILL NEED HELP?</h4>
                <p>Our Support Team is here to help you around the clock.</p>
                <Link to="/contact-us">
                  <button className="btn__primary mt-4   buttonPrimary">
                    contact us
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Questions;
