import React, {useState} from "react";
import {Card, Carousel} from "react-bootstrap";
import VideoPreviewModal from "./VideoPreviewModal";

const Slider = ({
  item,
  alt,
  dynamicClass,
  carouselinner1,
  tag,
  amount,
  collectedAmount,
}) => {
  const [index, setIndex] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [mediaURL, setMediaURL] = useState({});
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  const ShowModalPreview = (media) => {
    setModalShow(true);
    setMediaURL(media);
  };
  return (
    <>
      <Carousel
        activeIndex={index}
        className={carouselinner1}
        onSelect={handleSelect}
        interval={null}
        controls={false}
        indicators={false}
      >
        {item?.map((item) => {
          return (
            <Carousel.Item>
              {collectedAmount && (
                <div className=" urgentFundraise ">
                  <span>
                    Fundraised{"  "}Rs.{amount}
                  </span>
                </div>
              )}
              <Card.Img
                variant="top"
                className={
                  dynamicClass ? dynamicClass : "fundRaisingImgBackground"
                }
                src={item?.url}
                alt={alt ? alt : ""}
                loading="lazy"
              />
              {tag && (
                <div className="fund-tag">
                  <span>
                    {tag}
                  </span>
                </div>
              )}
            </Carousel.Item>
          );
        })}
      </Carousel>
      <VideoPreviewModal
        media={mediaURL}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default Slider;
