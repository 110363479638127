import React from "react";
import { Button, Modal } from "react-bootstrap";

const ImagePreviewModal = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        {props?.media && (
          <img style={{ width: "100%", height: "100%" }} src={props?.media} />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ImagePreviewModal;
