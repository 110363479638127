import React from "react";
import {Container} from "react-bootstrap";
import BreadCrumb from "../components/BreadCrumb";

const StartFundraiser = () => {
  return (
    <>
      <BreadCrumb
        heading="Start Fundraiser for Free"
        badge="START FUNDRAISER FOR FREE"
      />

      <section className="section--about">
        <section className="section section--white">
          <Container>
            <div className="text-center">
              <h3>COMMING SOOM</h3>
            </div>
          </Container>
        </section>
      </section>
    </>
  );
};

export default StartFundraiser;
