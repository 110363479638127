import React from "react";
import "react-circular-progressbar/dist/styles.css";
import "../../assets/css/atlantis.css";
import "../../assets/css/demo.css";
import {getUserDonationDetails} from "../../Api-Calls/supporter";
import {useState} from "react";
import {useEffect} from "react";
import {getUserById} from "../../Api-Calls/authentication";
import TotalCollectedDonation from "./widgets/TotalCollectedDonation";
import TotalFundRaiserCauses from "./widgets/TotalFundRaiserCauses";
import TotalDonors from "./widgets/TotalDonors";
import UserDonationTable from "./widgets/UserDonationTable";
import FeedActivity from "./widgets/FeedActivity";
import {getUserFundraise} from "../../Features/fundraisers/fundraiserSlice";
import UserFundraiserTable from "./widgets/UserFundraiserTable";
import DonationAmount from "./widgets/DonationAmount";
import WithdrawAmount from "./widgets/WithdrawAmount";
import {useDispatch, useSelector} from "react-redux";
import {updateUserState} from "../../Features/user/authSlice";
import HelmetSEO from "../../components/HelmetSEO";

export default function DashBoard() {
  const {
    auth: {user},
  } = useSelector((store) => store);
  const dispatchRedux = useDispatch();

  const [fundraiser, setFundraiser] = useState([]);
  const [donationDetail, setDonationDetail] = useState([]);
  const [donationAmount, setDonationAmount] = useState("");
  const [collectedAmount, setCollectedAmount] = useState("");
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [donorLength, setDonorLength] = useState();

  const getSupporterPayments = async () => {
    try {
      const res = await getUserDonationDetails(user?._id);
      if (res?.status == 200) {
        setDonationDetail(res?.data?.result);
        const donationAmount = res?.data?.result.reduce(
          (accumulator, item) => accumulator + item.amount,
          0
        );
        setDonationAmount(donationAmount);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getFundraiseData = async () => {
    try {
      dispatchRedux(getUserFundraise(user?._id)).then((res) => {
        if (res.type === "user/fundraisers/fulfilled") {
          setFundraiser(res?.payload?.result?.result);
          const userCollectedAmount = res?.payload?.result?.result.reduce(
            (accumulator, item) => accumulator + item.collectedAmount,
            0
          );
          setCollectedAmount(userCollectedAmount);
          const userWithdrawAmount = res?.payload?.result?.result.reduce(
            (accumulator, item) => accumulator + item.withdrawAmount,
            0
          );
          setWithdrawAmount(userWithdrawAmount);
          if (res?.payload) {
            let donorLen = 0;
            res?.payload?.result?.result.map((item) => {
              donorLen += item.donations.length;
              setDonorLength(donorLen);
            });
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  const getUser = async () => {
    try {
      const res = await getUserById(user?._id);
      if (res?.status == 200) {
        dispatchRedux(updateUserState(res?.data?.result?.user));
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getUser();
    getSupporterPayments();
    getFundraiseData();
  }, []);
  const data1 = [
    {
      title: "Cause 1",
      value: 15,
      color: "#196f3d",
    },
    {
      title: "Cause 2",
      value: 14,
      color: "#a93226",
    },
    {
      title: "Cause 3",
      value: 2,
      color: " #1f618d",
    },
    {
      title: "Cause 4",
      value: 20,
      color: "#839192",
    },
    {
      title: "Cause 5",
      value: 15,
      color: "#d35400",
    },
    {
      title: "Cause 6",
      value: 12,
      color: " #a9cce3",
    },
    {
      title: "Cause 7",
      value: 5,
      color: "#2e4053",
    },
    {
      title: "Cause 8",
      value: 6,
      color: "#18a6ba",
    },
    {
      title: "Cause 9",
      value: 14,
      color: "#a93246",
    },
    {
      title: "Cause 10",
      value: 9,
      color: "#1f118d",
    },
  ];
  const data = {
    labels: [
      "January",
      "Febaurary",
      "March",
      "April",
      "May",
      "June",
      "July",
      "Augest",
      "September",
      "October",
      "November",
      "December",
    ],
    datasets: [
      {
        label: "Donations Amount PKR",
        data: [20, 12, 19, 3, 5, 2, 3, 4, 3, 5, 6, 7, 3],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(238, 130, 238,0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
          "rgba(238, 130, 238,1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };
  return (
    <>
      <HelmetSEO title="Dashboard" description="Dashboard" />
      <div className="page-inner">
        <div className="dashboardflex mt-n5">
          <div className="d-flex-item">
            <TotalCollectedDonation amount={collectedAmount} />
          </div>
          <div className="d-flex-item">
            <DonationAmount amount={donationAmount} />
          </div>
          <div className="d-flex-item">
            <WithdrawAmount amount={withdrawAmount} />
          </div>
          <div className="d-flex-item">
            <TotalFundRaiserCauses fundraiser={fundraiser} />
          </div>
          <div className="d-flex-item">
            <TotalDonors donorLength={donorLength} />
          </div>
        </div>
        <div className="don-table">
          <UserDonationTable donationDetail={donationDetail} />
        </div>
        <div className="don-table">
          <UserFundraiserTable fundraiser={fundraiser} />
        </div>
        <div className="row">
          <div className="col-md-12">
            <FeedActivity donationDetail={donationDetail} />
          </div>
        </div>
      </div>
    </>
  );
}
