import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Col, Container, Form, Row } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import TextInput from "./TextInput";
import InputMask from "react-input-mask";
import ImgCrop from "antd-img-crop";
import { getCategory } from "../Api-Calls/categoryApi";
import moment from "moment";
import { Upload,Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import ImagePreviewModal from "./ImagePreviewModal";
import { SwalErrorMessage, SwalMessage } from "./sweetAlertMessage";
import { addFundraise } from "../Api-Calls/fundraise";
export default function CompleteFundraiserModal({ setTagModal }) {
  const {
    auth: { user },
  } = useSelector((store) => store);
  const [fileList, setFileList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [mediaURL, setMediaURL] = useState({});
  const [file, setFile] = useState([]);
  const [imageError, setImageError] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [category, setCategory] = useState([]);
  const [titleLength, setTitleLength] = useState(0);
  const [descriptionLength, setDescriptionLength] = useState(0);
  const [descriptioneErrorLength, setDescriptionErrorLength] = useState("");
  const [titleErrorMessage, setTitleErrorMessage] = useState("");
  const [previewImage, setPreviewImage] = useState({});

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({ criteriaMode: "all" });

  const [stateData, setState] = useState({
    phoneNo: user?.phoneNo,
    zipcode: user?.zipcode,
    cnicNo: user?.cnicNo,
    errors: {
      cell: "",
      zipcode: "",
    },
  });
  const handleOnChangeImages = ({ fileList: newFileList }) => {
    setImageError(null);
    setFileList(newFileList);
    setFile({
      files: newFileList,
      fileUrl: URL.createObjectURL(newFileList[0].originFileObj),
    });
  };
  const getCategoryData = async () => {
    try {
      const res = await getCategory();
      if (res?.status == 200) {
        setCategory(res?.data?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleChange = (e) => {
    let { name, value } = e.target;
    if (name === "description") {
      setDescriptionLength(value.length);
    }
    if (name === "title" && value) {
      setTitleLength(value.length);
      if (value.length > 50) {
        setTitleErrorMessage("Title cannot exceed 50 characters");
      }
    }
    if (name === "endDate") {
      setState({ ...stateData, errors: { ...errors, endDate: true } })
    }
  };
  const fieldValidation = (caseStartDate, caseEndDate) => {
    const startDate = moment(caseStartDate);
    const endDate = moment(caseEndDate)
    let check = true;
    if (fileList?.length === 0) {
      check = false
      setImageError("Image is required!");
    }
    if (startDate.isAfter(endDate) || startDate.isSame(endDate)) {
      check = false
      setState({ ...stateData, errors: { ...errors, endDate: false } })
    }
    if (descriptionLength > 1000) {
      check = false
      setDescriptionErrorLength("Message cannot exceed 1000 characters");
    }
    if (titleLength > 50) {
      check = false
      setTitleErrorMessage("Title cannot exceed 50 characters");
    }
    return check;
  }
  const onSubmit = async (data) => {
    const formData = new FormData();
    if (file?.files?.length > 0) {
      Array?.from(file?.files)?.forEach((item, index) => {
        formData?.append("files", item?.originFileObj);
      });
    }
    if (documents?.files?.length > 0) {
      Array?.from(documents?.files)?.forEach((item, index) => {
        formData?.append("documents", item);
      });
    }
    formData?.append("category", data?.category);
    formData?.append("title", data?.title);
    formData?.append("description", data?.description);
    formData?.append("startDate", data?.startDate);
    formData?.append("endDate", data?.endDate);
    formData?.append("status", "Pending");
    formData?.append("totalAmount", data?.totalAmount);
    formData?.append("userId", user?._id);
    if (fieldValidation(data.startDate, data.endDate)) {
      setLoader(true);
      try {
        const res = await addFundraise(formData);
        if (res?.status == 201) {
          SwalMessage(res?.data?.status, res?.data?.message, "success");
          setLoader(false);
          reset({
            title: "",
            category: [],
            endDate: moment().format("YYYY-MM-DD"),
            description: "",
            totalAmount: ""
          });
          setFile();
          setDocuments();
          setFileList([]);
          setTagModal(false)
        } else if (!res?.data?.status == "error") {
          SwalErrorMessage(
            "Oops!" + " " + res?.data?.status,
            res?.data?.message || "Some thing went wrong",
            "error",
            true
          );
          setLoader(false);
        }
      } catch (error) {
        SwalErrorMessage(
          "Oops!" + " " + error?.response?.data?.status,
          error.response.data.message,
          "error",
          true
        );
        setLoader(false);
      }
    } else {
      setLoader(false);
    }
  };
  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  const onPreview = async (file) => {
    if (file.thumbUrl) {
      setPreviewImage({
        src: file.thumbUrl,
        visible: true,
        title: file.name,
      });
    }
  };
  const ShowImageModalPreview = (media) => {
      setModalShow(true);
      setMediaURL(media);
  };

  let documentObj = [];
  let documentArray = [];
  const uploadMultipleDocuments = (e) => {
    documentObj.push(e.target.files);
    for (let i = 0; i < documentObj[0].length; i++) {
      documentArray.push(URL.createObjectURL(documentObj[0][i]));
    }

    let detail = {
      files: e.target.files,
      fileUrl: documentArray,
    };
    setDocuments(detail);
  };
  useEffect(() => {
    getCategoryData();
  }, []);
  return (
    <div className="col-md-9 mx-auto">
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ textAlign: "left" }}
      >
        <Row className="mt-5">
          <Col className="mb-3" lg={6}>
            <Form.Label className="mb-1">
              <b>Title</b> <span className="text-danger">*</span>
            </Form.Label>
            <div className="mb-1">
              <TextInput
                className="form-control"
                placeholder="title"
                control={control}
                {...register("title", {
                  required: "Title is required",
                  onChange: (e) => handleChange(e),
                })}
              />
              {titleLength > 50 && (
                <p className="text-danger  my-2 ms-2 text-start  ">
                  {titleErrorMessage}
                </p>
              )}
            </div>
          </Col>
          <Col className="mb-3" lg={6}>
            <Form.Label className="mb-1">
              <b>SELECT CATEGORY</b>{" "}
              <span className="text-danger">*</span>
            </Form.Label>
            <select
              className="form-control form-select"
              {...register("category", {
                required: "Category is required!",
              })}
            >
              <option
                value=""
              >
                Select Category
              </option>
              {category?.map((category, index) => {
                return (
                  <option
                    value={category._id}
                  >
                    {category?.title}
                  </option>
                );
              })}
            </select>
            <p className="text-danger mx-1 my-2">
              {errors?.category?.message}
            </p>
          </Col>

          <Col className="mb-3" lg={6}>
            <Form.Label className="mb-1 d-flex">
              <b>START DATE</b>
            </Form.Label>
            <div className="mb-1">
              <TextInput
                className="form-control"
                id="datepicker"
                name="startDate"
                defaultValue={moment().format("YYYY-MM-DD")}
                placeholder="last date"
                min={moment().format("YYYY-MM-DD")}
                type="date"
                control={control}
              />
            </div>
          </Col>
          <Col className="mb-3" lg={6}>
            <Form.Label className="mb-1 d-flex">
              <b>END DATE</b>
              <span className="text-danger">*</span>
            </Form.Label>
            <div className="mb-1">
              <TextInput
                className="form-control"
                type="date"
                id="datepicker"
                placeholder="end date"
                control={control}
                defaultValue={moment().format("YYYY-MM-DD")}
                min={moment().format("YYYY-MM-DD")}
                {...register("endDate", {
                  required: "End date should not be equal or less than start date!",
                })}
              />
              {stateData?.errors?.endDate === false && (
                <p className="text-danger  my-2 ms-2 ">
                  End date should not be equal or less than start date!
                </p>
              )}
            </div>
          </Col>

          <Col className="mb-3" lg={6}>
            <Form.Label className="mb-1">
              <b>TOTAL AMOUNT</b>
              <span className="text-danger">*</span>
            </Form.Label>
            <div className="mb-1">
              <InputMask
                autoComplete="off"
                mask="9999999"
                {...register("totalAmount", {
                  required: "Total Amount is required!",
                })}
                maskChar={null}
                inputMode="numeric"
                placeholder="Enter Amount"
                className="form-control dir-ltr customInputLogin"
              />
              <p className="text-danger  my-2 ms-2 ">
                {errors?.totalAmount?.message}
              </p>
            </div>
          </Col>
          <Col className="mb-3" lg={6}>
            <Form.Label className="mb-1 d-flex">
              <b>STATUS</b>
            </Form.Label>
            <div className="mb-1">
              <TextInput
                type="text"
                name="status"
                className="form-control"
                disabled
                defaultValue={"Pending"}
                placeholder="status"
                control={control}
              />
            </div>
          </Col>
          <Col className="mb-3" lg={12}>
            <Form.Label className="mb-1 d-flex">
              <b>DESCRIPTION</b>
              <span className="text-danger">*</span>
            </Form.Label>
            <div className="mb-1">
              <textarea
                placeholder="Type Your Description"
                className="form-control"
                style={{
                  resize: "none",
                  height: "150px",
                }}
                {...register("description", {
                  required: "Description is required",
                  onChange: (e) => handleChange(e),
                })}
              ></textarea>
              <p className="text-danger mx-1 my-2">
                {errors?.description?.message}
              </p>
              {descriptionLength > 1000 && (
                <p className="text-danger mx-1 my-2">
                  {descriptioneErrorLength
                    ? descriptioneErrorLength
                    : "Message cannot exceed 1000 characters"}
                </p>
              )}
              {descriptionLength > 1000 ? (
                <p className="text-danger mx-1 my-2">
                  Message limit {descriptionLength} to 1000.
                </p>
              ) : (
                <p className="text-dark mx-1 my-2">
                  Message limit {descriptionLength} to 1000.
                </p>
              )}
            </div>
          </Col>
          <Col className="mb-3  col-12 ">
            <Form.Label className="mb-1 ms-2 d-flex">
              <b>ADD IMAGE</b>
              <span className="text-danger">*</span>
            </Form.Label>
            <div className="fund-img mb-3">
              {" "}
              <ImgCrop
                aspect={1 / 1.2}
                showGrid
                rotationSlider
                id="profileUploadFile"
                showReset
              >
                <Upload
                  action=""
                  listType="picture-card"
                  onPreview={onPreview}
                  fileList={fileList}
                  onChange={handleOnChangeImages}
                >
                  {fileList?.length < 1 && "+ Upload"}
                </Upload>
              </ImgCrop>
              <Modal
                open={previewImage.visible}
                title={previewImage.title}
                footer={null}
                onCancel={() =>
                  setPreviewImage({
                    ...previewImage,
                    visible: false,
                  })
                }
              >
                <img
                  alt="example"
                  style={{ width: "100%" }}
                  src={previewImage.src}
                />
              </Modal>
              <p className="text-danger  my-2 ms-2 text-start  ">
                {imageError}
              </p>
            </div>
          </Col>
          <Col className="col-12">
            <div className="mb-4">
              <Form.Label className="mb-1 d-flex">
                <b>ADD DOCUMENTS</b>
              </Form.Label>
              <input
                type="file"
                multiple
                id="uploadDocument"
                accept="image/png, image/jpeg, image/jpg, pdf , docx"
                className="form-control"
                name="documents"
                placeholder="Add Documents"
                onChange={uploadMultipleDocuments}
              />
            </div>
            {documents?.fileUrl && (
              <>
                <div className="d-flex mb-4 flex-wrap">
                  {documents?.fileUrl?.map((fileUrl, index) => {
                    const fileName =
                      documents?.files[index]?.name;
                    const fileExtension = fileName
                      .split(".")
                      .pop();
                    let imageSrc = fileUrl;
                    return (
                      <div key={index}>
                        <img
                          style={{
                            width: "150px",
                            height: "120px",
                            marginRight: "10px",
                            objectFit: "cover",
                            borderRadius: "12px",
                            marginBottom: "10px",
                            cursor: "pointer",
                          }}
                          onClick={(e) =>
                            ShowImageModalPreview(fileUrl)
                          }
                          src={imageSrc}
                          alt="documents"
                        />
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </Col>
          <div className="text-center my-4">
            {loader ? (
              <button className="btn__primary mx-auto shadow buttonPrimary">
                <i
                  className="fa mx-3 fa-circle-o-notch fa-spin"
                  style={{ fontSize: "24px" }}
                ></i>
              </button>
            ) : (
              <button
                className="btn__primary mx-auto shadow buttonPrimary"
                type="submit"
              >
                Add Case
              </button>
            )}
          </div>
        </Row>
      </form>
      <ImagePreviewModal
        media={mediaURL}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </div>
  );
}
