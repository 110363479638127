import axios from "axios";

export const addComment = async (slug, payload) => {
  const token = JSON.parse(localStorage.getItem("userToken"));
  return await axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/fundraiser-comment/add-comment/${slug}`,
    payload,
    {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const getComment = async (slug) => {
  return await axios.get(
    `${process.env.REACT_APP_BACKEND_URL}/fundraiser-comment/get-comment-by-fundraiser-slug/${slug}`
  );
};
