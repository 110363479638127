import React, { useEffect, useRef } from "react";
import {Container} from "react-bootstrap";

const FundraiserBreadCrumb = (props) => {
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Load the image when it becomes visible
            sectionRef.current.style.backgroundImage = `url(${window.innerWidth <= 600 ? props?.mobileImage : props?.webImage})`;
            observer.unobserve(sectionRef.current);
          }
        });
      },
      { threshold: 0.5 } // Adjust the threshold as needed
    );

    observer.observe(sectionRef.current);

    return () => {
      observer.disconnect();
    };
  }, [props?.webImage, props?.mobileImage]);
  return (
    <section
      className="section donation--bg"
      ref={sectionRef}
      style={{
        marginTop: "63px",
        // backgroundImage: `url(${
        //   window?.innerWidth <= 600 ? props?.mobileImage : props?.webImage
        // })`,
      }}
    >
      <div
        class="overlay"
        style={{backgroundColor: "rgba(0, 40, 102, 0.3)"}}
      ></div>
      <Container className="h-100">
        <div className="text-center  justify-content-center align-items-center h-100">
          <h2 className="banner-heading mt-lg-2 ">{props.heading}</h2>
          <p className="banner-desc mt-xl-5 mt-md-4">{props.description}</p>
        </div>
        <span></span>
      </Container>
    </section>
  );
};

export default FundraiserBreadCrumb;
