import axios from "axios";

export const addContact = async (payload) => {
  return await axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/contact-us/add-contact`,
    payload
  );
};
export const addVolunteer = async (payload) => {
  return await axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/volunteer/add-volunteer`,
    payload
  );
};

export const updateSubscribers = async (data) => {
  return await axios.get(
    `${process.env.REACT_APP_BACKEND_URL}/un-subscribe/${data}`
    );
  };
  
export const sendReview = async (id,payload) => {
  const token = JSON.parse(localStorage.getItem("userToken"));
    return await axios.patch(
      `${process.env.REACT_APP_BACKEND_URL}/supporter/send-review/${id}`,
      payload,
      {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

export const addSubscription = async (payload) => {
  return await axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/add-subscriber`,
    payload
  );
};