import React from 'react'
import { Link } from 'react-router-dom'

const NotFound = () => {
  return (
    <div className="page-not-found">
      <div className="wrapper not-found" >
        <h1 className="animated fadeIn" style={{color: "white"}}>404</h1>
        <div className="desc animated fadeIn" style={{color: "white"}}><span>OOPS!</span><br />Looks like you get lost</div>
        <Link to={'/'} className="py-3 btn__secondary buttonPrimary start-fundraise-btn mt-4 animated fadeInUp">
          <span className="btn-label mr-2">
            <i className="flaticon-home"></i>
          </span>
          Back To Home Page
        </Link>
      </div>
    </div>
  )
}

export default NotFound