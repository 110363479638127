import axios from 'axios'

const BASE_URL = process.env.REACT_APP_BACKEND_URL
const token = JSON.parse(localStorage.getItem("userToken"));

export default axios.create({
    baseURL: BASE_URL
})

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
    },
})
