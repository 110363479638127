import OwlCarousel from "react-owl-carousel";
import VideoPreviewModal from "./VideoPreviewModal";
import {useState} from "react";
import Card from "react-bootstrap/Card";
import cuteIndianChild from "../assets/img/gallery/cute-indian-child-doing-his-homework.png";
import givingCannedFood from "../assets/img/gallery/giving-canned-food-refugee.png";
import hungryChild from "../assets/img/gallery/hungry-children-being-fed-by-charity.png";
import parendHand from "../assets/img/gallery/parent-hand-holding-piggy-bank.png";
import patiendgivesBlood from "../assets/img/gallery/patient-gives-blood-analysis-medical.png";
import volunteerTeenGril from "../assets/img/gallery/volunteer-teengirl-preparing-donation-boxes.png";

const gallery = [
  {
    url: cuteIndianChild,
    type: "image",
  },
  {
    url: hungryChild,
    type: "image",
  },
  {
    url: givingCannedFood,
    type: "image",
  },
  {
    url: parendHand,
    type: "image",
  },
  {
    url: patiendgivesBlood,
    type: "image",
  },
  ,
  {
    url: volunteerTeenGril,
    type: "image",
  },
];

const responsiveOptions = {
  0: {
    items: 1, // Number of items to display at 0px and above
  },
  768: {
    items: 3, // Number of items to display at 768px and above
  },
  992: {
    items: 5, // Number of items to display at 992px and above
  },
};
export default function Gallery({dynamicClass}) {
  const [modalShow, setModalShow] = useState(false);
  const [mediaURL, setMediaURL] = useState({});

  return (
    <>
      <section className="py-5 my-2 section--gray">
        <div className="container py-3">
          <div className="sectionHeading text-center mb-5 mt-5 mt-md-0">
            <h2>A Visual Journey</h2>
          </div>
          <OwlCarousel
            margin={10}
            responsive={responsiveOptions}
            items={5}
            loop
            autoplay={true}
            className="owl-theme fundRaisingSection  gallery"
          >
            {gallery?.map((item) => {
              return (
                <Card className="blog__card">
                  <Card.Img
                    style={{cursor: "pointer"}}
                    variant="top"
                    className={`${
                      dynamicClass ? dynamicClass : "fundRaisingImgBackground "
                    } blog__image`}
                    src={item?.url}
                    loading="lazy"
                  />
                </Card>
              );
            })}
          </OwlCarousel>
        </div>
      </section>
      <VideoPreviewModal
        media={mediaURL}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}
