import React, {useEffect, useState} from "react";
import {Container} from "react-bootstrap";
import testimonialBackground from "../../assets/img/Testimonials.jpg";
import {useParams} from "react-router-dom";
import "../../assets/css/MedicalFunding.css";
import OwlCarousel from "react-owl-carousel";
import TestimonialCard from "../../components/TestimonialCard";
import Accordion from "react-bootstrap/Accordion";
import {constantIcons, constantImages} from "../../Constant";
import {getFundraiseByCategorySlug} from "../../Api-Calls/categoryApi";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FundingForm from "../../components/FundingForm";
import FundraiserBreadCrumb from "../../components/FundraiserBreadCrumb";
import WhatBuildingBridgesOffer from "../../components/staticComponents/WhatBuildingBridgesOffer";
import ActiveFundraiser from "../../components/ActiveFundraiser";
import webBanner from "../../assets/img/staticImages/medical-crowdfunding-images/medical-banner.png";
import mobileBanner from "../../assets/img/staticImages/medical-crowdfunding-images/medical-banner-mobile.png";
import mobileImage from "../../assets/img/staticImages/medical-crowdfunding-images/mobile-image.png";
import HelmetSEO from "../../components/HelmetSEO";
import WhyChooseBuildingBridges from "../../components/WhyChooseBuildingBridges";
import SupportFundraiserFaqQuestion from "../../components/SupportFundraiserFaqQuestion";

const MedicalFunding = (params) => {
  const {slug} = useParams();
  const images = constantImages();
  const icons = constantIcons();
  const [fundraiser, setFundraiser] = useState([]);
  const getFundraisebyCategotyId = async () => {
    try {
      const res = await getFundraiseByCategorySlug(slug);
      if (res?.status == 200) {
        setFundraiser(res?.data?.result?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getFundraisebyCategotyId();
  }, []);

  return (
    <>
      <HelmetSEO title="Medical Funding" description="Medical Funding" />
      <ToastContainer />
      <FundraiserBreadCrumb
        heading="Medical Funding"
        description={
          <p>
            Building bridges is an online fundraising platform that aims to
            allow its users to create fundraisers for a wide range of medical
            and surgical treatments, ensuring that every underprivileged
            individual receives the best healthcare available.
          </p>
        }
        webImage={images.medicalSection1}
        mobileImage={mobileImage}
      />

      <ActiveFundraiser fundraiser={fundraiser} slug={slug} />
      <WhyChooseBuildingBridges
        mainHeading="Medical"
        icon1={icons.medicalChoosseIcon1}
        icon2={icons.medicalChoosseIcon2}
        icon3={icons.medicalChoosseIcon3}
        icon4={icons.medicalChoosseIcon4}
        heading1="Support for Expensive Treatments"
        heading2="Empowering Financial Aid Seekers"
        heading3="Rapid Response to Emergencies"
        heading4="Building a Supportive Community"
        description1="Building Bridges provides support to the underprivileged communities by giving everyone the option to create fundraisers for medical treatments that are beyond their affordability."
        description2="Building Bridges empowers the financial aid seekers by providing them with the option of hassle free online fundraising. The collected amount can be withdrawn anytime."
        description3="Building Bridges enables efficient fundraising for medical emergencies in a short period of time, helping the individuals access critical medical support in times of urgent need."
        description4="Building Bridges aims to create a supportive community that financially aids the underprivileged communities during medical emergencies, offering assistance at a critical point in time."
      />

      <section className="">
        <FundingForm
          title="Building Bridges for Medical Funding "
          paragraph={`
            Building Bridges allows you to create medical fundraisers in three easy steps. 
            This process allows compassionate donors to connect with the underprivileged 
            communities, providing vital financial support for their critical healthcare needs. 
            `}
          mobileBanner={mobileBanner}
          webBanner={webBanner}
        />
      </section>

      <WhatBuildingBridgesOffer />

      <section
        className=" testimonialSection pb-5 section--gray"
        style={{
          backgroundImage: `url(${testimonialBackground}) `,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
        }}
      >
        <div className="sectionHeading text-center mb-5">
          <h2 className="mb-4 high-title title-background mt-5">
            Success Stories
          </h2>
          <div className="col-md-7 mx-auto divider"></div>
        </div>
        <div>
          <div className="sectionHeading text-center mb-5"></div>
          <div className="container">
            <div className="col-md-9 mx-auto ">
              <OwlCarousel
                items={1}
                margin={30}
                autoplay={true}
                className="owl-theme fundRaisingSection testimonial-background"
              >
                <TestimonialCard image="false" />
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
      <SupportFundraiserFaqQuestion
        heading1="What is the purpose of medical funding?"
        heading2="Who will benefit from the medical fundraisers?"
        heading3="How will my donation be used?"
        heading4="Are there any processing or administrative fees deducted from donations?"
        heading5="Can I choose which medical cause or project my donation goes to?"
        heading6="How can I get involved beyond donating?"
        description1="Building Bridges aims to provide users the platform for
        creating fundraisers for such patients who are in dire need
        of medical support but are unable to access quality
        healthcare in due time because of financial constraints."
        description2="Building bridges is an online fundraising platform that
        provides the users to create fundraisers for a wide range of
        medical and surgical treatments, ensuring that every
        underprivileged individual receives the best and timely
        healthcare support. Building Bridges aims to operate by
        transcending, racial, social, political, economic,
        religious, and other divides."
        description3="All the funds collected for a particular fundraiser can be
        withdrawn by the user at anytime. The collected amount is
        deposited in the user’s bank account."
        description4="Building Bridges does not deduct administrative fees,
        processing costs, or any other hidden charges associated
        with the donations. The collected amount against each
        fundraisers is deposited in the user’s account for the
        created fundraisers."
        description5="Yes, there are a number of trending fundraisers in the
        Medical Funding section from which the donors can choose to
        assist any case that resonates with them."
        description6="Apart form donating, you can always create a fundraiser for
        supporting any cause that is either close to your heart or
        has been recommended to you by someone you know. You can
        become a part of our fundraising campaigns by sharing the
        fundraisers on your personal social media accounts and
        encourage your friends and loved ones to do the same.
        Volunteering to spread awareness and encourage potential
        donors to support our platform and give special attention to
        the medical funding charity page is also an option. Building
        Bridged offers you the opportunity to create a fundraiser in
        the name of your deceased loved ones too."
      />
    </>
  );
};

export default MedicalFunding;
