import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios, {axiosPrivate} from "../../Api-Calls/axios";
import ColumnGroup from "antd/es/table/ColumnGroup";

// initial state of the slice
const initialState = {
  fundraiseList: null,
  fundLoading: false,
  userFundraiser: null,
  fundraiserCategories: null,
};

// all fundraiser
export const getAllFundraisers = createAsyncThunk(
  "all/fundraisers",
  async (param, thunkAPI) => {
    try {
      let url = "/fundraiser/get-all-fundraiser";
      if (param && param.page && param.limit) {
        url = `/fundraiser/get-all-fundraiser?page=${param.page}&limit=${param.limit}`;
      }
      const res = await axios.get(url, {});
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
// get all zakat fundraiser
export const getAllZakatFundraisers = createAsyncThunk(
  "all/fundraisers",
  async (param, thunkAPI) => {
    try {
      let url = "/fundraiser/get-all-zakat-fundraiser";
      if (param && param.page && param.limit) {
        url = `/fundraiser/get-all-zakat-fundraiser?page=${param.page}&limit=${param.limit}`;
      }
      const res = await axios.get(url, {});
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// Get user fundraiser
export const getUserFundraise = createAsyncThunk(
  "user/fundraisers",
  async (id, thunkAPI) => {
    try {
      const token = JSON.parse(localStorage.getItem("userToken"));
      const res = await axios.get(`/user/fundraiser/${id}`, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// get call fundraisers categories
export const getAllCategories = createAsyncThunk(
  "all/categories",
  async (param, thunkAPI) => {
    try {
      const res = await axios.get(`/category/get-categories`);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const fundraiserSlice = createSlice({
  name: "fundraisers",
  initialState,
  reducers: {
    emptyUserFundraiser: (state) => {
      state.userFundraiser = null;
    },
  },
  extraReducers: {
    // Get ALl fundraisers
    [getAllFundraisers.pending]: (state) => {
      state.fundLoading = true;
    },
    [getAllFundraisers.fulfilled]: (state, {payload}) => {
      state.fundLoading = false;
      state.fundraiseList = payload.result;
    },
    [getAllFundraisers.rejected]: (state, {payload}) => {
      state.fundLoading = false;
    },

    // get user fundraiser

    [getUserFundraise.pending]: (state) => {
      state.fundLoading = true;
    },
    [getUserFundraise.fulfilled]: (state, {payload}) => {
      state.fundLoading = false;
      state.userFundraiser = payload.result;
    },
    [getUserFundraise.rejected]: (state, {payload}) => {
      state.fundLoading = false;
    },

    // get fundraiser categories
    [getAllCategories.pending]: (state) => {
      state.fundLoading = true;
    },
    [getAllCategories.fulfilled]: (state, {payload}) => {
      state.fundLoading = false;
      state.fundraiserCategories = payload.result;
    },
    [getAllCategories.rejected]: (state, {payload}) => {
      state.fundLoading = false;
    },
  },
});

export default fundraiserSlice.reducer;
export const {emptyUserFundraiser} = fundraiserSlice.actions;
