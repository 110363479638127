import React from "react";
import { constantIcons } from "../../../Constant";

export default function TotalDonors({ donorLength }) {
  const icons = constantIcons();
  return (
    <div className="card">
      <div className="card-body">
        <div className="d-flex align-items-center ">
          <img className="cardIcon" src={icons.totalDonors} alt="Donation" />
          <div className="ms-1">
            <h3 className="cardTitle">{donorLength ? donorLength : 0}</h3>
            <p className="widget-text">Donors</p>
          </div>
        </div>
      </div>
    </div>
  );
}
