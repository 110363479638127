import React, {useEffect, useState} from "react";
import {getAllCategory} from "../Api-Calls/categoryApi";
import FundraiserCard from "../components/FundraiserCard";
import bannerBg from "./../assets/img/browse-fundraise.png";
import Loader from "../components/loader";
import BreadCrumb from "../components/BreadCrumb";
import {useParams} from "react-router-dom";
import Gallery from "../components/Gallery";
import {useDispatch, useSelector} from "react-redux";
import ReactPaginate from "react-paginate";
import {getAllFundraisers} from "../Features/fundraisers/fundraiserSlice";
import HelmetSEO from "../components/HelmetSEO";
import NoResultFound from "../components/NoResultFound";

const Fundraisers = () => {
  const {id} = useParams();
  const {
    auth: {user},
    fundraiser: {fundraiseList, fundraiserCategories},
  } = useSelector((store) => store);
  const dispatchRedux = useDispatch();
  const [loading, setLoading] = useState(true);
  const [fundraise, setFundraise] = useState([]);

  const getFundraiseData = async () => {
    try {
      const params = {
        page: 1,
        limit: 20,
      };
      setLoading(true);
      dispatchRedux(getAllFundraisers(params)).then((res) => {
        if (res.type === "all/fundraisers/fulfilled") {
          setLoading(false);
          setFundraise(fundraiseList);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getFundraiseData();
  }, []);
 
  const handlePageClick = (event) => {
    setLoading(true);
    const params = {
      page: event.selected + 1,
      limit: 20,
    };
    dispatchRedux(getAllFundraisers(params)).then((res) => {
      if (res.type === "all/fundraisers/fulfilled") {
        setLoading(false);
        setFundraise(fundraiseList);
      }
    });
  };
  return (
    <>
      <HelmetSEO title="Fundraisers" description="Fundraisers" />

      <BreadCrumb
        heading="FUNDRAISERS"
        badge="HOW DOES IT WORK"
        webImage={bannerBg}
        mobileImage={bannerBg}
      />
      <section className="container py-5">
        <div className=" ">
          {loading ? (
            <>
              <Loader />{" "}
            </>
          ) : (
            <>
              <div className="filterMainContent">
                <div className="fundraiser-flx  ">
                  {fundraiseList?.result &&
                  fundraiseList?.result?.length > 0 ? (
                    <>
                      {fundraiseList?.result?.map((fundraise, index) => {
                        return (
                          <div className="">
                            <FundraiserCard fundraise={fundraise} />
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <NoResultFound />
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </section>
      <ReactPaginate
        previousLabel="previous"
        breakLabel="..."
        nextLabel="next"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={fundraiseList?.totalPages}
        renderOnZeroPageCount={null}
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination justify-content-center"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        activeClassName="active"
        marginPagesDisplayed={2}
      />
      <Gallery />
    </>
  );
};

export default Fundraisers;
