import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import BreadCrumb from "../components/BreadCrumb";
import email from "../assets/img/email.png";
import phone from "../assets/img/phone.png";
import banner from "../assets/img/contactBanner.png";
import { useForm } from "react-hook-form";
import TextInput from "../components/TextInput";
import { addContact } from "../Api-Calls/contactUs";
import { SwalMessage } from "../components/sweetAlertMessage";
import Select from "react-select";
import { countries } from "../data/country";
import InputMask from "react-input-mask";
import HelmetSEO from "../components/HelmetSEO";

const Contact = () => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    setFocus,
    formState: { errors },
  } = useForm();
  const [messageLength, setMessageLength] = useState(0);
  const [loader, setLoader] = useState(false);
  const [maskNo, setMaskNo] = useState("");
  const [errorMessageLength, setErrorMessageLength] = useState("");
  const [subjectLength, setSubjectLength] = useState("");
  const [subjectErrorMessage, setSubjectErrorMessage] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [countryState, setCountryState] = useState({
    country: "",
    countryCode: "",
  });
  const [stateData, setState] = useState({
    phoneNo: "",
    zipcode: "",
    errors: {
      cell: "",
      zipcode: "",
      country: "",
    },
  });
  console.log(errors);
  useEffect(() => {
    if (window.innerWidth <= 600) {
      const fieldsWithErrors = Object.keys(errors).filter(
        (field) => !!errors[field]
      );
      if (fieldsWithErrors.length > 0) {
        setFocus(fieldsWithErrors[0]);
        document.documentElement.scrollTo({
          top: window.innerWidth <= 600 ? 1400 : 0,
          left: 0,
          behavior: "smooth",
        });
      }
    }
  }, [
    errors.fullName,
    errors.email,
    errors.phoneNo,
    errors.subject,
    errors.message,
    setFocus,
  ]);

  const handleChange = (e) => {
    let { name, value } = e.target;
    if (name === "message") {
      setMessageLength(value.length);
    }
    if (name === "subject") {
      setSubjectLength(value.length);
    }
  };
  const onSubmit = async (data) => {
    try {
      if (window.innerWidth <= 600) {
        if (countryState.country === undefined || countryState.country === "") {
          document.documentElement.scrollTo({
            top: window.innerWidth <= 600 ? 1400 : 0,
            left: 0,
            behavior: "smooth",
          });
        }
      }
      if (messageLength > 500) {
        setErrorMessageLength("Message cannot exceed 500 characters");
      } else if (subjectLength > 30) {
        setSubjectErrorMessage("Subject cannot exceed 30 characters");
      } else {
        let detail = {
          ...data,
          phoneNo: `${countryState.countryCode} ${data.phoneNo}`,
          country: countryState?.country.trim(),
        };
        if (countryState.country === "") {
          setState({ ...stateData, errors: { ...errors, country: false } });
          return;
        }
        setLoader(true);
        const res = await addContact(detail);
        if (res?.status == 201) {
          SwalMessage(res?.data?.status, res?.data?.message, "success");
          setSelectedCountry(null);
          reset({
            fullName: "",
            email: "",
            phoneNo: "",
            country: "",
            subject: "",
            message: "",
          });
          setLoader(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const onCountryChange = (e) => {
    setCountryState({ country: `${e.label} `, countryCode: `+${e.code}` });
    setMaskNo(e.mask);
    setSelectedCountry(e);
  };
  const customStyles = {
    option: (base, state) => ({
      ...base,
      color: "#1e2022",
      backgroundColor: "white",
      padding: ".5rem 3rem .5rem .5rem",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "rgba(189,197,209,.3)", // Change this to your desired border color on hover
      },
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
    control: (baseStyles, stateData) => ({
      ...baseStyles,
      paddingLeft: "10px",
      paddingRight: "10px",
      paddingTop: "8px",
      paddingBottom: "8px",
      backgroundColor: "#fff",
    }),
  };
  return (
    <>
      <HelmetSEO title="Contact us" description="Conatctus" />
      <BreadCrumb
        heading="CONTACT US"
        badge="CONTACT US"
        webImage={banner}
        mobileImage={banner}
        overlay={false}
      />
      <section className="section  section__contact fq-div">
        <Container>
          <Row>
            <Col xl="6">
              <h3>Let us know what’s on your mind</h3>
              <p>
                Thank you for your interest in joining our mission to make a
                difference in the world. Whether you are a potential donor, a
                fundraiser creator, someone looking to collaborate, or you have
                any queries, we are eager to hear from you. Your support and
                suggestions help us continue our work in our areas of interest.
              </p>
              <p>
                Please reach out to Building Bridges’ team using the contact
                information provided below, and let’s build bridges of hope
                together by helping the helpless.
              </p>

              <div className="d-flex align-items-center mb-4 mt-5 section__explore">
                <a
                  href={
                    "https://mail.google.com/mail/u/0/?view=cm&fs=1&to=info@buildingbridgesforislam.com"
                  }
                  target="_blank"
                  rel="noreferrer">
                  <div className="volunteer__section">
                    <img src={email} alt="" loading="lazy" />
                  </div>
                </a>
                <div className="voluntyeer__description">
                  <div className="d-flex align-items-center justify-content-between">
                    <h6
                      className="mb-1"
                      style={{
                        fontSize: "19px",
                        color: "white",
                        fontWeight: "800",
                      }}>
                      <a
                        href={
                          "https://mail.google.com/mail/u/0/?view=cm&fs=1&to=info@buildingbridgesforislam.com"
                        }
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: "white" }}>
                        Send Email:{" "}
                      </a>
                    </h6>
                  </div>
                  <p className="mb-0">
                    <a
                      href={
                        "https://mail.google.com/mail/u/0/?view=cm&fs=1&to=info@buildingbridgesforislam.com"
                      }
                      target="_blank"
                      rel="noreferrer"
                      style={{ color: "white" }}>
                      buildingbridgeinfo@gmail.com
                    </a>
                  </p>
                </div>
              </div>

              <div className="d-flex align-items-center mb-4 ">
                <a
                  href="https://wa.me/+9203352716051"
                  target="_blank"
                  rel="noreferrer">
                  <div className="volunteer__section">
                    <img src={phone} alt="" loading="lazy" />
                  </div>
                </a>
                <div className="voluntyeer__description">
                  <div className="d-flex align-items-center justify-content-between">
                    <h6
                      className="mb-1"
                      style={{
                        fontSize: "19px",
                        color: "white",
                        fontWeight: "800",
                      }}>
                      <a
                        href="https://wa.me/+9203352716051"
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: "white" }}>
                        Contact or WhatsApp:{" "}
                      </a>
                    </h6>
                  </div>
                  <p className="mb-0">
                    <a
                      href="https://wa.me/+9203352716051"
                      target="_blank"
                      rel="noreferrer"
                      style={{ color: "white" }}>
                      +92 03352716051
                    </a>
                  </p>
                </div>
              </div>
            </Col>
            <Col xl="6">
              <iframe
                className="w-100 h-100 min__height--mobile"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d77003.2092110113!2d74.42376539253218!3d31.46662193769449!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391909eb51419d33%3A0x2bcaa24bc46b86b!2sTechMateTech%20LLC%20Pakistan!5e0!3m2!1sen!2s!4v1662622420713!5m2!1sen!2s"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"></iframe>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="pt-0 section section__form--contact">
        <Container className="section--gray section">
          <div>
            <Col xl="8" className="text-center mx-auto px-3 px-xl-0">
              <h2 className="mb-4">Send us a Direct Message</h2>
            </Col>
            <Col xl="8" className="mx-auto">
              <Form
                className="mt-5 container"
                onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col className="mb-3" lg={6}>
                    <Form.Label className="mb-1">
                      <b>Name</b> <span className="text-danger">*</span>
                    </Form.Label>
                    <div className="mb-1">
                      <TextInput
                        name={"fullName"}
                        className="form-control"
                        rules={{
                          required: "Name is required.",
                        }}
                        control={control}
                        placeholder="Name"
                      />
                    </div>
                  </Col>

                  <Col className="mb-3" lg={6}>
                    <Form.Label className="mb-1">
                      <b>Email Address</b>{" "}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <div className="mb-1">
                      <TextInput
                        name={"email"}
                        className="form-control"
                        rules={{
                          required: "Email is required.",
                          pattern: {
                            value:
                              /^\s*(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/,
                            message: "Invalid  email address.",
                          },
                        }}
                        control={control}
                        placeholder="Email Address"
                      />
                    </div>
                  </Col>
                  <Col className="mb-3" lg={6}>
                    <Form.Label className="mb-1">
                      <b>Country</b>
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <div className="mb-1">
                      <Select
                        onChange={(e) => onCountryChange(e)}
                        styles={customStyles}
                        className="form-contro"
                        options={countries}
                        value={selectedCountry}
                      />
                      {stateData?.errors?.country === false && (
                        <p className="text-danger  my-2 ms-2 ">
                          Country is required.
                        </p>
                      )}
                    </div>
                  </Col>
                  <Col className="mb-3" lg={2} md={3} sm={4}>
                    <Form.Label className="mb-1">
                      <b>Code</b>
                    </Form.Label>
                    <Form.Label className="mb-1"></Form.Label>
                    <div className="mb-1">
                      <input
                        readOnly
                        control={control}
                        autoComplete="off"
                        value={
                          countryState.countryCode !== "+undefined"
                            ? countryState.countryCode
                            : ""
                        }
                        name="PhoneCode"
                        placeholder="+92"
                        className="form-control dir-ltr customInputLogin"
                      />
                    </div>
                  </Col>
                  <Col className="mb-3" lg={4} md={9} sm={8}>
                    <Form.Label className="mb-1">
                      <b>Phone Number</b>
                    </Form.Label>
                    <div className="mb-1">
                      <InputMask
                        inputMode="numeric"
                        {...register("phoneNo", {
                          required: "Phone Number is required",
                        })}
                        autoComplete="off"
                        mask={maskNo ? maskNo : "99999-99999"}
                        maskChar={null}
                        name="phoneNo"
                        placeholder="Phone Number"
                        className="form-control dir-ltr customInputLogin"
                      />
                      <p className="text-danger  my-2 ms-2 ">
                        {errors?.phoneNo?.message}
                      </p>
                    </div>
                  </Col>
                  <Col className="mb-3" lg={6}>
                    <Form.Label className="mb-1">
                      <b>Subject</b> <span className="text-danger">*</span>
                    </Form.Label>
                    <div className="mb-1">
                      <TextInput
                        className="form-control"
                        {...register("subject", {
                          required: "Subject is required",
                          onChange: (e) => handleChange(e),
                        })}
                        control={control}
                        placeholder="Type Your Subject"
                      />
                    </div>
                    {subjectLength > 30 && (
                      <p className="text-danger mx-1 my-2">
                        {subjectErrorMessage
                          ? subjectErrorMessage
                          : "Subject cannot exceed 30 characters"}
                      </p>
                    )}
                  </Col>
                  <Col className="mb-3" col={12}>
                    <Form.Label className="mb-1">
                      <b>Message</b> <span className="text-danger">*</span>
                    </Form.Label>
                    <textarea
                      placeholder="Type Your Message"
                      className="form-control"
                      style={{
                        resize: "none",
                        height: "150px",
                      }}
                      {...register("message", {
                        required: "Message is required",
                        onChange: (e) => handleChange(e),
                      })}></textarea>
                    <p className="text-danger mx-1 my-2">
                      {errors?.message?.message}
                    </p>
                    {messageLength > 500 && (
                      <p className="text-danger mx-1 my-2">
                        {errorMessageLength
                          ? errorMessageLength
                          : "Message cannot exceed 500 characters"}
                      </p>
                    )}
                    {messageLength > 500 ? (
                      <p className="text-danger mx-1 my-2">
                        Message limit {messageLength} to 500.
                      </p>
                    ) : (
                      <p className="text-dark mx-1 my-2">
                        Message limit {messageLength} to 500.
                      </p>
                    )}
                  </Col>
                  <div className="text-center mt-4">
                    {loader ? (
                      <button className="btn__primary mx-auto shadow buttonPrimary">
                        <i
                          className="fa mx-3 fa-circle-o-notch fa-spin"
                          style={{ fontSize: "24px" }}></i>
                      </button>
                    ) : (
                      <button className="btn__primary mx-auto shadow buttonPrimary">
                        contact us
                      </button>
                    )}
                  </div>
                </Row>
              </Form>
            </Col>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Contact;
