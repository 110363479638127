import React, { Fragment, useState, useEffect } from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
const Loader = (props) => {
  const { loading } = props;
  const [show, setShow] = useState(loading);
  useEffect(() => {
    setTimeout(() => {
      setShow(false);
    }, 3000000);
  }, [show]);

  return (
   <div className="text-center my-5">
     <ScaleLoader color="#002866" />
   </div>
  );
};
export default Loader;
