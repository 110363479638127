import {Table} from "antd";
import {useState} from "react";
import moment from "moment";
import {Button} from "react-bootstrap";

export default function UserDonationTable({donationDetail}) {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const columns = [
    {
      title: "Fundraising Cause",
      dataIndex: "FundraisingCause",
    },
    {
      title: "Funding Type",
      dataIndex: "FundingType",
    },
    {
      title: "Donor",
      dataIndex: "Donor",
    },
    {
      title: "Payment Status",
      dataIndex: "payment_status",
    },
    {
      title: "Date",
      dataIndex: "Date",
    },
    {
      title: "Donated Amount",
      dataIndex: "DonatedAmount",
    },
  ];
  const data = [];

  for (let i = 0; i < donationDetail?.length; i++) {
    data.push({
      FundraisingCause: (
        <div className="d-flex align-items-center gap-2">
          {" "}
          <img
            style={{borderRadius: "8px"}}
            width={50}
            src={donationDetail[i]?.fundraise_id?.imageUrl[0]}
            alt=""
          />
          <p style={{fontWeight: 600}}>
            {donationDetail[i]?.fundraise_id?.title}
          </p>
        </div>
      ),
      FundingType:
        donationDetail[i]?.fundraise_id?.category[0]?.slug
          ?.charAt(0)
          ?.toUpperCase() +
        donationDetail[i]?.fundraise_id?.category[0]?.slug?.slice(1) +
        " " +
        "Funding",
      Donor: donationDetail[i]?.personalInfo?.username,
      payment_status: donationDetail[i]?.payment_status,
      Date: moment(donationDetail[i]?.created).format("L"),
      DonatedAmount: <>Rs. {donationDetail[i]?.amount}</>,
      action: (
        <>
          <Button className="btn-sm btn-info text-white">
            <i className="fa fa-eye"></i>
          </Button>{" "}
          &nbsp;
          <Button className="btn-sm btn-warning">
            <i className="fa fa-edit"></i>
          </Button>{" "}
          &nbsp;
          <Button className="btn-sm btn-danger">
            <i className="fa fa-trash"></i>
          </Button>
        </>
      ),
    });
  }
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table?.SELECTION_ALL,
      Table?.SELECTION_INVERT,
      Table?.SELECTION_NONE,
      {
        key: "odd",
        text: "Select Odd Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys?.filter((_, index) => {
            if (index % 2 !== 0) {
              return false;
            }

            return true;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: "even",
        text: "Select Even Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys?.filter((_, index) => {
            if (index % 2 !== 0) {
              return true;
            }

            return false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };
  return (
    <div className="card">
      <div className="card-header">
        <div className="card-title">My Donations</div>
      </div>

      {data && (
        <Table
          pagination={{
            pageSize: 4,
          }}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={data}
        />
      )}
    </div>
  );
}
