export const countries = [
  { label: "Afghanistan", code: "93", iso: "AF", mask: "999-9999" },
  { label: "Albania", code: "355", iso: "AL", mask: "999-999" },
  { label: "Algeria", code: "213", iso: "DZ", mask: "99-999999" },
  { label: "American Samoa", code: "1-684", iso: "AS", mask: "999-9999" },
  { label: "Andorra", code: "376", iso: "AD", mask: "999-999999" },
  { label: "Angola", code: "244", iso: "AO", mask: "999-999-999" },
  { label: "Anguilla", code: "1-264", iso: "AI", mask: "999-9999999" },
  { label: "Antarctica", code: "672", iso: "AQ", mask: "9999-999-999" },
  { label: "Antigua and Barbuda", code: "1-268", iso: "AG", mask: "999-9999" },
  { label: "Argentina", code: "54", iso: "AR", mask: "999-9999999" },
  { label: "Armenia", code: "374", iso: "AM", mask: "999-999-999" },
  { label: "Aruba", code: "297", iso: "AW", mask: "999-9999" },
  { label: "Australia", code: "61", iso: "AU", mask: "999-999-999" },
  { label: "Austria", code: "43", iso: "AT", mask: "9-999-9999" },
  { label: "Azerbaijan", code: "994", iso: "AZ", mask: "999-9999" },
  { label: "Bahamas", code: "1-242", iso: "BS", mask: "9999999" },
  { label: "Bahrain", code: "973", iso: "BH", mask: "99-999999" },
  { label: "Bangladesh", code: "880", iso: "BD", mask: "999999999" },
  { label: "Barbados", code: "1-246", iso: "BB", mask: "9999-999-9999" },
  { label: "Belarus", code: "375", iso: "BY", mask: "999-999999999" },
  { label: "Belgium", code: "32", iso: "BE", mask: "99999999999" },
  { label: "Belize", code: "501", iso: "BZ", mask: "999-9999999" },
  { label: "Benin", code: "229", iso: "BJ", mask: "999-999" },
  { label: "Bermuda", code: "1-441", iso: "BM", mask: "9999999" },
  { label: "Bhutan", code: "975", iso: "BT", mask: "999-9999999" },
  { label: "Bolivia", code: "591", iso: "BO", mask: "999-99999999" },
  { label: "Bosnia and Herzegovina", code: "387", iso: "BA", mask: "999-99999999" },
  { label: "Botswana", code: "267", iso: "BW", mask: "999-9999999" },
  { label: "Brazil", code: "55", iso: "BR", mask: "99999-9999" },
  { label: "British Indian Ocean Territory", code: "246", iso: "IO", mask: "99-9999999999" },
  { label: "British Virgin Islands", code: "1-284", iso: "VG", mask: "999-9999" },
  { label: "Brunei", code: "673", iso: "BN", mask: "999-9999999" },
  { label: "Bulgaria", code: "359", iso: "BG", mask: "999-99999999" },
  { label: "Burkina Faso", code: "226", iso: "BF", mask: "999-99999999" },
  { label: "Burundi", code: "257", iso: "BI", mask: "999-99999999" },
  { label: "Cambodia", code: "855", iso: "KH", mask: "999-99999999" },
  { label: "Cameroon", code: "237", iso: "CM", mask: "999-999999999" },
  { label: "Canada", code: "1", iso: "CA", mask: "99999999999" },
  { label: "Cape Verde", code: "238", iso: "CV", mask: "999-9999999" },
  { label: "Cayman Islands", code: "1-345", iso: "KY", mask: "999-999" },
  { label: "Central African Republic", code: "236", iso: "CF", mask: "999-99999999" },
  { label: "Chad", code: "235", iso: "TD", mask: "999-99999999" },
  { label: "Chile", code: "56", iso: "CL", mask: "999-9999-999" },
  { label: "China", code: "86", iso: "CN", mask: "99-9999-9999" },
  { label: "Christmas Island", code: "61", iso: "CX", mask: "99-999999999" },
  { label: "Cocos Islands", code: "61", iso: "CC", mask: "99-999999999" },
  { label: "Colombia", code: "57", iso: "CO", mask: "99-9999999999" },
  { label: "Comoros", code: "269", iso: "KM", mask: "999-9999999" },
  { label: "Cook Islands", code: "682", iso: "CK", mask: "999-99999" },
  { label: "Costa Rica", code: "506", iso: "CR", mask: "999-99999999" },
  { label: "Croatia", code: "385", iso: "HR", mask: "999-99999999" },
  { label: "Cuba", code: "53", iso: "CU", mask: "99-99999999" },
  { label: "Curacao", code: "599", iso: "CW", mask: "999-99999999" },
  { label: "Cyprus", code: "357", iso: "CY", mask: "999-99999999" },
  { label: "Czech Republic", code: "420", iso: "CZ", mask: "999-999999999" },
  { label: "Democratic Republic of the Congo", code: "243", iso: "CD", mask: "999-999999999" },
  { label: "Denmark", code: "45", iso: "DK", mask: "99-999999999" },
  { label: "Djibouti", code: "253", iso: "DJ", mask: "999-999999999" },
  { label: "Dominica", code: "1-767", iso: "DM", mask: "999-9999" },
  { label: "Dominican Republic", code: "1-809, 1-829, 1-849", iso: "DO", mask: "999-9999" },
  { label: "East Timor", code: "670", iso: "TL", mask: "9999999" },
  { label: "Ecuador", code: "593", iso: "EC", mask: "99999999" },
  { label: "Egypt", code: "20", iso: "EG", mask: "999999999" },
  { label: "El Salvador", code: "503", iso: "SV", mask: "9999-9999" },
  { label: "Equatorial Guinea", code: "240", iso: "GQ", mask: "999-999" },
  { label: "Eritrea", code: "291", iso: "ER", mask: "9-999-999" },
  { label: "Estonia", code: "372", iso: "EE", mask: "999-9999" },
  { label: "Ethiopia", code: "251", iso: "ET", mask: "99-999-9999" },
  { label: "Falkland Islands", code: "500", iso: "FK", mask: "99-999" },
  { label: "Faroe Islands", code: "298", iso: "FO", mask: "999-999" },
  { label: "Fiji", code: "679", iso: "FJ", mask: "999-9999" },
  { label: "Finland", code: "358", iso: "FI", mask: "9999-9999" },
  { label: "France", code: "33", iso: "FR", mask: "99-99-99-99" },
  { label: "French Polynesia", code: "689", iso: "PF", mask: "99-99-99-99" },
  { label: "Gabon", code: "241", iso: "GA", mask: "99-99-99-99" },
  { label: "Gambia", code: "220", iso: "GM", mask: "999-9999"},
  { label: "Georgia", code: "995", iso: "GE", mask: "99-999-99-99" },
  { label: "Germany", code: "49", iso: "DE", mask: "99-999999" },
  { label: "Ghana", code: "233", iso: "GH", mask: "99-999-9999" },
  { label: "Gibraltar", code: "350", iso: "GI", mask: "9999-9999" },
  { label: "Greece", code: "30", iso: "GR", mask: "99-9999-9999" },
  { label: "Greenland", code: "299", iso: "GL", mask: "999-999" },
  { label: "Grenada", code: "1-473", iso: "GD", mask: "999-9999" },
  { label: "Guam", code: "1-671", iso: "GU", mask: "999-9999" },
  { label: "Guatemala", code: "502", iso: "GT", mask: "9999-9999" },
  { label: "Guernsey", code: "44-1481", iso: "GG", mask: "9999-999999" },
  { label: "Guinea", code: "224", iso: "GN", mask: "999-999-999" },
  { label: "Guinea-Bissau", code: "245", iso: "GW", mask: "99-999-9999" },
  { label: "Guyana", code: "592", iso: "GY", mask: "999-9999" },
  { label: "Haiti", code: "509", iso: "HT", mask: "9999-9999" },
  { label: "Honduras", code: "504", iso: "HN", mask: "9999-9999" },
  { label: "Hong Kong", code: "852", iso: "HK", mask: "9999-9999" },
  { label: "Hungary", code: "36", iso: "HU", mask: "99-9999999" },
  { label: "Iceland", code: "354", iso: "IS", mask: "999-9999" },
  { label: "India", code: "91", iso: "IN", mask: "99999-99999" },
  { label: "Indonesia", code: "62", iso: "ID", mask: "999-9999-9999" },
  { label: "Iran", code: "98", iso: "IR", mask: "99-9999-9999" },
  { label: "Iraq", code: "964", iso: "IQ", mask: "9-999-9999" },
  { label: "Ireland", code: "353", iso: "IE", mask: "9-999-9999" },
  { label: "Isle of Man", code: "44-1624", iso: "IM", mask: "999999" },
  { label: "Israel", code: "972", iso: "IL", mask: "9-999-9999" },
  { label: "Italy", code: "39", iso: "IT", mask: "99-9999-9999" },
  { label: "Ivory Coast", code: "225", iso: "CI", mask: "99-99-99-99-99" },
  { label: "Jamaica", code: "1-876", iso: "JM", mask: "999-9999" },
  { label: "Japan", code: "81", iso: "JP", mask: "99-9999-9999" },
  { label: "Jersey", code: "44-1534", iso: "JE", mask: "999999" },
  { label: "Jordan", code: "962", iso: "JO", mask: "9-999-9999" },
  { label: "Kazakhstan", code: "7", iso: "KZ", mask: "999-999-9999" },
  { label: "Kenya", code: "254", iso: "KE", mask: "99-999-9999" },
  { label: "Kiribati", code: "686", iso: "KI", mask: "9999-9999" },
  { label: "Kosovo", code: "383", iso: "XK", mask: "99-999-999" },
  { label: "Kuwait", code: "965", iso: "KW", mask: "9999-9999" },
  { label: "Kyrgyzstan", code: "996", iso: "KG", mask: "999-999-999" },
  { label: "Laos", code: "856", iso: "LA", mask: "99-999-999" },
  { label: "Latvia", code: "371", iso: "LV", mask: "9999-9999" },
  { label: "Lebanon", code: "961", iso: "LB", mask: "9-999-999" },
  { label: "Lesotho", code: "266", iso: "LS", mask: "99-999-999" },
  { label: "Liberia", code: "231", iso: "LR", mask: "99-999-9999" },
  { label: "Libya", code: "218", iso: "LY", mask: "99-999-9999" },
  { label: "Liechtenstein", code: "423", iso: "LI", mask: "999-9999" },
  { label: "Lithuania", code: "370", iso: "LT", mask: "9-999-9999" },
  { label: "Luxembourg", code: "352", iso: "LU", mask: "999-999-999" },
  { label: "Macao", code: "853", iso: "MO", mask: "9999-9999" },
  { label: "Macedonia", code: "389", iso: "MK", mask: "99-999-9999" },
  { label: "Madagascar", code: "261", iso: "MG", mask: "99-99-999-99" },
  { label: "Malawi", code: "265", iso: "MW", mask: "9-999-999" },
  { label: "Malaysia", code: "60", iso: "MY", mask: "9-9999-9999" },
  { label: "Maldives", code: "960", iso: "MV", mask: "999-9999" },
  { label: "Mali", code: "223", iso: "ML", mask: "9999-9999" },
  { label: "Malta", code: "356", iso: "MT", mask: "9999-9999" },
  { label: "Marshall Islands", code: "692", iso: "MH", mask: "9999-9999" },
  { label: "Mauritania", code: "222", iso: "MR", mask: "9999-9999" },
  { label: "Mauritius", code: "230", iso: "MU", mask: "999-9999" },
  { label: "Mayotte", code: "262", iso: "YT", mask: "999-999-999" },
  { label: "Mexico", code: "52", iso: "MX", mask: "99-9999-9999" },
  { label: "Micronesia", code: "691", iso: "FM", mask: "999-9999" },
  { label: "Moldova", code: "373", iso: "MD", mask: "9999-9999" },
  { label: "Monaco", code: "377", iso: "MC", mask: "9999-9999" },
  { label: "Mongolia", code: "976", iso: "MN", mask: "99-999-999" },
  { label: "Montenegro", code: "382", iso: "ME", mask: "99-999-999" },
  { label: "Montserrat", code: "1-664", iso: "MS", mask: "999-9999" },
  { label: "Morocco", code: "212", iso: "MA", mask: "99-999-999" },
  { label: "Mozambique", code: "258", iso: "MZ", mask: "99-999-999" },
  { label: "Myanmar", code: "95", iso: "MM", mask: "9-999-9999" },
  { label: "Namibia", code: "264", iso: "NA", mask: "99-999-999" },
  { label: "Nauru", code: "674", iso: "NR", mask: "999-9999" },
  { label: "Nepal", code: "977", iso: "NP", mask: "9-999-9999" },
  { label: "Netherlands", code: "31", iso: "NL", mask: "99-999-9999" },
  { label: "Netherlands Antilles", code: "599", iso: "AN", mask: "999-9999" },
  { label: "New Caledonia", code: "687", iso: "NC", mask: "999-999" },
  { label: "New Zealand", code: "64", iso: "NZ", mask: "9-999-9999" },
  { label: "Nicaragua", code: "505", iso: "NI", mask: "9999-9999" },
  { label: "Niger", code: "227", iso: "NE", mask: "99-999-999" },
  { label: "Nigeria", code: "234", iso: "NG", mask: "9-999-9999" },
  { label: "Niue", code: "683", iso: "NU", mask: "9999" },
  { label: "North Korea", code: "850", iso: "KP", mask: "999-999-9999" },
  { label: "Northern Mariana Islands", code: "1-670", iso: "MP", mask: "999-9999" },
  { label: "Norway", code: "47", iso: "NO", mask: "9999-9999" },
  { label: "Oman", code: "968", iso: "OM", mask: "9999-9999" },
  { label: "Pakistan", code: "92", iso: "PK", mask: "999-9999999" },
  { label: "Palau", code: "680", iso: "PW", mask: "999-9999"},
  { label: "Palestine", code: "970", iso: "PS", mask: "99-999-9999" },
  { label: "Panama", code: "507", iso: "PA", mask: "999-9999" },
  { label: "Papua New Guinea", code: "675", iso: "PG", mask: "999-9999" },
  { label: "Paraguay", code: "595", iso: "PY", mask: "99-999-9999" },
  { label: "Peru", code: "51", iso: "PE", mask: "9-999-9999" },
  { label: "Philippines", code: "63", iso: "PH", mask: "99-9999-9999" },
  { label: "Pitcairn", code: "64", iso: "PN", mask: "9999-9999" },
  { label: "Poland", code: "48", iso: "PL", mask: "99-999-9999" },
  { label: "Portugal", code: "351", iso: "PT", mask: "999-999-999" },
  { label: "Puerto Rico", code: "1-787, 1-939", iso: "PR", mask: "999-9999" },
  { label: "Qatar", code: "974", iso: "QA", mask: "9999-9999" },
  { label: "Republic of the Congo", code: "242", iso: "CG", mask: "99-999-9999" },
  { label: "Reunion", code: "262", iso: "RE", mask: "999-99-99-99" },
  { label: "Romania", code: "40", iso: "RO", mask: "99-999-9999" },
  { label: "Russia", code: "7", iso: "RU", mask: "999-999" },
  { label: "Rwanda", code: "250", iso: "RW", mask: "999-999-999" },
  { label: "Saint Barthelemy", code: "590", iso: "BL", mask: "999-99-99-99" },
  { label: "Saint Helena", code: "290", iso: "SH", mask: "99-999" },
  { label: "Saint Kitts and Nevis", code: "1-869", iso: "KN", mask: "999-9999" },
  { label: "Saint Lucia", code: "1-758", iso: "LC", mask: "999-9999" },
  { label: "Saint Martin", code: "590", iso: "MF", mask: "999-99-99-99" },
  { label: "Saint Pierre and Miquelon", code: "508", iso: "PM", mask: "99-99-99" },
  { label: "Saint Vincent and the Grenadines", code: "1-784", iso: "VC", mask: "999-9999" },
  { label: "Samoa", code: "685", iso: "WS", mask: "99-999" },
  { label: "San Marino", code: "378", iso: "SM", mask: "9999-999-999" },
  { label: "Sao Tome and Principe", code: "239", iso: "ST", mask: "99-999999" },
  { label: "Saudi Arabia", code: "966", iso: "SA", mask: "99-999-9999" },
  { label: "Senegal", code: "221", iso: "SN", mask: "99-999-9999" },
  { label: "Serbia", code: "381", iso: "RS", mask: "99-999-9999" },
  { label: "Seychelles", code: "248", iso: "SC", mask: "999-9999" },
  { label: "Sierra Leone", code: "232", iso: "SL", mask: "99-999-999" },
  { label: "Singapore", code: "65", iso: "SG", mask: "9999-9999" },
  { label: "Sint Maarten", code: "1-721", iso: "SX", mask: "999-99-99-99" },
  { label: "Slovakia", code: "421", iso: "SK", mask: "9-9999-9999" },
  { label: "Slovenia", code: "386", iso: "SI", mask: "9-999-9999" },
  { label: "Solomon Islands", code: "677", iso: "SB", mask: "99-999" },
  { label: "Somalia", code: "252", iso: "SO", mask: "999-9999" },
  { label: "South Africa", code: "27", iso: "ZA", mask: "99-999-999" },
  { label: "South Korea", code: "82", iso: "KR", mask: "9-999-9999" },
  { label: "South Sudan", code: "211", iso: "SS", mask: "9999-99999" },
  { label: "Spain", code: "34", iso: "ES", mask: "999-999-999" },
  { label: "Sri Lanka", code: "94", iso: "LK", mask: "99-999-9999" },
  { label: "Sudan", code: "249", iso: "SD" , mask: "99-999-9999"},
  { label: "Suriname", code: "597", iso: "SR", mask: "999-999" },
  { label: "Svalbard and Jan Mayen", code: "47", iso: "SJ" , mask: "999-99-999"},
  { label: "Swaziland", code: "268", iso: "SZ" , mask: "9999-9999"},
  { label: "Sweden", code: "46", iso: "SE", mask: "9-9999-9999" },
  { label: "Switzerland", code: "41", iso: "CH", mask: "99-999-9999" },
  { label: "Syria", code: "963", iso: "SY", mask: "99-999-9999" },
  { label: "Taiwan", code: "886", iso: "TW", mask: "9-9999-9999" },
  { label: "Tajikistan", code: "992", iso: "TJ", mask: "999-999-9999" },
  { label: "Tanzania", code: "255", iso: "TZ", mask: "99-999-9999" },
  { label: "Thailand", code: "66", iso: "TH", mask: "9-999-9999" },
  { label: "Togo", code: "228", iso: "TG", mask: "99-99-99-99" },
  { label: "Tokelau", code: "690", iso: "TK", mask: "9999-999" },
  { label: "Tonga", code: "676", iso: "TO", mask: "99-999" },
  { label: "Trinidad and Tobago", code: "1-868", iso: "TT", mask: "999-9999" },
  { label: "Tunisia", code: "216", iso: "TN", mask: "99-999-999" },
  { label: "Turkey", code: "90", iso: "TR", mask: "999-999-9999" },
  { label: "Turkmenistan", code: "993", iso: "TM", mask: "99-999-999" },
  { label: "Turks and Caicos Islands", code: "1-649", iso: "TC", mask: "999-9999" },
  { label: "Tuvalu", code: "688", iso: "TV", mask: "99999" },
  { label: "U.S. Virgin Islands", code: "1-340", iso: "VI", mask: "999-9999" },
  { label: "Uganda", code: "256", iso: "UG", mask: "99-999-9999" },
  { label: "Ukraine", code: "380", iso: "UA", mask: "99-999-9999" },
  { label: "United Arab Emirates", code: "971", iso: "AE", mask: "9-999-9999" },
  { label: "United Kingdom", code: "44", iso: "GB", mask: "99-9999-9999" },
  { label: "United States", code: "1", iso: "US", mask: "999-999-9999" },
  { label: "Uruguay", code: "598", iso: "UY", mask: "9-999-9999" },
  { label: "Uzbekistan", code: "998", iso: "UZ", mask: "99-999-9999" },
  { label: "Vanuatu", code: "678", iso: "VU", mask: "99999" },
  { label: "Vatican", code: "379", iso: "VA", mask: "999-999-9999" },
  { label: "Venezuela", code: "58", iso: "VE", mask: "999-999-9999" },
  { label: "Vietnam", code: "84", iso: "VN", mask: "99-9999-9999" },
  { label: "Wallis and Futuna", code: "681", iso: "WF", mask: "999-999" },
  { label: "Western Sahara", code: "212", iso: "EH", mask: "999-999-999" },
  { label: "Yemen", code: "967", iso: "YE", mask: "9-999-999" },
  { label: "Zambia", code: "260", iso: "ZM", mask: "999-999-999" },
  { label: "Zimbabwe", code: "263", iso: "ZW", mask: "99-999-9999" },
];
