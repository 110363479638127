import React, {useState} from "react";
// import "antd/dist/antd.css";
import {Table} from "antd";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {Button, Modal} from "react-bootstrap";
import {getSupporter, updateSupporterById} from "../../..//Api-Calls/supporter";
import {
  SwalErrorMessage,
  SwalMessage,
} from "../../../components/sweetAlertMessage";
import userPicture from "../../../assets/img/userDefaultImg.png";

const SupporterDatatable = (props) => {
  const {supporter, setSupporter, columns} = props;
  const [supporterDetail, setSupporterDetail] = useState();
  const [tagModal, setTagModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const showTagModal = (e, details) => {
    setSupporterDetail(details);
    setTagModal(true);
  };
  const handleClose = () => {
    setTagModal(false);
  };

  const data = [];
  for (let i = 0; i < supporter?.length; i++) {
    data.push({
      sr: supporter[i]?.seqId,
      username: supporter[i]?.personalInfo?.username,
      email: supporter[i]?.personalInfo?.email,
      title: supporter[i]?.fundraise_id?.title,
      payment_status: supporter[i]?.payment_status,
      payment_method: supporter[i]?.payment_method,
      totalAmount: <>Rs. {supporter[i]?.totalAmount}</>,

      action: (
        <>
          <Button
            onClick={(e) => showTagModal(e, supporter[i])}
            className="action-button-view"
          >
            <i className="fa fa-eye"></i>
          </Button>{" "}
        </>
      ),
    });
  }

  const getSupporterData = async () => {
    try {
      const res = await getSupporter();
      if (res?.status == 200) {
        setSupporter(res?.data?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table?.SELECTION_ALL,
      Table?.SELECTION_INVERT,
      Table?.SELECTION_NONE,
      {
        key: "odd",
        text: "Select Odd Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys?.filter((_, index) => {
            if (index % 2 !== 0) {
              return false;
            }

            return true;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: "even",
        text: "Select Even Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys?.filter((_, index) => {
            if (index % 2 !== 0) {
              return true;
            }

            return false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };
  const [paymentStatus, setPaymentStaus] = useState("");
  const handleChange = (e) => {
    let {name, value} = e.target;

    setPaymentStaus({
      ...paymentStatus,
      [name]: value,
    });
  };
  const handleUpdate = () => {
    setLoading(true);
    updateSupporterById(supporterDetail?._id, paymentStatus)
      .then((res) => {
        SwalMessage(res?.data?.status, res?.data?.message, "success");

        getSupporterData();
        handleClose();
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch((error) => {
        SwalErrorMessage(
          "Oops!" + " " + error?.response?.data?.status,
          error?.response?.data?.message,
          "error",
          true
        );
      });
  };
  const ShowModalPreview = () => {
    var el = document.getElementById("full-screenVideo");
    if (el.requestFullscreen) {
      el.requestFullscreen();
    } else if (el.msRequestFullscreen) {
      el.msRequestFullscreen();
    } else if (el.mozRequestFullScreen) {
      el.mozRequestFullScreen();
    } else if (el.webkitRequestFullscreen) {
      el.webkitRequestFullscreen();
    }
  };

  return (
    <>
      <Table rowSelection={rowSelection} columns={columns} dataSource={data} />

      <Modal show={tagModal} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Single Transaction Detail</Modal.Title>
          <button onClick={handleClose} className="border-0">
            X
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="p-2">
            <div className="d-flex mb-2">
              <div className="mb-1 flex1">
                <strong>Username:</strong>
              </div>
              <div className="mx-2 flex2 text-capitalize">
                {supporterDetail?.personalInfo?.username}
              </div>
            </div>

            <div className="d-flex mb-2">
              <div className="mb-1 flex1">
                <strong>Email:</strong>
              </div>
              <div className="mx-2 flex2">
                {supporterDetail?.personalInfo?.email}
              </div>
            </div>
            <div className="d-flex mb-2">
              <div className="mb-1 flex1">
                <strong>Profile Image:</strong>
              </div>
              <div className="mx-2 flex2">
                <img
                  src={
                    supporterDetail?.personalInfo?.picture != ""
                      ? supporterDetail?.personalInfo?.picture
                      : userPicture
                  }
                  style={{
                    height: "100px",
                    width: "100px",
                    objectFit: "cover",
                  }}
                />
              </div>
            </div>

            <div className="d-flex mb-4">
              <div className="mb-1 flex1">
                <strong>Payment Method:</strong>
              </div>
              <div className="mx-2 flex2">
                {supporterDetail?.payment_method}
              </div>
            </div>
            <div className="d-flex mb-4">
              <div className="mb-1 flex1">
                <strong>Total Amount:</strong>
              </div>
              <div className="mx-2 flex2">
                Rs. {supporterDetail?.totalAmount}
              </div>
            </div>
            {supporterDetail?.payment_status == "Inprogress" && (
              <div className="d-flex mb-4">
                <div className="mb-1 flex1">
                  <strong>Proof of Payment:</strong>
                </div>
                <div className="mx-2 flex2">
                  <img
                    src={supporterDetail?.picture}
                    style={{
                      height: "150px",
                      width: "150px",
                      objectFit: "cover",
                    }}
                    className="w-50"
                    id="full-screenVideo"
                    onClick={ShowModalPreview}
                  />
                </div>
              </div>
            )}
            {supporterDetail?.payment_status == "Verified" && (
              <div className="d-flex mb-4">
                <div className="mb-1 flex1">
                  <strong>Proof of Payment:</strong>
                </div>
                <div className="mx-2 flex2">
                  <img
                    src={supporterDetail?.picture}
                    style={{
                      height: "150px",
                      width: "150px",
                      objectFit: "cover",
                    }}
                    className="w-50"
                  />
                </div>
              </div>
            )}
            <div className="d-flex mb-4">
              <div className="mb-1 flex1">
                <strong>Payment Status:</strong>
              </div>
              <div className="mx-2 flex2">
                {supporterDetail?.payment_status}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-info" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default SupporterDatatable;
