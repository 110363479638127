import Cookies from "js-cookie";
import React from "react";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { signOut } from "./Features/user/authSlice";

const PrivateRoute = ({ children }) => {
  const dispatchRedux = useDispatch()
  const token = Cookies.get("userToken");
  useEffect(() => {
    if (!token) {
      dispatchRedux(signOut())
      localStorage.removeItem("userToken");
      localStorage.removeItem("user");
    }
  }, []);
  return token ? children : <Navigate to="/login" />;
};
export default PrivateRoute;
