import React, {useEffect, useState, useContext} from "react";
import {Container, Tab, Tabs} from "react-bootstrap";
import BreadCrumb from "../components/BreadCrumb";
import {useParams} from "react-router-dom";
import banner from "../assets/img/stories-banner.png";
import mobileBanner from "../assets/img/stories-banner-mobile.png";
import {getSuccessStoryBySlug} from "../Api-Calls/successStories";
import fb from "../assets/img/social/fb-w-bg-round.png";
import insta from "../assets/img/social/insta-w-bg-round.png";
import linkedIn from "../assets/img/social/lnk-w-bg-round.png";
import youtube from "../assets/img/social/ytb-w-bg-round.png";
import x from "../assets/img/social/twtr-w-bg-round.png";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import HTMLRenderer from "react-html-renderer";
import HelmetSEO from "../components/HelmetSEO";
import {staticImages} from "../Constant";
import Context from "../Context/context";
import {getSuccessStories} from "../Api-Calls/successStories";

const SuccessStoriesDetails = () => {
  const {slug} = useParams();
  const [state, dispatch] = useContext(Context);
  const {
    fundraiser: {fundraiseList},
  } = useSelector((store) => store);
  const [story, setStory] = useState([]);
  const getStoryData = async () => {
    try {
      const res = await getSuccessStoryBySlug(slug);
      if (res?.status == 200) {
        setStory(res?.data?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getStoryData();
  }, [slug]);
  const getSuccessStoriesList = async () => {
    try {
      const params = {
        page: 1,
        limit: 12,
      };
      const res = await getSuccessStories(params);
      if (res?.status == 200) {
        let data = res?.data?.result?.result;
        let pages = res?.data?.result?.totalPages;

        dispatch({
          type: "SUCCESS_STORIES",
          payload: data,
        });
        localStorage.setItem("successStories", JSON.stringify(data));
        localStorage.setItem("storyPages", JSON.stringify(pages));
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getSuccessStoriesList();
  }, []);
  return (
    <>
      <HelmetSEO title={story?.title} description={story?.meta_description} />

      <BreadCrumb
        heading="SUCCESS STORIES"
        badge="Success Story"
        webImage={banner}
        mobileImage={mobileBanner}
      />
      <section className="mt-5 p-3">
        <Container>
          <div className="row">
            <div className="col-lg-8">
              <h2>{story?.title}</h2>
              <div className="divider-gray"></div>
              <h5>Inspire Generosity by Sharing these Stories</h5>
              <div className="mt-4 social-div">
                <a
                  className="scs-scl-btn"
                  target={"_blank"}
                  rel="noreferrer"
                  href="https://www.facebook.com/people/Building-Bridges/61551243035166/?mibextid=LQQJ4d"
                >
                  {" "}
                  <img src={fb} alt="" loading="lazy"/> Facebook{" "}
                </a>
                <a
                  className="scs-scl-btn isnta-bg"
                  target={"_blank"}
                  rel="noreferrer"
                  href="https://www.instagram.com/building_bridges_global/"
                >
                  {" "}
                  <img src={insta} alt="" loading="lazy"/> Instagram{" "}
                </a>
                <a
                  className="scs-scl-btn linkedin-bg"
                  target={"_blank"}
                  rel="noreferrer"
                  href="https://www.linkedin.com/company/building-bridges-global/posts/?feedView=all"
                >
                  {" "}
                  <img src={linkedIn} alt="" loading="lazy"/> LinkedIn{" "}
                </a>
                <a
                  className="scs-scl-btn bg-x"
                  target={"_blank"}
                  rel="noreferrer"
                  href="https://x.com/buildingb29305?s=11&t=HvDthFTk8WnKzbE2aKuNCw"
                >
                  {" "}
                  <img src={x} alt="" loading="lazy"/> Twitter{" "}
                </a>
                <a
                  className="scs-scl-btn youtube-bg"
                  target={"_blank"}
                  rel="noreferrer"
                  href="https://www.youtube.com/channel/UCIIxIxzK-rxIx9ibRNeO-7A"
                >
                  {" "}
                  <img src={youtube} alt="" loading="lazy"/> Youtube{" "}
                </a>
              </div>
              <div className="mt-4">
                {story?.imageUrl?.length > 0 && (
                  <img
                    className="success-banner"
                    src={story.imageUrl[0]?.url}
                    alt={story?.meta_image_description}
                    style={{maxHeight: "70vh"}}
                    loading="lazy"
                  />
                )}
              </div>
              <div>
                <Tabs
                  defaultActiveKey="successstory"
                  transition={false}
                  id="noanim-tab-example"
                  className="my-4"
                >
                  <Tab eventKey="successstory" title="Success Story">
                    <div style={{textAlign: "justify"}}>
                      {" "}
                      <HTMLRenderer html={story?.description} />
                    </div>
                  </Tab>
                  <Tab eventKey="suppoters" title="Suppoters">
                    <div className="d-flex">
                      {/* suppoter */}
                      {story?.topDoners?.map((item, index) => (
                        <div
                          key={index}
                          className="d-flex justify-content-center flex-column text-center mx-2"
                        >
                          <img
                            style={{objectFit: "cover"}}
                            className="mx-auto rounded"
                            width={75}
                            height={75}
                            src={
                              item?.personalInfo?.picture
                                ? item?.personalInfo?.picture
                                : staticImages.userDefaultImg
                            }
                            alt=""
                            loading="lazy"
                          />
                          <h6 className="mt-2">{item?.personalInfo?.username}</h6>
                          <p>Donated: Rs. {item?.amount}</p>
                        </div>
                      ))}
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
            <div className="col-lg-4 mt-3">
              <h4>Success Stories</h4>
              <div className="mt-4">
                {state?.successStoriesList &&
                  state?.successStoriesList
                    ?.slice(0, 7)
                    .map((fundraiser, index) => {
                      const {title, description, slug} = fundraiser;
                      return (
                        <div key={index}>
                          <Link
                            to={`/success-stories/${slug}`}
                            className="scs-fund-flex py-2"
                          >
                            <img
                              className="scs-fund-img"
                              src={fundraiser?.imageUrl[0]?.url}
                              alt=""
                              loading="lazy"
                            />
                            <div>
                              <h6>{title}</h6>
                              <p>{description?.slice(0, 50)}</p>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
              </div>
              <Link to="/success-stories">
                {" "}
                <h6 className="pt-4">See More...</h6>{" "}
              </Link>
            </div>
          </div>
          <div></div>
        </Container>
      </section>
    </>
  );
};

export default SuccessStoriesDetails;
