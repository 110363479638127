import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  Dropdown,
  Modal,
  ModalBody,
  ModalHeader,
  ModalTitle,
  Nav,
  Navbar,
} from "react-bootstrap";
import { HashLink } from "react-router-hash-link";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LogoBlack from "../../assets/img/logo/PoweredByVariationBlack.svg";
import LogoWhite from "../../assets/img/logo/Powered By Variation White.svg";
import menubar from "../../assets/img/bar.svg";
import SideModalDonation from "../SideModalDonation";
import Context from "../../Context/context";
import { getFundraiseSearch } from "../../Api-Calls/fundraise";
import { staticImages } from "../../Constant";
import { useDispatch, useSelector } from "react-redux";
import { signOut } from "../../Features/user/authSlice";
import UserNameLength from "../../components/UserNameLength";
import {
  emptyUserFundraiser,
  getAllCategories,
} from "../../Features/fundraisers/fundraiserSlice";
import swal from "sweetalert";

function Header() {
  const dispatchRedux = useDispatch();

  const {
    auth: { user },
    fundraiser: { fundraiserCategories },
  } = useSelector((store) => store);
  const history = useLocation();
  const [navColor, setNavColor] = useState({ bg: "", text: "#", logo: "" });
  const [menu, setMenu] = useState(false);

  const [show, setShow] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const navigate = useNavigate();

  const [state, dispatch] = useContext(Context);
  const handleCloseSearch = () => setShowSearch(false);

  const handleShow = () => {
    navigate("/payment-fundraiser");
  };
  const handleShowSearch = () => {
    setMenu(false);
    setShowSearch(true);
  };
  const handleLogout = () => {
    swal({
      title: "Are you sure you want to logout?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatchRedux(signOut());
        dispatchRedux(emptyUserFundraiser());
        navigate("/");
      }
    });
  };

  const [notFound, setNotFound] = useState("");
  useEffect(() => {
    getcategoryData();
  }, []);
  useEffect(() => {
    dispatch({
      type: "SUCCESS_STORIES",
      payload: JSON.parse(localStorage.getItem("successStories")),
    });
    dispatch({
      type: "BLOGS",
      payload: JSON.parse(localStorage.getItem("blogs")),
    });
  }, []);

  const [allFundraiser1, setAllFundraiser1] = useState([]);
  const [allFundraiser2, setAllFundraiser2] = useState([]);
  const [loader, setLoader] = useState(false);

  const getcategoryData = async () => {
    dispatchRedux(getAllCategories());
  };
  const handleSearch = async (e) => {
    let value = e.target.value.toLowerCase();
    try {
      const res = await getFundraiseSearch(value);
      if (res?.status == 200) {
        let data = res?.data?.result;
        setAllFundraiser2(data);
        if (data.length == 0) {
          setNotFound(res?.data?.message);
        } else {
          setNotFound("");
        }
      }
    } catch (error) {
      console.log(error);
    }
    if (value.length > 2) {
      setAllFundraiser1(allFundraiser2);
    } else {
      setAllFundraiser1([]);
    }
  };
  const gotoFundraiserDetail = (slug) => {
    navigate(`/fundraiser/${slug}`);
    setShowSearch(false);
  };
  const listenScrollEvent = (e) => {
    if (history.pathname === "/") {
      if (window.scrollY > 5) {
        setNavColor({ bg: "white", text: "#002865", logo: "black" });
      }
    }
  };

  useEffect(() => {
    if (history.pathname === "/") {
      window.addEventListener("scroll", listenScrollEvent);
      setNavColor({ bg: "none", text: "white", logo: "white" });
    } else {
      setNavColor({ bg: "white", text: "#002865", logo: "black" });
    }
  }, [history.pathname]);

  const [currentColor, setCurrentColor] = useState(0);

  const colors = ["#002866", "#f9cb40"];

  const changeColor = () => {
    setCurrentColor((currentColor + 1) % colors.length);
  };

  useEffect(() => {
    const interval = setInterval(changeColor, 1000); // Change color every second
    return () => clearInterval(interval); // Cleanup function
  });
  // console.log(colors[currentColor]);
  return (
    <>
      <div className="d-none d-xl-block ">
        <Navbar
          collapseOnSelect
          expand="lg"
          variant="light"
          fixed="top"
          style={{ background: navColor.bg }}
          className="header__top ">
          <Container className="mobile__navbar mw-100 mx-lg-5">
            <Navbar.Brand>
              <div className="d-flex align-items-start">
                <HashLink smooth to={"/#home"}>
                  <img
                    src={navColor.logo === "white" ? LogoWhite : LogoBlack}
                    alt="header-logo"
                    className="navbar__logo"
                    loading="lazy"
                  />
                </HashLink>
                <div className="beta-tag">
                  {" "}
                  <Link
                    style={{
                      color: "#002866",
                    }}
                    smooth
                    to={"/beta"}>
                    Beta
                  </Link>
                </div>
              </div>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav1" />
            <Navbar.Collapse id="responsive-navbar-nav1">
              <Nav className="w-100">
                <ul className="header__menu mb-0">
                  <li></li>
                  {/* <li>
                    <Link
                      style={{color: navColor.text}}
                      className="menus"
                      to={"/ramadan-campaign"}
                    >
                      Ramadan Campaign
                    </Link>
                  </li> */}
                  {/* <li>
                    <Link
                      style={{ color: colors[currentColor] === "#f9cb40" ? "#002866" : "#fff", backgroundColor: colors[currentColor], padding: "7px", borderRadius: "8px", }}
                      className={`menus-${colors[currentColor].replace("#", "")}`}
                      to={"/ramadan-campaign"}
                    >
                      Ramadan Campaign
                    </Link>
                  </li>  */}
                  <li>
                    <Dropdown>
                      <Dropdown.Toggle
                        className="btnTransparent menus"
                        style={{ color: `${navColor.text}` }}>
                        <span
                          style={{ color: navColor.text }}
                          className="menus">
                          Support Fundraisers
                        </span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="border-0 shadow-lg dropdownMenuProfile ">
                        {fundraiserCategories &&
                          fundraiserCategories.map((cat, index) => {
                            const capitalizedSlug =
                              cat?.slug.charAt(0).toUpperCase() +
                              cat?.slug.slice(1).toLowerCase();
                            return (
                              <Dropdown.Item
                                key={index}
                                className={"activeFundraiseMenu"}>
                                <Link
                                  className="menus"
                                  to={`/${cat?.slug}-funding/${cat?.slug}`}>
                                  {capitalizedSlug} {"Funding"}
                                </Link>
                              </Dropdown.Item>
                            );
                          })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                  <li>
                    <Link
                      style={{ color: navColor.text }}
                      className="menus"
                      to={"/how-its-work"}>
                      How does it work
                    </Link>
                  </li>
                  <li>
                    <Link
                      style={{ color: navColor.text }}
                      className="menus"
                      to="/about-us">
                      About us
                    </Link>
                  </li>

                  <li>
                    <div
                      style={{ color: navColor.text }}
                      className="menus"
                      onClick={handleShowSearch}>
                      <i className="fa fa-search "></i>
                    </div>
                  </li>
                  {user ? (
                    <li>
                      <div className="menus">
                        <div
                          className="d-flex align-items-center justify-content-center"
                          style={{ color: navColor.text }}>
                          <Dropdown>
                            <Dropdown.Toggle
                              style={{ color: navColor.text }}
                              id="dropdown-basic"
                              className="btnTransparent menus">
                              {!loader && (
                                <img
                                  className="mx-2"
                                  style={{
                                    height: "35px",
                                    width: "35px",
                                    borderRadius: "50%",
                                  }}
                                  src={
                                    user.picture
                                      ? user.picture
                                      : staticImages.userDefaultImg
                                  }
                                  loading="lazy"
                                />
                              )}
                              <span
                                style={{ color: navColor.text }}
                                className="text-capitalize menus">
                                <UserNameLength
                                  username={user?.username}
                                  length={10}
                                />
                              </span>
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="border-0 shadow-lg dropdownMenuProfile">
                              <Dropdown.Item
                                className={"activeFundraiseMenu"}
                                href="#">
                                <Link
                                  className="d-block menus"
                                  to={"/dashboard/user-dashboard"}>
                                  Dashboard
                                </Link>
                              </Dropdown.Item>

                              <Dropdown.Item
                                className={"d-block activeFundraiseMenu menus"}
                                onClick={handleLogout}>
                                Logout
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </li>
                  ) : (
                    <li className="d-flex">
                      <Link to={"/login"}>
                        <button
                          className="btn__secondary shadow signin-button"
                          style={{
                            padding: "4px 8px",
                            color: navColor.text,
                            border: `1px solid ${navColor.text}`,
                            borderRadius: "8px",
                          }}>
                          Sign in
                        </button>
                      </Link>
                    </li>
                  )}
                </ul>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      <div className="d-block d-xl-none  fixed-top header__top bg-light">
        <div className="d-flex h-100 px-4 justify-content-between align-items-center navbar">
          <div className="d-flex align-items-start">
            <Link to={"/"}>
              <img
                src={LogoBlack}
                alt="header-logo"
                className="navbar__logo"
                loading="lazy"
              />
            </Link>
            <div className="beta-tag">
              {" "}
              <Link
                style={{
                  color: "#002866",
                }}
                smooth
                to={"/beta"}>
                Beta
              </Link>
            </div>
          </div>
          <div onClick={() => setMenu(!menu)}>
            <img
              src={menubar}
              alt="header-logo"
              style={{ width: "26px" }}
              loading="lazy"
            />
          </div>
        </div>
        {menu && (
          <div>
            <Nav className="mobile__menu">
              <ul className="header__menu">
                {/* <li>
                    <Link
                      style={{color: navColor.text}}
                      className="menus"
                      onClick={() => setMenu(false)}
                      to={"/ramadan-campaign"}
                    >
                      Ramadan Campaign
                    </Link>
                  </li> */}
                <li>
                  <Dropdown>
                    <Dropdown.Toggle className="btnTransparent menus">
                      <span className="menus">Support Fundraisers</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="border-0 shadow-lg dropdownMenuProfile ">
                      {fundraiserCategories &&
                        fundraiserCategories.map((cat) => {
                          const capitalizedSlug =
                            cat?.slug.charAt(0).toUpperCase() +
                            cat?.slug.slice(1).toLowerCase();
                          return (
                            <Dropdown.Item
                              onClick={() => setMenu(!menu)}
                              key={cat._id}
                              className={"activeFundraiseMenu"}>
                              <Link
                                className="menus"
                                to={`/${cat.slug}-funding/${cat.slug}`}>
                                {capitalizedSlug} {"Funding"}
                              </Link>
                            </Dropdown.Item>
                          );
                        })}
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
                <li>
                  <Link
                    className="menus"
                    onClick={() => setMenu(false)}
                    to="/how-its-work">
                    How does it work
                  </Link>
                </li>
                <li>
                  <Link
                    className="menus"
                    onClick={() => setMenu(false)}
                    to="/about-us">
                    About us
                  </Link>
                </li>
                {user ? (
                  <li>
                    <div className="menus">
                      <div
                        className="d-flex align-items-center "
                        style={{ color: navColor.text }}>
                        <Dropdown>
                          <Dropdown.Toggle
                            style={{ color: navColor.text }}
                            id="dropdown-basic"
                            className="btnTransparent menus">
                            {!loader && (
                              <img
                                className="me-2"
                                style={{
                                  height: "35px",
                                  width: "35px",
                                  borderRadius: "50%",
                                }}
                                src={
                                  user.picture
                                    ? user.picture
                                    : staticImages.userDefaultImg
                                }
                                alt=""
                                loading="lazy"
                              />
                            )}
                            <span className="text-capitalize menus">
                              {" "}
                              <UserNameLength
                                username={user?.username}
                                length={15}
                              />
                            </span>
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="border-0 shadow-lg dropdownMenuProfile">
                            <Dropdown.Item
                              className={"activeFundraiseMenu"}
                              href="#">
                              <Link
                                onClick={() => setMenu(false)}
                                className="d-block menus"
                                to={"/dashboard/user-dashboard"}>
                                Dashboard
                              </Link>
                            </Dropdown.Item>

                            <Dropdown.Item
                              className={"d-block activeFundraiseMenu menus"}
                              onClick={handleLogout}>
                              Logout
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </li>
                ) : (
                  <li className="d-flex">
                    <Link to={"/login"}>
                      <button
                        onClick={() => setMenu(false)}
                        className="btn__secondary shadow "
                        style={{
                          padding: "4px 8px",
                          color: "#002865",
                          border: `1px solid #002865`,
                          borderRadius: "8px",
                        }}>
                        Sign in
                      </button>
                    </Link>
                  </li>
                )}
              </ul>
            </Nav>
          </div>
        )}
      </div>

      {/* Modal start */}
      <SideModalDonation show={show} onHide={() => setShow(false)} />

      <Modal
        className="search__modal px-0"
        show={showSearch}
        onHide={handleCloseSearch}>
        <Container>
          <ModalHeader
            style={{ padding: "50px 100px  0px 50px" }}
            className="border-0"
            closeButton>
            <div className="col-md-10 mx-auto">
              <ModalTitle>Search Fundraisers</ModalTitle>
            </div>
          </ModalHeader>
          <ModalBody style={{ padding: "20px 0 50px" }}>
            <div className="col-md-10 mx-auto">
              <div class="input-group mb-3">
                <input
                  name="name"
                  placeholder="Search for Fundraisers"
                  onChange={(event) => handleSearch(event)}
                  type="text"
                  class="form-control"
                  aria-label="Search Something..."
                  aria-describedby="basic-addon2"
                />
              </div>
              <ul className="searchValue">
                {allFundraiser1 && allFundraiser1?.length > 0 ? (
                  <>
                    {allFundraiser1?.map((item, index) => {
                      return (
                        <>
                          <li
                            key={index}
                            onClick={() => gotoFundraiserDetail(item?.slug)}>
                            {" "}
                            {item?.title}{" "}
                            {allFundraiser1?.urgent && (
                              <span className="urgentvalue">Urgent</span>
                            )}
                          </li>
                          <hr className="my-2" />
                        </>
                      );
                    })}
                  </>
                ) : (
                  <>{notFound}</>
                )}
              </ul>
            </div>
          </ModalBody>
        </Container>
      </Modal>
      {/* modal end */}
    </>
  );
}

export default Header;
