import React, {useEffect, useState} from "react";
import {Container} from "react-bootstrap";
import {useParams} from "react-router-dom";
import "../../assets/css/MedicalFunding.css";
import testimonialBackground from "../../assets/img/Testimonials.jpg";
import OwlCarousel from "react-owl-carousel";
import TestimonialCard from "../../components/TestimonialCard";
import Accordion from "react-bootstrap/Accordion";
import {constantIcons, constantImages} from "../../Constant";
import {getFundraiseByCategorySlug} from "../../Api-Calls/categoryApi";
import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FundingForm from "../../components/FundingForm";
import FundraiserBreadCrumb from "../../components/FundraiserBreadCrumb";
import WhatBuildingBridgesOffer from "../../components/staticComponents/WhatBuildingBridgesOffer";
import ActiveFundraiser from "../../components/ActiveFundraiser";
import webBanner from "../../assets/img/staticImages/animal-crowdfunding-images/animal-banner.png";
import mobileBanner from "../../assets/img/staticImages/animal-crowdfunding-images/animal-banner-mobile.png";
import mobileImage from "../../assets/img/staticImages/animal-crowdfunding-images/mobile-image.png";
import {Helmet} from "react-helmet";
import HelmetSEO from "../../components/HelmetSEO";
import WhyChooseBuildingBridges from "../../components/WhyChooseBuildingBridges";
import SupportFundraiserFaqQuestion from "../../components/SupportFundraiserFaqQuestion";

const AnimalFunding = () => {
  const {slug} = useParams();
  const images = constantImages();
  const icons = constantIcons();
  const [fundraiser, setFundraiser] = useState([]);
  const getFundraisebyCategotyId = async () => {
    try {
      const res = await getFundraiseByCategorySlug(slug);
      if (res?.status == 200) {
        setFundraiser(res?.data?.result?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getFundraisebyCategotyId();
  }, []);

  return (
    <>
      <HelmetSEO title="Animal Funding" description="Animal Funding" />
      <ToastContainer />

      <FundraiserBreadCrumb
        heading="Animal Funding"
        description={
          <p>
            Building Bridges unites animal lovers and advocates to protect and
            care for the magnificent beings on our planet. Join us in our
            mission to ensure the well-being of animals and create a
            compassionate world for them.
          </p>
        }
        webImage={images.animalSection3}
        mobileImage={mobileImage}
      />
      <ActiveFundraiser fundraiser={fundraiser} slug={slug} />
      <WhyChooseBuildingBridges
        mainHeading="Animal"
        icon1={icons.animalChooseIcon1}
        icon2={icons.animalChooseIcon2}
        icon3={icons.animalChooseIcon3}
        icon4={icons.animalChooseIcon4}
        heading1="Support for Expensive Treatments"
        heading2="Empowering Financial Aid Seekers"
        heading3="Emergency, Rescue, Rehabilitation"
        heading4="Building a Compassionate Community"
        description1="Building Bridges provides support to pet owners, caretakers, animal rights advocates & various organizations to create fundraisers for medical treatments of suffering animals."
        description2="Building Bridges empowers the financial aid seekers by providing them with the option of hassle free online fundraising. The collected amount can be withdrawn anytime."
        description3="Building Bridges enables efficient fundraising for emergencies and rehabilitation of animals after rescue. Fundraisers can be also generated for animal conservation initiatives."
        description4=" Building Bridges aims to create a compassionate community that joins our mission to ensure protection, care and well-being of the lovely creatures with whom we share the world."
      />

      <section className="">
        <FundingForm
          title="Building Bridges for Animal Funding"
          paragraph={`
            Building Bridges allows you to create fundraisers for animals in three easy steps. 
            This process allows animal lovers and advocates to create fundraisers for emergencies, 
            rescue, rehabilitation, various animal conservation initiatives to ensure well-being of the animals.
            `}
          mobileBanner={mobileBanner}
          webBanner={webBanner}
        />
      </section>

      <WhatBuildingBridgesOffer />

      <section
        className=" testimonialSection py-5 section--gray"
        style={{
          backgroundImage: `url(${testimonialBackground}) `,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
        }}
      >
        <div className="sectionHeading text-center mb-5">
          <h2 className="mb-4 high-title title-background mt-5">
            Success Stories
          </h2>
          <div className="col-md-7 mx-auto divider"></div>
        </div>
        <div>
          <div className="sectionHeading text-center mb-5"></div>
          <div className="container">
            <div className="col-md-9 mx-auto ">
              <OwlCarousel
                items={1}
                margin={30}
                autoplay={true}
                className="owl-theme fundRaisingSection testimonial-background"
              >
                <TestimonialCard image="false" />
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
      <SupportFundraiserFaqQuestion
        heading1="What are the Animal funding projects and who do these projects benefit?"
        heading2="Can I choose which animal projects to support?"
        heading3="Do you work with reputable animal organizations?"
        heading4="Can I donate in memory of a beloved pet?"
        heading5=" Can I volunteer for any animal welfare project that is underway?"
        heading6="How can I get involved beyond donating?"
        description1="Building Bridges is an online funding platform which is
        dedicated to raising funds for protection, well-being, and
        maintenance of pets and stray animals. Various individuals,
        animal rights and rescue organizations, foundations and
        trusts can create campaigns for animals on our platform.
        Supporters can then contribute funds online, and the
        collected donations are used to meet the needs of the
        animals."
        description2="Yes, Building Bridges allows the donors and supporters to
        browse and choose from a variety of animal-related
        fundraisers that have been created by various individuals,
        animal rights and rescue organizations, foundations and
        trusts. As a donor you can browse through the created
        fundraisers and select to support the ones that resonate the
        most with you."
        description3=" Yes, Building Bridges aims to partner with individuals and
        various established and credible animal charities to ensure
        that the donations made by the public are used in making a
        meaningful impact on animal welfare."
        description4="Building Bridges is a platform that aims to unite animal
        lovers and advocates to create a compassionate world for
        them. In the loving memory of their pet, any pet owner can
        create a fundraiser that supports an animal welfare project.
        Likewise, donors are given an opportunity to choose to
        honour the memory of their pets by contributing to already
        trending fundraisers on our platform."
        description5="Building Bridges primarily focuses on creating and
        supporting fundraisers, however some animal projects may
        offer volunteer opportunities. For this purpose, the
        interested volunteers are always welcome to contact the
        respective animal rights and rescue organizations,
        foundations and trusts in order to explore the possibilities
        of assisting the magnificent beings who share the world with
        us."
        description6="Apart form donating, you can always create a fundraiser for
        supporting any cause that is either close to your heart or
        has been recommended to you by someone you know. You can
        become a part of our fundraising campaigns by sharing the
        fundraisers on your personal social media accounts and
        encourage your friends and loved ones to do the same.
        Volunteering to spread awareness and encourage potential
        donors to support our platform and give special attention to
        the Animal Funding charity page is also an option. Building
        Bridges offers you the opportunity to create a fundraiser in
        the loving memory of your pet as well."
      />
    </>
  );
};

export default AnimalFunding;
