import {Helmet} from "react-helmet";

const HelmetSEO = ({title, description}) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta name="description" content={description} />
    </Helmet>
  );
};

export default HelmetSEO;
